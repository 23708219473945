import { useEffect } from "react";
import { API_SITE_URL } from "config";

const useTrackPOPEvent = (encriptedClientId: string) => {
  const trackPOPEvent = async (encriptedClientId: string) => {
    try {
      const response = await fetch(`${API_SITE_URL}/trackPOPEvent`, {
        method: "POST",
        body: JSON.stringify({ encriptedClientId }),
      });
      if (!response.ok) {
        console.debug(response.statusText);
        throw new Error("Error fetching trackPOPEvent service");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (encriptedClientId) {
      trackPOPEvent(encriptedClientId);
    }
  }, []);
};

export default useTrackPOPEvent;
