import React from "react";
import SvgLogo from "img/storydots-logo.svg";

function Logo(props: {
  src?: string;
  className?: string;
  style?: React.CSSProperties;
  logo?: string;
}) {
  return (
    <img
      src={props.logo || SvgLogo}
      alt="storydots-logo"
      style={{ width: "200px" }}
      {...props}
    />
  );
}

export default Logo;
