import { styled } from "@mui/system";

export const Video = styled("video")(() => ({
  zIndex: 3,
  position: "absolute",
  top: "245px",
  left: "calc(50% - 600px)",
  width: "1200px",
  maxWidth: "100%",
  "@media (max-width: 1200px)": {
    left: "0px",
  },
  "@media (max-width: 770px)": {
    top: "250px",
    left: "5%",
    maxWidth: "90%",
    height: "700px",
  },
  "@media (max-width: 500px)": {
    top: "calc(230px + ((50px + (100vw - 500px) * 0.1) * 1.2) * 2 + (100vw - 500px) * 0.7)", // 230px (container padding top + distance from nav to title + title line height * 2) + video resize (negative number)
  },
  "@media (max-width: 350px)": {
    top: "calc(260px + ((50px + (100vw - 500px) * 0.1) * 1.2) * 2 + (100vw - 500px) * 0.7)", // 260px (container padding top + distance from nav to title + title line height * 2) + video resize (negative number)
  }
}));

export const Image = styled("img")(() => ({
  zIndex: 3,
  position: "absolute",
  top: "245px",
  left: "calc(50% - 600px)",
  width: "1200px",
  maxWidth: "100%",
  "@media (max-width: 1200px)": {
    position: "initial",
    left: "0px",
  },
  "@media (max-width: 770px)": {
    top: "250px",
    left: "5%",
    maxWidth: "90%",
    height: "700px",
    width: 400,
  },
  "@media (max-width: 500px)": {
    top: "calc(230px + ((50px + (100vw - 500px) * 0.1) * 1.2) * 2 + (100vw - 500px) * 0.7)", // 230px (container padding top + distance from nav to title + title line height * 2) + video resize (negative number)
  },
  "@media (max-width: 350px)": {
    top: "calc(260px + ((50px + (100vw - 500px) * 0.1) * 1.2) * 2 + (100vw - 500px) * 0.7)", // 260px (container padding top + distance from nav to title + title line height * 2) + video resize (negative number)
  }
}));