import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  maxWidth: "1127px",
  marginBottom: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media (max-width: 1150px)": {
    marginBottom: "15px",
  },
  "@media (max-width: 625px)": {
    marginBottom: "5px",
  },
  "@media (max-width: 430px)": {
    marginBottom: "50px",
  },
  "@media (max-width: 350px)": {
    marginBottom: "10px",
  },
}));

export const FallbackContainer = styled(Box)(() => ({
  width: "100%",
  height: "130px",
  marginBottom: "30px",
  background: "transparent",
  "@media (max-width: 1150px)": {
    height: "203px",
    marginBottom: "15px",
  },
  "@media (max-width: 625px)": {
    height: "276px",
    marginBottom: "5px",
  },
  "@media (max-width: 430px)": {
    height: "250px",
    marginBottom: "50px",
  },
  "@media (max-width: 350px)": {
    height: "360px",
    marginBottom: "10px",
  },
}));

export const Description = styled(Typography)(() => ({
  maxWidth: "85%",
  margin: "0px auto 30px auto",
  color: "rgba(0, 0, 0, 1)",
  textAlign: "center",
  fontSize: "20px",
  fontWeight: 300,
  lineHeight: "27.32px",
  "@media (max-width: 350px)": {
    fontSize: "18px",
  }
}));

export const LogosContainer = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  "@media (max-width: 1150px)": {
    maxWidth: "800px",
  },
  "@media (max-width: 625px)": {
    maxWidth: "500px",
  },
  "@media (max-width: 430px)": {
    maxWidth: "360px",
  },
  "@media (max-width: 350px)": {
   flexDirection: "column",
   alignItems: "center",
  },
}));

export const PlatformLogo = styled("img")(() => ({
  margin: "0px 35px 35px 35px",
  width: "fit-content",
  maxHeight: "38px",
  "@media (max-width: 625px)": {
    margin: "0px 25px 35px 25px",
  },
  "@media (max-width: 464px)": {
    margin: "0px 15px 35px 15px",
  },
  "@media (max-width: 430px)": {
    maxHeight: "30px",
    margin: "0px 10px 25px 10px",
  },
}));
