import { useTheme } from "@mui/material";

const Quotes = () => {
  const theme = useTheme();

  return (
    <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.99 3.29881C8.01 2.03881 9.09 1.34881 10.23 1.22881C11.43 1.04881 12.42 1.25881 13.2 1.85881C14.04 2.45881 14.52 3.32881 14.64 4.46881C14.76 5.54881 14.28 6.74881 13.2 8.06881C12.36 9.02881 11.7 9.98881 11.22 10.9488C10.74 11.8488 10.35 12.7488 10.05 13.6488C12.51 13.8888 14.46 14.7888 15.9 16.3488C17.4 17.8488 18.15 19.8588 18.15 22.3788C18.15 24.9588 17.34 27.0588 15.72 28.6788C14.16 30.2388 12 31.0188 9.24 31.0188C6.48 31.0188 4.32 30.0888 2.76 28.2288C1.2 26.3088 0.42 23.6688 0.42 20.3088C0.42 17.7888 0.81 15.1788 1.59 12.4788C2.37 9.77881 4.17 6.71881 6.99 3.29881ZM27.69 3.29881C28.71 2.03881 29.79 1.34881 30.93 1.22881C32.13 1.04881 33.12 1.25881 33.9 1.85881C34.74 2.45881 35.22 3.32881 35.34 4.46881C35.46 5.54881 34.98 6.74881 33.9 8.06881C33.06 9.02881 32.4 9.98881 31.92 10.9488C31.44 11.8488 31.05 12.7488 30.75 13.6488C33.21 13.8888 35.16 14.7888 36.6 16.3488C38.1 17.8488 38.85 19.8588 38.85 22.3788C38.85 24.9588 38.04 27.0588 36.42 28.6788C34.86 30.2388 32.7 31.0188 29.94 31.0188C27.18 31.0188 25.02 30.0888 23.46 28.2288C21.9 26.3088 21.12 23.6688 21.12 20.3088C21.12 17.7888 21.51 15.1788 22.29 12.4788C23.07 9.77881 24.87 6.71881 27.69 3.29881Z" fill={theme.palette.primary.main}/>
    </svg>
  );
}

export default Quotes;