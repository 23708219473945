import { styled } from "@mui/system";
import { Box } from "@mui/material";

const bandWidth = 1394;
const leftTransform = "translateX(0px)";
const rightTransform = `translateX(-${bandWidth}px)`;

export const Container = styled(Box)(() => ({
  position: "relative",
  width: `${bandWidth + 2}px`,
  padding: "0px 1px",
  maxWidth: "90vw",
  height: "460px",
  minHeight: "460px",
  overflow: "hidden",
  borderLeft:"1px solid white",

}));

export const ConveyorBand = styled(
  Box,
  { shouldForwardProp: (prop) => !["leftSelected"].includes(prop as string) }
)<{ leftSelected: boolean; }>(
  ({ leftSelected }) => ({
    position: "absolute",
    top: "6px",
    left: "0px",
    display: "flex",
    transform: `${leftSelected ? leftTransform : rightTransform}`,
    transition: "0.2s all",
    "@media (max-width: 1550px)": {
      transform: `${leftSelected ? leftTransform : "translateX(-90vw)"}`,
    }
  })
);

export const LeftFade = styled(Box)(() => ({
  zIndex: 10,
  position: "absolute",
  left: "-3px",
  width: "20px",
  height: "calc(100% - 40px)",
  background: "linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5))",
  filter: "blur(2px)",
}));

export const RightFade = styled(LeftFade)(() => ({
  left: "unset",
  right: "-3px",
  background: "linear-gradient(-90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5))",
}));
