import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  minWidth: "700px",
  display: "flex",
  "@media (max-width: 1540px)": {
    minWidth: "unset",
    maxWidth: "100%",
  },
  "@media (max-width: 859px)": {
    width: "700px",
    marginBottom: "40px",
  },
  "@media (max-width: 500px)": {
    width: "700px",
    marginBottom: "20px",
  },
}));

export const InnerContainer = styled(Box)(() => ({
  margin: "240px auto auto auto",
  "@media (max-width: 1500px)": {
    margin: "40px auto auto auto",
  },
  "@media ((max-width: 1500px) and (min-width: 860px)) or (max-width: 650px)": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const TitleContainer = styled(Box)(() => ({
  "@media (max-width: 1500px) and (min-width: 860px)": {
    display: "flex",
  },
  "@media (max-width: 650px)": {
    marginBottom: "25px",
    padding: "0px 10px",
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: "40px",
  fontWeight: 400,
  lineHeight: "47.6px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  "@media (max-width: 950px) and (min-width: 860px)": {
    fontSize: "35px",
    lineHeight: "42px",
  },
  "@media (max-width: 650px)": {
    textAlign: "center",
    fontSize: "calc(8px + 4vw)",
    lineHeight: "calc(15px + 4vw)",
  },
}));

export const TitleSecondLine = styled(Title)(() => ({
  fontWeight: 800,
  marginBottom: "15px",
  "@media (max-width: 1500px) and (min-width: 860px)": {
    marginLeft: "10px",
  },
}));

export const Subtitle = styled(
  Typography,
  { shouldForwardProp: (prop) => !["isPT", "isEN"].includes(prop as string) }
)<{ isPT: boolean; isEN: boolean }>(
  ({ isPT, isEN }) => ({
    width: `${isEN ? "365px" : isPT ? "450px" : "400px"}`,
    maxWidth: "100%",
    marginBottom: "30px",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "35px",
    "@media (max-width: 1500px) and (min-width: 860px)": {
      width: "100%",
      marginBottom: "10px",
      textAlign: "center",
    },
    "@media (max-width: 650px)": {
      textAlign: "center",
    },
    "@media (max-width: 430px)": {
      width: "95%",
    },
  })
);

export const LastSubtitle = styled(Subtitle)(() => ({
  marginBottom: "25px",
  "@media (max-width: 1500px)": {
    marginBottom: "40px",
  },
}));

export const ActionsContainer = styled(Box)(() => ({
  display: "flex",
  gap: "20px",
  "@media (max-width: 1500px)": {
    justifyContent: "center",
  },
  "@media (max-width: 859px) and (min-width: 651px)": {
    justifyContent: "flex-start",
  },
  "@media (max-width: 500px)": {
    flexDirection: "column",
    alignItems: "center",
  },
}));
