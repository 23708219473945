import React, { FC } from "react";
import {
  Container,
  StyledIconButton,
} from "./styledComponents";
import useGetSocialMediaArray from "./useGetSocialMediaArray";

const FooterSocialMedia: FC = () => {
  const socialMediaArray = useGetSocialMediaArray();

  return (
    <Container component="ul">
      {socialMediaArray.map((media, id) => {
        return (
          <li key={`socialMediaLinkIcon${id}`}>
            <a
              href={media.link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={media.aria}
            >
              <StyledIconButton
                tabIndex={-1}
                aria-label={media.aria}
              >
                {<media.icon />}
              </StyledIconButton>
            </a>
          </li>
        );
      })}
    </Container>
  );
};

export default FooterSocialMedia;
