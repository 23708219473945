import ACFLogo from "./ACF-logo.svg";
import ACFLogoDisabled from "./ACF-logo-disabled.svg";
import aoniLogo from "./aoni-logo.svg";
import aoniLogoDisabled from "./aoni-logo-disabled.svg";
import gubeeLogo from "./gubee-logo.svg";
import gubeeLogoDisabled from "./gubee-logo-disabled.svg";
import kiricochoLogo from "./kiricocho-logo.svg";
import kiricochoLogoDisabled from "./kiricocho-logo-disabled.svg";
import miniAnimaLogo from "./mini-anima-logo.svg";
import miniAnimaLogoDisabled from "./mini-anima-logo-disabled.svg";
import nicLogo from "./nic-logo.svg";
import nicLogoDisabled from "./nic-logo-disabled.svg";
import padelstoreLogo from "./padelstore-logo.svg";
import padelstoreLogoDisabled from "./padelstore-logo-disabled.svg";
import peterpanLogo from "./peterpan-logo.svg";
import peterpanLogoDisabled from "./peterpan-logo-disabled.svg";
import pompavanaLogo from "./pompavana-logo.svg";
import pompavanaLogoDisabled from "./pompavana-logo-disabled.svg";
import repitucoLogo from "./repituco-logo.svg";
import repitucoLogoDisabled from "./repituco-logo-disabled.svg";

export const supportCompaniesObj = {
  ACF: {
    logo: ACFLogo,
    disabled: ACFLogoDisabled,
  },
  aoni: {
    logo: aoniLogo,
    disabled: aoniLogoDisabled,
  },
  gubee: {
    logo: gubeeLogo,
    disabled: gubeeLogoDisabled,
  },
  kiricocho: {
    logo: kiricochoLogo,
    disabled: kiricochoLogoDisabled,
  },
  miniAnima: {
    logo: miniAnimaLogo,
    disabled: miniAnimaLogoDisabled,
  },
  nic: {
    logo: nicLogo,
    disabled: nicLogoDisabled,
  },
  padelstore: {
    logo: padelstoreLogo,
    disabled: padelstoreLogoDisabled,
  },
  peterpan: {
    logo: peterpanLogo,
    disabled: peterpanLogoDisabled,
  },
  pompavana: {
    logo: pompavanaLogo,
    disabled: pompavanaLogoDisabled,
  },
  repituco: {
    logo: repitucoLogo,
    disabled: repitucoLogoDisabled,
  },
};

// This array is used for doing the layout in an specified order
export const supportCompaniesArray = [
  {
    logo: aoniLogo,
    disabled: aoniLogoDisabled,
    link: "https://www.aoni.com.ar/",
  }, {
    logo: pompavanaLogo,
    disabled: pompavanaLogoDisabled,
    link: "https://www.pompavana.me/",
  }, {
    logo: miniAnimaLogo,
    disabled: miniAnimaLogoDisabled,
    link: "https://www.minianima.com.ar/",
  }, {
    logo: kiricochoLogo,
    disabled: kiricochoLogoDisabled,
    link: "https://kiricocho.com.ar/",
  }, {
    logo: gubeeLogo,
    disabled: gubeeLogoDisabled,
    link: "https://www.gubee.com.ar/",
  }, {
    logo: repitucoLogo,
    disabled: repitucoLogoDisabled,
    link: "https://repituco.com/",
  }, {
    logo: peterpanLogo,
    disabled: peterpanLogoDisabled,
    link: "https://peterpan.com.ar/",
  }, {
    logo: ACFLogo,
    disabled: ACFLogoDisabled,
    link: "https://www.mascarasacf.com.ar/",
  }, {
    logo: nicLogo,
    disabled: nicLogoDisabled,
    link: "https://queesnic.com/",
  }, {
    logo: padelstoreLogo,
    disabled: padelstoreLogoDisabled,
    link: "https://padelstore.com.ar/",
  }
];
