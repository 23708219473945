import React, { FC } from "react";
import { MeetingsCalendar, SignalNoiseTextureBG, SuccessCases } from "components";
import {
  Container,
  GradientBackground,
  OverflowBand,
} from "./styledComponents";

const SuccessAndCalendar: FC = () => {
  return (
    <Container>
      <SignalNoiseTextureBG />
      <GradientBackground />
      <SuccessCases />
      <MeetingsCalendar />
      <OverflowBand />
    </Container>
  );
};

export default SuccessAndCalendar;
