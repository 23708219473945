import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const StartupMidContainer = styled(Box)`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 375px) {
    margin-bottom: 20px;
  }
`;

export const StartupMidUpperText = styled(Typography)`
  width: 100%;
  margin-top: 36px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  @media (max-width: 375px) {
    margin-top: 30px;
    margin-bottom: 5px;
  }
`;

export const StartupMidLowerText = styled(Typography)`
  width: 100%;
  margin-top: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.02px;
  letter-spacing: 0.15px;
`;
