import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  marginBottom: "32px",
}));

export const FallbackContainer = styled(Box)(() => ({
  width: "1115px",
  maxWidth: "calc(100vw - 130px)",
  height: "367px",
  marginBottom: "70px",
  background: "rgba(255, 255, 255, 1)",
  borderRadius: "32px",
  "@media (max-width: 770px)": {
    width: "330px",
    height: "560px",
  },
  "@media (max-width: 475px)": {
    maxWidth: "calc(100vw - 30px)",
  },
}));
