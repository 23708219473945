import { FC, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { GeneralContext } from "context";
import { useTranslation } from "react-i18next";
import LaunchIcon from '@mui/icons-material/Launch';
import {
  StyledButton,
} from "./styledComponents";

interface Props {
  colorType?: "primaryBG" | "secondaryBG" | "contrastBG"
  margins?: string;
  text?: string;
  className?: string;
  to?: string
  reload?: boolean
};

const ScheduleButton: FC<Props> = ({
  colorType = "primaryBG",
  margins = "0px",
  text,
  className,
  to,
  reload
}) => {
  const { bookDemoRef } = useContext(GeneralContext);
  const { t } = useTranslation("NavBar");
  const location = useLocation();
  const isMain = location.pathname === "/";

  const handleClickAtMain = () => {
    if(isMain) {
      const calendarTop = bookDemoRef.current?.getBoundingClientRect().top
      window.scrollTo({
        top: window.scrollY + calendarTop,
        behavior: 'smooth',
      });
    } else if (reload) {
      setTimeout(( ) => {
        const calendarTop = bookDemoRef.current?.getBoundingClientRect().top
        window.scrollTo({
          top: window.scrollY + calendarTop,
          behavior: 'smooth',
        });
      }, 500)
    }
  };

  return (
    <Link to={to || "/regala"}>
      <StyledButton
        endIcon={<LaunchIcon />}
        className={className}
        colorType={colorType}
        margins={margins}
        onClick={handleClickAtMain}
        tabIndex={-1}
      >
        {text || t("Gift")}
      </StyledButton>
    </Link>
  );
};

export default ScheduleButton;
