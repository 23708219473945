import { useTranslation } from "react-i18next";
import posters from "img/integrations";
import { SCHEDULE_CALL } from "config";

interface IPlatform {
  name?: string;
  imageSrc: string;
  primaryAction: {
    link: string;
    text: string;
    color?: string;
  };
  secondaryAction?: {
    link: string;
    text: string;
    color?: string;
  };
  banner?: string;
}

const useGetPlatformsArray = () => {
  const { t, i18n } = useTranslation("Integrations");
  const currentLocale = i18n.language;

  const platformsArray: IPlatform[] = [
    {
      name: "vtex",
      imageSrc: posters.vtex,
      primaryAction: {
        link: "https://vtex.io/docs/app/storydotspartnerar.storydots-virtual-greeting@0.x/",
        text: t("Showcase.seeDocumentation"),
      },
      secondaryAction: {
        link: SCHEDULE_CALL,
        text: t("Showcase.bookADemo"),
      },
    },
    {
      name: "magento",
      imageSrc: posters.magento,
      primaryAction: {
        link: SCHEDULE_CALL,
        text: t("Showcase.bookADemo"),
      },
    },
    {
      name: "tiendanube",
      imageSrc: currentLocale.includes("pt-")
        ? posters.nuvemshop
        : posters.tiendanube,
      primaryAction: {
        link: currentLocale.includes("pt-")
          ? "https://www.nuvemshop.com.br/loja-aplicativos-nuvem/storydots"
          : "https://www.tiendanube.com/tienda-aplicaciones-nube/storydots",
        text: t("Showcase.install"),
      },
      secondaryAction: {
        link: SCHEDULE_CALL,
        text: t("Showcase.bookADemo"),
      },
    },
    {
      name: "shopify",
      imageSrc: posters.shopify,
      primaryAction: {
        link: "https://apps.shopify.com/storydots",
        text: t("Showcase.install"),
      },
      secondaryAction: {
        link: SCHEDULE_CALL,
        text: t("Showcase.bookADemo"),
      },
    },
    {
      name: "api",
      imageSrc: posters.api,
      primaryAction: {
        link: "https://github.com/Storydots/api",
        text: t("Showcase.install"),
      },
      secondaryAction: {
        link: SCHEDULE_CALL,
        text: t("Showcase.bookADemo"),
      },
    },
    {
      name: "woocommerce",
      imageSrc: posters.woocommerce,
      primaryAction: {
        link: SCHEDULE_CALL,
        text: t("Showcase.bookADemo"),
      },
      banner: "Q4 2023",
    },
  ];

  return platformsArray;
};

export default useGetPlatformsArray;
