import React, { FC } from "react";
import { Carousel, ReviewCard } from "components";
import {
  Container,
} from "./styledComponents";
import useOptionsAndResponsiveness from "./useOptionsAndResponsiveness";
import useGetCardsArray from "./useGetCardsArray";

const ReviewsCarousel: FC = () => {
  const cardsArray = useGetCardsArray();
  const {
    cardWidth,
    cardHeight,
    navButtons,
    leftMargin,
    rightMargin,
  } = useOptionsAndResponsiveness();

  return (
    <Container>
      <Carousel
        cardWidth={cardWidth}
        cardHeight={cardHeight}
        navButtons={navButtons}
        leftMargin={leftMargin}
        rightMargin={rightMargin}
      >
        {cardsArray.map((card:any, id:number) => {
          return(
            <ReviewCard
              card={card}
              key={`reviewCardNumber${id}`}
            />
          )
        })}
      </Carousel>
    </Container>
  );
};

export default ReviewsCarousel;
