import { FC } from "react";
import getStyledComponents from './getStyledComponents';
import {
  PlanBottomItems,
  PlanSubscribeButton,
  PriceSection,
  DiscountPriceSection,
  ScalePlanMidSection,
  StartupPlanMidSection,
  UnlimitedPlanMidSection
} from 'components';
import glow from 'img/pricingPlans/glow.svg';
import { countriesData } from "helpers";

interface IProps {
  plan: "scale" | "startup" | "unlimited"
}

const PricingCard: FC<IProps> = ({plan}) => {
  const isScale = plan === "scale";
  const isStartup = plan === "startup";
  const highlightCard = isStartup;
  const {
    BorderContainer,
    Container,
    GiftsImage,
    HeaderContainer,
    GlowImage,
    CardTitle,
  } = getStyledComponents(isScale, isStartup, highlightCard);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const country = params.get("country")?.toLowerCase() || 'ar';
  const planName = countriesData[country as keyof typeof countriesData][plan].planName

  return (
    <BorderContainer>
      <Container>
        <GiftsImage
          src={require(`img/pricingPlans/${plan}Gifts.png`).default}
          alt={`${plan} plan Gifts`}
        />
        <HeaderContainer>
          <GlowImage src={glow} alt={`${plan} plan glow`} />
          <CardTitle variant="h2">{planName}</CardTitle>
        </HeaderContainer>
        {/*country === 'ar' ? <DiscountPriceSection plan={plan} /> : */<PriceSection plan={plan} />}
        {isScale
          ? <ScalePlanMidSection />
          : isStartup
            ? <StartupPlanMidSection />
            : <UnlimitedPlanMidSection />
        }
        <PlanBottomItems plan={plan} isHighlighted={highlightCard} />
        <PlanSubscribeButton plan={plan} country={country} />
      </Container>
    </BorderContainer>
  );
};

export default PricingCard;