import { styled } from "@mui/system";
import { Box, Theme } from "@mui/material";
import bgPattern from "img/bgPattern.svg";

const Container = styled(Box)(
  ({
    theme,
  }: {
    theme: Theme & { layout?: { backgroundPattern: boolean } };
  }) => ({
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100svh",
    padding: 2,
    backgroundImage: theme.layout?.backgroundPattern
      ? `linear-gradient(
    rgba(255, 255, 255, 0.82),
    rgba(255, 255, 255, 0.82)
  ),
  url(${bgPattern});`
      : "none",
    backgroundSize: "200px",
  })
);

export default Container;
