import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  zIndex: 1,
  position: "relative",
  width: "100%",
  maxWidth: "100%",
  height: "1420px",
  paddingTop: "113px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  background: "transparent",
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: "4px",
  color: theme.palette.text.primary,
  fontSize: "60px",
  fontWeight: 800,
  lineHeight: "60px",
  letterSpacing: "-1.5px",
  textAlign: "center",
  "@media (max-width: 770px)": {
    fontSize: "calc(60px + (100vw - 770px) * 0.08)",
    lineHeight: "calc(60px + (100vw - 770px) * 0.08)",
  },
}));

export const TitleSecondLine = styled(Title)(({ theme }) => ({
  height: "70px",
  marginBottom: "75px",
  backgroundImage: `linear-gradient(-3deg, ${theme.palette.primary.main} 25%, ${theme.palette.secondary.main})`,
  backgroundClip: "text",
  color: "transparent",
  "@media (max-width: 770px)": {
    height: "calc(70px + (100vw - 770px) * 0.08)",
    marginBottom:"calc(75px + (100vw - 770px) * 0.08)",
  },
}));
