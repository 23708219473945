import { styled } from "@mui/material";
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  SwipeableDrawer,
} from "@mui/material";
import { keyframes } from '@emotion/react'

const appear = keyframes({
  "0%": {
    transform: "translateX(-400%)",
    opacity: "0.000001",
  },
  "100%": {
    transform: "translateX(0%)",
    opacity: "1",
  },
});

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(() => ({
  color: "rgba(255, 255, 255, 1)",
  "& .MuiPaper-root": {
    width: "320px",
    padding: "24px",
    background: "rgba(37, 37, 37, 1)",
    color: "inherit",
  },
  "& .MuiTypography-root": {
    textAlign: "left",
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: "32px",
  }
}));

export const CloseContainer = styled(Box)(() => ({
  width: "100%",
  height: "50px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-end",
}));

export const StyledIconButton = styled(IconButton)(() => ({
  width: "35px",
  height: "35px",
  marginRight: "-5px",
  color: "inherit",
  "& svg": {
    fontSize: "35px !important",
  },
}));

export const LogoContainer = styled(Box)(() => ({
  "@keyframes logoAppear": {
    "0%": {
      transform: "translateX(-225%)",
      opacity: "0.00001",
    },
    "100%": {
      transform: "translateX(0%)",
      opacity: "1",
    },
  },
  animation: `logoAppear 500ms ease-out`,
}));

export const StyledListItem = styled(
  ListItem,
  { shouldForwardProp: (prop) => !["appearingTime"].includes(prop as string) }
)<{ appearingTime: number }>(
  ({ appearingTime }) => ({
    width: "100%",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    animation: `${appear} ${appearingTime}ms ease-out`,
    "& a": {
      width: "100%",
      height: "100%",
    },
  })
);

export const AnchorContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
}));

export const StyledListItemButton = styled(ListItemButton)(() => ({
  width: "100%",
  height: "40px",
  marginTop: "20px",
  padding: "0px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& svg": {
    fontSize: "26px !important",
  },
}));

export const StyledDivider = styled(
  Divider,
  { shouldForwardProp: (prop) => !["appearingTime"].includes(prop as string) }
)<{ appearingTime: number }>(
  ({ appearingTime }) => ({
    width: "100%",
    background: "rgba(255, 255, 255, 1)",
    color: "rgba(255, 255, 255, 1)",
    animation: `${appear} ${appearingTime}ms ease-out`,
  })
);
