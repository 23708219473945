import { styled } from "@mui/system";
import { Box } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const getStyledComponents = (isHighlighted: boolean) => {
  const PlanItemsContainer = styled(Box)`
    width: 100%;
    margin-bottom: 30px;
  `;

  const PlanItem = styled(Box)`
    width: 100%;
    margin-bottom: ${isHighlighted ? "10px" : "4px"};
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `;

  const CheckContainer = styled(Box)`
    width: 19px;
    height: 19px;
    margin-right: 15px;
    padding-left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(98, 66, 232, 0.08);
    color: rgba(98, 66, 232, 1);
    border-radius: 50%;
  `;

  const PlanItemText = styled(Box)`
    text-align: left;
    font-size: 15px;
    font-weight: 300;
    line-height: 22.88px;
    letter-spacing: 0.15px;
  `;

  const InfoTooltipContainer = styled("div")`
    position: relative;
    height: 16px;
    margin-right: 10px;
    display: inline-block;
    @media (max-width: 371px) {
      margin-left: 30px;
      transform: translateX(-30px);
    }
  `;

  const UalaLogo = styled("img")`
    width: 59px;
    margin-left: 0px;
    transform: translateY(0.5px);
  `;

  const StyledInfoIcon = styled(InfoIcon)`
    z-index: 10;
    position: absolute;
    width: 19px;
    margin-left: 2px;
    transform: translateY(-1px);
    cursor: help;
  `;

  return {
    PlanItemsContainer,
    PlanItem,
    CheckContainer,
    PlanItemText,
    InfoTooltipContainer,
    UalaLogo,
    StyledInfoIcon,
  }
}

export default getStyledComponents;
