import { styled } from "@mui/system";
import { Box, IconButton } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  margin: "0px",
  padding: "0px",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: "50px",
  height: "50px",
  padding: "0px",
  color: "currentColor",
  "& svg": {
    width: "50px",
    height: "50px",
  },
  "&:hover": {
    color: theme.palette.secondary.main,
  },
}));
