import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const UnlimitedMidContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;

export const UnlimitedMidUpperText = styled(Typography)`
  width: 100%;
  margin-top: 25px;
  margin-bottom: 18px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.5px;
  letter-spacing: 0.15px;
`;

export const TNEvolutionImageAnchor = styled("a")`
  margin-bottom: 48px;
  max-height: 38px;
  @media (max-width: 375px) {
    margin-bottom: 35px;
  }
`;
export const UnlimitedMidLowerText = styled(Typography)`
  width: 100%;
  margin-bottom: 20px;
  margin-top: -20px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.02px;
  letter-spacing: 0.15px;
`;