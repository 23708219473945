import { FC, useEffect, useContext, useState } from "react";
import { GeneralContext, PricingPlansContext } from 'context';
import {
  Container,
  SDxTN,
  SDAnchor,
  TNAnchor,
} from "./styledComponents";
import useStyles from "./useStyles";
import SDxTNHorizontal from 'img/pricingPlans/sd-tn-horizontal.svg';
import SDxTNVertical from 'img/pricingPlans/sd-tn-vertical.svg';
import SDxNVHorizontal from 'img/pricingPlans/sd-nv-horizontal.svg';
import SDxNVVertical from 'img/pricingPlans/sd-nv-vertical.svg';

const StoryDotsXTiendaNube: FC = () => {
  const { viewportWidth } = useContext(GeneralContext);
  const { countrySelected } = useContext(PricingPlansContext);
  const [SDxTNSVG, setSDxTNSVG] = useState(SDxTNHorizontal);
  const [SDxNVSVG, setSDxNVSVG] = useState(SDxNVHorizontal);
  const [changeToHorizontal, setChangeToHorizontal] = useState<boolean>(true);
  const [changeToVertical, setChangeToVertical] = useState<boolean>(true);
  const [isVertical, setIsVertical] = useState(false);
  const styles = useStyles();

  useEffect(() => {
    if (viewportWidth < 475 && changeToVertical) {
      setChangeToVertical(false);
      setChangeToHorizontal(true);
      setSDxTNSVG(SDxTNVertical);
      setSDxNVSVG(SDxNVVertical);
      setIsVertical(true);
    };
    if (viewportWidth >= 475 && changeToHorizontal) {
      setChangeToHorizontal(false);
      setChangeToVertical(true);
      setSDxTNSVG(SDxTNHorizontal);
      setSDxNVSVG(SDxNVHorizontal);
      setIsVertical(false);
    };
  }, [viewportWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      className={`${isVertical && styles.container}`}
    >
      <SDxTN
        src={countrySelected === "br" ? SDxNVSVG : SDxTNSVG}
        alt="StoryDots x TiendaNube"
        className={`${isVertical && styles.SDxTN }`}
      />
      <SDAnchor
        href="https://storydots.app/"
        target="_blank"
        className={`${isVertical && styles.SDAnchor}`}

      />
      <TNAnchor
        href="https://www.tiendanube.com/tienda-aplicaciones-nube/storydots/"
        target="_blank"
        rel="noopener noreferrer"
        className={`${isVertical && styles.TNAnchor}`}
      />
    </Container>
  );
};

export default StoryDotsXTiendaNube;
