import React, { FC, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { ReviewsCarouselFallback, ScheduleButton } from "components";
import {
  Container,
  Title,
  Subtitle,
  ButtonDescription,
} from "./styledComponents";

const ReviewsCarousel = lazy(() => import("../../../Organisms/ReviewsCarousel"));

const SuccessCases: FC = () => {
  const { t } = useTranslation("Main");

  return (
    <Container component="section">
      <Title variant="h2">
        {t("SuccessCases.title")}
      </Title>
      <Subtitle>
        {t("SuccessCases.subtitle")}
      </Subtitle>
      <Suspense fallback={<ReviewsCarouselFallback />}>
        <ReviewsCarousel />
      </Suspense>
      <ButtonDescription>
        {t("SuccessCases.buttonDescription")}
      </ButtonDescription>
      <ScheduleButton colorType={"secondaryBG"} text={t("SuccessCases.primaryButton")} to="#MeetingsCalendar"/>
    </Container>
  );
};

export default SuccessCases;
