import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(150deg, rgba(234, 231, 255, 1) 35%, rgba(234, 231, 255, 0.4) 45%, rgba(238, 252, 255, 0.4) 55%, rgba(238, 252, 255, 1) 65%);
`;

export const JoinTitle = styled(Typography)`
  max-width: 80%;
  margin: 0px 0px 45px 0px;
  font-size: 32px;
  font-weight: 800;
  line-height: 43.71px;
  letter-spacing: 0.46px;
  text-align: center;
  @media (max-width: 1190px) {
    margin: -10px 0px -30px 0px;
  }
  @media (max-width: 775px) {
    margin: 0px 0px 20px 0px;
  }
  @media (max-width: 475px) {
    margin: 0px 0px 40px 0px;
    font-size: 26px;
    line-height: 35px;
  }
  @media (max-width: 381px) {
    font-size: 24px;
    line-height: 32px;
  }
  @media (max-width: 354px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (max-width: 325px) {
    max-width: 85%;
  }
`;

export const CardsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-bottom: 52px;
  @media (max-width: 1190px) {
    margin-right: 20px;
    margin-bottom: 22px;
    margin-left: 20px;
    width: 776px;
  }
  @media (max-width: 775px) {
    max-width: 100%;
    justify-content: center;
  }
`;

export const BottomText = styled(Typography)`
  max-width: 80%;
  margin-bottom: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 21.86px;
  letter-spacing: 0.46px;
`;
