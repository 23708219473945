import { useContext, useEffect, useState } from "react";
import { GeneralContext } from 'context';

interface Props {
  widthBreakpoint: number;
  setSmallFunction: Function;
  setLargeFunction: Function;
};

const useHandleSwapAsset = ({ widthBreakpoint, setSmallFunction, setLargeFunction }:Props) => {
  const { viewportWidth } = useContext(GeneralContext);
  const [changeToSmall, setChangeToSmall] = useState<boolean>(true);
  const [changeToLarge, setChangeToLarge] = useState<boolean>(true);

  useEffect(() => {
    if (viewportWidth <= widthBreakpoint && changeToSmall) {
      setChangeToSmall(false);
      setChangeToLarge(true);
      setSmallFunction();
    };
    if (viewportWidth > widthBreakpoint && changeToLarge) {
      setChangeToLarge(false);
      setChangeToSmall(true);
      setLargeFunction();
    };
  }, [viewportWidth]); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useHandleSwapAsset;
