import { useTranslation } from "react-i18next";
import { ONBOARDING_WEBSITE } from "config";

interface IListItem {
  text: string;
  to: string;
  blankTarget: boolean;
}

const useGetListItemsArray = () => {
  const { t } = useTranslation("NavBar");

  const listItemsArray: IListItem[] = [
    {
      text: t("tour"), 
      to: ONBOARDING_WEBSITE,
      blankTarget: true,
    }, {
      text: t("integrations"),
      to: "/integrations",
      blankTarget: false,
    }, {
      text: t("Gift"),
      to: "/regala",
      blankTarget: false,
    },
  ];

  return listItemsArray;
}

export default useGetListItemsArray;
