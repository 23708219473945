import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  height: "100%",
  maxHeight: "100%",
  display: "flex"
}));

export const CardStore = styled("img")(() => ({
  width: "100%",
  minWidth: "100%",
  height: "100%",
  minHeight: "100%",
  objectFit: "cover",
  '&:hover': {
    cursor: 'pointer',
    transform: "scale(1.03); opacity:0.95",
    transition: "transform 0.4s ease-in-out"
  },
}));


