import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100vw",
	height: "160px",
	marginTop: "140px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 900px)": {
    paddingLeft: "0px",
    justifyContent: "center",
  },
  "@media (max-width: 600px)": {
    paddingLeft: "0px",
    marginTop: "100px",
    height: "100px",
    justifyContent: "center",
  },
}));


export const Title = styled(Typography)(({ theme }) => ({
  maxWidth: "90%",
  marginTop: "50px",
  margin: "0px",
  textAlign: "left",
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "60px",
  "@media (max-width: 900px)": {
    textAlign: "center",
  },
  "@media (max-width: 770px)": {
    fontSize: "calc(42px + (100vw - 770px) * 0.035)",
    lineHeight: "calc(60px + (100vw - 770px) * 0.055)",
  },
  "@media (max-width: 500px)": {
    fontSize: "calc(42px + (100vw - 770px) * 0.050)",
    lineHeight: "calc(60px + (100vw - 770px) * 0.055)",
  },
}));