import ar from './ar.svg';
import br from './br.svg';
import cl from './cl.svg';
import co from './co.svg';
import mx from './mx.svg';

interface ICountriesProps {
  ar:string;
  br:string;
  cl:string;
  co:string;
  mx:string;
};

const countryFlags:ICountriesProps = {ar, br, cl, co, mx};

export default countryFlags;
