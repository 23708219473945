import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  JoinTitle,
  CardsContainer,
  BottomText,
} from "./styledComponents";
import { PricingCard, StoryDotsXTiendaNube } from 'components';

const PricingPlansMain: FC = () => {
  const {t} = useTranslation("pricingPlans");

  return (
    <Container>
      <StoryDotsXTiendaNube />
      <JoinTitle variant="h2" >
        {t("mainSection.joinTitle")}
      </JoinTitle>
      <CardsContainer>
        <PricingCard plan="scale" />
        <PricingCard plan="startup" />
        <PricingCard plan="unlimited" />
      </CardsContainer>
      <BottomText>
        {t("mainSection.bottomText.start")}
        <strong>{t("mainSection.bottomText.bold")}</strong>
        {t("mainSection.bottomText.end")}
        <strong>{t("mainSection.bottomText.endBold")}</strong>
      </BottomText>
    </Container>
  );
};

export default PricingPlansMain;
