import { Button as MUIButton } from "@mui/material";
import { styled } from "@mui/material";

const Button = styled(MUIButton)(() => ({
  width: "100%",
  height: "56px",
  maxWidth: 300,
  borderRadius: "12px",
  textTransform: "none",
  fontSize: "18px",
  fontWeight: 700,
}));

export default Button;
