const mercadoPagoBaseUrl =
  "https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=";
const stripeBaseUrl = "https://buy.stripe.com/";

interface IPlan {
  plan: "scale" | "startup" | "unlimited";
  planName:
    | "scale"
    | "startup"
    | "unlimited"
    | "início"
    | "avançado"
    | "sem limites"
    | "inicial"
    | "avanzado"
    | "ilimitado";
  baseUrl: string;
  planArray: {
    price: string;
    link: string;
    offerPrice?: string;
    offerLink?: string;
  }[];
}

interface ICountry {
  language: string;
  scale: IPlan;
  startup: IPlan;
  unlimited: IPlan;
}

interface ICountriesData {
  ar: ICountry;
  br: ICountry;
  cl: ICountry;
  co: ICountry;
  mx: ICountry;
}

export const countriesData: ICountriesData = {
  ////////// Argentina //////////
  ar: {
    language: "es",
    scale: {
      plan: "scale",
      planName: "inicial",
      baseUrl: mercadoPagoBaseUrl,
      planArray: [
        {
          price: "9.990",
          link: "2c938084878e8f6501879041bd4f006e",
          offerPrice: "1.196",
          offerLink: "2c9380848792a2960187959f308400c2",
        },
      ],
    },
    startup: {
      plan: "startup",
      planName: "avanzado",
      baseUrl: mercadoPagoBaseUrl,
      planArray: [
        {
          price: "18.990",
          link: "2c9380848383e02f0183adfef7a41e26",
          offerPrice: "2.876",
          offerLink: "2c93808487942273018795a1435f009e",
        },
        {
          price: "29.990",
          link: "2c93808482ead1b20182eb54b99a0074",
          offerPrice: "5.396",
          offerLink: "2c93808487942273018795a2e575009f",
        },
        {
          price: "39.990",
          link: "2c93808483c4e5320183d1be96970793",
          offerPrice: "7.996",
          offerLink: "2c9380848792a296018795a3f92100c6",
        },
        {
          price: "49.990",
          link: "2c93808482ead1b20182eb57f6e90078",
          offerPrice: "9.596",
          offerLink: "2c9380848791901d018795a53b000133",
        },
      ],
    },
    unlimited: {
      plan: "unlimited",
      planName: "ilimitado",
      baseUrl: mercadoPagoBaseUrl,
      planArray: [
        {
          price: "119.990",
          link: "2c93808482dbb01c0182eb59116205dd",
          offerPrice: "19.992",
          offerLink: "2c9380848793b461018795a6be1500d6",
        },
      ],
    },
  },
  ////////// Brasil //////////
  br: {
    language: "pt",
    scale: {
      plan: "scale",
      planName: "início",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "55",
          link: "7sIg2NgHs2Vu3te5kk",
        },
      ],
    },
    startup: {
      plan: "startup",
      planName: "avançado",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "99",
          link: "eVa5o92QCanW4xi00o",
        },
        {
          price: "169",
          link: "7sI6sdgHsdA83tebJ7",
        },
        {
          price: "249",
          link: "6oEaIt8aWfIg3te4gG",
        },
        {
          price: "299",
          link: "4gwdUFcrc53C3te14v",
        },
      ],
    },
    unlimited: {
      plan: "unlimited",
      planName: "sem limites",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "799",
          link: "5kAbMx1MyeEcgg09B2",
        },
      ],
    },
  },
  ////////// Chile //////////
  cl: {
    language: "es",
    scale: {
      plan: "scale",
      planName: "inicial",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "8.990",
          link: "dR64k562O8fO2pa14m",
        },
      ],
    },
    startup: {
      plan: "startup",
      planName: "avanzado",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "13.990",
          link: "4gwdUF76Scw4gg0eVd",
        },
        {
          price: "26.990",
          link: "5kA7whgHsfIgd3OcN6",
        },
        {
          price: "39.990",
          link: "5kA7whgHsfIgd3OcN6",
        },
        {
          price: "44.990",
          link: "3cs2bXcrc9jS7JuaF0",
        },
      ],
    },
    unlimited: {
      plan: "unlimited",
      planName: "ilimitado",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "124.990",
          link: "00g17TcrcdA8bZK3cz",
        },
      ],
    },
  },
  ////////// Colombia //////////
  co: {
    language: "es",
    scale: {
      plan: "scale",
      planName: "inicial",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "49.990",
          link: "eVag2Nezk7bK9RCbIS",
        },
      ],
    },
    startup: {
      plan: "startup",
      planName: "avanzado",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "79.990",
          link: "cN22bXbn8gMk7JudR1",
        },
        {
          price: "149.990",
          link: "6oEaIt76SanW0h2eV6",
        },
        {
          price: "199.990",
          link: "9AQ03P0Iu9jSd3OaER",
        },
        {
          price: "249.990",
          link: "cN2dUF9f09jS8Ny4gu",
        },
      ],
    },
    unlimited: {
      plan: "unlimited",
      planName: "ilimitado",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "699.990",
          link: "dR66sdcrc53C6Fq28n",
        },
      ],
    },
  },
  ////////// Mexico //////////
  mx: {
    language: "es",
    scale: {
      plan: "scale",
      planName: "inicial",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "199",
          link: "bIY3g1crcfIg8Ny8wN",
        },
      ],
    },
    startup: {
      plan: "startup",
      planName: "avanzado",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "349",
          link: "dR68Al9f0bs0d3O28e",
        },
        {
          price: "599",
          link: "cN22bX76S7bK0h2fZ5",
        },
        {
          price: "849",
          link: "aEU6sdezk67G4xi8wE",
        },
        {
          price: "999",
          link: "dR68Al76S0NmfbW7sB",
        },
      ],
    },
    unlimited: {
      plan: "unlimited",
      planName: "ilimitado",
      baseUrl: stripeBaseUrl,
      planArray: [
        {
          price: "2,799",
          link: "eVa17T1My1Rq3teaEU",
        },
      ],
    },
  },
};

interface ISliderSpecifics {
  sliderMarks: {
    value: number;
    label: string;
  }[];
  sliderValues: {
    step: number;
    min: number;
    max: number;
    defaultValue: number;
  };
}

export const scalePlanSliderSpecifics: ISliderSpecifics = {
  sliderMarks: [
    {
      value: 25,
      label: "25",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 75,
      label: "75",
    },
    {
      value: 100,
      label: "100",
    },
  ],
  sliderValues: {
    step: 25,
    min: 25,
    max: 100,
    defaultValue: 25,
  },
};
