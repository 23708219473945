import React, { useContext, useEffect } from "react";
import { GeneralContext } from "context";
import { useTranslation } from "react-i18next";
import { Container, Title, TitleSecondLine } from "./styledComponents";

const MeetingsCalendar = () => {
  const { bookDemoRef } = useContext(GeneralContext);
  const { t } = useTranslation("Main");

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [])

  return (
    <Container component="section" ref={bookDemoRef} id="MeetingsCalendar">
      <Title variant="h2">{t("MeetingsCalendar.title")}</Title>
      <TitleSecondLine variant="h2">
        {t("MeetingsCalendar.titleSecondLine")}
      </TitleSecondLine>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/leogierberg/storydots?hide_event_type_details=1"
        style={{
          position: "relative",
          minWidth: "clamp(320px, 700px, 60vw)",
          height: "710px",
          overflow: "hidden",
        }}
      ></div>
    </Container>
  );
};

export default MeetingsCalendar;
