import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import locales from "locales";


const DETECTION_OPTIONS = {
  order: ["querystring", "localStorage", "navigator"],
  lookupQuerystring: 'locale',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en",
    resources: locales,
  });

export default i18n;
