import { styled } from "@mui/system";
import { Box } from "@mui/material";

const baseHeight = 1740;
const overflowBandHeight = 100;

export const Container = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: `${baseHeight + overflowBandHeight}px`,
  overflow: "hidden",
  "@media (max-width: 770px)": {
    height: "fit-content",
    paddingBottom: "30px",
  },
}));

export const GradientBackground = styled("div")(({ theme }) => ({
  "@keyframes backgroundMovement": {
    "0%": { backgroundPosition: `10% 25%` },
    "15%": { backgroundPosition: `0% 50%` },
    "30%": { backgroundPosition: `10% 75%` },
    "40%": { backgroundPosition: `45% 100%` },
    "50%": { backgroundPosition: `90% 75%` },
    "65%": { backgroundPosition: `100% 50%` },
    "80%": { backgroundPosition: `90% 25%` },
    "90%": { backgroundPosition: `45% 0%` },
    "100%": { backgroundPosition: `10% 25%` },
  },
  zIndex: "-3",
  position: "absolute",
  inset: "0px",
  width: "100%",
  height: "100%",
  background: `radial-gradient(${theme.palette.secondary.main.replace("1)", "0.85)")}, ${theme.palette.primary.main.replace("1)", "0.85)")} 75%)`,
  backgroundSize: `600% 450%`,
  backgroundPosition: `10% 75%`,
  animation: "backgroundMovement 60s infinite linear",
}));

export const OverflowBand = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "0px",
  left: "0px",
  zIndex: "-1",
  width: "100%",
  height: `${overflowBandHeight}px`,
  background: theme.palette.primary.main,
  "@media (max-width: 672px)": {
    display: "none",
  },
}));
