import { Palette, PaletteOptions, createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    layout?: {
      backgroundPattern?: boolean;
      buttons?: any;
    };
    palette: Palette & PaletteOptions & {custom: {
      buttons: any;
    };
  };
    shape: Shape;
  }
  interface ThemeOptions {
    layout?: {
      backgroundPattern?: boolean;
      buttons?: any;
    };
    //@ts-ignore
    palette: Palette & PaletteOptions & {custom: {
        buttons: any;
      };
    };
    shape: Shape;
  }
}

interface ButtonsShapeOptions {
  borderRadius: number;
}

interface Shape {
  buttons: ButtonsShapeOptions;
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(98, 66, 232, 1)",
      light: "rgba(148, 122, 255, 1)",
      dark: "rgba(64, 43, 154, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      main: "rgba(0, 252, 195, 1)",
      light: "rgba(106, 255, 221, 1)",
      dark: "rgba(0, 192, 149, 1)",
      contrastText: "rgba(64, 43, 154, 1)",
    },
  },
  shape: {
    buttons: {
      borderRadius: 1,
  },
  },
  typography: {
    fontFamily: "Manrope, sans-serif",
  },
  layout: {
    backgroundPattern: true
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});
