import { LOCATION } from '../../types/storesLocationEnum';
import { STOREINTERFACE } from './../../types/storesInterface';
import { STORETYPE } from './../../types/storesCategoriesEnum';

import STORE01 from "img/stores/01.jpg"
import STOREACF from "img/stores/ACF.jpg"
import STOREAMEN from "img/stores/Amen by Nah.jpg"
import STOREAONI from "img/stores/AONI.jpg"
import STOREBICHOCANASTO from "img/stores/Bicho Canasto.jpg"
import STOREBODACIOUS from "img/stores/Bodacious.jpg"
import STORECAJU from "img/stores/Caju Bags.jpg"
import STORECASADIONISIO from "img/stores/Casa Dionisio.jpg"
import STORECELSIUS from "img/stores/Celsius.jpg"
import STORECHIMI from "img/stores/Chimi.jpg"
import STORECOMBUSTIONLOVE from "img/stores/Combustion Love.jpg"
import STORECONTENTINA from "img/stores/Contentina.jpg"
import STOREDELIVERYDEFLORES from "img/stores/Delivery de Flores.jpg"
import STOREEPBA from "img/stores/EPBA.jpg"
import STOREEYY from "img/stores/Eyy.jpg"
import STOREFIDA from "img/stores/FIDA.jpg"
import STOREFK from "img/stores/fk.jpg"
import STOREGUBEE from "img/stores/Gubee.jpg"
import STOREKIRICOCHO from "img/stores/Kiricocho.jpg"
import STORELITTLESUN from "img/stores/Little Sun.jpg"
import STORELUXO from "img/stores/Luxo.jpg"
import STOREMALABLACK from "img/stores/Malablack.jpg"
import STOREMALIBUOUTFITTERS from "img/stores/Malibu Outfitters.jpg"
import STOREMIES from "img/stores/Mies.jpg"
import STOREMINIJULI from "img/stores/Mini Juli.jpg"
import STORENATURALCOSAS from "img/stores/Natural Cosas Lindas.jpg"
import STORENEOMEN from "img/stores/Neo Men.jpg"
import STORENIC from "img/stores/nic.jpg"
import STORENOLOBUSQUES from "img/stores/No lo busques mas.jpg"
import STOREPADELSTORE from "img/stores/Padel Store.jpg"
import STOREPETERPAN from "img/stores/peter pan.jpg"
import STOREPINTOSHOME from "img/stores/Pintos Home.jpg"
import STOREPLAYFORFUN from "img/stores/PlayForFun.jpg"
import STOREPLAZASPA from "img/stores/Plaza Spa.jpg"
import STOREPOUPON from "img/stores/Poupon.jpg"
import STOREPRUSSIA from "img/stores/Prussia.jpg"
import STOREQUICA from "img/stores/Quica.jpg"
import STOREREINACASA from "img/stores/Reina Casa.jpg"
import STOREREPITUCO from "img/stores/Repituco.jpg"
import STORESENTEZ from "img/stores/Sentez.jpg"
import STORETUTTIFRUTTI from "img/stores/Tutti Frutti.jpg"
import STOREVIENTOSDECORDOBA from "img/stores/Vientos de Cordoba.jpg"
import STORETODOMODA from "img/stores/Todomoda.jpg"
import STOREISADORA from "img/stores/Isadora.jpg"
import STOREPROTOTYPE from "img/stores/Prototype.jpg"
import STOREMINIANIMA from "img/stores/Mini anima.jpg"
import STOREKITCHENAID from "img/stores/KitchenAid.jpg"
import STOREVIAMO from "img/stores/Viamo.jpg"
import STOREKEYBISCAYNE from "img/stores/KeyBiscayne.jpg"


const STORES: STOREINTERFACE[] = [
    {
        name: "KitchenAid",
        alt: "kitchen_aid",
        image: STOREKITCHENAID,
        store_type: [STORETYPE.HOME_GARDEN],
        location_type: [LOCATION.ONLINE],
        url: "https://www.kitchenaid.com.ar/"
    },
    {
        name: "Isadora",
        alt: "isadora",
        image: STOREISADORA,
        store_type: [STORETYPE.CLOTHING_ACCESSORIES, STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE, LOCATION.PHYSICAL],
        url: "https://ar.isadoraonline.com/"
    },
    {
        name: "Todomoda",
        alt: "todomoda",
        image: STORETODOMODA,
        store_type: [STORETYPE.CLOTHING, STORETYPE.CLOTHING_ACCESSORIES],
        location_type: [LOCATION.ONLINE, LOCATION.PHYSICAL],
        url: "https://ar.todomoda.com/"
    },
    {
        name: "Prototype",
        alt: "prototype",
        image: STOREPROTOTYPE,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.prototype.com.ar/"
    },
    {
        name: "Viamo",
        alt: "viamo",
        image: STOREVIAMO,
        store_type: [STORETYPE.CLOTHING, STORETYPE.CLOTHING_ACCESSORIES],
        location_type: [LOCATION.PHYSICAL],
        url: "https://www.viamo.com/"
    },
    {
        name: "Key Biscayne",
        alt: "key_biscayne",
        image: STOREKEYBISCAYNE,
        store_type: [STORETYPE.CLOTHING, STORETYPE.CLOTHING_ACCESSORIES],
        location_type: [LOCATION.ONLINE],
        url: "https://www.keybiscayne.com.ar/"
    },
    {
        name: "Peter Pan",
        alt: "peter_pan",
        image: STOREPETERPAN,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://peterpan.com.ar/"
    },
    {
        name: "ACF",
        alt: "acf",
        image: STOREACF,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.mascarasacf.com.ar/"
    },
    {
        name: "Mini Ánima",
        alt: "mini_anima",
        image: STOREMINIANIMA,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.minianima.com.ar/"
    },
    {
        name: "Pintos Home",
        alt: "pintos_home",
        image: STOREPINTOSHOME,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.pintoshogar.com.ar/"
    },
    {
        name: "Repituco",
        alt: "repituco",
        image: STOREREPITUCO,
        store_type: [STORETYPE.GIFTS, STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://repituco.com/"
    },
    {
        name: "AONI",
        alt: "aoni",
        image: STOREAONI,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.aoni.com.ar/"
    },
    {
        name: "LUXO",
        alt: "luxo",
        image: STORELUXO,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.luxo.com.ar/"
    },
    {
        name: "Delivery de flores",
        alt: "delivery_de_flores",
        image: STOREDELIVERYDEFLORES,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://deliverydeflores.com.ar/"
    },
    {
        name: "Gubee",
        alt: "gubee",
        image: STOREGUBEE,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.gubee.com.ar/"
    },
    {
        name: "NIC",
        alt: "nic",
        image: STORENIC,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://queesnic.com/"
    },
    {
        name: "Plaza Spa",
        alt: "plaza_spa",
        image: STOREPLAZASPA,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.plazaspa.com.ar/"
    },
    {
        name: "Casa Dionisio",
        alt: "casa_dionisio",
        image: STORECASADIONISIO,
        store_type: [STORETYPE.GASTRONOMY],
        location_type: [LOCATION.PHYSICAL],
        url: "https://www.tienda.dionisioonline.com.ar/"
    },
    {
        name: "Amen by Nah",
        alt: "amen_by_nah",
        image: STOREAMEN,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.amenbynah.com/"
    },
    {
        name: "Reina Casa",
        alt: "reina_casa",
        image: STOREREINACASA,
        store_type: [STORETYPE.GIFTS, STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://reinacasa.com.ar/"
    },
    {
        name: "Combustion Love",
        alt: "combustion_love",
        image: STORECOMBUSTIONLOVE,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://combustionlove.com.ar/"
    },
    {
        name: "Kiricocho",
        alt: "kiricocho",
        image: STOREKIRICOCHO,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://kiricocho.com.ar/"
    },
    {
        name: "Mini Juli",
        alt: "mini_juli",
        image: STOREMINIJULI,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.minijuli.com.ar/"
    },
    {
        name: "Mies",
        alt: "mies",
        image: STOREMIES,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.tiendamies.com/"
    },
    {
        name: "Sentez",
        alt: "sentez",
        image: STORESENTEZ,
        store_type: [STORETYPE.CLOTHING_ACCESSORIES],
        location_type: [LOCATION.ONLINE],
        url: "https://www.sentez.com.ar/"
    },
    {
        name: "Chimi",
        alt: "chimi",
        image: STORECHIMI,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.chimi.com.ar/"
    },
    {
        name: "Padel Store",
        alt: "padel_store",
        image: STOREPADELSTORE,
        store_type: [STORETYPE.SPORTS, STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.padelstore.com.ar/"
    },
    {
        name: "Celsius",
        alt: "celsius",
        image: STORECELSIUS,
        store_type: [STORETYPE.CLOTHING_ACCESSORIES],
        location_type: [LOCATION.ONLINE],
        url: "https://www.celsiuslatam.com.ar/"
    },
    {
        name: "Prussia",
        alt: "prussia",
        image: STOREPRUSSIA,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.prussia.com.ar/"
    },
    {
        name: "NeoMen",
        alt: "neomen",
        image: STORENEOMEN,
        store_type: [STORETYPE.CLOTHING_ACCESSORIES],
        location_type: [LOCATION.ONLINE],
        url: "https://neomen.com.ar/"
    },
    {
        name: "Bodacious",
        alt: "bodacious",
        image: STOREBODACIOUS,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.bodaciousclothing.com/"
    },
    {
        name: "Mala Black",
        alt: "mala_black",
        image: STOREMALABLACK,
        store_type: [STORETYPE.JEWELRY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.malablackaccesorios.com.ar/"
    },
    {
        name: "Play For Fun",
        alt: "play_for_fun",
        image: STOREPLAYFORFUN,
        store_type: [STORETYPE.ELECTRONICS_IT],
        location_type: [LOCATION.ONLINE],
        url: "https://www.playxfun.com/"
    },
    {
        name: "Malibu Outfitters",
        alt: "malibu_outfitters",
        image: STOREMALIBUOUTFITTERS,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.malibuoutfitters.com.ar/"
    },
    {
        name: "Contentina",
        alt: "contentina",
        image: STORECONTENTINA,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.contentina.com.ar/"
    },
    {
        name: "FK",
        alt: "fk",
        image: STOREFK,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.floppykenny.com/fk-studio/"
    },
    {
        name: "Little Sun",
        alt: "little_sun",
        image: STORELITTLESUN,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.littlesun.com.ar/"
    },
    {
        name: "FIDA",
        alt: "fida",
        image: STOREFIDA,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.fidaclothes.com/"
    },
    {
        name: "Eyy",
        alt: "eyy",
        image: STOREEYY,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://tiendaeyy.com.ar/"
    },
    {
        name: "Bicho Canasto",
        alt: "bicho_canasto",
        image: STOREBICHOCANASTO,
        store_type: [STORETYPE.TOYS],
        location_type: [LOCATION.ONLINE],
        url: "https://www.bichocanasto.com/"
    },
    {
        name: "Caju Bags",
        alt: "caju_bags",
        image: STORECAJU,
        store_type: [STORETYPE.CLOTHING_ACCESSORIES],
        location_type: [LOCATION.ONLINE],
        url: "https://cajubags.com/"
    },
    {
        name: "EPBA",
        alt: "epba",
        image: STOREEPBA,
        store_type: [STORETYPE.OTHERS],
        location_type: [LOCATION.ONLINE],
        url: "https://www.estatepipesba.com.ar/"
    },
    {
        name: "Poupon",
        alt: "poupon",
        image: STOREPOUPON,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.pouponbebes.com.ar/"
    },
    {
        name: "01",
        alt: "01",
        image: STORE01,
        store_type: [STORETYPE.ELECTRONICS_IT],
        location_type: [LOCATION.ONLINE],
        url: "https://www.cerounopc.com.ar/"
    },
    {
        name: "Tutti Frutti",
        alt: "tutti_frutti",
        image: STORETUTTIFRUTTI,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.tuttifruttinenas.ar/"
    },
    {
        name: "Quica",
        alt: "quica",
        image: STOREQUICA,
        store_type: [STORETYPE.CLOTHING],
        location_type: [LOCATION.ONLINE],
        url: "https://www.quica.com.ar/"
    },
    {
        name: "Natural cosas lindas",
        alt: "natural_cosas_lindas",
        image: STORENATURALCOSAS,
        store_type: [STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.naturalcosaslindas.com.ar/"
    },
    {
        name: "Vientos de Cordoba",
        alt: "vientos_de_cordoba",
        image: STOREVIENTOSDECORDOBA,
        store_type: [STORETYPE.GASTRONOMY, STORETYPE.HEALTH_BEAUTY],
        location_type: [LOCATION.ONLINE],
        url: "https://vientosdecordoba.mitiendanube.com/"
    },
    {
        name: "No lo busques mas",
        alt: "no_lo_busques_mas",
        image: STORENOLOBUSQUES,
        store_type: [STORETYPE.GASTRONOMY],
        location_type: [LOCATION.ONLINE],
        url: "https://www.nolobusquesmas.com/"
    },
]

export default STORES