import { FC } from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItemText,
  SwipeableDrawerProps,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import StorydotsLogo from "img/footer/StorydotsLogo";
import {
  StyledSwipeableDrawer,
  CloseContainer,
  LogoContainer,
  StyledIconButton,
  StyledListItem,
  AnchorContainer,
  StyledListItemButton,
  StyledDivider,
} from "./styledComponents";
import useGetListItemsArray from "./useGetListItemsArray";

const MainDrawer: FC<SwipeableDrawerProps> = (props) => {
  const listItemsArray = useGetListItemsArray();

  return (
    <StyledSwipeableDrawer {...props} anchor="left">
      <CloseContainer>
        <StyledIconButton onClick={(e) => props.onClose(e)}>
          <CloseIcon />
        </StyledIconButton>
      </CloseContainer>
      <nav>
        <LogoContainer onClick={(e) => props.onClose(e)}>
          <Link to="/">
            <StorydotsLogo />
          </Link>
        </LogoContainer>
        <List>
          {listItemsArray.map(({ text, to, blankTarget }, id) => (
            <div key={`DrawrItemContainer${text}${id}`}>
              <StyledListItem
                disablePadding
                appearingTime={(id + 6) * 100}
              >
                {blankTarget
                  ? <AnchorContainer onClick={(e) => props.onClose(e)}>
                      <a
                        href={to}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StyledListItemButton tabIndex={-1}>
                          <ListItemText primary={text} />
                          <LaunchIcon />
                        </StyledListItemButton>
                      </a>
                    </AnchorContainer>
                  : <AnchorContainer onClick={(e) => props.onClose(e)}>
                      <Link to={to}>
                        <StyledListItemButton tabIndex={-1}>
                          <ListItemText primary={text} />
                          <ArrowForwardIcon />
                        </StyledListItemButton>
                      </Link>
                    </AnchorContainer>
                }
              </StyledListItem>
              {id < listItemsArray.length - 1
                && <StyledDivider appearingTime={(id + 6.5) * 100} />
              }
            </div>
          ))}
        </List>
      </nav>
    </StyledSwipeableDrawer>
  );
};

export default MainDrawer;
