import React, { FC } from "react";
import {
  Container,
  Title,
  Text,
  ImageContainer,
} from "./styledComponents";

interface Props {
  title: string;
  text: string;
  Image: () => JSX.Element;
};

const EndToEndCard: FC<Props> = ({
  title,
  text,
  Image,
}) => {
  return (
    <Container>
      <Title variant="h3">
        {title}
      </Title>
      <Text>
        {text}
      </Text>
      <ImageContainer>
        <Image />
      </ImageContainer>
    </Container>
  );
};

export default EndToEndCard;
