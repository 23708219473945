import Ecommerce1 from "./end-to-end-ecommerce-1"
import Ecommerce2 from "./end-to-end-ecommerce-2"
import Ecommerce3 from "./end-to-end-ecommerce-3"
import Ecommerce4 from "./end-to-end-ecommerce-4"
import Retail1 from "./end-to-end-retail-1"
import Retail2 from "./end-to-end-retail-2"
import Retail3 from "./end-to-end-retail-3"
import Retail4 from "./end-to-end-retail-4"

const endToEndImages = {
  Ecommerce1,
  Ecommerce2,
  Ecommerce3,
  Ecommerce4,
  Retail1,
  Retail2,
  Retail3,
  Retail4,
}

export default endToEndImages;
