import React from 'react';
import useStyles from './useStyles';

interface Props {
  text: string,
  specifics: string
};

function Tooltip({ text, specifics }: Props) {
  const classes = useStyles();

  return (
    <div className={[classes.container, specifics].join(' ')}>
      {text}
    </div>
  )
};

export default Tooltip;

/* Styles required at specifics sent:
  container: {
    top: ,
    left: ,
    width: ,
    height: ,
    "&::before": {
      top: ,
      left: ,
      border(XXX): "7px solid #616161"
    }
  }
*/
