import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  "@keyframes moveBackground": {
    "0%": {
      backgroundSize: "300% 300%",
      backgroundPosition: "50% 44%",
    },
    "10%": {
      backgroundSize: "450% 450%",
      backgroundPosition: "50% 30%",
    },
    "20%": {
      backgroundSize: "600% 600%",
      backgroundPosition: "67% 36%",
    },
    "35%": {
      backgroundSize: "600% 600%",
      backgroundPosition: "67% 64%",
    },
    "50%": {
      backgroundSize: "600% 600%",
      backgroundPosition: "50% 70%",
    },
    "65%": {
      backgroundSize: "600% 600%",
      backgroundPosition: "34% 64%",
    },
    "80%": {
      backgroundSize: "600% 600%",
      backgroundPosition: "34% 36%",
    },
    "90%": {
      backgroundSize: "450% 450%",
      backgroundPosition: "50% 30%",
    },
    "100%": {
      backgroundSize: "300% 300%",
      backgroundPosition: "50% 44%",
    },
  },
  zIndex: "-3",
  position: "absolute",
  inset: "0px",
  width: "100%",
  height: "100%",
  background: `radial-gradient(${theme.palette.secondary.main}, ${theme.palette.primary.main} 30%)`,
  backgroundSize: "300% 300%",
  backgroundPosition: "50% 44%",
  animation: "moveBackground 60s infinite linear",
  opacity: "0.9",
}));
