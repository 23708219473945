import React, { FC } from "react";
import {
  Container,
} from "./styledComponents";

const MainHeroMovingBG: FC = () => {
  return (
    <Container />
  );
}

export default MainHeroMovingBG;
