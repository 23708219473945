import React, { FC, useState } from "react";
import { EndToEndStepsCards, EndToEndSwitch } from "components";
import {
  Container,
} from "./styledComponents";

const EndToEndSteps: FC = () => {
  const [leftSelected, setLeftSelected] = useState(true);

  const handleLeftSelect = () => setLeftSelected(true);

  const handleRightSelect = () => setLeftSelected(false);

  return (
    <Container>
      <EndToEndSwitch
        leftSelected={leftSelected}
        handleLeftClick={handleLeftSelect}
        handleRightClick={handleRightSelect}
      />
      <EndToEndStepsCards leftSelected={leftSelected} />
    </Container>
  );
};

export default EndToEndSteps;
