import React from "react";

type Image = Record<string, any>;
type Lottie = Record<string, any>;

interface ImagesContext {
    images: Image;
    lotties: Lottie;
    clientID: string,
  }

export const CustomImagesContext = React.createContext<ImagesContext>({
    images: {},
    lotties: {},
    clientID: ""
  });