import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Title,
  Container
} from "./styledComponents";

const StoresTitle: FC = () => {
  const {t} = useTranslation("Stores");

  return (
    <Container>
      <Title variant="h1">
        {t("Title.title")}
      </Title>
    </Container>
  );
};

export default StoresTitle;