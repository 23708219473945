import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tooltipSpecifics: {
    top: "-28px",
    left: "-153px",
    width: "242px",
    height: "25px",
    "&::before": {
      top: "calc(100% - 1px)",
      left: "89.7%",
      borderTop: "7px solid #616161",
    },
    "@media (max-width: 371px)": {
      left: "-31px",
      "&::before": {
        left: "39.3%",
      },
    },
  },
}));

export default useStyles;
