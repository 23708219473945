import { FC, useContext } from "react";
import { LayoutContext } from "context";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ONBOARDING_WEBSITE } from "config";
import { ScheduleButton } from "components";
import {
  NavbarWrapper,
  LogoContainer,
  NavButtonsContainer,
  NavbarButton,
  MenuButton,
} from "./styledComponents";
import MenuIcon from '@mui/icons-material/Menu';
import storydotsLogoSrc from "img/storydots-logo.svg";
import storydotsWhiteLogoSrc from "img/storydots-white-logo.svg";


interface IProps {
  onClickMenuButton: () => void;
}

const Navbar: FC<IProps> = ({ onClickMenuButton }) => {
  const { isNavbarLight, isNavbarVisible } = useContext(LayoutContext);
  const location = useLocation();
  const isMain = location.pathname === "/";
  const { t } = useTranslation("NavBar");

  return (
    <>
      {isNavbarVisible &&
        <NavbarWrapper
          component="nav"
          isNavbarLight={isNavbarLight}
        >
          <MenuButton onClick={onClickMenuButton}>
            <MenuIcon />
          </MenuButton>
          <Link to={"/"}>
            <LogoContainer isMain={isMain}>
              <img
                src={isNavbarLight ? storydotsWhiteLogoSrc : storydotsLogoSrc}
                alt="Storydots logo"
                width={240}
                height={48}
                loading="lazy"
              />
            </LogoContainer>
          </Link>
          <NavButtonsContainer component="ul">
            <li>
              <a
                href={ONBOARDING_WEBSITE}
                target="_blank"
                rel="noopener noreferrer"
              >
                <NavbarButton tabIndex={-1}>
                  {t("tour").toUpperCase()}
                </NavbarButton>
              </a>
            </li>
            <li>
              <Link to={"/integrations"}> 
                <NavbarButton tabIndex={-1}>
                  {t("integrations").toUpperCase()}
                </NavbarButton>
              </Link>
            </li>
            <li>
              <ScheduleButton colorType={"secondaryBG"} />
            </li>
          </NavButtonsContainer>
        </NavbarWrapper>
      }
    </>
  );
};

export default Navbar;
