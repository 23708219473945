import { Button, Box, useTheme } from "@mui/material";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import { useContext } from "react";
import { CustomImagesContext } from "context";
import { Container, Title, Subtitle, Logo } from "../components";
import HeroBannerLottieSrc from "./bag.json";

function GetTag() {
  //Search for already created tag within this flow
  const StoryURL = window.localStorage.getItem("storydots-story-url");
  const location = useLocation();
  const { t } = useTranslation("TagDispenser");
  const theme: any = useTheme();

  const styles = {
    button: {
      width: "100%",
      textTransform: theme.layout?.buttons?.textTransform || "none",
      height: "56px",
      maxWidth: 300,
      borderRadius:
        `${theme.shape?.buttons?.borderRadius} !important` || "12px !important",
      fontSize: "18px",
      fontWeight: 700,
    },
    buttonSecondary: {
      visibility: "initial",
      mt: "16px",
    },
  };

  const { encryptedClientID } = useParams<{ encryptedClientID: string }>();
  const history = useHistory();
  //@ts-ignore
  const userEmail = location?.state?.email;
  const { images, lotties } = useContext(CustomImagesContext);
  //@ts-ignore
  const storyURL = StoryURL || "";
  window.localStorage.setItem("storydots-story-url", storyURL);

  return (
    <Container theme={theme} justifyContent="space-between">
      <Logo
        style={{ position: "absolute", top: "2rem", width: "200px" }}
        logo={images["logo"]}
      />
      <Lottie
        loop
        animationData={lotties["sendEmailAnimation.json"] || HeroBannerLottieSrc}
        play
        style={{ width: 400, height: 400 }}
      />
      <Title variant="h5" sx={{ mb: 2 }}>
        {t("GetTag.title")}
      </Title>
      <Subtitle sx={{ fontSize: "16px" }} gutterBottom>
        {t("GetTag.subtitle")}
      </Subtitle>
      <Box flexGrow={0.5} />
      {storyURL ? (
        <>
          <Button
            fullWidth
            variant="contained"
            sx={{
              ...styles.button,
              backgroundColor: theme.palette.custom?.buttons
                ? theme.palette.custom.buttons.main
                : theme.palette.primary?.main,
              "&:hover": {
                backgroundColor:
                  theme.palette.custom?.buttons.dark || theme.palette.primary.dark,
                color:
                  theme.palette.custom?.buttons.textColorLight ||
                  theme.palette.primary.contrastText,
              },
            }}
            type="submit"
            onClick={() => storyURL && window.location.assign(storyURL)}
          >
            {t("GetTag.action.primary")}
          </Button>
          <Button
            fullWidth
            color="secondary"
            onClick={() =>
              history.push(`/dispenser/${encryptedClientID}/finish`, {
                email: userEmail,
              })
            }
            sx={{ ...styles.button, ...styles.buttonSecondary }}
          >
            {t("GetTag.action.secondary")}
            <TimerOutlinedIcon
              fontSize="small"
              sx={{
                marginLeft: "7px",
              }}
            />
          </Button>
        </>
      ) : (
        <Link
          to={`/dispenser/${encryptedClientID}`}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textDecoration: "none",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            sx={styles.button}
            type="submit"
          >
            {t("GetTag.action.tertiary")}
          </Button>
        </Link>
      )}
    </Container>
  );
}

export default GetTag;
