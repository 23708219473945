import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { API_URL } from "config";


const RedirectShortCode: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { short_code } = useParams<{ short_code: string }>();

  const getUrlByShortCode = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${API_URL}/short/${short_code}`)
      const redirectURL = await response.json();
      if (!response.ok) {
        console.debug(response.statusText);
        throw new Error("Error fetching url");
      }
      window.location.replace(redirectURL.long_url)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    short_code && getUrlByShortCode()
  }, [])

  return (
    <>
      <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {loading && <CircularProgress />}
        {error && <p style={{color: 'red'}}>{error}</p>}
      </div>
    </>
  )
};

export default RedirectShortCode;