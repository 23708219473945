import { FC, useContext } from "react";
import { PricingPlansContext } from "context";
import { PriceContainer, CountryCoin, Price } from "./styledComponents";

const countriesCoins = {
  ar: "ARS",
  br: "BRL",
  cl: "CLP",
  co: "COP",
  mx: "MXN",
};

interface IProps {
  plan: "scale" | "startup" | "unlimited";
}

const PriceSection: FC<IProps> = ({ plan }) => {
  const {
    scalePlanPrice,
    startupPlanPrice,
    unlimitedPlanPrice,
    countrySelected,
  } = useContext(PricingPlansContext);

  return (
    <PriceContainer>
      <CountryCoin>
        {countriesCoins[countrySelected as keyof typeof countriesCoins]}
      </CountryCoin>
      <Price>
        {plan === "scale"
          ? scalePlanPrice
          : plan === "startup"
          ? startupPlanPrice
          : unlimitedPlanPrice}
      </Price>
    </PriceContainer>
  );
};

export default PriceSection;
