import { useTheme } from "@mui/material";

const Ecommerce4 = () => {
  const theme = useTheme();

  return (
    <svg width="253" height="248" viewBox="0 0 253 248" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3663_40522)">
      <path d="M38.3995 236V206.88H-42.9805V173.86L16.0395 48.8H56.5995L-2.42046 173.86H38.3995V127.06H74.2795V173.86H90.9195V206.88H74.2795V236H38.3995Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <g clipPath="url(#clip1_3663_40522)">
      <path d="M176.421 14.5835H137.314C136.11 14.5835 135.135 15.5625 135.135 16.7701V16.8707C135.135 18.0784 136.11 19.0573 137.314 19.0573H176.421C177.624 19.0573 178.6 18.0784 178.6 16.8707V16.7701C178.6 15.5625 177.624 14.5835 176.421 14.5835Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M228.635 2.56006H85.1004C82.1855 2.57178 79.3015 3.15976 76.6131 4.29041C73.9247 5.42105 71.4846 7.0722 69.4323 9.14952C67.3799 11.2268 65.7555 13.6896 64.6519 16.3972C63.5483 19.1047 62.987 22.0039 63.0002 24.9292V385.072C62.987 387.998 63.5483 390.897 64.6519 393.605C65.7555 396.312 67.3799 398.775 69.4323 400.852C71.4846 402.929 73.9247 404.581 76.6131 405.711C79.3015 406.842 82.1855 407.43 85.1004 407.442H146.475C146.535 407.221 146.664 407.027 146.844 406.887C147.024 406.748 147.245 406.672 147.472 406.67H166.391C166.618 406.672 166.839 406.748 167.019 406.887C167.199 407.027 167.329 407.221 167.388 407.442H228.635C231.549 407.43 234.434 406.842 237.122 405.711C239.81 404.581 242.25 402.929 244.303 400.852C246.355 398.775 247.979 396.312 249.083 393.605C250.187 390.897 250.748 387.998 250.735 385.072V24.9292C250.748 22.0039 250.187 19.1047 249.083 16.3972C247.979 13.6896 246.355 11.2268 244.303 9.14952C242.25 7.0722 239.81 5.42105 237.122 4.29041C234.434 3.15976 231.549 2.57178 228.635 2.56006Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M234.24 34.436H79.4946C74.5243 34.436 70.4951 38.4796 70.4951 43.4676V369.889C70.4951 374.877 74.5243 378.921 79.4946 378.921H234.24C239.211 378.921 243.24 374.877 243.24 369.889V43.4676C243.24 38.4796 239.211 34.436 234.24 34.436Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <mask id="mask0_3663_40522" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="70" y="33" width="174" height="347">
      <path d="M234.039 34.0007H79.9606C75.0118 34.0007 71 38.0386 71 43.0196V368.982C71 373.963 75.0118 378.001 79.9606 378.001H234.039C238.988 378.001 243 373.963 243 368.982V43.0196C243 38.0386 238.988 34.0007 234.039 34.0007Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      </mask>
      <g mask="url(#mask0_3663_40522)">
      <rect x="63.4429" y="35.0007" width="187" height="13" rx="6" fill={theme.palette.primary.main} fillOpacity="0.8"/>
      <rect x="63.4429" y="35.0007" width="187" height="38" rx="6" fill={theme.palette.primary.main} fillOpacity="0.4"/>
      </g>
      <g clipPath="url(#clip2_3663_40522)">
      <path d="M146.602 90.0007C147.656 91.3426 148.622 92.5561 149.574 93.7796C151.653 96.4435 151.653 96.4484 149.086 98.7128C146.992 100.558 144.567 101.905 142.18 103.301C139.711 104.741 137.261 106.231 135.49 108.545C134.738 109.531 134.299 109.309 133.708 108.461C133.118 107.612 132.41 106.779 131.756 105.94C129.55 103.138 129.56 103.148 132.083 100.711C133.717 99.2297 135.531 97.9648 137.481 96.9467C140.775 95.077 144.084 93.1629 146.602 90.0007Z" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <path d="M195.365 176.761C196.829 178.591 197.986 180.274 199.357 181.778C200.304 182.819 199.879 183.441 199.133 184.245C197.222 186.15 195.055 187.772 192.696 189.065C189.611 190.88 186.39 192.518 184.131 195.448C183.433 196.351 183.038 196.085 182.515 195.355C181.993 194.624 181.422 193.924 180.866 193.218C178.167 189.765 178.128 189.617 181.661 186.805C183.276 185.566 184.979 184.45 186.756 183.465C189.87 181.64 192.925 179.785 195.365 176.761Z" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <path d="M110 144.014C111.044 142.535 112.147 141.281 112.87 139.846C114.012 137.577 115.398 137.562 117.296 138.8C119.073 139.941 120.73 141.264 122.24 142.747C125.129 145.623 127.945 148.583 131.917 150.24C130.941 151.666 130.053 152.929 129.17 154.187C127.217 156.93 127.217 156.925 124.289 155.04C122.353 153.694 120.554 152.155 118.921 150.447C116.31 147.897 113.685 145.337 110 144.014Z" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <path d="M208 154.236C206.916 155.77 205.848 156.949 205.164 158.321C203.949 160.787 202.544 160.486 200.626 159.169C198.445 157.603 196.402 155.848 194.521 153.925C192.081 151.518 189.538 149.234 186.102 147.946C187.439 146.027 188.718 144.246 189.933 142.426C190.553 141.499 191.212 141.75 192.022 142.105C194.462 143.166 196.414 144.833 198.288 146.678C201.177 149.559 204.023 152.48 208 154.236Z" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <path d="M194.121 117.133C194.807 118.161 195.55 119.149 196.346 120.093C199.694 123.547 199.708 123.517 196.385 126.877C194.169 129.121 194.169 129.121 191.915 126.847C188.625 123.517 188.64 123.537 192.017 120.059C192.686 119.358 193.159 118.48 194.121 117.133Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M171.086 129.235C169.539 130.586 168.577 131.341 167.723 132.195C164.883 135.016 164.898 135.031 162.043 132.15C159.359 129.432 159.359 129.432 161.999 126.763C164.898 123.833 164.873 123.862 167.855 126.763C168.709 127.621 169.768 128.248 171.086 129.235Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M155.074 175.242C154.419 175.566 153.8 175.962 153.229 176.421C149.291 180.367 149.374 180.303 145.45 176.554C143.986 175.158 143.942 174.418 145.45 172.992C149.398 169.228 149.31 169.159 153.229 173.091C153.794 173.554 154.402 173.961 155.045 174.304L155.074 175.242Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M178.47 108.589C178.121 108.568 177.784 108.455 177.492 108.26C177.201 108.065 176.966 107.796 176.811 107.479C176.617 107.161 176.534 106.786 176.574 106.415C176.613 106.044 176.774 105.696 177.03 105.427C178.465 103.991 179.924 102.58 181.422 101.219C181.699 100.97 182.055 100.828 182.425 100.818C182.796 100.808 183.158 100.931 183.448 101.165C183.767 101.345 184.026 101.617 184.193 101.946C184.36 102.275 184.426 102.647 184.385 103.014C184.282 104.124 179.641 108.564 178.47 108.589Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M147.339 124.77C147.242 125.102 147.088 125.414 146.885 125.693C145.518 127.084 144.162 128.48 142.698 129.772C142.526 129.951 142.319 130.091 142.09 130.182C141.861 130.274 141.615 130.315 141.369 130.303C141.124 130.292 140.883 130.227 140.663 130.115C140.444 130.002 140.251 129.843 140.096 129.649C139.316 128.82 139.281 127.834 140.048 127C141.399 125.599 142.829 124.272 144.264 122.955C144.532 122.697 144.878 122.54 145.246 122.508C145.614 122.475 145.982 122.57 146.289 122.777C146.64 122.967 146.927 123.257 147.114 123.612C147.301 123.967 147.379 124.37 147.339 124.77Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M153.205 152.371C153.158 152.771 152.969 153.139 152.673 153.407C151.345 154.72 150.037 156.056 148.651 157.305C147.744 158.128 146.699 158.133 145.875 157.162C145.698 156.988 145.559 156.779 145.468 156.548C145.376 156.318 145.333 156.07 145.342 155.821C145.35 155.572 145.411 155.328 145.518 155.105C145.626 154.881 145.779 154.683 145.967 154.522C147.275 153.19 148.647 151.918 150.013 150.65C150.27 150.382 150.607 150.207 150.972 150.152C151.337 150.096 151.71 150.164 152.033 150.344C152.406 150.526 152.716 150.816 152.924 151.177C153.133 151.538 153.231 151.954 153.205 152.371Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M116.383 113.68C116.77 113.813 117.134 114.006 117.462 114.252C118.804 115.54 120.126 116.852 121.39 118.199C121.592 118.374 121.753 118.593 121.859 118.839C121.966 119.085 122.017 119.353 122.007 119.621C121.998 119.89 121.929 120.153 121.805 120.391C121.682 120.629 121.506 120.836 121.293 120.996C121.126 121.177 120.923 121.321 120.697 121.417C120.471 121.514 120.228 121.561 119.983 121.556C119.738 121.551 119.496 121.493 119.275 121.387C119.053 121.282 118.856 121.13 118.697 120.942C117.32 119.6 115.954 118.204 114.626 116.793C114.369 116.527 114.209 116.181 114.171 115.811C114.134 115.44 114.222 115.068 114.421 114.756C114.605 114.399 114.89 114.106 115.24 113.915C115.589 113.723 115.988 113.641 116.383 113.68Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M176.713 166.875C176.429 166.894 176.144 166.847 175.88 166.739C175.616 166.63 175.381 166.463 175.19 166.249C173.921 165 172.662 163.742 171.432 162.455C170.593 161.572 170.456 160.575 171.344 159.648C172.233 158.72 173.243 158.804 174.121 159.648C175.458 160.94 176.747 162.282 178.025 163.629C178.293 163.883 178.472 164.218 178.535 164.584C178.598 164.949 178.543 165.326 178.377 165.657C178.239 165.996 178.01 166.29 177.716 166.505C177.422 166.72 177.075 166.848 176.713 166.875Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M125.87 184.561C125.846 186.174 124.118 187.21 122.942 186.228C121.297 184.801 119.763 183.247 118.355 181.581C117.779 180.925 118.033 180.032 118.653 179.366C119.204 178.784 119.985 178.433 120.605 178.873C122.564 180.287 124.29 182.005 125.719 183.964C125.813 184.149 125.865 184.353 125.87 184.561Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      </g>
      <path d="M250.735 118.88V90.1353H250.974C251.554 90.1353 252.109 90.3663 252.519 90.7774C252.929 91.1885 253.159 91.7461 253.159 92.3275V116.687C253.159 117.27 252.928 117.829 252.517 118.242C252.107 118.654 251.55 118.885 250.969 118.885H250.729L250.735 118.88Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M250.735 145.499V202.205C251.379 202.205 251.997 201.948 252.453 201.49C252.908 201.033 253.164 200.413 253.164 199.766V147.959C253.163 147.314 252.906 146.695 252.451 146.239C251.995 145.783 251.378 145.527 250.735 145.527V145.499Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M120.898 27.1662C123.36 27.1662 125.356 25.1632 125.356 22.6923C125.356 20.2215 123.36 18.2185 120.898 18.2185C118.436 18.2185 116.44 20.2215 116.44 22.6923C116.44 25.1632 118.436 27.1662 120.898 27.1662Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M118.724 24.6887C118.332 24.252 118.077 23.7082 117.992 23.1262C117.907 22.5442 117.996 21.95 118.248 21.4187C118.499 20.8874 118.902 20.4429 119.405 20.1413C119.908 19.8397 120.489 19.6946 121.075 19.7243C121.66 19.7539 122.224 19.9571 122.694 20.3081C123.164 20.659 123.521 21.1421 123.717 21.6961C123.914 22.2501 123.943 22.8503 123.8 23.4208C123.657 23.9912 123.349 24.5064 122.915 24.9012C122.626 25.1643 122.289 25.3676 121.921 25.4994C121.554 25.6313 121.165 25.6889 120.775 25.6692C120.386 25.6494 120.004 25.5526 119.652 25.3844C119.3 25.2161 118.985 24.9797 118.724 24.6887Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M95.1641 24.5378C94.7996 24.0753 94.58 23.5146 94.5331 22.9267C94.4863 22.3389 94.6142 21.7503 94.9008 21.2354C95.1873 20.7205 95.6196 20.3026 96.143 20.0344C96.6663 19.7661 97.2572 19.6598 97.8408 19.7287C98.4244 19.7976 98.9745 20.0387 99.4216 20.4214C99.8686 20.8042 100.192 21.3115 100.352 21.879C100.512 22.4466 100.5 23.0489 100.319 23.6098C100.137 24.1707 99.7938 24.6649 99.3323 25.0299C99.0266 25.2727 98.6761 25.4525 98.3011 25.5592C97.926 25.6659 97.5336 25.6972 97.1465 25.6515C96.7593 25.6058 96.3849 25.4839 96.0448 25.2928C95.7046 25.1017 95.4053 24.8451 95.1641 24.5378Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M95.833 24.0066C95.5731 23.6767 95.4165 23.2768 95.3832 22.8577C95.3498 22.4385 95.4412 22.0188 95.6456 21.6517C95.8501 21.2846 96.1585 20.9867 96.5317 20.7956C96.905 20.6045 97.3264 20.5288 97.7425 20.5782C98.1587 20.6275 98.5509 20.7997 98.8695 21.0729C99.1881 21.346 99.4187 21.7079 99.5323 22.1127C99.6458 22.5175 99.6371 22.947 99.5073 23.3469C99.3775 23.7467 99.1324 24.0989 98.803 24.3589C98.5851 24.5318 98.3353 24.6599 98.068 24.7358C97.8007 24.8117 97.5211 24.834 97.2452 24.8012C96.9692 24.7685 96.7025 24.6815 96.4602 24.5451C96.2178 24.4087 96.0047 24.2257 95.833 24.0066Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M157 123.834C146.65 123.834 138.25 132.421 138.25 143.001C138.25 153.581 146.65 162.167 157 162.167C167.35 162.167 175.75 153.581 175.75 143.001C175.75 132.421 167.35 123.834 157 123.834ZM154.858 150.393C154.201 150.897 153.25 150.428 153.25 149.599V136.403C153.25 135.574 154.201 135.105 154.858 135.609L163.465 142.207C163.987 142.607 163.987 143.394 163.465 143.794L154.858 150.393Z" fill={theme.palette.primary.main}/>
      <rect x="84" y="83.0007" width="146" height="120" rx="15" stroke={theme.palette.primary.main} strokeWidth="2"/>
      <rect x="84" y="212.001" width="146" height="25" rx="6" fill={theme.palette.primary.main}/>
      <g clipPath="url(#clip3_3663_40522)">
      <path d="M158.737 227.37C158.737 227.714 158.737 228.058 158.737 228.402C158.737 228.608 158.799 228.784 159.03 228.866C159.26 228.949 159.44 228.875 159.61 228.729C161.337 227.249 163.065 225.77 164.796 224.29C165.072 224.053 165.067 223.846 164.786 223.604C163.058 222.124 161.329 220.645 159.6 219.165C159.438 219.031 159.264 218.954 159.043 219.032C158.808 219.114 158.737 219.282 158.737 219.486C158.737 220.166 158.727 220.845 158.743 221.523C158.747 221.72 158.678 221.761 158.466 221.768C154.213 221.907 150.589 224.856 150.067 228.599C150.026 228.896 150.019 229.198 149.998 229.498C149.982 229.74 150.079 229.924 150.363 229.986C150.638 230.047 150.8 229.909 150.926 229.715C151.922 228.186 153.37 227.131 155.252 226.537C156.324 226.199 157.437 226.125 158.565 226.148C158.715 226.152 158.741 226.196 158.741 226.313C158.731 226.665 158.737 227.018 158.737 227.37Z" fill={theme.palette.primary.contrastText}/>
      </g>
      <rect x="95.4429" y="54.0007" width="136" height="14" rx="7" fill={theme.palette.primary.contrastText}/>
      <circle cx="104.443" cy="61.0007" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="221.443" cy="61.0007" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="208.443" cy="61.0007" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="113.443" y="58.0007" width="86" height="7" rx="3.5" fill={theme.palette.primary.main} fillOpacity="0.3"/>
      <path d="M85.0004 57.5582L83.9429 56.5007L79.4429 61.0007L83.9429 65.5007L85.0004 64.4432L81.5654 61.0007L85.0004 57.5582Z" fill={theme.palette.primary.contrastText}/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_3663_40522">
      <rect width="253" height="248" fill={theme.palette.primary.contrastText}/>
      </clipPath>
      <clipPath id="clip1_3663_40522">
      <rect width="194" height="251" fill={theme.palette.primary.contrastText} transform="translate(59 -3)"/>
      </clipPath>
      <clipPath id="clip2_3663_40522">
      <rect width="98" height="106" fill={theme.palette.primary.contrastText} transform="translate(110 90.0007)"/>
      </clipPath>
      <clipPath id="clip3_3663_40522">
      <rect width="15" height="11" fill={theme.palette.primary.contrastText} transform="translate(150 219.001)"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default Ecommerce4;
