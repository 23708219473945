import { useTheme } from "@mui/material";

const Retail3 = () => {
  const theme = useTheme();

  return (
    <svg width="255" height="249" viewBox="0 0 255 249" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3663_39652)">
      <path d="M34.9933 240.38C25.6333 240.38 16.7066 238.517 8.21326 234.79C-0.280072 230.977 -7.69007 225.647 -14.0167 218.8C-20.2567 211.953 -24.7201 203.893 -27.4067 194.62L6.91326 185.52C8.73326 192.367 12.2866 197.697 17.5733 201.51C22.9466 205.237 28.7533 207.1 34.9933 207.1C40.3666 207.1 45.2633 205.8 49.6833 203.2C54.1899 200.6 57.7433 197.09 60.3433 192.67C63.0299 188.25 64.3733 183.353 64.3733 177.98C64.3733 169.92 61.5999 163.03 56.0533 157.31C50.5933 151.503 43.5733 148.6 34.9933 148.6C32.3933 148.6 29.8799 148.99 27.4533 149.77C25.0266 150.463 22.6866 151.46 20.4333 152.76L4.57326 124.94L64.1133 75.54L66.7133 83.0801H-20.1267V49.8H96.8733V83.0801L50.5933 127.8L50.3333 117.92C61.0799 119.567 70.1366 123.337 77.5033 129.23C84.9566 135.037 90.5899 142.23 94.4033 150.81C98.3033 159.303 100.253 168.36 100.253 177.98C100.253 189.767 97.2633 200.383 91.2833 209.83C85.3033 219.19 77.3733 226.643 67.4933 232.19C57.6133 237.65 46.7799 240.38 34.9933 240.38Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <g clipPath="url(#clip1_3663_39652)">
      <path d="M176.421 14.5828H137.314C136.11 14.5828 135.135 15.5617 135.135 16.7693V16.87C135.135 18.0776 136.11 19.0566 137.314 19.0566H176.421C177.624 19.0566 178.6 18.0776 178.6 16.87V16.7693C178.6 15.5617 177.624 14.5828 176.421 14.5828Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M228.635 2.55933H85.1004C82.1855 2.57105 79.3015 3.15903 76.6131 4.28967C73.9247 5.42032 71.4846 7.07147 69.4323 9.14879C67.3799 11.2261 65.7555 13.6889 64.6519 16.3964C63.5483 19.104 62.987 22.0032 63.0002 24.9285V385.072C62.987 387.997 63.5483 390.896 64.6519 393.604C65.7555 396.311 67.3799 398.774 69.4323 400.851C71.4846 402.929 73.9247 404.58 76.6131 405.711C79.3015 406.841 82.1855 407.429 85.1004 407.441H146.475C146.535 407.221 146.664 407.026 146.844 406.887C147.024 406.747 147.245 406.671 147.472 406.669H166.391C166.618 406.671 166.839 406.747 167.019 406.887C167.199 407.026 167.329 407.221 167.388 407.441H228.635C231.549 407.429 234.434 406.841 237.122 405.711C239.81 404.58 242.25 402.929 244.303 400.851C246.355 398.774 247.979 396.311 249.083 393.604C250.187 390.896 250.748 387.997 250.735 385.072V24.9285C250.748 22.0032 250.187 19.104 249.083 16.3964C247.979 13.6889 246.355 11.2261 244.303 9.14879C242.25 7.07147 239.81 5.42032 237.122 4.28967C234.434 3.15903 231.549 2.57105 228.635 2.55933Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M234.24 34.4353H79.4946C74.5243 34.4353 70.4951 38.4789 70.4951 43.4668V369.889C70.4951 374.877 74.5243 378.92 79.4946 378.92H234.24C239.211 378.92 243.24 374.877 243.24 369.889V43.4668C243.24 38.4789 239.211 34.4353 234.24 34.4353Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <mask id="mask0_3663_39652" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="70" y="33" width="174" height="346">
      <path d="M234.039 34H79.9606C75.0118 34 71 38.0379 71 43.0188V368.981C71 373.962 75.0118 378 79.9606 378H234.039C238.988 378 243 373.962 243 368.981V43.0188C243 38.0379 238.988 34 234.039 34Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      </mask>
      <g mask="url(#mask0_3663_39652)">
      <rect x="63.4429" y="35" width="187" height="13" rx="6" fill={theme.palette.primary.main} fillOpacity="0.8"/>
      <rect x="63.4429" y="35" width="187" height="38" rx="6" fill={theme.palette.primary.main} fillOpacity="0.4"/>
      </g>
      <path d="M250.735 118.879V90.1346H250.974C251.554 90.1346 252.109 90.3655 252.519 90.7766C252.929 91.1878 253.159 91.7454 253.159 92.3268V116.687C253.159 117.27 252.928 117.829 252.517 118.241C252.107 118.653 251.55 118.885 250.969 118.885H250.729L250.735 118.879Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M250.735 145.498V202.204C251.379 202.204 251.997 201.947 252.453 201.49C252.908 201.032 253.164 200.412 253.164 199.766V147.959C253.163 147.313 252.906 146.694 252.451 146.238C251.995 145.782 251.378 145.526 250.735 145.526V145.498Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M120.898 27.1654C123.36 27.1654 125.356 25.1624 125.356 22.6916C125.356 20.2208 123.36 18.2178 120.898 18.2178C118.436 18.2178 116.44 20.2208 116.44 22.6916C116.44 25.1624 118.436 27.1654 120.898 27.1654Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M118.724 24.6879C118.332 24.2512 118.077 23.7075 117.992 23.1255C117.907 22.5435 117.996 21.9493 118.248 21.418C118.499 20.8867 118.902 20.4422 119.405 20.1406C119.908 19.839 120.489 19.6938 121.075 19.7235C121.66 19.7532 122.224 19.9564 122.694 20.3073C123.164 20.6583 123.521 21.1413 123.717 21.6954C123.914 22.2494 123.943 22.8496 123.8 23.42C123.657 23.9905 123.349 24.5057 122.915 24.9004C122.626 25.1636 122.289 25.3669 121.921 25.4987C121.554 25.6305 121.165 25.6882 120.775 25.6685C120.386 25.6487 120.004 25.5519 119.652 25.3836C119.3 25.2154 118.985 24.9789 118.724 24.6879Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M95.1641 24.5371C94.7996 24.0745 94.58 23.5138 94.5331 22.926C94.4863 22.3381 94.6142 21.7495 94.9008 21.2347C95.1873 20.7198 95.6196 20.3018 96.143 20.0336C96.6663 19.7654 97.2572 19.659 97.8408 19.7279C98.4244 19.7968 98.9745 20.0379 99.4216 20.4207C99.8686 20.8035 100.192 21.3108 100.352 21.8783C100.512 22.4458 100.5 23.0482 100.319 23.609C100.137 24.1699 99.7938 24.6642 99.3323 25.0292C99.0266 25.272 98.6761 25.4518 98.3011 25.5585C97.926 25.6651 97.5336 25.6965 97.1465 25.6508C96.7593 25.6051 96.3849 25.4832 96.0448 25.2921C95.7046 25.101 95.4053 24.8444 95.1641 24.5371Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M95.833 24.0058C95.5731 23.676 95.4165 23.2761 95.3832 22.8569C95.3498 22.4377 95.4412 22.018 95.6456 21.6509C95.8501 21.2839 96.1585 20.9859 96.5317 20.7948C96.905 20.6037 97.3264 20.5281 97.7425 20.5774C98.1587 20.6268 98.5509 20.799 98.8695 21.0721C99.1881 21.3453 99.4187 21.7072 99.5323 22.112C99.6458 22.5168 99.6371 22.9463 99.5073 23.3461C99.3775 23.746 99.1324 24.0982 98.803 24.3582C98.5851 24.5311 98.3353 24.6592 98.068 24.7351C97.8007 24.811 97.5211 24.8332 97.2452 24.8005C96.9692 24.7678 96.7025 24.6807 96.4602 24.5444C96.2178 24.408 96.0047 24.225 95.833 24.0058Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <rect x="83" y="82" width="148" height="10" rx="2" fill={theme.palette.primary.contrastText}/>
      <rect x="83" y="82" width="148" height="10" rx="2" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <rect x="83" y="96" width="148" height="3" rx="1.5" fill={theme.palette.primary.contrastText}/>
      <rect x="83" y="96" width="148" height="3" rx="1.5" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <g clipPath="url(#clip2_3663_39652)">
      <path d="M156.931 181.989C152.4 181.989 147.868 182.025 143.336 181.964C142.065 181.975 140.799 181.801 139.577 181.45C135.677 180.22 133.38 177.438 133.099 173.365C132.726 168.001 133.353 162.719 135.945 157.889C137.862 154.308 140.843 152.226 144.936 152.097C145.836 152.07 146.822 152.448 147.661 152.858C149.345 153.674 150.88 154.806 152.586 155.536C156.464 157.185 160.204 156.505 163.774 154.469C164.403 154.134 165 153.742 165.559 153.298C167.378 151.771 169.396 151.844 171.502 152.437C174.537 153.293 176.693 155.244 178.116 158.003C180.717 163.054 181.387 168.502 180.813 174.06C180.33 178.672 176.371 181.884 171.433 181.961C166.604 182.038 161.767 181.977 156.94 181.977L156.931 181.989Z" fill={theme.palette.primary.main}/>
      <path d="M142.788 137.981C142.78 135.216 143.586 132.51 145.105 130.208C146.625 127.905 148.788 126.108 151.321 125.046C153.854 123.983 156.644 123.702 159.336 124.238C162.028 124.774 164.501 126.103 166.443 128.057C168.384 130.011 169.707 132.502 170.242 135.214C170.778 137.926 170.503 140.737 169.451 143.291C168.4 145.845 166.62 148.027 164.337 149.561C162.053 151.095 159.37 151.911 156.626 151.907C154.807 151.919 153.004 151.567 151.321 150.871C149.638 150.176 148.108 149.151 146.821 147.855C145.534 146.56 144.515 145.02 143.822 143.325C143.13 141.63 142.778 139.814 142.788 137.981Z" fill={theme.palette.primary.main}/>
      </g>
      <rect x="100" y="121" width="114" height="64" rx="7" stroke={theme.palette.primary.main} strokeWidth="2" strokeDasharray="22 120"/>
      <rect x="84" y="108" width="146" height="92" rx="11" stroke={theme.palette.primary.main} strokeWidth="2"/>
      <rect x="83" y="209" width="148" height="25" rx="6" fill={theme.palette.primary.main}/>
      <path d="M159.321 221.604C159.321 222.42 159.325 223.237 159.321 224.051C159.316 224.782 158.941 225.168 158.219 225.176C157.401 225.185 156.582 225.185 155.762 225.176C155.022 225.168 154.651 224.785 154.65 224.025C154.65 222.382 154.638 220.739 154.658 219.097C154.662 218.768 154.562 218.7 154.266 218.717C153.823 218.742 153.378 218.727 152.932 218.722C152.581 218.722 152.289 218.598 152.143 218.247C151.997 217.897 152.128 217.608 152.377 217.353C153.719 215.999 155.059 214.645 156.396 213.293C156.781 212.906 157.183 212.902 157.564 213.285C158.916 214.643 160.263 216.005 161.606 217.371C161.846 217.615 161.964 217.899 161.831 218.237C161.69 218.592 161.401 218.718 161.049 218.722C160.558 218.729 160.066 218.733 159.575 218.722C159.375 218.717 159.316 218.779 159.318 218.982C159.327 219.855 159.321 220.729 159.321 221.604Z" fill={theme.palette.primary.contrastText}/>
      <path d="M156.987 229.304C155.327 229.304 153.666 229.304 152.003 229.304C149.636 229.3 148.002 227.642 148.002 225.241C148.002 224.059 147.997 222.876 148.002 221.691C148.006 221.212 148.303 220.859 148.73 220.783C148.933 220.744 149.143 220.777 149.323 220.877C149.504 220.978 149.645 221.139 149.721 221.333C149.791 221.513 149.784 221.704 149.784 221.892C149.784 223.004 149.81 224.117 149.777 225.229C149.737 226.606 150.751 227.516 152.031 227.501C155.329 227.46 158.626 227.46 161.923 227.501C163.211 227.517 164.234 226.601 164.184 225.199C164.144 224.078 164.177 222.953 164.175 221.83C164.175 221.539 164.209 221.262 164.424 221.043C164.541 220.916 164.692 220.827 164.858 220.787C165.025 220.747 165.2 220.758 165.36 220.818C165.697 220.932 165.941 221.192 165.943 221.546C165.953 223.037 166.083 224.533 165.893 226.01C165.644 227.943 164.081 229.275 162.14 229.3C160.427 229.322 158.706 229.304 156.987 229.304Z" fill={theme.palette.primary.contrastText}/>
      <rect x="83" y="242" width="148" height="10" rx="2" fill={theme.palette.primary.contrastText}/>
      <rect x="83" y="242" width="148" height="10" rx="2" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <rect x="95.4429" y="54" width="136" height="14" rx="7" fill={theme.palette.primary.contrastText}/>
      <circle cx="104.443" cy="61" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="221.443" cy="61" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="208.443" cy="61" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="113.443" y="58" width="86" height="7" rx="3.5" fill={theme.palette.primary.main} fillOpacity="0.3"/>
      <path d="M85.0004 57.5575L83.9429 56.5L79.4429 61L83.9429 65.5L85.0004 64.4425L81.5654 61L85.0004 57.5575Z" fill={theme.palette.primary.contrastText}/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_3663_39652">
      <rect width="255" height="249" fill={theme.palette.primary.contrastText}/>
      </clipPath>
      <clipPath id="clip1_3663_39652">
      <rect width="195" height="249" fill={theme.palette.primary.contrastText} transform="translate(60)"/>
      </clipPath>
      <clipPath id="clip2_3663_39652">
      <rect width="48" height="58" fill={theme.palette.primary.contrastText} transform="translate(133 124)"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default Retail3;
