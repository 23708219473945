import { createContext, FC, useState, useRef } from "react";
import getWindowDimensions from "helpers/getWindowDimensions";

interface IContext {
  viewportWidth: number;
  viewportHeight: number;
  bookDemoRef?: any;
}

export const GeneralContext = createContext<IContext>({
  viewportWidth: getWindowDimensions().width,
  viewportHeight: getWindowDimensions().height,
  bookDemoRef: null,
});

export const GeneralContextProvider: FC = ({ children }) => {
  const [viewportWidth, setViewportWidth] = useState<number>(getWindowDimensions().width);
  const [viewportHeight, setViewportHeight] = useState<number>(getWindowDimensions().height);
  const bookDemoRef = useRef(null)

  window.addEventListener('resize', () => {
    const windowDimensions = getWindowDimensions();
    setViewportWidth(windowDimensions.width);
    setViewportHeight(windowDimensions.height);
  });

  return (
    <GeneralContext.Provider
      value={{
        viewportWidth,
        viewportHeight,
        bookDemoRef
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};
