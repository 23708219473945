import { styled } from "@mui/system";

export const LogoContainer = styled("a")`
  box-sizing: border-box;
  width: 196px;
  max-width: 40%;
  height: 92px;
  max-height: 20vw;
  margin: 0 12px 16px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F3F4F6;
  :hover {
    background: transparent;
  }
  :active {
    background: transparent;
  }
`;

export const LogoImage = styled("img")`
  width: 70%;
  max-width: 70%;
  max-height: 60%;
  pointer-events: none;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
