import { FC } from "react";
import {
  Container,
  Text,
} from "./styledComponents";

interface IPlatformReleaseBannerProps {
  text: string;
};

const PlatformReleaseBanner: FC<IPlatformReleaseBannerProps> = ({ text }) => {
  return (
    <Container>
      <Text>
        {text}
      </Text>
    </Container>
  );
};

export default PlatformReleaseBanner;
