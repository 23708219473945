import { useTheme } from "@mui/material";
const Ecommerce2 = () => {
  const theme = useTheme();

  return (
    <svg width="253" height="241" viewBox="0 0 253 241" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3663_40115)">
      <path d="M-22.957 231.74V201.06L55.563 131.38C61.4563 126.18 65.6163 121.283 68.043 116.69C70.4696 112.097 71.683 107.807 71.683 103.82C71.683 98.1 70.513 93.03 68.173 88.61C65.833 84.1034 62.5396 80.5934 58.293 78.08C54.0463 75.48 49.063 74.18 43.343 74.18C37.363 74.18 32.0763 75.5667 27.483 78.34C22.9763 81.0267 19.4663 84.6234 16.953 89.13C14.4396 93.55 13.2696 98.36 13.443 103.56H-22.957C-22.957 90.7334 -20.1404 79.64 -14.507 70.28C-8.8737 60.92 -1.03036 53.6834 9.02297 48.57C19.0763 43.4567 30.7763 40.9 44.123 40.9C56.343 40.9 67.263 43.5867 76.883 48.96C86.5896 54.2467 94.2163 61.6567 99.763 71.19C105.31 80.7234 108.083 91.7734 108.083 104.34C108.083 113.613 106.783 121.327 104.183 127.48C101.583 133.547 97.7263 139.18 92.613 144.38C87.4996 149.493 81.2163 155.3 73.763 161.8L23.063 206.26L20.203 198.46H108.083V231.74H-22.957Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <g clipPath="url(#clip1_3663_40115)">
      <path d="M176.421 15.5828H137.314C136.11 15.5828 135.135 16.5617 135.135 17.7693V17.87C135.135 19.0776 136.11 20.0566 137.314 20.0566H176.421C177.624 20.0566 178.6 19.0776 178.6 17.87V17.7693C178.6 16.5617 177.624 15.5828 176.421 15.5828Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M228.635 3.55933H85.1004C82.1855 3.57105 79.3015 4.15903 76.6131 5.28967C73.9247 6.42032 71.4846 8.07147 69.4323 10.1488C67.3799 12.2261 65.7555 14.6889 64.6519 17.3964C63.5483 20.104 62.987 23.0032 63.0002 25.9285V386.072C62.987 388.997 63.5483 391.896 64.6519 394.604C65.7555 397.311 67.3799 399.774 69.4323 401.851C71.4846 403.929 73.9247 405.58 76.6131 406.711C79.3015 407.841 82.1855 408.429 85.1004 408.441H146.475C146.535 408.221 146.664 408.026 146.844 407.887C147.024 407.747 147.245 407.671 147.472 407.669H166.391C166.618 407.671 166.839 407.747 167.019 407.887C167.199 408.026 167.329 408.221 167.388 408.441H228.635C231.549 408.429 234.434 407.841 237.122 406.711C239.81 405.58 242.25 403.929 244.303 401.851C246.355 399.774 247.979 397.311 249.083 394.604C250.187 391.896 250.748 388.997 250.735 386.072V25.9285C250.748 23.0032 250.187 20.104 249.083 17.3964C247.979 14.6889 246.355 12.2261 244.303 10.1488C242.25 8.07147 239.81 6.42032 237.122 5.28967C234.434 4.15903 231.549 3.57105 228.635 3.55933Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M234.24 35.4353H79.4946C74.5243 35.4353 70.4951 39.4789 70.4951 44.4668V370.889C70.4951 375.877 74.5243 379.92 79.4946 379.92H234.24C239.211 379.92 243.24 375.877 243.24 370.889V44.4668C243.24 39.4789 239.211 35.4353 234.24 35.4353Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <mask id="mask0_3663_40115" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="69" y="34" width="176" height="347">
      <path d="M234.24 35.4353H79.4946C74.5243 35.4353 70.4951 39.4789 70.4951 44.4668V370.889C70.4951 375.877 74.5243 379.92 79.4946 379.92H234.24C239.211 379.92 243.24 375.877 243.24 370.889V44.4668C243.24 39.4789 239.211 35.4353 234.24 35.4353Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      </mask>
      <g mask="url(#mask0_3663_40115)">
      <rect x="63.4429" y="36" width="187" height="13" rx="6" fill={theme.palette.primary.main} fillOpacity="0.8"/>
      <rect x="63.4429" y="36" width="187" height="38" rx="6" fill={theme.palette.primary.main} fillOpacity="0.4"/>
      </g>
      <path d="M250.735 119.879V91.1346H250.974C251.554 91.1346 252.109 91.3655 252.519 91.7766C252.929 92.1878 253.159 92.7454 253.159 93.3268V117.687C253.159 118.27 252.928 118.829 252.517 119.241C252.107 119.653 251.55 119.885 250.969 119.885H250.729L250.735 119.879Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M250.735 146.498V203.204C251.379 203.204 251.997 202.947 252.453 202.49C252.908 202.032 253.164 201.412 253.164 200.766V148.959C253.163 148.313 252.906 147.694 252.451 147.238C251.995 146.782 251.378 146.526 250.735 146.526V146.498Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M120.898 28.1654C123.36 28.1654 125.356 26.1624 125.356 23.6916C125.356 21.2208 123.36 19.2178 120.898 19.2178C118.436 19.2178 116.44 21.2208 116.44 23.6916C116.44 26.1624 118.436 28.1654 120.898 28.1654Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M118.724 25.6879C118.332 25.2512 118.077 24.7075 117.992 24.1255C117.907 23.5435 117.996 22.9493 118.248 22.418C118.499 21.8867 118.902 21.4422 119.405 21.1406C119.908 20.839 120.489 20.6938 121.075 20.7235C121.66 20.7532 122.224 20.9564 122.694 21.3073C123.164 21.6583 123.521 22.1413 123.717 22.6954C123.914 23.2494 123.943 23.8496 123.8 24.42C123.657 24.9905 123.349 25.5057 122.915 25.9004C122.626 26.1636 122.289 26.3669 121.921 26.4987C121.554 26.6305 121.165 26.6882 120.775 26.6685C120.386 26.6487 120.004 26.5519 119.652 26.3836C119.3 26.2154 118.985 25.9789 118.724 25.6879Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M95.1641 25.5371C94.7996 25.0745 94.58 24.5138 94.5331 23.926C94.4863 23.3381 94.6142 22.7495 94.9008 22.2347C95.1873 21.7198 95.6196 21.3018 96.143 21.0336C96.6663 20.7654 97.2572 20.659 97.8408 20.7279C98.4244 20.7968 98.9745 21.0379 99.4216 21.4207C99.8686 21.8035 100.192 22.3108 100.352 22.8783C100.512 23.4458 100.5 24.0482 100.319 24.609C100.137 25.1699 99.7938 25.6642 99.3323 26.0292C99.0266 26.272 98.6761 26.4518 98.3011 26.5585C97.926 26.6651 97.5336 26.6965 97.1465 26.6508C96.7593 26.6051 96.3849 26.4832 96.0448 26.2921C95.7046 26.101 95.4053 25.8444 95.1641 25.5371Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M95.833 25.0058C95.5731 24.676 95.4165 24.2761 95.3832 23.8569C95.3498 23.4377 95.4412 23.018 95.6456 22.6509C95.8501 22.2839 96.1585 21.9859 96.5317 21.7948C96.905 21.6037 97.3264 21.5281 97.7425 21.5774C98.1587 21.6268 98.5509 21.799 98.8695 22.0721C99.1881 22.3453 99.4187 22.7072 99.5323 23.112C99.6458 23.5168 99.6371 23.9463 99.5073 24.3461C99.3775 24.746 99.1324 25.0982 98.803 25.3582C98.5851 25.5311 98.3353 25.6592 98.068 25.7351C97.8007 25.811 97.5211 25.8332 97.2452 25.8005C96.9692 25.7678 96.7025 25.6807 96.4602 25.5444C96.2178 25.408 96.0047 25.225 95.833 25.0058Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <circle cx="156.443" cy="106" r="23" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="83.4429" y="138" width="146" height="9" rx="4.5" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <rect x="83.4429" y="156" width="146" height="9" rx="4.5" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <g clipPath="url(#clip2_3663_40115)">
      <path d="M162.191 187.669L166.17 190.358C166.484 190.569 166.791 190.792 167.114 190.987C168.099 191.581 169.25 191.023 169.421 189.874C169.436 189.76 169.443 189.645 169.441 189.53C169.441 185.514 169.441 181.497 169.441 177.481C169.441 176.4 168.774 175.723 167.819 175.774C167.44 175.794 167.139 175.98 166.841 176.183L162.194 179.331C162.194 178.691 162.194 178.149 162.194 177.608C162.183 175.439 160.783 174.003 158.652 174.001C154.764 173.994 150.876 173.994 146.988 174.001C144.856 174.008 143.449 175.445 143.443 177.607C143.443 181.531 143.443 185.454 143.443 189.375C143.449 191.572 144.856 193 147.029 193C150.882 193 154.733 193 158.58 193C160.792 193 162.185 191.586 162.191 189.349C162.193 188.831 162.191 188.315 162.191 187.669Z" fill={theme.palette.primary.main}/>
      <rect x="151.5" y="178" width="3" height="10" rx="1.5" fill={theme.palette.primary.contrastText}/>
      <rect x="148" y="184.5" width="3" height="10" rx="1.5" transform="rotate(-90 148 184.5)" fill={theme.palette.primary.contrastText}/>
      </g>
      <rect x="83.4429" y="202" width="146" height="25" rx="6" fill={theme.palette.primary.main} fillOpacity="0.3"/>
      <circle cx="169.5" cy="85.5" r="9.5" fill={theme.palette.primary.main}/>
      <path d="M169.058 90V83.136L167.438 84.132V82.332L169.058 81.36H170.726V90H169.058Z" fill={theme.palette.primary.contrastText}/>
      <g clipPath="url(#clip3_3663_40115)">
      <path d="M166.349 103.015C163.175 105.63 160.097 108.148 157.053 110.702C156.589 111.097 156.344 111.019 155.932 110.667C152.893 108.056 149.835 105.481 146.78 102.896C146.676 102.808 146.594 102.663 146.37 102.679V103.375C146.37 107.694 146.361 112.012 146.38 116.33C146.38 116.835 146.276 116.988 145.78 116.988C143.959 116.954 143 115.969 143 114.023C143 109.332 143 104.641 143 99.9508C143 98.0095 143.976 97.0105 145.788 97C146.193 97 146.434 97.2132 146.697 97.4462C149.918 100.265 153.136 103.088 156.351 105.916C156.696 106.22 156.892 106.258 157.253 105.927C160.277 103.16 163.32 100.422 166.356 97.6712C167.633 96.517 169.517 97.2145 169.903 98.9874C169.967 99.3086 169.997 99.6367 169.993 99.9653C169.999 104.656 169.993 109.347 170 114.037C170 116.256 168.61 117.186 166.578 116.963C166.196 116.921 166.358 116.599 166.356 116.401C166.344 114.122 166.349 111.842 166.349 109.557V103.015Z" fill={theme.palette.primary.contrastText}/>
      </g>
      <rect x="95.4429" y="55" width="136" height="14" rx="7" fill={theme.palette.primary.contrastText}/>
      <circle cx="104.443" cy="62" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="221.443" cy="62" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="208.443" cy="62" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="113.443" y="59" width="86" height="7" rx="3.5" fill={theme.palette.primary.main} fillOpacity="0.3"/>
      <path d="M85.0004 58.5575L83.9429 57.5L79.4429 62L83.9429 66.5L85.0004 65.4425L81.5654 62L85.0004 58.5575Z" fill={theme.palette.primary.contrastText}/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_3663_40115">
      <rect width="253" height="241" fill={theme.palette.primary.contrastText}/>
      </clipPath>
      <clipPath id="clip1_3663_40115">
      <rect width="195" height="241" fill={theme.palette.primary.contrastText} transform="translate(58)"/>
      </clipPath>
      <clipPath id="clip2_3663_40115">
      <rect width="26" height="19" fill={theme.palette.primary.contrastText} transform="matrix(1 0 0 -1 143.443 193)"/>
      </clipPath>
      <clipPath id="clip3_3663_40115">
      <rect width="27" height="18" fill={theme.palette.primary.contrastText} transform="translate(143 97)"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default Ecommerce2;
