import React from "react";
import {
  CirclesContainer,
  LargerCircle,
  LargeCircle,
  MediumCircle,
  SmallCircle,
  SmallerCircle,
} from "./styledComponents";

interface Props {
  children: JSX.Element;
}

const CirclesBackground = ({children}:Props) => {
  return (
    <CirclesContainer component="section">
      <LargerCircle />
      <LargeCircle />
      <MediumCircle />
      <SmallCircle />
      <SmallerCircle />
      {children}
    </CirclesContainer>
  );
};

export default CirclesBackground;
