import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "317px",
  minWidth: "317px",
  height: "397px",
  margin: "0px 12px",
  paddingTop: "29px",
  background: theme.palette.primary.contrastText,
  borderRadius: "32px",
  boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)",
  overflow: "hidden",
  "@media (max-width: 382px)": {
    minWidth: "calc(90vw - 34px)",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  width: "100%",
  marginBottom: "8px",
  color: theme.palette.primary.main,
  textAlign: "center",
  fontSize: "24px",
  fontWeight: 800,
  lineHeight: "32.02px",
}));

export const Text = styled(Typography)(() => ({
  width: "264px",
  maxWidth: "85%",
  margin: "0px auto auto auto",
  color: "rgba(37, 37, 37, 1)",
  textAlign: "justify",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20.02px",
  letterSpacing: "0.15px",
}));

export const ImageContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: "-6px",
  left: "0px",
}));
