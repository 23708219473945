import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ContactSalesButton, ScheduleButton } from "components/Atoms";
import { SCHEDULE_CALL } from "config";
import {
  Container,
  InnerContainer,
  TitleContainer,
  Title,
  TitleSecondLine,
  Subtitle,
  LastSubtitle,
  ActionsContainer,
} from "./styledComponents";
import useStyles from "./useStyles";

const PlatformsHeader: FC = () => {
  const { t, i18n } = useTranslation("Integrations");
  const currentLocale = i18n.language;
  const isPT = currentLocale.includes("pt-");
  const isEN = currentLocale.includes("en-");
  const classes = useStyles();

  return (
    <Container>
      <InnerContainer>
        <TitleContainer>
          <Title>
            {t("header.title.firstLine")}
          </Title>
          <TitleSecondLine>
            {t("header.title.secondLine")}
          </TitleSecondLine>
        </TitleContainer>
        <Subtitle isPT={isPT} isEN={isEN}>
          {t("header.subtitle1")}
        </Subtitle>
        <LastSubtitle isPT={isPT} isEN={isEN}>
          {t("header.subtitle2")}
        </LastSubtitle>
        <ActionsContainer>
          <ScheduleButton text={t("header.primaryButton")} to="/#MeetingsCalendar" reload/>
          <ContactSalesButton
            colorType="contrastBG"
            text={t("header.secondaryButton")}
            className={classes.secondaryButton}
          />
        </ActionsContainer>
      </InnerContainer>
    </Container>
  );
};

export default PlatformsHeader;
