import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const CirclesContainer = styled(Box)(() => ({
  position: "relative",
  height: "1420px",
  background: "white",
  overflow: "hidden",
}));

export const Circle = styled("div")(() => ({
  position: "absolute",
  border: "2px solid rgba(140, 140, 140, 0.1)",
  borderRadius: "50%",
}));

export const SmallerCircle = styled(Circle)(() => ({
  width: "368px",
  height: "368px",
  top: "490px",
  left: "calc(50% - 184px)",
}));

export const SmallCircle = styled(Circle)(() => ({
  width: "762px",
  height: "762px",
  top: "293px",
  left: "calc(50% - 381px)",
}));

export const MediumCircle = styled(Circle)(() => ({
  width: "1160px",
  height: "1160px",
  top: "94px",
  left: "calc(50% - 580px)",
}));

export const LargeCircle = styled(Circle)(() => ({
  width: "1558px",
  height: "1558px",
  top: "-105px",
  left: "calc(50% - 779px)",
}));

export const LargerCircle = styled(Circle)(() => ({
  width: "1968px",
  height: "1968px",
  top: "-310px",
  left: "calc(50% - 984px)",
}));
