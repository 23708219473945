import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)(() => ({
  position: "absolute",
  top: "20px",
  left: "-40px",
  width: "184px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(255, 34, 102, 1)",
  transform: "rotate(-30deg)",
}));

export const Text = styled(Typography)(() => ({
  color: "white",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "24px",
  letterSpacing: "0.4px",
}));
