import apiPoster from "./api-platform-poster.png"
import magentoPoster from "./magento-platform-poster.png"
import nuvemshopPoster from "./nuvemshop-platform-poster.png"
import shopifyPoster from "./shopify-platform-poster.png"
import tiendanubePoster from "./tiendanube-platform-poster.png"
import vtexPoster from "./vtex-platform-poster.png"
import woocommercePoster from "./woocommerce-platform-poster.png"

const posters = {
  api: apiPoster,
  magento: magentoPoster,
  nuvemshop: nuvemshopPoster,
  shopify: shopifyPoster,
  tiendanube: tiendanubePoster,
  vtex: vtexPoster,
  woocommerce: woocommercePoster,
};

export default posters;
