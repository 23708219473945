import { styled } from "@mui/system";
import { TextField } from "@mui/material";


export const SearchBar = styled(TextField)(() => ({
  width: "35%",
  "@media (max-width: 850px)": {
    width: "100%",
  },
}));
