import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const CustomSelectContainer = styled(Box)`
  z-index: 20;
  position: absolute;
  top: 20px;
  right: 30px;
  border: 2px solid rgba(98, 66, 232, 1);
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  :hover {
    border: 2px solid rgba(98, 66, 232, 0.5);
  }
  @media (max-width: 775px) {
    top: 10px;
    right: 20px;
  }
`;

export const SelectedContainer = styled("div")`
  z-index: 21;
  min-width: 85px;
  height: 28px;
  display: flex;
  :focus {
    opacity: 0.2;
  }
`;

export const SelectedCountryParagraph = styled("p")`
  width: 42px;
  margin: 0 2px 0 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(98, 66, 232, 1);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const CustomOptionContainer = styled("div")`
  width: 100%;
  height: 28px;
  padding: 4px 0px 4px 12px;
  display: flex;
  background: rgba(234, 231, 255, 1);
  color: rgba(98, 66, 232, 1);
  border-top: 1px solid rgba(98, 66, 232, 0.3);
  :hover {
    background: rgba(98, 66, 232, 1);
    color: white;
  }
`;

export const OptionCountryText = styled("p")`
  width: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
`;
