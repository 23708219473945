import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  MainHeroMovingBG,
  MainHeroVideo,
  SignalNoiseTextureBG,
} from "components";
import {
  Container,
  Title,
  Span,
} from "./styledComponents";

const MainHero: FC = () => {
  const { t } = useTranslation("Main");

  return (
    <Container component="section">
      <SignalNoiseTextureBG />
      <MainHeroMovingBG />
      <Title variant="h1">
        <Span>
          {t("Hero.titleSpan")}
        </Span>
        {t("Hero.title")}
      </Title>
      <MainHeroVideo />
    </Container>
  );
};

export default MainHero;
