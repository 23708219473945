import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Background,
  Thumb,
  LeftText,
  RightText,
} from "./styledComponents";

interface Props {
  leftSelected: boolean;
  handleLeftClick: () => void;
  handleRightClick: () => void;
}

const EndToEndSwitch: FC<Props> = ({
  leftSelected,
  handleLeftClick,
  handleRightClick,
}) => {
  const { t } = useTranslation("Main");

  return (
    <Container>
      <Background />
      <Thumb leftSelected={leftSelected} />
      <LeftText
        onClick={handleLeftClick}
        leftSelected={leftSelected}
      >
        {t("EndToEnd.switch.left")}
      </LeftText>
      <RightText
        onClick={handleRightClick}
        leftSelected={leftSelected}
      >
        {t("EndToEnd.switch.right")}
      </RightText>
    </Container>
  );
};

export default EndToEndSwitch;
