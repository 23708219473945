import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  zIndex: 9999,
  position: "fixed",
  right: "20px",
  bottom: "20px",
  width: "62px",
  height: "62px",
  padding: "0px",
  borderRadius: "50%",
  "@media (max-width: 650px)": {
    right: "10px",
    bottom: "10px",
    width: "50px",
    height: "50px",
  },
}));

export const WhatsappImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  margin: "0px",
  filter: "brightness(130%)"
}));
