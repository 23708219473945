import { FC } from "react";
import {
  BrandInput,
} from "./styledComponents";
import { FormControl, Select, MenuItem } from '@mui/material';
import STORES from "helpers/createStoresObjects";
import { useTranslation } from "react-i18next";
import { STOREINTERFACE } from "types/storesInterface";
import { STORETYPE } from "types/storesCategoriesEnum";
import { LOCATION } from "types/storesLocationEnum";
import { FILTERS } from "components/Organisms/StoresSearch";

interface Props {
  handleChange: any; // NO PUDE ENCONTRAR EL TIPO
  filters: FILTERS
}

const StoresInputBrand: FC<Props> = ({ filters, handleChange }) => {

  const { t } = useTranslation("Stores");

  const alphabeticalSort = (a: STOREINTERFACE, b: STOREINTERFACE) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  }


  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
      <BrandInput id="brand-select">{t("Filters.brand")}</BrandInput>
      <Select
        labelId="brand-select"
        id="brand-select"
        label="Marca"
        name='brand'
        onChange={handleChange}
        value={filters.brand}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          }, sx: {
            maxHeight: "550px"
          }
        }}
      >
        <MenuItem value="">
          {t("Filters.everything")}
        </MenuItem>
        {
          STORES
            .filter((element: STOREINTERFACE) =>
              filters.store_type && filters.location_type
                ?
                element.store_type.includes(filters.store_type as STORETYPE) && element.location_type.includes(filters.location_type as LOCATION)
                :
                filters.store_type
                  ?
                  element.store_type.includes(filters.store_type as STORETYPE)
                  :
                  filters.location_type
                    ?
                    element.location_type.includes(filters.location_type as LOCATION)
                    :
                    element)
            .sort(alphabeticalSort)
            .map((element) => <MenuItem key={element.alt} value={element.alt}>{element.name}</MenuItem>)
        }
      </Select>
    </FormControl>
  );
};

export default StoresInputBrand;