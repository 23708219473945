import { styled } from "@mui/system";
import { Button } from "@mui/material";

export const StyledButton = styled(
  Button,
  { shouldForwardProp: (prop) => !["colorType", "margins"].includes(prop as string) }
)<{ colorType: "primaryBG" | "secondaryBG" | "contrastBG"; margins: string;}>(
  ({ theme, colorType, margins }) => ({
    width: "200px",
    height: "56px",
    margin: margins,
    padding: "8px 0px",
    color: `${
      colorType === "primaryBG"
        ? theme.palette.primary.contrastText
        : colorType === "secondaryBG"
          ? theme.palette.secondary.contrastText
          : theme.palette.primary.main
    }`,
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "28.32px",
    letterSpacing: "0.46px",
    background: `${
      colorType === "primaryBG"
      ? theme.palette.primary.main
      : colorType === "secondaryBG"
        ? theme.palette.secondary.main
        : theme.palette.primary.contrastText
    }`,
    border: `${
      colorType === "contrastBG"
        ? `1px solid ${theme.palette.primary.main}`
        : ""
    }`,
    borderRadius: "16px",
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
    textTransform: "none",
    "& svg": {
      fontSize: "26px !important",
    },
    "&:hover, &:focus, &:active ": {
      color: `${
        colorType === "primaryBG"
        ? theme.palette.primary.main
        : colorType === "secondaryBG"
          ? theme.palette.secondary.main
          : theme.palette.primary.contrastText
      }`,
      background: `${
        colorType === "primaryBG"
          ? theme.palette.primary.contrastText
          : colorType === "secondaryBG"
            ? theme.palette.secondary.contrastText
            : theme.palette.primary.main
      }`,
      border: `${
        colorType === "contrastBG" || colorType === "secondaryBG"
          ? ""
          : `1px solid ${theme.palette.primary.main}`
      }`,
    },
  })
);
