import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { PricingPlansContext } from 'context';
import {
  StartupMidContainer,
  StartupMidUpperText,
  StartupMidLowerText,
} from './styledComponents';
import { PlanSlider } from 'components';
import { countriesData, scalePlanSliderSpecifics } from 'helpers/pricingPlansData';

const StartupPlanMidSection: FC = () => {
  const { countrySelected } = useContext(PricingPlansContext);
  const {t} = useTranslation("pricingPlans");
  const tr = "mainSection.pricingCard.startupMid.";

  const startupData = {
    ...countriesData[countrySelected as keyof typeof countriesData].startup,
    ...scalePlanSliderSpecifics
  };

  return (
    <StartupMidContainer>
      <StartupMidUpperText>
        {t(`${tr}upperText`)}
      </StartupMidUpperText>
      <PlanSlider sliderData={startupData} />
      <StartupMidLowerText>
        {t(`${tr}lowerText`)}
      </StartupMidLowerText>
    </StartupMidContainer>
  );
};

export default StartupPlanMidSection;
