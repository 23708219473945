import React, { FC } from "react";
import { useLightNavbar } from "hooks";
import {
  EndToEndIntegration,
  MainHero,
  SuccessAndCalendar,
  MainModalInvalidPlan,
  PageWrapper,
} from "components";

const Main: FC = () => {
  useLightNavbar(true);

  return (
    <PageWrapper>
      <main>
        <MainHero />
        <EndToEndIntegration />
        <SuccessAndCalendar />
        <MainModalInvalidPlan />
      </main>
    </PageWrapper>
  );
};

export default Main;
