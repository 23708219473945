import { styled } from "@mui/system";
import { Grid, Container } from "@mui/material";

export const Box = styled(Container)(() => ({
  marginBottom: "42px",
  justifyContent: "center",
}))

export const CardsGrid = styled(Grid)(() => ({
  columnGap: 0,
}))
