import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { PricingPlansContext } from 'context';
import {
  ScaleMidContainer,
  ScaleMidUpperText,
  PerfitImageAnchor,
  GiftyImageAnchor,
  ScaleMidLowerText,
} from './styledComponents';
import perfitLogo from 'img/pricingPlans/perfit-logo-black.svg';
import giftyLogo from 'img/pricingPlans/gifty-logo-black.svg';

const ScalePlanMidSection: FC = () => {
  const { countrySelected } = useContext(PricingPlansContext);
  const {t} = useTranslation("pricingPlans");
  const tr = "mainSection.pricingCard.scaleMid.";
  const perfitLink = "https://www.myperfit.com";
  const giftyLink = "https://gifty.flowy.website/";

  return (
    <ScaleMidContainer>
      <ScaleMidUpperText>
        {t(`${tr}upperText.start`)}
        <strong>{t(`${tr}upperText.bold${countrySelected === "ar" ? "AR" : ""}`)}</strong>
      </ScaleMidUpperText>
      <ScaleMidLowerText>
        {t(`${tr}lowerText`)}
      </ScaleMidLowerText>
      <PerfitImageAnchor
        href={perfitLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={perfitLogo} alt="Perfit logo" />
      </PerfitImageAnchor>
      <GiftyImageAnchor
        href={giftyLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={giftyLogo} alt="Gifty logo" />
      </GiftyImageAnchor>
    </ScaleMidContainer>
  );
};

export default ScalePlanMidSection;
