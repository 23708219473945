import React, { FC } from "react";
import { SignalNoiseDiv } from "./styledComponents";

const SignalNoiseTextureBG: FC = () => {
  return (
    <SignalNoiseDiv />
  );
}

export default SignalNoiseTextureBG;
