import React from "react"
import { Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

interface ICustomSelection {
  customImage: any
  defaultImage: string
  classes: any
  handleButton: () => void
  customLink: string
  buttonText: string
  imageAlt: string
}

export default function CustomSelection({
  customImage,
  defaultImage,
  classes,
  handleButton,
  customLink,
  buttonText,
  imageAlt
}: ICustomSelection) {

  const [showDefaultImage, setShowDefaultImage] = React.useState<boolean>(false)

  const onImageError = () => {
    setShowDefaultImage(true)
  }

  return (
    <Box 
      className={`${classes.imageContainer} ${imageAlt === "sender image" ? classes.sender : classes.receiver }`}
    >
      {!showDefaultImage ?
        <>
          <Link
            to={customLink}
            className={classes.customImage}
          >
            <img
              src={customImage}
              className={classes.customImage}
              alt={imageAlt}
              onError={onImageError}
            />
          </Link>
        </>
        : <>
          <img
            src={defaultImage}
            alt={imageAlt}
            className={classes.image}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleButton}
          >
            <div style={{ paddingRight: "5px" }}>{buttonText}</div>
            <ArrowForwardIcon />
          </Button>
        </>}
    </Box>
  )
}