import { FC } from "react";
import {
    CategoryInput,
} from "./styledComponents";
import { FormControl, Select, MenuItem } from '@mui/material';
import { useTranslation } from "react-i18next";
import { categories } from "./storesCategories";
import { Category } from "types/storesCategoriesInterface";
import { FILTERS } from "components/Organisms/StoresSearch";

interface Props {
  handleChange: any; // NO PUDE ENCONTRAR EL TIPO
  filters: FILTERS;
}


const StoresInputCategory: FC<Props> = ({ filters, handleChange }) => {

const language = useTranslation().i18n.language
const {t} = useTranslation("Stores");

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
    <CategoryInput id="category-select">{t("Filters.category")}</CategoryInput>
    <Select
      labelId="category-select"
      id="category-select"
      label="Rubro"
      name="store_type"
      onChange={handleChange}
      value={filters.store_type}
    >
      <MenuItem value="">
        {t("Filters.everything")}
      </MenuItem>
      {
        categories.map((element: Category) =><MenuItem key={element.store_type} value={element.store_type}>{
          language === "en" ? element.category_name.en
          :
          language === "pt" ? element.category_name.pt
          : element.category_name.es}</MenuItem>)
      }
    </Select>
  </FormControl>
  );
};

export default StoresInputCategory;