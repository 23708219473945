import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  padding: "105px 0px 50px 0px",
  display: "flex",
  justifyContent: "center",
  background: "linear-gradient(85deg, rgba(255, 255, 255, 1), rgba(238, 252, 255, 1) 55%, rgba(238, 252, 255, 1) 75%, rgba(234, 231, 255, 1))",
  "@media (max-width: 1500px)": {
    paddingBottom: "40px",
    background: "linear-gradient(90deg, rgba(234, 231, 255, 0.2), rgba(238, 252, 255, 0.4) 5%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 80%, rgba(238, 252, 255, 0.4) 95%, rgba(234, 231, 255, 0.2))",
  },
}));

export const InnerContainer = styled(Box)(() => ({
  width: "100%",
  maxWidth: "1800px",
  display: "flex",
  justifyContent: "space-evenly",
  "@media (max-width: 1500px)": {
    flexDirection: "column",
    alignItems: "center",
  },
}));
