import { STORETYPE } from "types/storesCategoriesEnum"
import { Category } from "types/storesCategoriesInterface"



export const categories: Category[] = [
    {
        store_type: STORETYPE.CLOTHING,
        category_name: {
            en: "Clothing",
            es: "Indumentaria",
            pt: "Vestuário"
        }
    },
    {
        store_type: STORETYPE.CLOTHING_ACCESSORIES,
        category_name: {
            en: "Accessories clothing",
            es: "Accesorios indumentaria",
            pt: "Acessórios de vestuário"
        }
    },
    {
        store_type: STORETYPE.GASTRONOMY,
        category_name: {
            en: "Gastronomy",
            es: "Gastronomía",
            pt: "Gastronomia"
        }
    },
    {
        store_type: STORETYPE.HOME_GARDEN,
        category_name: {
            en: "Home & Garden",
            es: "Casa y Jardín",
            pt: "Casa e jardim"
        }
    },
    {
        store_type: STORETYPE.JEWELRY,
        category_name: {
            en: "Jewelry",
            es: "Joyería",
            pt: "Jóias"
        }
    },
    {
        store_type: STORETYPE.HEALTH_BEAUTY,
        category_name: {
            en: "Health & Beauty",
            es: "Salud y Belleza",
            pt: "Saúde e beleza"
        }
    },
    {
        store_type: STORETYPE.SPORTS,
        category_name: {
            en: "Sports",
            es: "Deportes",
            pt: "Esportes"
        }
    },
    {
        store_type: STORETYPE.GIFTS,
        category_name: {
            en: "Gifts",
            es: "Regalos",
            pt: "Presentes"
        }
    },
    {
        store_type: STORETYPE.TOYS,
        category_name: {
            en: "Toys",
            es: "Juguetes",
            pt: "Brinquedos"
        }
    },
    {
        store_type: STORETYPE.ELECTRONICS_IT,
        category_name: {
            en: "Electronics",
            es: "Electrónica",
            pt: "Eletrônicos"
        }
    },
    {
        store_type: STORETYPE.OTHERS,
        category_name: {
            en: "Others",
            es: "Otros",
            pt: "Outros"
        }
    },
]