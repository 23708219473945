import { FC, useContext, useEffect } from "react";
import { LayoutContext, PricingPlansContextProvider } from "context";
import PricingPlans from "components/Blocks/PricingPlans";
import { PageWrapper } from "components";

const PricingPlansPage: FC = () => {
  const { setIsNavbarVisible } = useContext(LayoutContext);

  useEffect(() => {
    setIsNavbarVisible(false);

    return () => setIsNavbarVisible(true);
  }, [setIsNavbarVisible]);

  return (
    <PricingPlansContextProvider>
      <PageWrapper>
        <PricingPlans />
      </PageWrapper>
    </PricingPlansContextProvider>
  );
};

export default PricingPlansPage;
