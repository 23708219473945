import rafaelSanchezDecorative from "./rafael-sanchez-decorate-review.jpg";
import denisSerranoDecorative from "./denis-serrano-decorate-review.jpg";
import gabrielaWajnermanDecorative from "./gabriela-wajnerman-decorate-review.jpg";
import rafaelSanchez from "./rafael-sanchez.png";
import denisSerrano from "./denis-serrano.png";
import gabrielaWajnerman from "./gabriela-wajnerman.png";
import isadora from "../brands/isadora.svg";
import todomoda from "../brands/todomoda.svg";
import tiendanube from "./tiendanube-logo.png";
import modo from "../brands/modo.svg";

export { default as Quotes } from "./Quotes";

export const personsImages = {
  rafaelSanchez,
  denisSerrano,
  gabrielaWajnerman,
};

export const reviewsImages = {
  rafaelSanchezDecorative,
  denisSerranoDecorative,
  gabrielaWajnermanDecorative,
};

export const logosImages = {
  isadora,
  todomoda,
  tiendanube,
  modo,
};
