import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  GradientContainer,
  Title,
} from "./styledComponents";

const PrivacyTitle: FC = () => {
  const {t} = useTranslation("PrivacyTerms");

  return (
    <GradientContainer>
      <Title variant="h1">
        {t("Title.title")}
      </Title>
    </GradientContainer>
  );
};

export default PrivacyTitle;
