import { FC } from "react";
import { Box } from "@mui/material";
import PricingPlansMain from "./PricingPlansMain";
import SupportCompanies from "./SupportCompanies";
import { CountrySelector } from "components";

const PricingPlans: FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        background: "white",
        fontFamily: "'Manrope', 'Poppins', sans-serif",
        "& .MuiTypography-root": {
          fontFamily: "'Manrope', 'Poppins', sans-serif",
        }
      }}
    >
      <CountrySelector />
      <PricingPlansMain />
      <SupportCompanies />
    </Box>
  );
};

export default PricingPlans;