import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Tooltip } from 'components';
import {
  Container,
  WhatsappImage,
} from "./styledComponents";
import useStyles from "./useStyles";
import { WHATSAPP_CONTACT } from "config";
import whatsappContactImg from 'img/whatsappContact.png';

function WhatsappContact() {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const classes = useStyles();
  const {t} = useTranslation("whatsappContact")
  const tooltipText = t("tooltip");

  return (
    <Container
      onMouseEnter={() => setDisplayTooltip(true)}
      onMouseLeave={() => setDisplayTooltip(false)}
    >
      <a
        href={WHATSAPP_CONTACT}
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsappImage
          src={whatsappContactImg}
          alt="whatsapp logo"
        />
        {displayTooltip && <Tooltip text={tooltipText} specifics={classes.tooltipSpecifics} />}
      </a>
    </Container>
  );
};

export default WhatsappContact;
