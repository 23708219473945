import React, { FC } from "react";
import { EndToEndCard } from "components";
import {
  CardsContainer,
} from "./styledComponents";

interface Card {
  title: string;
  text: string;
  image: () => JSX.Element;
}

interface Props {
  cardsArray: Card[];
};

const EndToEndCardsContainer: FC<Props> = ({cardsArray}) => {
  return (
    <CardsContainer>
      {cardsArray.map((card:Card, id:number) => {
        return (
          <EndToEndCard
            title={card.title}
            text={card.text}
            Image={card.image}
            key={`${card.title}${id}`}
          />
        )
      })}
    </CardsContainer>
  );
};

export default EndToEndCardsContainer;
