import { FC, useEffect, useRef, useState } from "react";
import {
  CustomSelectContainer,
  SelectedContainer,
  SelectedCountryParagraph,
  CustomOptionContainer,
  OptionCountryText,
} from './styledComponents';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import flags from 'img/pricingPlans/countryFlags';
import useSelectCountry from "hooks/useSelectCountry";

const countries = ["ar", "br", "cl", "co", "mx"];

const CountrySelector: FC = () => {
  const [countryFlagSrc, setCountryFlagSrc] = useState(flags.ar);
  const [selectedCountryText, setSelectedCountryText] = useState<string>("ar");
  const [selectFocus, setSelectFocus] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const focused = useRef(false);
  const flagBackup = useRef<string>("");
  const textBackup = useRef<string>("");
  const selectCountry = useSelectCountry();

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const urlHasCountry = params.get("country");
    const urlCountry:string = urlHasCountry && countries.includes(urlHasCountry)
      ? urlHasCountry
      : selectedCountryText;

    setCountryFlagSrc(flags[urlCountry as keyof typeof flags]);
    setSelectedCountryText(urlCountry);
    selectCountry(urlCountry);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetHoverCountry = (value:string) => {
    setCountryFlagSrc(flags[value as keyof typeof flags]);
    setSelectedCountryText(value);
  };

  const handleRecoverFromHover = () => {
    setCountryFlagSrc(flagBackup.current);
    setSelectedCountryText(textBackup.current);
  };

  const handleCountrySelection = (value:string) => {
    selectCountry(value);
  };

  return (
    <CustomSelectContainer>
      <SelectedContainer
        ref={selectRef}
        tabIndex={0}
        onClick={() => { if (focused.current) selectRef.current?.blur() }}
        onFocus={() => {
          flagBackup.current = countryFlagSrc;
          textBackup.current = selectedCountryText;
          setSelectFocus((prev:boolean) => !prev);
          setTimeout(() => focused.current = true, 300);
        }}
        onBlur={() => {
          setSelectFocus(false);
          focused.current = false;
          if (textBackup.current !== selectedCountryText)
            handleCountrySelection(selectedCountryText);
        }}
      >
        <img src={countryFlagSrc} alt="Selected country flag" />
        <SelectedCountryParagraph>
          {selectedCountryText}
          <KeyboardArrowDownIcon sx={{fontSize: "18px", paddingTop: "2px"}}/>
        </SelectedCountryParagraph>
      </SelectedContainer>
      {selectFocus &&
        <>
          {countries.map((country:string, id:number) => {
            return (
              <CustomOptionContainer
                key={`${id}${country}`}
                onMouseEnter={() => handleSetHoverCountry(country)}
                onMouseLeave={handleRecoverFromHover}
              >
                <img
                  src={flags[country as keyof typeof flags]}
                  alt={`${country} flag`}
                />
                <OptionCountryText>{country}</OptionCountryText>
              </CustomOptionContainer>
            )
          })}
        </>
      }
    </CustomSelectContainer>
  );
};

export default CountrySelector;
