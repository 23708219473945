import { APP_URL } from "./environment";

export const EMAIL_PATTERN = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;
export const ES_CALENDLY = "https://calendly.com/leogierberg";
export const FACEBOOK_WEBSITE = "https://www.facebook.com/storydotsapp";
export const INSTAGRAM_WEBSITE = "https://www.instagram.com/storydotsapp/";
export const LINKEDIN_WEBSITE = "https://www.linkedin.com/company/storydots";
export const MAIL_TO = "mailto:hola@storydots.app";
export const ONBOARDING_WEBSITE = "https://demo.storydots.app/onboarding";
export const SCHEDULE_CALL = `${APP_URL}#MeetingsCalendar`;
export const STORES_PLATFORMS = { tiendanube: 1, api: 3 };
export const TOUR_WEBSITE = "https://demo.storydots.app/tour?api_key=Dk5hHlNafJ4zMcTWiPDkA8Cr2nWC72uV2kislLDR";
export const VIEW_DEMO = "https://demo.storydots.app/tour/cart?api_key=Dk5hHlNafJ4zMcTWiPDkA8Cr2nWC72uV2kislLDR";
export const WHATSAPP_CONTACT = "https://wa.me/5492215449395";
