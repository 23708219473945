import React, { FC } from "react";
import { EndToEndCardsContainer } from "components";
import {
  Container,
  ConveyorBand,
  LeftFade,
  RightFade,
} from "./styledComponents";
import useGetCardsArray from "./useGetCardsArrays";

interface Props {
  leftSelected: boolean;
};

const EndToEndStepsCards: FC<Props> = ({
  leftSelected,
}) => {
  const { 
    ecommerceCardsArray,
    retailCardsArray,
  } = useGetCardsArray();

  return (
    <Container>
      <LeftFade />
      <ConveyorBand leftSelected={leftSelected}>
        <EndToEndCardsContainer cardsArray={ecommerceCardsArray} />
        <EndToEndCardsContainer cardsArray={retailCardsArray} />
      </ConveyorBand>
      <RightFade />
    </Container>
  );
};

export default EndToEndStepsCards;
