import { createContext, FC, useState } from "react";
import { countriesData } from 'helpers/pricingPlansData';

const { scale, startup, unlimited } = countriesData.ar
const scalePrice = `${scale.planArray[0].price}`;
const scaleLink = `${scale.baseUrl}${scale.planArray[0].link}`;
const offerScalePrice = `${scale.planArray[0].offerPrice}`
const offerScaleLink = `${scale.baseUrl}${scale.planArray[0].offerLink}`
const startupPrice = `${startup.planArray[0].price}`;
const startupLink = `${startup.baseUrl}${startup.planArray[0].link}`;
const offerStartupPrice = `${startup.planArray[0].offerPrice}`;
const offerStartupLink = `${startup.baseUrl}${startup.planArray[0].offerLink}`;
const unlimitedPrice = `${unlimited.planArray[0].price}`;
const unlimitedLink = `${unlimited.baseUrl}${unlimited.planArray[0].link}`;
const offerUnlimitedPrice = `${unlimited.planArray[0].offerPrice}`;
const offerUnlimitedLink = `${unlimited.baseUrl}${unlimited.planArray[0].offerLink}`;

export const PricingPlansContext = createContext({
  scalePlanPrice: scalePrice,
  setScalePlanPrice: (_:string) => {},
  scalePlanLink: scaleLink,
  setScalePlanLink: (_:string) => {},
  offerScalePlanPrice: offerScalePrice,
  setOfferScalePlanPrice: (_:string) => {},
  offerScalePlanLink: offerScaleLink,
  setOfferScalePlanLink: (_:string) => {},
  startupPlanPrice: startupPrice,
  setStartupPlanPrice: (_:string) => {},
  startupPlanLink: startupLink,
  setStartupPlanLink: (_:string) => {},
  offerStartupPlanPrice: offerStartupPrice,
  setOfferStartupPlanPrice: (_:string) => {},
  offerStartupPlanLink: offerStartupLink,
  setOfferStartupPlanLink: (_:string) => {},
  unlimitedPlanPrice: unlimitedPrice,
  setUnlimitedPlanPrice: (_:string) => {},
  unlimitedPlanLink: unlimitedLink,
  setUnlimitedPlanLink: (_:string) => {},
  offerUnlimitedPlanPrice: offerUnlimitedPrice,
  setOfferUnlimitedPlanPrice: (_:string) => {},
  offerUnlimitedPlanLink: offerUnlimitedLink,
  setOfferUnlimitedPlanLink: (_:string) => {},
  countrySelected: "ar",
  setCountrySelected: (_:string) => {},
});

export const PricingPlansContextProvider: FC = ({ children }) => {
  const [scalePlanPrice, setScalePlanPrice] = useState(scalePrice);
  const [scalePlanLink, setScalePlanLink] = useState(scaleLink);
  const [offerScalePlanPrice, setOfferScalePlanPrice] = useState(offerScalePrice);
  const [offerScalePlanLink, setOfferScalePlanLink] = useState(offerScaleLink);
  const [startupPlanPrice, setStartupPlanPrice] = useState(startupPrice);
  const [startupPlanLink, setStartupPlanLink] = useState(startupLink);
  const [offerStartupPlanPrice, setOfferStartupPlanPrice] = useState(offerStartupPrice);
  const [offerStartupPlanLink, setOfferStartupPlanLink] = useState(offerStartupLink);
  const [unlimitedPlanPrice, setUnlimitedPlanPrice] = useState(unlimitedPrice);
  const [unlimitedPlanLink, setUnlimitedPlanLink] = useState(unlimitedLink);
  const [offerUnlimitedPlanPrice, setOfferUnlimitedPlanPrice] = useState(offerUnlimitedPrice);
  const [offerUnlimitedPlanLink, setOfferUnlimitedPlanLink] = useState(offerUnlimitedLink);
  const [countrySelected, setCountrySelected] = useState("ar");

  return (
    <PricingPlansContext.Provider 
      value={{
        scalePlanPrice,
        setScalePlanPrice,
        scalePlanLink,
        setScalePlanLink,
        offerScalePlanPrice,
        setOfferScalePlanPrice,
        offerScalePlanLink,
        setOfferScalePlanLink,
        startupPlanPrice,
        startupPlanLink,
        setStartupPlanPrice,
        setStartupPlanLink,
        offerStartupPlanPrice,
        setOfferStartupPlanPrice,
        offerStartupPlanLink,
        setOfferStartupPlanLink,
        unlimitedPlanPrice,
        setUnlimitedPlanPrice,
        unlimitedPlanLink,
        setUnlimitedPlanLink,
        offerUnlimitedPlanPrice,
        setOfferUnlimitedPlanPrice,
        offerUnlimitedPlanLink,
        setOfferUnlimitedPlanLink,
        countrySelected,
        setCountrySelected,
      }}
    >
      {children}
    </PricingPlansContext.Provider>
  );
};
