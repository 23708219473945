import { useTheme } from "@mui/material";

const Ecommerce3 = () => {
  const theme = useTheme();

  return (
    <svg width="316" height="238" viewBox="0 0 316 238" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3663_40263)">
      <path d="M34.9933 229.38C25.6333 229.38 16.7066 227.517 8.21326 223.79C-0.280072 219.977 -7.69007 214.647 -14.0167 207.8C-20.2567 200.953 -24.7201 192.893 -27.4067 183.62L6.91326 174.52C8.73326 181.367 12.2866 186.697 17.5733 190.51C22.9466 194.237 28.7533 196.1 34.9933 196.1C40.3666 196.1 45.2633 194.8 49.6833 192.2C54.1899 189.6 57.7433 186.09 60.3433 181.67C63.0299 177.25 64.3733 172.353 64.3733 166.98C64.3733 158.92 61.5999 152.03 56.0533 146.31C50.5933 140.503 43.5733 137.6 34.9933 137.6C32.3933 137.6 29.8799 137.99 27.4533 138.77C25.0266 139.463 22.6866 140.46 20.4333 141.76L4.57326 113.94L64.1133 64.54L66.7133 72.0801H-20.1267V38.8H96.8733V72.0801L50.5933 116.8L50.3333 106.92C61.0799 108.567 70.1366 112.337 77.5033 118.23C84.9566 124.037 90.5899 131.23 94.4033 139.81C98.3033 148.303 100.253 157.36 100.253 166.98C100.253 178.767 97.2633 189.383 91.2833 198.83C85.3033 208.19 77.3733 215.643 67.4933 221.19C57.6133 226.65 46.7799 229.38 34.9933 229.38Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <g clipPath="url(#clip1_3663_40263)">
      <path d="M89.3791 1H326.507C335.896 1 343.507 8.61116 343.507 18V201.436H72.3792V18C72.3792 8.61115 79.9903 1 89.3791 1Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2"/>
      <rect x="84.1611" y="15.9952" width="250.777" height="179.014" rx="8" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2"/>
      <path d="M70.9508 225H348.149C358.008 225 366 217.008 366 207.149C366 203.915 363.379 201.294 360.145 201.294H58.9546C55.721 201.294 53.0996 203.915 53.0996 207.149C53.0996 217.008 61.0919 225 70.9508 225Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2"/>
      <path d="M184.915 211.076H234.185C239.803 211.076 244.417 206.78 244.921 201.294H174.179C174.683 206.78 179.297 211.076 184.915 211.076Z" stroke={theme.palette.primary.main} strokeWidth="2"/>
      <mask id="mask0_3663_40263" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="84" y="14" width="239" height="183">
      <path d="M309.653 15H97.3469C90.5279 15 85 20.3792 85 23V191.255C85 193.875 90.5279 196 97.3469 196H309.653C316.472 196 322 193.875 322 191.255V19.7454C322 17.1246 316.472 15 309.653 15Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      </mask>
      <g mask="url(#mask0_3663_40263)">
      <rect x="78" y="16" width="254" height="15" rx="6" fill={theme.palette.primary.main} fillOpacity="0.8"/>
      <path d="M71.3792 23.1375C71.3792 19.8237 74.0654 17.1375 77.3792 17.1375H319.227C322.541 17.1375 325.227 19.8237 325.227 23.1375V51.8389C325.227 55.1526 322.541 57.8389 319.227 57.8389H77.3792C74.0654 57.8389 71.3792 55.1526 71.3792 51.8389V23.1375Z" fill={theme.palette.primary.main} fillOpacity="0.4"/>
      <rect x="114.223" y="37.4882" width="212.076" height="14.9953" rx="7.49763" fill={theme.palette.primary.contrastText}/>
      <circle cx="123.863" cy="44.9859" r="5.35545" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="313.446" cy="44.9859" r="5.35545" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="299.521" cy="44.9859" r="5.35545" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="133.502" y="41.7726" width="156.379" height="7.49763" rx="3.74882" fill={theme.palette.primary.main} fillOpacity="0.3"/>
      <path d="M103.038 41.2986L101.905 40.1659L97.0854 44.9858L101.905 49.8057L103.038 48.673L99.3588 44.9858L103.038 41.2986Z" fill={theme.palette.primary.contrastText}/>
      <rect x="97.4065" y="67.5864" width="23.5" height="23.5" rx="3.75" stroke={theme.palette.primary.main} strokeWidth="1.5"/>
      <g clipPath="url(#clip2_3663_40263)">
      <line x1="2" y1="-2" x2="7.93144" y2="-2" transform="matrix(0.712019 0.70216 -0.712019 0.70216 99.7417 79.3857)" stroke={theme.palette.primary.main} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <line x1="2" y1="-2" x2="14.5524" y2="-2" transform="matrix(0.712019 -0.70216 0.712019 0.70216 106.825 86.3716)" stroke={theme.palette.primary.main} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <rect x="128.406" y="67.4648" width="23.7431" height="23.7431" rx="3.75" stroke={theme.palette.primary.main} strokeWidth="1.5"/>
      <path d="M139.664 83.9657C139.664 84.9882 139.657 86.0113 139.669 87.0344C139.669 87.3066 139.598 87.4135 139.308 87.4117C137.691 87.3994 136.075 87.4067 134.459 87.4049C133.875 87.4049 133.604 87.1327 133.604 86.5379C133.601 84.7887 133.601 83.0395 133.604 81.2903C133.604 80.682 133.877 80.4116 134.481 80.4116C136.068 80.4116 137.654 80.4116 139.238 80.4116C139.604 80.4116 139.666 80.4688 139.666 80.8356C139.665 81.8786 139.664 82.9219 139.664 83.9657Z" fill={theme.palette.primary.main}/>
      <path d="M140.895 83.9091C140.895 82.885 140.895 81.8619 140.895 80.8398C140.895 80.4656 140.948 80.4097 141.315 80.4097C142.91 80.4097 144.506 80.4097 146.102 80.4097C146.667 80.4097 146.952 80.6874 146.954 81.2515C146.958 83.0212 146.958 84.7908 146.954 86.5605C146.954 87.1301 146.674 87.4017 146.105 87.4029C144.479 87.4029 142.853 87.3993 141.227 87.4079C140.972 87.4079 140.888 87.3206 140.891 87.0687C140.899 86.0161 140.895 84.9629 140.895 83.9091Z" fill={theme.palette.primary.main}/>
      <path d="M136.429 79.5928C135.515 79.5928 134.6 79.5928 133.685 79.5928C133.047 79.591 132.774 79.32 132.774 78.6822C132.774 78.0063 132.774 77.3304 132.774 76.6575C132.774 76.1149 133.058 75.8188 133.599 75.8175C135.499 75.812 137.4 75.8175 139.3 75.8132C139.556 75.8132 139.666 75.9171 139.666 76.1819C139.66 77.1939 139.657 78.2097 139.666 79.2186C139.666 79.5007 139.567 79.6008 139.293 79.5978C138.34 79.5873 137.384 79.5928 136.429 79.5928Z" fill={theme.palette.primary.main}/>
      <path d="M144.126 75.8152C145.041 75.8152 145.956 75.8152 146.87 75.8152C147.511 75.8152 147.782 76.0868 147.783 76.7234C147.783 77.3993 147.783 78.0752 147.783 78.7511C147.783 79.2943 147.497 79.5911 146.958 79.5923C145.065 79.5972 143.177 79.5923 141.287 79.5972C141.011 79.5972 140.883 79.5131 140.888 79.2071C140.903 78.2153 140.893 77.2229 140.894 76.2306C140.894 75.8564 140.934 75.8176 141.321 75.817L144.126 75.8152Z" fill={theme.palette.primary.main}/>
      <path d="M143.301 75.2725C142.701 75.2727 142.105 75.1796 141.533 74.9966C141.361 74.9394 141.194 74.8636 141.038 74.7704C140.467 74.4233 140.355 73.8727 140.812 73.3873C141.695 72.449 142.775 71.8277 144.066 71.7018C145.388 71.5721 146.274 72.6358 145.953 73.9077C145.754 74.6979 145.131 75.0002 144.42 75.1729C144.053 75.262 143.676 75.2657 143.301 75.2725ZM141.965 73.8838C142.199 73.9776 142.446 74.0334 142.697 74.049C143.295 74.1105 143.893 74.1443 144.472 73.9261C144.742 73.8235 144.883 73.6539 144.816 73.3381C144.729 72.9283 144.558 72.8078 144.083 72.8742C143.285 72.9848 142.622 73.383 141.965 73.8838Z" fill={theme.palette.primary.main}/>
      <path d="M137.248 75.2724C136.646 75.2644 136.051 75.2196 135.499 74.9449C134.757 74.5744 134.438 73.8984 134.612 73.0615C134.785 72.2246 135.353 71.7269 136.171 71.6894C137.005 71.6513 137.739 71.9635 138.445 72.358C138.889 72.6038 139.313 72.8876 139.666 73.2631C140.252 73.8898 140.133 74.5 139.357 74.873C138.871 75.1065 138.106 75.2503 137.248 75.2724ZM138.636 73.9021C138.025 73.4394 137.449 73.0904 136.78 72.9251C136.594 72.8741 136.401 72.8534 136.208 72.8637C135.975 72.8827 135.837 72.994 135.777 73.2219C135.672 73.6152 135.763 73.821 136.161 73.9458C136.963 74.1952 137.761 74.1049 138.636 73.9021Z" fill={theme.palette.primary.main}/>
      <rect x="160.4" y="69.3364" width="145" height="20" rx="10" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="97.4065" y="97.5864" width="23.5" height="23.5" rx="3.75" stroke={theme.palette.primary.main} strokeWidth="1.5"/>
      <g clipPath="url(#clip3_3663_40263)">
      <line x1="2" y1="-2" x2="7.93144" y2="-2" transform="matrix(0.712019 0.70216 -0.712019 0.70216 99.7417 109.386)" stroke={theme.palette.primary.main} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <line x1="2" y1="-2" x2="14.5524" y2="-2" transform="matrix(0.712019 -0.70216 0.712019 0.70216 106.825 116.372)" stroke={theme.palette.primary.main} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <rect x="128.406" y="97.4648" width="23.7431" height="23.7431" rx="3.75" stroke={theme.palette.primary.main} strokeWidth="1.5"/>
      <path d="M139.664 113.966C139.664 114.988 139.657 116.011 139.669 117.034C139.669 117.307 139.598 117.414 139.308 117.412C137.691 117.399 136.075 117.407 134.459 117.405C133.875 117.405 133.604 117.133 133.604 116.538C133.601 114.789 133.601 113.04 133.604 111.29C133.604 110.682 133.877 110.412 134.481 110.412C136.068 110.412 137.654 110.412 139.238 110.412C139.604 110.412 139.666 110.469 139.666 110.836C139.665 111.879 139.664 112.922 139.664 113.966Z" fill={theme.palette.primary.main}/>
      <path d="M140.895 113.909C140.895 112.885 140.895 111.862 140.895 110.84C140.895 110.466 140.948 110.41 141.315 110.41C142.91 110.41 144.506 110.41 146.102 110.41C146.667 110.41 146.952 110.687 146.954 111.251C146.958 113.021 146.958 114.791 146.954 116.561C146.954 117.13 146.674 117.402 146.105 117.403C144.479 117.403 142.853 117.399 141.227 117.408C140.972 117.408 140.888 117.321 140.891 117.069C140.899 116.016 140.895 114.963 140.895 113.909Z" fill={theme.palette.primary.main}/>
      <path d="M136.429 109.593C135.515 109.593 134.6 109.593 133.685 109.593C133.047 109.591 132.774 109.32 132.774 108.682C132.774 108.006 132.774 107.33 132.774 106.658C132.774 106.115 133.058 105.819 133.599 105.818C135.499 105.812 137.4 105.818 139.3 105.813C139.556 105.813 139.666 105.917 139.666 106.182C139.66 107.194 139.657 108.21 139.666 109.219C139.666 109.501 139.567 109.601 139.293 109.598C138.34 109.587 137.384 109.593 136.429 109.593Z" fill={theme.palette.primary.main}/>
      <path d="M144.126 105.815C145.041 105.815 145.956 105.815 146.87 105.815C147.511 105.815 147.782 106.087 147.783 106.723C147.783 107.399 147.783 108.075 147.783 108.751C147.783 109.294 147.497 109.591 146.958 109.592C145.065 109.597 143.177 109.592 141.287 109.597C141.011 109.597 140.883 109.513 140.888 109.207C140.903 108.215 140.893 107.223 140.894 106.231C140.894 105.856 140.934 105.818 141.321 105.817L144.126 105.815Z" fill={theme.palette.primary.main}/>
      <path d="M143.301 105.272C142.701 105.273 142.105 105.18 141.533 104.997C141.361 104.939 141.194 104.864 141.038 104.77C140.467 104.423 140.355 103.873 140.812 103.387C141.695 102.449 142.775 101.828 144.066 101.702C145.388 101.572 146.274 102.636 145.953 103.908C145.754 104.698 145.131 105 144.42 105.173C144.053 105.262 143.676 105.266 143.301 105.272ZM141.965 103.884C142.199 103.978 142.446 104.033 142.697 104.049C143.295 104.11 143.893 104.144 144.472 103.926C144.742 103.824 144.883 103.654 144.816 103.338C144.729 102.928 144.558 102.808 144.083 102.874C143.285 102.985 142.622 103.383 141.965 103.884Z" fill={theme.palette.primary.main}/>
      <path d="M137.248 105.272C136.646 105.264 136.051 105.22 135.499 104.945C134.757 104.574 134.438 103.898 134.612 103.062C134.785 102.225 135.353 101.727 136.171 101.689C137.005 101.651 137.739 101.963 138.445 102.358C138.889 102.604 139.313 102.888 139.666 103.263C140.252 103.89 140.133 104.5 139.357 104.873C138.871 105.106 138.106 105.25 137.248 105.272ZM138.636 103.902C138.025 103.439 137.449 103.09 136.78 102.925C136.594 102.874 136.401 102.853 136.208 102.864C135.975 102.883 135.837 102.994 135.777 103.222C135.672 103.615 135.763 103.821 136.161 103.946C136.963 104.195 137.761 104.105 138.636 103.902Z" fill={theme.palette.primary.main}/>
      <rect x="160.4" y="99.3364" width="145" height="20" rx="10" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="97.4065" y="127.586" width="23.5" height="23.5" rx="3.75" stroke={theme.palette.primary.main} strokeWidth="1.5"/>
      <g clipPath="url(#clip4_3663_40263)">
      <line x1="2" y1="-2" x2="7.93144" y2="-2" transform="matrix(0.712019 0.70216 -0.712019 0.70216 99.7417 139.386)" stroke={theme.palette.primary.main} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <line x1="2" y1="-2" x2="14.5524" y2="-2" transform="matrix(0.712019 -0.70216 0.712019 0.70216 106.825 146.372)" stroke={theme.palette.primary.main} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <rect x="128.406" y="127.465" width="23.7431" height="23.7431" rx="3.75" stroke={theme.palette.primary.main} strokeWidth="1.5"/>
      <path d="M139.664 143.966C139.664 144.988 139.657 146.011 139.669 147.034C139.669 147.307 139.598 147.414 139.308 147.412C137.691 147.399 136.075 147.407 134.459 147.405C133.875 147.405 133.604 147.133 133.604 146.538C133.601 144.789 133.601 143.04 133.604 141.29C133.604 140.682 133.877 140.412 134.481 140.412C136.068 140.412 137.654 140.412 139.238 140.412C139.604 140.412 139.666 140.469 139.666 140.836C139.665 141.879 139.664 142.922 139.664 143.966Z" fill={theme.palette.primary.main}/>
      <path d="M140.895 143.909C140.895 142.885 140.895 141.862 140.895 140.84C140.895 140.466 140.948 140.41 141.315 140.41C142.91 140.41 144.506 140.41 146.102 140.41C146.667 140.41 146.952 140.687 146.954 141.251C146.958 143.021 146.958 144.791 146.954 146.561C146.954 147.13 146.674 147.402 146.105 147.403C144.479 147.403 142.853 147.399 141.227 147.408C140.972 147.408 140.888 147.321 140.891 147.069C140.899 146.016 140.895 144.963 140.895 143.909Z" fill={theme.palette.primary.main}/>
      <path d="M136.429 139.593C135.515 139.593 134.6 139.593 133.685 139.593C133.047 139.591 132.774 139.32 132.774 138.682C132.774 138.006 132.774 137.33 132.774 136.658C132.774 136.115 133.058 135.819 133.599 135.818C135.499 135.812 137.4 135.818 139.3 135.813C139.556 135.813 139.666 135.917 139.666 136.182C139.66 137.194 139.657 138.21 139.666 139.219C139.666 139.501 139.567 139.601 139.293 139.598C138.34 139.587 137.384 139.593 136.429 139.593Z" fill={theme.palette.primary.main}/>
      <path d="M144.126 135.815C145.041 135.815 145.956 135.815 146.87 135.815C147.511 135.815 147.782 136.087 147.783 136.723C147.783 137.399 147.783 138.075 147.783 138.751C147.783 139.294 147.497 139.591 146.958 139.592C145.065 139.597 143.177 139.592 141.287 139.597C141.011 139.597 140.883 139.513 140.888 139.207C140.903 138.215 140.893 137.223 140.894 136.231C140.894 135.856 140.934 135.818 141.321 135.817L144.126 135.815Z" fill={theme.palette.primary.main}/>
      <path d="M143.301 135.272C142.701 135.273 142.105 135.18 141.533 134.997C141.361 134.939 141.194 134.864 141.038 134.77C140.467 134.423 140.355 133.873 140.812 133.387C141.695 132.449 142.775 131.828 144.066 131.702C145.388 131.572 146.274 132.636 145.953 133.908C145.754 134.698 145.131 135 144.42 135.173C144.053 135.262 143.676 135.266 143.301 135.272ZM141.965 133.884C142.199 133.978 142.446 134.033 142.697 134.049C143.295 134.11 143.893 134.144 144.472 133.926C144.742 133.824 144.883 133.654 144.816 133.338C144.729 132.928 144.558 132.808 144.083 132.874C143.285 132.985 142.622 133.383 141.965 133.884Z" fill={theme.palette.primary.main}/>
      <path d="M137.248 135.272C136.646 135.264 136.051 135.22 135.499 134.945C134.757 134.574 134.438 133.898 134.612 133.062C134.785 132.225 135.353 131.727 136.171 131.689C137.005 131.651 137.739 131.963 138.445 132.358C138.889 132.604 139.313 132.888 139.666 133.263C140.252 133.89 140.133 134.5 139.357 134.873C138.871 135.106 138.106 135.25 137.248 135.272ZM138.636 133.902C138.025 133.439 137.449 133.09 136.78 132.925C136.594 132.874 136.401 132.853 136.208 132.864C135.975 132.883 135.837 132.994 135.777 133.222C135.672 133.615 135.763 133.821 136.161 133.946C136.963 134.195 137.761 134.105 138.636 133.902Z" fill={theme.palette.primary.main}/>
      <rect x="160.4" y="129.336" width="145" height="20" rx="10" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="197" y="159" width="107" height="25" rx="6" fill={theme.palette.primary.main} fillOpacity="0.3"/>
      <rect x="96.0854" y="158.735" width="92.1137" height="25.7062" rx="6" fill={theme.palette.primary.main}/>
      <g clipPath="url(#clip5_3663_40263)">
      <path d="M142.146 167.158C144.607 167.158 147.067 167.158 149.524 167.158C150.965 167.158 151.784 167.962 151.784 169.375C151.784 171.544 151.784 173.71 151.784 175.875C151.784 177.283 150.961 178.085 149.517 178.085C149.181 178.085 148.738 178.194 148.537 178.028C148.336 177.863 148.48 177.407 148.475 177.079C148.465 176.426 148.475 175.773 148.475 175.121C148.475 174.773 148.404 174.428 148.268 174.106C148.131 173.784 147.931 173.491 147.679 173.245C147.427 173 147.128 172.805 146.799 172.673C146.47 172.541 146.118 172.474 145.762 172.475C143.352 172.467 140.942 172.467 138.534 172.475C138.178 172.473 137.825 172.54 137.496 172.671C137.166 172.803 136.866 172.998 136.614 173.244C136.361 173.491 136.161 173.783 136.024 174.106C135.887 174.429 135.817 174.774 135.817 175.124C135.81 176.011 135.809 176.898 135.821 177.785C135.821 178.006 135.79 178.117 135.52 178.095C135.046 178.055 134.565 178.143 134.094 178.036C133.168 177.822 132.518 177.068 132.512 176.134C132.5 173.796 132.5 171.458 132.513 169.118C132.52 168.004 133.414 167.163 134.577 167.158C137.098 167.151 139.621 167.158 142.146 167.158ZM137.023 170.693C137.022 170.518 136.967 170.347 136.867 170.202C136.767 170.057 136.625 169.945 136.46 169.879C136.295 169.813 136.113 169.796 135.938 169.831C135.763 169.866 135.603 169.951 135.477 170.075C135.351 170.199 135.266 170.358 135.232 170.529C135.197 170.701 135.216 170.879 135.284 171.041C135.353 171.203 135.469 171.341 135.617 171.438C135.766 171.535 135.94 171.587 136.119 171.587C136.238 171.587 136.356 171.564 136.466 171.519C136.577 171.474 136.676 171.408 136.761 171.325C136.845 171.242 136.911 171.143 136.956 171.035C137.001 170.926 137.024 170.81 137.023 170.693Z" fill={theme.palette.primary.contrastText}/>
      <path d="M142.124 180.156C140.971 180.156 139.816 180.156 138.662 180.156C137.974 180.153 137.628 179.812 137.626 179.132C137.623 177.84 137.623 176.548 137.626 175.256C137.626 174.6 137.978 174.251 138.643 174.25C140.976 174.25 143.309 174.25 145.642 174.25C146.306 174.25 146.657 174.6 146.659 175.257C146.662 176.561 146.662 177.865 146.659 179.17C146.659 179.802 146.301 180.154 145.661 180.156C144.482 180.159 143.303 180.156 142.124 180.156Z" fill={theme.palette.primary.contrastText}/>
      <path d="M142.146 165.975H136.428C135.907 165.975 135.817 165.887 135.817 165.375C135.817 165.104 135.81 164.833 135.823 164.561C135.865 163.726 136.547 163.034 137.4 163.029C140.559 163.015 143.719 163.015 146.88 163.029C147.735 163.029 148.418 163.723 148.462 164.559C148.475 164.829 148.468 165.1 148.467 165.372C148.467 165.89 148.38 165.976 147.864 165.976L142.146 165.975Z" fill={theme.palette.primary.contrastText}/>
      </g>
      </g>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_3663_40263">
      <rect width="316" height="238" fill={theme.palette.primary.contrastText}/>
      </clipPath>
      <clipPath id="clip1_3663_40263">
      <rect width="264" height="226" fill={theme.palette.primary.contrastText} transform="translate(52)"/>
      </clipPath>
      <clipPath id="clip2_3663_40263">
      <rect width="19.0223" height="16.6445" fill={theme.palette.primary.contrastText} transform="translate(99.7417 72.2994)"/>
      </clipPath>
      <clipPath id="clip3_3663_40263">
      <rect width="19.0223" height="16.6445" fill={theme.palette.primary.contrastText} transform="translate(99.7417 102.299)"/>
      </clipPath>
      <clipPath id="clip4_3663_40263">
      <rect width="19.0223" height="16.6445" fill={theme.palette.primary.contrastText} transform="translate(99.7417 132.299)"/>
      </clipPath>
      <clipPath id="clip5_3663_40263">
      <rect width="19.2796" height="17.1374" fill={theme.palette.primary.contrastText} transform="translate(132.502 163.019)"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default Ecommerce3;
