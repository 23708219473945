export const FacebookIcon = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 -1 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M11.6045 17.0333H13.673V24.2333C13.673 24.4111 13.7764 24.5 13.9833 24.5H17.3963C17.6032 24.5 17.7066 24.4111 17.7066 24.2333V17.0333H20.0854C20.1888 17.0333 20.3957 16.9444 20.3957 16.7667L20.706 14.1C20.706 13.9222 20.6025 13.8333 20.3957 13.8333H17.7066V12.2333C17.7066 11.7 18.0169 11.4333 18.6374 11.4333H20.3957C20.6025 11.4333 20.706 11.3444 20.706 11.1667V8.76667C20.706 8.58889 20.6025 8.5 20.3957 8.5H18.0169H17.9135C16.7758 8.5 15.7415 8.85556 14.8107 9.47778C13.9833 10.1 13.5696 10.9889 13.7764 11.9667V13.9222H11.6045C11.3976 13.9222 11.2942 14.0111 11.2942 14.1889V16.8556C11.3976 16.9444 11.501 17.0333 11.6045 17.0333Z"
      />
    </svg>
  );
};

export const InstagramIcon = () => {
  return (
    <svg
      width="33"
      height="34"
      viewBox="1 1 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M19.25 9.5H13.75C11.1667 9.5 9 11.6667 9 14.25V19.75C9 22.3333 11.1667 24.5 13.75 24.5H19.25C21.8333 24.5 24 22.3333 24 19.75V14.25C24 11.6667 21.8333 9.5 19.25 9.5ZM10.75 14.25C10.75 12.5833 12.0833 11.25 13.75 11.25H19.25C20.9167 11.25 22.25 12.5833 22.25 14.25V19.75C22.25 21.4167 20.9167 22.75 19.25 22.75H13.75C12.0833 22.75 10.75 21.4167 10.75 19.75V14.25Z"
      />
      <path
        fill="currentColor"
        d="M16.5002 20.9168C18.6668 20.9168 20.4168 19.1668 20.4168 17.0002C20.4168 14.8335 18.6668 13.0835 16.5002 13.0835C14.3335 13.0835 12.5835 14.8335 12.5835 17.0002C12.5835 19.1668 14.3335 20.9168 16.5002 20.9168ZM16.5002 14.8335C17.6668 14.8335 18.6668 15.8335 18.6668 17.0002C18.6668 18.1668 17.6668 19.1668 16.5002 19.1668C15.3335 19.1668 14.3335 18.1668 14.3335 17.0002C14.3335 15.8335 15.3335 14.8335 16.5002 14.8335Z"
      />
      <path
        fill="currentColor"
        d="M20.4167 13.7503C20.8333 13.7503 21.0833 13.417 21.0833 13.0837C21.0833 12.667 20.75 12.417 20.4167 12.417C20 12.417 19.75 12.7503 19.75 13.0837C19.75 13.417 20.0833 13.7503 20.4167 13.7503Z"
      />
    </svg>
  );
};

export const LinkedinIcon = () => {
  return (
    <svg
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M24 24.5V19.0001C24 16.3002 23.4 14.2002 20.2999 14.2002C19.0999 14.2002 17.9999 14.8002 17.3999 15.8002V14.5002H14.3999V24.5H17.4999V19.6001C17.4999 18.3001 17.6999 17.0002 19.3999 17.0002C21 17.0002 21 18.5001 21 19.6001V24.5H24Z"
      />
      <path
        fill="currentColor"
        d="M9.30005 14.5H12.4001V24.4999H9.30005V14.5Z"
      />
      <path
        fill="currentColor"
        d="M10.8 9.5C9.80001 9.5 9 10.3 9 11.3C9 12.3 9.80001 13.0999 10.8 13.0999C11.8 13.0999 12.6 12.3 12.6 11.3C12.6 10.3 11.8 9.5 10.8 9.5Z"
      />
    </svg>
  );
};

export const MailToIcon = () => {
  return (
    <svg
      width="28"
      height="23"
      viewBox="-11 -15 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M24.6667 0.833332H3.33335C1.86669 0.833332 0.68002 2.03333 0.68002 3.5L0.666687 19.5C0.666687 20.9667 1.86669 22.1667 3.33335 22.1667H24.6667C26.1334 22.1667 27.3334 20.9667 27.3334 19.5V3.5C27.3334 2.03333 26.1334 0.833332 24.6667 0.833332ZM24.6667 19.5H3.33335V6.16667L14 12.8333L24.6667 6.16667V19.5ZM14 10.1667L3.33335 3.5H24.6667L14 10.1667Z"
      />
    </svg>
  )
};
