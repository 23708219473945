const PlatziStartups  = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="270" height="23" viewBox="0 0 291.8 46"> 
      <path d="M34.2,37.5c-1.6,1.5-3,3-4.6,4.5c-0.7,0.7-1.6,1.3-2.5,1.7c-2.7,1-5.1,0.6-7.2-1.4c-5-4.8-9.8-9.7-14.6-14.6
        c-2.6-2.6-2.6-6.9,0-9.5l0,0c4.9-4.9,9.7-9.7,14.6-14.6c2.6-2.6,6.8-2.6,9.5,0c3.3,3.3,6.5,6.5,9.8,9.8c2.6,2.6,2.6,6.7,0.1,9.3
        C36.2,25.9,33,29,30,32c-0.1,0.1-0.2,0.2-0.3,0.4c-0.3,0.1-0.3,0.3,0,0.6c1.4,1.4,2.8,2.8,4.2,4.2C34.1,37.2,34.2,37.3,34.2,37.5z
        M34.2,18.3c0-0.1-0.1-0.2-0.3-0.4l-8.9-9c-0.2-0.2-0.4-0.3-0.6,0c-4.6,4.7-9.2,9.2-13.8,13.8c-0.2,0.2-0.2,0.4,0,0.6
        c4.7,4.6,9.2,9.2,13.8,13.8c0.2,0.2,0.4,0.2,0.6,0c1.4-1.4,2.8-2.8,4.2-4.2c0.2-0.2,0.2-0.4,0-0.6c-1.4-1.4-2.8-2.8-4.2-4.2
        c-0.2-0.2-0.3-0.4,0-0.7c3-3,6-6,8.9-8.9L34.2,18.3z" fill="currentColor"/>
      <path d="M47,22.9c0-4.5,0-9.1,0-13.6c0-0.4,0.1-0.5,0.5-0.5c3.8,0,7.5,0,11.3,0c2.3-0.1,4.6,0.7,6.4,2.1
        c2.2,1.8,3.4,4.3,3.5,7.1c0.3,3.1-0.8,6.1-3.2,8.2c-1.8,1.6-4,2.4-6.4,2.4c-2.3,0.1-4.6,0-6.9,0c-0.4,0-0.6,0.1-0.6,0.6
        c0,2.5,0,5,0,7.4c0,0.3-0.1,0.5-0.5,0.5c-1.3,0-2.5,0-3.8,0c-0.4,0-0.5-0.1-0.5-0.5C47,32,47,27.4,47,22.9z M51.7,18.6
        c0,1.8,0,3.6,0,5.4c0,0.3,0.1,0.4,0.4,0.4c2.2,0,4.3,0,6.5,0c3,0.1,5.5-2.3,5.6-5.4c0-0.1,0-0.3,0-0.3c0.1-3.2-2-6-5.8-5.9
        c-2.1,0.1-4.2,0-6.3,0c-0.4,0-0.5,0.2-0.5,0.5C51.7,15,51.7,16.9,51.7,18.6z" fill="currentColor"/>
      <path d="M97,33.3c-0.3,0.4-0.5,0.8-0.8,1.2c-1.4,1.9-3.8,2.9-6.1,2.8c-4.4-0.2-7.4-2.8-8.4-7.4
        c-0.5-2.3-0.3-4.6,0.4-6.8c1.2-3,3.8-5.2,6.9-5.7c3.8-0.8,7.7-0.3,11.2,1.3c0.3,0.1,0.3,0.3,0.3,0.5c0,4.1,0,8.1,0,12.2
        c0,0.3,0,0.7,0,1c0.1,1,0.5,1.4,1.6,1.4c0.5,0,0.5,0.2,0.5,0.6c0,0.7,0,1.4,0,2.1c0,0.2,0,0.3-0.3,0.4c-1.1,0.2-2.2,0.2-3.2,0
        c-1.3-0.3-2.3-1.4-2.3-2.8C97,33.9,97,33.6,97,33.3z M96.3,24.8c0-1,0-1.9,0-2.9c0-0.3-0.2-0.5-0.4-0.6c-1.6-0.6-3.3-0.8-4.9-0.6
        c-2.3,0.2-4.2,1.8-4.8,3.9c-0.5,1.7-0.5,3.4,0.1,5c0.8,2.7,3.5,4.2,6.2,3.4c0.1,0,0.2-0.1,0.3-0.1c1.9-0.5,2.8-2,3.3-3.8
        C96.3,27.8,96.4,26.3,96.3,24.8z" fill="currentColor"/>
      <path d="M127.8,33.3h2.8c2.3,0,4.5,0,6.8,0c0.3,0,0.4,0.1,0.4,0.4c0,1,0,2,0,3.1c0,0.3-0.1,0.3-0.3,0.3H122
        c-0.3,0-0.5-0.6-0.3-1c0.1-0.1,0.1-0.2,0.2-0.3c3-4.8,6.1-9.6,9.4-14.2l0.3-0.3h-0.5c-2.8,0-5.5,0-8.3,0c-0.3,0-0.5-0.1-0.4-0.4
        c0-0.9,0-1.8,0-2.8c0-0.3,0.1-0.4,0.4-0.4c4.9,0,9.9,0,14.9,0c0.4,0,0.4,0.2,0.4,0.5c0,0.2-0.1,0.3-0.3,0.5
        c-3.1,4.8-6.2,9.7-9.5,14.4L127.8,33.3z" fill="currentColor"/>
      <path d="M107.6,26c0-1.4,0-2.8,0-4.3c0-0.3-0.1-0.5-0.5-0.5c-1,0-2.1,0-3.1,0c-0.3,0-0.4,0-0.4-0.4c0-0.9,0-1.8,0-2.8
        c0-0.3,0.1-0.4,0.4-0.4c1,0,2,0,3,0c0.5,0,0.5-0.2,0.5-0.6c0-1.3,0-2.5,0-3.8c0-0.4,0.1-0.5,0.5-0.5c1.2,0,2.3,0,3.4,0
        c0.4,0,0.5,0.2,0.5,0.5c0,1.3,0,2.5,0,3.8c0,0.5,0.2,0.6,0.6,0.5c1.9,0,3.8,0,5.8,0c0.4,0,0.5,0.1,0.5,0.5c0,0.9,0,1.8,0,2.7
        c0,0.3-0.1,0.4-0.4,0.4c-2,0-3.9,0-5.9,0c-0.3,0-0.4,0.1-0.4,0.4c0,2.7,0,5.4,0,8c0,0.8,0.1,1.8,0.4,2.6c0.6,1.3,1.9,2.1,3.3,1.9
        c1-0.1,2-0.3,2.9-0.6c0.3-0.1,0.4-0.1,0.4,0.3c0.1,0.9,0.3,1.8,0.3,2.7c0,0.2,0.1,0.3-0.2,0.3c-2.2,0.8-4.5,0.9-6.8,0.5
        c-2.6-0.6-4-2.3-4.6-4.8c-0.3-1.4-0.3-2.8-0.3-4.3C107.6,27.4,107.6,26.7,107.6,26z" fill="currentColor"/>
      <path d="M71.7,20.2c0-3.7,0-7.4,0-11c0-0.3,0.1-0.4,0.4-0.4c1.2,0,2.3,0,3.5,0c0.3,0,0.4,0.1,0.4,0.4
        c0,6.9,0,13.7,0,20.6c0,0.8,0,1.6,0.2,2.3c0.2,1.1,1.2,1.9,2.3,1.8c0.5,0,1,0,1.4-0.1c0.3-0.1,0.3,0,0.3,0.3c0,0.9,0.1,1.8,0.2,2.8
        c0,0.2,0,0.3-0.3,0.3c-1.5,0.3-3,0.3-4.5,0.1c-2.2-0.5-3.2-1.9-3.7-4c-0.3-1.2-0.3-2.3-0.3-3.5C71.7,26.6,71.7,23.4,71.7,20.2z" fill="currentColor"/>
      <path d="M145.2,27.3c0,3.1,0,6.2,0,9.2c0,0.4-0.1,0.5-0.5,0.5c-1.2,0-2.3,0-3.5,0c-0.3,0-0.4-0.1-0.4-0.3
        c0-6.3,0-12.5,0-18.7c0-0.3,0.1-0.3,0.3-0.3c1.2,0,2.4,0,3.6,0c0.4,0,0.4,0.2,0.4,0.5C145.2,21.1,145.2,24.2,145.2,27.3z" fill="currentColor"/>
      <path d="M143.1,13.9c-1.4,0.1-2.6-1-2.7-2.4c0-0.1,0-0.2,0-0.3c-0.1-1.4,1-2.5,2.4-2.6c0.1,0,0.2,0,0.3,0
        c1.4-0.1,2.7,1,2.8,2.4s-1,2.7-2.4,2.8C143.2,13.9,143.2,13.9,143.1,13.9L143.1,13.9z" fill="currentColor"/>
      <g>
        <path d="M171.7,17.3c-0.5-2.6-2.4-4.9-6.1-4.9c-3,0-5.7,2-5.7,5c0,2.3,1.4,3.8,4,4.4l3.8,0.9c3.9,0.9,6.3,3.2,6.3,6.7
          c0,3.9-3.3,7.1-8.3,7.1c-5.5,0-8.6-3.7-9-7.5l2.1-0.7c0.3,3.3,2.8,6.1,6.9,6.1c3.9,0,6.1-2.1,6.1-4.9c0-2.3-1.6-4-4.5-4.7l-3.8-0.9
          c-3.4-0.8-5.8-2.9-5.8-6.5c0-3.9,3.8-7.1,7.9-7.1c5.1,0,7.5,3,8.2,6.2L171.7,17.3z" fill="currentColor"/>
        <path d="M182.6,19.1h4.4V21h-4.4v10.2c0,2.1,1,3.1,2.9,3.1c0.6,0,1.2-0.1,1.5-0.3v1.9c-0.3,0.1-1.1,0.3-2.1,0.3
          c-2.7,0-4.4-1.7-4.4-4.8V21h-3.6v-1.9h1.3c1.7,0,2.4-0.7,2.4-2.5v-2.9h2.1L182.6,19.1L182.6,19.1z" fill="currentColor"/>
        <path d="M190.5,31.7c0-2.9,2-4.5,5.1-5l4.5-0.7c1.5-0.2,1.8-0.9,1.8-1.6c0-2.2-1.5-3.8-4.3-3.8c-2.7,0-4.5,1.5-4.8,3.9
          l-2-0.4c0.4-3.2,3.1-5.4,6.7-5.4c4.4,0,6.4,2.7,6.4,5.9v8.6c0,1.3,0.1,2.2,0.2,2.9H202c-0.1-0.4-0.2-1.5-0.2-2.9
          c-0.8,1.6-2.8,3.3-5.9,3.3C192.5,36.5,190.5,34.1,190.5,31.7z M201.8,29.1v-1.9c-0.1,0.1-0.6,0.3-1.2,0.4l-4.7,0.7
          c-2,0.3-3.2,1.4-3.2,3.2c0,1.6,1.3,3.1,3.6,3.1C199.5,34.7,201.8,32.5,201.8,29.1z" fill="currentColor"/>
        <path d="M219,21.3c-0.4-0.1-0.9-0.2-1.5-0.2c-2.9,0-5,1.8-5,5.7V36h-2.1V19.1h2.1v3.2c1-2.3,3-3.4,5.2-3.4
          c0.6,0,1,0.1,1.3,0.2V21.3z" fill="currentColor"/>
        <path d="M226.8,19.1h4.4V21h-4.4v10.2c0,2.1,1,3.1,2.9,3.1c0.6,0,1.2-0.1,1.5-0.3v1.9c-0.3,0.1-1.1,0.3-2.1,0.3
          c-2.7,0-4.4-1.7-4.4-4.8V21h-3.6v-1.9h1.3c1.7,0,2.4-0.7,2.4-2.5v-2.9h2.1L226.8,19.1L226.8,19.1z" fill="currentColor"/>
        <path d="M242,36.5c-4.3,0-6.3-3.2-6.3-6.7V19.1h2.1v10.3c0,2.7,1.2,5.2,4.6,5.2c3,0,4.8-2.3,4.8-5.1V19.1h2.1v13.7
          c0,1.3,0.1,2.5,0.2,3.2h-2c-0.1-0.4-0.2-1.3-0.2-2.4v-0.2C246.6,35.1,244.7,36.5,242,36.5z" fill="currentColor"/>
        <path d="M256,42.8V19.1h2.1v3.4c0.8-1.7,2.8-3.8,6.2-3.8c4.8,0,7.7,3.9,7.7,8.9s-3,8.9-7.7,8.9c-3.3,0-5.2-2-6.1-3.7
          v9.9H256V42.8z M263.9,20.6c-3.5,0-5.8,3.1-5.8,7c0,4,2.3,7,5.8,7s5.9-3.1,5.9-7S267.5,20.6,263.9,20.6z" fill="currentColor"/>
        <path d="M278,30.9c0.3,2.1,1.9,3.7,4.6,3.7c2.1,0,3.8-1.2,3.8-3c0-1.6-1.1-2.4-2.9-2.8l-2.7-0.6
          c-2.5-0.6-4.2-2.1-4.2-4.5c0-2.7,2.6-5,5.7-5c3.9,0,5.6,2.3,6.1,4.4l-1.9,0.7c-0.3-1.6-1.5-3.2-4.2-3.2c-1.9,0-3.6,1.2-3.6,3
          c0,1.5,0.9,2.3,2.7,2.7l2.7,0.6c2.8,0.6,4.4,2.2,4.4,4.7s-2.1,5-5.9,5c-4.2,0-6.2-2.7-6.6-4.9L278,30.9z" fill="currentColor"/>
      </g>
    </svg>
  );
};

export default PlatziStartups;


