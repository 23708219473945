import { PrivacyTitle, PrivacyInfo, PageWrapper } from "components";
import { useLightNavbar } from "hooks";
import { FC } from "react";

const PrivacyTerms: FC = () => {
  useLightNavbar(false);

  return (
    <>
      <PageWrapper>
        <PrivacyTitle />
        <PrivacyInfo />
      </PageWrapper>
    </>
  );
};

export default PrivacyTerms;
