const StorydotsLogo = () => {
  return (
    <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 200.7 40" xmlSpace="preserve" width="200" height="40" >
      <g>
        <path fill="currentColor" d="M10.2,19.9c1,0,2,0.8,2.1,1.8c0.2,1.5,0.7,2.7,1.4,3.6c1,1.1,2.3,1.7,3.8,1.7c1.6,0,2.8-0.6,3.8-1.7
          c0.7-0.9,1.2-2,1.4-3.6c0.1-1,1-1.8,2.1-1.8H28c1.3,0,2.3,1.2,2.1,2.5c-0.2,1.6-0.7,3-1.4,4.3c-1.1,1.9-2.6,3.3-4.5,4.4
          c-1.9,1-4.2,1.5-6.8,1.5s-4.8-0.5-6.8-1.5s-3.4-2.5-4.4-4.4c-0.7-1.3-1.2-2.7-1.4-4.3c-0.2-1.3,0.8-2.5,2.1-2.5H10.2z"/>
        <g>
          <g>
            <g>
              <path id="SVGID_1_" fill="currentColor" d="M10.2,19.9c1,0,2,0.8,2.1,1.8c0.2,1.5,0.7,2.7,1.4,3.6c1,1.1,2.3,1.7,3.8,1.7
                c1.6,0,2.8-0.6,3.8-1.7c0.7-0.9,1.2-2,1.4-3.6c0.1-1,1-1.8,2.1-1.8H28c1.3,0,2.3,1.2,2.1,2.5c-0.2,1.6-0.7,3-1.4,4.3
                c-1.1,1.9-2.6,3.3-4.5,4.4c-1.9,1-4.2,1.5-6.8,1.5s-4.8-0.5-6.8-1.5s-3.4-2.5-4.4-4.4c-0.7-1.3-1.2-2.7-1.4-4.3
                c-0.2-1.3,0.8-2.5,2.1-2.5H10.2z"/>
            </g>
            <g>
              <g>
                <defs>
                  <path id="SVGID_00000165951184131232121650000017274881266745695380_" d="M10.2,19.9c1,0,2,0.8,2.1,1.8
                    c0.2,1.5,0.7,2.7,1.4,3.6c1,1.1,2.3,1.7,3.8,1.7c1.6,0,2.8-0.6,3.8-1.7c0.7-0.9,1.2-2,1.4-3.6c0.1-1,1-1.8,2.1-1.8H28
                    c1.3,0,2.3,1.2,2.1,2.5c-0.2,1.6-0.7,3-1.4,4.3c-1.1,1.9-2.6,3.3-4.5,4.4c-1.9,1-4.2,1.5-6.8,1.5s-4.8-0.5-6.8-1.5
                    s-3.4-2.5-4.4-4.4c-0.7-1.3-1.2-2.7-1.4-4.3c-0.2-1.3,0.8-2.5,2.1-2.5H10.2z"/>
                </defs>
                <clipPath id="SVGID_00000108297277196587947500000016970946531808911529_">
                  <use xlinkHref="#SVGID_00000165951184131232121650000017274881266745695380_"  style={{ overflow: "visible" }}/>
                </clipPath>
                
                  <ellipse style={{ clipPath: "url(#SVGID_00000108297277196587947500000016970946531808911529_)" }} fill="currentColor" cx="25.4" cy="21.2" rx="13" ry="10.4"/>
              </g>
            </g>
          </g>
          <path fill="currentColor" d="M12,13.3c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4C10.2,17.3,12,15.5,12,13.3"/>
          <path fill="currentColor" d="M29.2,9.8h-4.9c-0.6,0-1.1,0.5-1.1,1.1v4.9c0,0.6,0.5,1.1,1.1,1.1h4.9c0.6,0,1.1-0.5,1.1-1.1v-5
            C30.3,10.3,29.8,9.8,29.2,9.8"/>
        </g>
      </g>
      <g>
        <path fill="currentColor" d="M47.6,30.4c-1,0-2.1-0.1-3.2-0.3c-1.1-0.2-2.1-0.5-3-0.9c-0.6-0.3-1-0.7-1.1-1.2c-0.2-0.5-0.2-0.9,0-1.4
          c0.2-0.4,0.5-0.8,0.9-1c0.4-0.2,0.9-0.2,1.5,0c1,0.4,1.9,0.7,2.7,0.9s1.6,0.3,2.3,0.3c1,0,1.7-0.2,2.2-0.5c0.5-0.3,0.7-0.7,0.7-1.3
          c0-0.8-0.6-1.4-1.8-1.5L45,22.8c-1.5-0.3-2.6-0.8-3.4-1.6c-0.8-0.8-1.2-1.9-1.2-3.2c0-1.2,0.3-2.2,1-3c0.7-0.9,1.6-1.5,2.7-2
          c1.2-0.5,2.5-0.7,4-0.7c2.1,0,3.9,0.4,5.5,1.2c0.5,0.3,0.9,0.6,1,1.1s0.1,0.9-0.1,1.4c-0.2,0.4-0.5,0.8-0.9,1
          c-0.4,0.2-0.9,0.2-1.5-0.1c-0.8-0.3-1.5-0.6-2.1-0.8c-0.6-0.2-1.2-0.2-1.8-0.2c-1.1,0-1.8,0.2-2.3,0.5c-0.5,0.3-0.7,0.8-0.7,1.3
          c0,0.8,0.6,1.4,1.7,1.5l3.7,0.7c1.5,0.3,2.7,0.8,3.5,1.5c0.8,0.8,1.2,1.8,1.2,3.1c0,1.8-0.7,3.2-2.1,4.2
          C51.8,29.9,50,30.4,47.6,30.4z"/>
        <path fill="currentColor" d="M66.7,30.4c-4.9,0-7.3-2.4-7.3-7.1v-6.7h-1.5c-1.4,0-2.1-0.7-2.1-2c0-1.4,0.7-2,2.1-2h1.5V9.9
          c0-1.8,0.9-2.7,2.7-2.7c1.8,0,2.6,0.9,2.6,2.7v2.7h3.3c1.4,0,2.1,0.7,2.1,2c0,1.3-0.7,2-2.1,2h-3.3v6.4c0,1,0.2,1.7,0.7,2.2
          s1.2,0.8,2.2,0.8c0.3,0,0.7,0,1-0.1c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.7,0.3,1.5c0,0.6-0.1,1.1-0.3,1.5
          c-0.2,0.4-0.5,0.7-1,0.9c-0.3,0.1-0.7,0.2-1.3,0.3C67.6,30.3,67.1,30.4,66.7,30.4z"/>
        <path fill="currentColor" d="M80.6,30.4c-1.9,0-3.5-0.4-4.9-1.1c-1.4-0.8-2.4-1.8-3.2-3.2s-1.1-3-1.1-4.8c0-1.8,0.4-3.4,1.1-4.8
          s1.8-2.4,3.2-3.1c1.4-0.7,3-1.1,4.9-1.1s3.5,0.4,4.9,1.1s2.5,1.8,3.2,3.1s1.1,3,1.1,4.8c0,1.9-0.4,3.5-1.1,4.8
          c-0.8,1.4-1.8,2.4-3.2,3.2C84.1,30,82.5,30.4,80.6,30.4z M80.6,26.4c1.1,0,2.1-0.4,2.8-1.2c0.7-0.8,1.1-2.1,1.1-3.8
          c0-1.7-0.3-3-1.1-3.8c-0.7-0.8-1.6-1.2-2.8-1.2c-1.1,0-2.1,0.4-2.7,1.2s-1.1,2.1-1.1,3.8c0,1.7,0.3,3,1.1,3.8
          C78.6,26,79.5,26.4,80.6,26.4z"/>
      </g>
      <g>
        <path fill="currentColor" d="M95.5,30.3c-1.8,0-2.7-0.9-2.7-2.7V15.1c0-1.8,0.9-2.7,2.6-2.7c1.7,0,2.6,0.9,2.6,2.7v0.6
          c0.9-2.1,2.6-3.2,5.3-3.4c0.6,0,1,0.1,1.4,0.4c0.3,0.3,0.5,0.9,0.5,1.7c0,0.7-0.1,1.3-0.4,1.7c-0.3,0.4-0.9,0.7-1.7,0.8l-0.8,0.1
          c-2.6,0.3-3.9,1.6-3.9,4v6.6C98.2,29.4,97.3,30.3,95.5,30.3z"/>
      </g>
      <g>
        <path fill="currentColor" d="M112.6,36.7c-0.9,0-1.6-0.3-2-1c-0.4-0.7-0.4-1.5,0.1-2.4l1.9-4.2l-5.8-13.2c-0.4-0.9-0.4-1.7,0-2.4
          c0.4-0.7,1.2-1,2.3-1c0.6,0,1.1,0.2,1.5,0.4c0.4,0.3,0.8,0.9,1.1,1.7l3.6,9.2l3.8-9.2c0.3-0.8,0.7-1.4,1.1-1.6
          c0.4-0.3,1-0.4,1.7-0.4c0.9,0,1.5,0.3,1.9,1c0.4,0.7,0.3,1.5-0.1,2.4l-8.3,18.8c-0.3,0.8-0.8,1.4-1.2,1.6
          C113.8,36.5,113.2,36.7,112.6,36.7z"/>
        <path fill="currentColor" d="M133,30.4c-1.5,0-2.8-0.4-4-1.1c-1.1-0.8-2-1.8-2.7-3.2c-0.6-1.4-0.9-3-0.9-4.8s0.3-3.5,0.9-4.8
          c0.6-1.4,1.5-2.4,2.7-3.1c1.1-0.7,2.5-1.1,4-1.1c1.2,0,2.2,0.3,3.2,0.8c1,0.5,1.7,1.2,2.2,2V7.5c0-1.8,0.9-2.7,2.6-2.7
          c1.8,0,2.7,0.9,2.7,2.7v20.1c0,1.8-0.9,2.7-2.6,2.7s-2.6-0.9-2.6-2.7v-0.1c-0.5,0.9-1.2,1.6-2.2,2.1
          C135.3,30.1,134.2,30.4,133,30.4z M134.6,26.4c1.1,0,2.1-0.4,2.8-1.2c0.7-0.8,1.1-2.1,1.1-3.8c0-1.7-0.3-3-1.1-3.8
          c-0.7-0.8-1.6-1.2-2.8-1.2c-1.1,0-2.1,0.4-2.7,1.2c-0.7,0.8-1.1,2.1-1.1,3.8c0,1.7,0.3,3,1.1,3.8C132.5,26,133.5,26.4,134.6,26.4z"
          />
        <path fill="currentColor" d="M156.1,30.4c-1.9,0-3.5-0.4-4.9-1.1c-1.4-0.8-2.4-1.8-3.2-3.2c-0.8-1.4-1.1-3-1.1-4.8c0-1.8,0.4-3.4,1.1-4.8
          c0.8-1.4,1.8-2.4,3.2-3.1c1.4-0.7,3-1.1,4.9-1.1s3.5,0.4,4.9,1.1s2.5,1.8,3.2,3.1s1.1,3,1.1,4.8c0,1.9-0.4,3.5-1.1,4.8
          c-0.8,1.4-1.8,2.4-3.2,3.2C159.6,30,158,30.4,156.1,30.4z M156.1,26.4c1.1,0,2.1-0.4,2.8-1.2s1.1-2.1,1.1-3.8c0-1.7-0.3-3-1.1-3.8
          c-0.7-0.8-1.6-1.2-2.8-1.2s-2.1,0.4-2.7,1.2c-0.7,0.8-1.1,2.1-1.1,3.8c0,1.7,0.3,3,1.1,3.8C154.1,26,155,26.4,156.1,26.4z"/>
        <path fill="currentColor" d="M177,30.4c-4.9,0-7.3-2.4-7.3-7.1v-6.7h-1.5c-1.4,0-2.1-0.7-2.1-2c0-1.4,0.7-2,2.1-2h1.5V9.9
          c0-1.8,0.9-2.7,2.7-2.7c1.8,0,2.6,0.9,2.6,2.7v2.7h3.3c1.4,0,2.1,0.7,2.1,2c0,1.3-0.7,2-2.1,2H175v6.4c0,1,0.2,1.7,0.7,2.2
          s1.2,0.8,2.2,0.8c0.3,0,0.7,0,1-0.1c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.7,0.3,1.5c0,0.6-0.1,1.1-0.3,1.5
          c-0.2,0.4-0.5,0.7-1,0.9c-0.3,0.1-0.7,0.2-1.3,0.3C177.8,30.3,177.3,30.4,177,30.4z"/>
        <path fill="currentColor" d="M189.4,30.4c-1,0-2.1-0.1-3.2-0.3c-1.1-0.2-2.1-0.5-3-0.9c-0.6-0.3-1-0.7-1.1-1.2c-0.2-0.5-0.2-0.9,0-1.4
          c0.2-0.4,0.5-0.8,0.9-1c0.4-0.2,0.9-0.2,1.5,0c1,0.4,1.9,0.7,2.7,0.9s1.6,0.3,2.3,0.3c1,0,1.7-0.2,2.2-0.5c0.5-0.3,0.7-0.7,0.7-1.3
          c0-0.8-0.6-1.4-1.8-1.5l-3.7-0.7c-1.5-0.3-2.6-0.8-3.4-1.6c-0.8-0.8-1.2-1.9-1.2-3.2c0-1.2,0.3-2.2,1-3c0.7-0.9,1.6-1.5,2.7-2
          c1.2-0.5,2.5-0.7,4-0.7c2.1,0,3.9,0.4,5.5,1.2c0.5,0.3,0.9,0.6,1,1.1s0.1,0.9-0.1,1.4c-0.2,0.4-0.5,0.8-0.9,1
          c-0.4,0.2-0.9,0.2-1.5-0.1c-0.8-0.3-1.5-0.6-2.1-0.8S190.5,16,190,16c-1.1,0-1.8,0.2-2.3,0.5s-0.7,0.8-0.7,1.3
          c0,0.8,0.6,1.4,1.7,1.5l3.7,0.7c1.5,0.3,2.7,0.8,3.5,1.5c0.8,0.8,1.2,1.8,1.2,3.1c0,1.8-0.7,3.2-2.1,4.2
          C193.5,29.9,191.7,30.4,189.4,30.4z"/>
      </g>
    </svg>
  );
};

export default StorydotsLogo;
