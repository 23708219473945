import { FC } from "react";
import {
  CardsGrid,
  Box
} from "./styledComponents";
import StoreCard from "../../Molecules/StoresCard/index"
import { useContext } from "react";
import { StoresContext } from "context";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Card {
  name: string;
  alt: string;
  image: string;
  url: string;
};


const StoresGrid: FC = () => {

  const stores = useContext(StoresContext).state
  const { t } = useTranslation("Stores");


  return (
    <Box>
      <CardsGrid container direction={"row"}>
        {
          stores.length > 0
            ?
            stores.map((card: Card) => <Grid item xs={6} md={3} lg={12 / 5} key={card.alt}><StoreCard card={card} key={card.alt} /></Grid>)
            :
            <Grid item><p style={{ fontWeight: "bold" }}>{t("Filters.noResults")}</p></Grid>
        }
      </CardsGrid>
    </Box>
  )
};

export default StoresGrid;