import React, { FC, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import {
  BrandsBanner,
  CirclesBackground,
  EndToEndStepsFallback,
  PlatformsCompatibilityFallback,
  ScheduleButton
} from "components";
import {
  Container,
  Title,
  TitleSecondLine,
} from "./styledComponents";

const EndToEndSteps = lazy(() => import("../../../Organisms/EndToEndSteps"));
const PlatformsCompatibility = lazy(() => import("../../../Molecules/PlatformsCompatibility"));

const EndToEndIntegration: FC = () => {
  const { t } = useTranslation("Main");

  return (
    <CirclesBackground>
      <Container>
        <BrandsBanner />
        <Title variant="h2">
          {t("EndToEnd.title")}
        </Title>
        <TitleSecondLine variant="h2">
          {t("EndToEnd.titleSecondLine")}
        </TitleSecondLine>
        <Suspense fallback={<EndToEndStepsFallback />}>
          <EndToEndSteps />
        </Suspense>
        <Suspense fallback={<PlatformsCompatibilityFallback />}>
          <PlatformsCompatibility />
        </Suspense>
        <ScheduleButton margins={"0px auto auto auto"} text={t("EndToEnd.primaryButton")} to="#MeetingsCalendar"/>
      </Container>
    </CirclesBackground>
  );
};

export default EndToEndIntegration;