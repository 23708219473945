import { useTranslation } from "react-i18next";
import endToEndImages from "img/mainPage/endToEndSteps";

interface Card {
  title: string;
  text: string;
  image: () => JSX.Element;
}

type CardsArray = Card[];

const useGetCardsArray = () => {
  const { t } = useTranslation("Main");

  const ecommerceCardsArray:CardsArray = [
    {
      title: t("EndToEnd.cards.left.card1.title"),
      text: t("EndToEnd.cards.left.card1.text"),
      image: endToEndImages.Ecommerce1,
    }, {
      title: t("EndToEnd.cards.left.card2.title"),
      text: t("EndToEnd.cards.left.card2.text"),
      image: endToEndImages.Ecommerce2,
    }, {
      title: t("EndToEnd.cards.left.card3.title"),
      text: t("EndToEnd.cards.left.card3.text"),
      image: endToEndImages.Ecommerce3,
    }, {
      title: t("EndToEnd.cards.left.card4.title"),
      text: t("EndToEnd.cards.left.card4.text"),
      image: endToEndImages.Ecommerce4,
    },
  ];
  
  const retailCardsArray:CardsArray = [
    {
      title: t("EndToEnd.cards.right.card1.title"),
      text: t("EndToEnd.cards.right.card1.text"),
      image: endToEndImages.Retail1,
    }, {
      title: t("EndToEnd.cards.right.card2.title"),
      text: t("EndToEnd.cards.right.card2.text"),
      image: endToEndImages.Retail2,
    }, {
      title: t("EndToEnd.cards.right.card3.title"),
      text: t("EndToEnd.cards.right.card3.text"),
      image: endToEndImages.Retail3,
    }, {
      title: t("EndToEnd.cards.right.card4.title"),
      text: t("EndToEnd.cards.right.card4.text"),
      image: endToEndImages.Retail4,
    },
  ];

  return {
    ecommerceCardsArray,
    retailCardsArray,
  };
};

export default useGetCardsArray;
