import { useContext, useEffect, useState } from "react";
import { GeneralContext } from 'context';

const useOptionsAndResponsiveness = () => {
  const { viewportWidth } = useContext(GeneralContext);
  const [cardWidth, setCardWidth]= useState<number>(1115);
  const [cardHeight, setCardHeight] = useState<number>(367);
  const [navButtons, setNavButtons] = useState<boolean>(true);
  const [leftMargin, setLeftMargin] = useState<number>(0);
  const [rightMargin, setRightMargin] = useState<number>(0);

  useEffect(() => {
    if (viewportWidth <= 475 && navButtons === true) {
      setNavButtons(false);
      setLeftMargin(15);
      setRightMargin(15)
      if (cardHeight === 560) return;
    }

    if (viewportWidth > 475 && navButtons === false) {
      setNavButtons(true);
      setLeftMargin(0);
      setRightMargin(0)
      if (cardHeight === 560) return;
    }

    if (viewportWidth <= 770 && cardHeight === 367) {
      setCardWidth(330);
      setCardHeight(560);
      return;
    }

    if (viewportWidth > 770 && cardHeight !== 367) {
      setCardWidth(1115);
      setCardHeight(367);
    }
  }, [viewportWidth, cardHeight, navButtons]);

  return {
    cardWidth,
    cardHeight,
    navButtons,
    leftMargin,
    rightMargin,
  }
};

export default useOptionsAndResponsiveness;
