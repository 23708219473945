import { FC } from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Container,
  ImageContainer,
  PlatformImage,
  ActionsContainer,
  SecondaryContainer,
  PrimaryContainer,
  SecondaryAction,
  PrimaryAction,
} from "./styledComponents";
import { PlatformReleaseBanner } from "components/Atoms";

interface IPlatformCardProps {
  imageSrc: string;
  primaryAction: {
    link: string;
    text: string;
    color?: string;
  };
  secondaryAction?: {
    link: string;
    text: string;
    color?: string;
  };
  banner?: string;
};

const PlatformCard: FC<IPlatformCardProps> = ({
  imageSrc,
  primaryAction,
  secondaryAction,
  banner,
}) => {
  return (
    <Container>
      <ImageContainer>
        {banner && <PlatformReleaseBanner text={banner} />}
        <PlatformImage
          src={imageSrc}
          alt="Platform poster"
          width={394}
          height={188}
        />
      </ImageContainer>
      <ActionsContainer>
        {secondaryAction &&
          <SecondaryContainer>
            <a
              href={secondaryAction.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SecondaryAction
                endIcon={<ChevronRightIcon />}
                setColor={secondaryAction.color}
                tabIndex={-1}
              >
                {secondaryAction.text}
              </SecondaryAction>
            </a>
          </SecondaryContainer>
        }
        <PrimaryContainer>
          <a
            href={primaryAction.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PrimaryAction
              endIcon={<ChevronRightIcon />}
              setColor={primaryAction.color}
              tabIndex={-1}
            >
              {primaryAction.text}
            </PrimaryAction>
          </a>
        </PrimaryContainer>
      </ActionsContainer>
    </Container>
  );
};

export default PlatformCard;
