import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const Container = styled(Box)`
  position: relative;
  max-width: 80%;
  margin: 50px auto 20px auto;
  overflow: hidden;
`;

export const SDxTN = styled("img")`
  max-width: 100%;
  height: 45px;
  margin: 0px;
`;

export const SDAnchor = styled("a")`
  position: absolute;
  top: 0;
  right: calc(50% + 15px);
  width: 248px;
  height: 45px;
`;

export const TNAnchor = styled("a")`
  position: absolute;
  top: 0;
  left: calc(50% + 7px);
  width: 258px;
  height: 45px;
`;
