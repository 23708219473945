import React, { FC, useContext } from "react";
import { GeneralContext } from "context";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FooterSocialMedia } from "components";
import StorydotsLogo from "img/footer/StorydotsLogo";
import GoogleForStartupsAndAWS from "img/footer/GoogleForStartupsAndAWS";
import VTEXAcceleratorProgram from "img/footer/VTEXAcceleratorProgram";
import PlatziStartups from "img/footer/PlatziStartups";
import {
  Container,
  LogoAndTermsContainer,
  StyledLogoLink,
  TermsAndConditions,
  GoogleAndAWSContainer,
  SupportedByText,
  VTEXContainer,
  WinnerText,
  SocialMediaContainer,
} from "./styledComponents";

const Footer: FC = () => {
  const { viewportWidth } = useContext(GeneralContext);
  const location = useLocation();
  const isMain = location.pathname === "/";
  const { t } = useTranslation("Footer");

  if (viewportWidth <= 672) return (
    <Container component="footer">
      <GoogleAndAWSContainer>
        <SupportedByText>
          Supported by
        </SupportedByText>
        <GoogleForStartupsAndAWS />
        <PlatziStartups />
      </GoogleAndAWSContainer>
      <VTEXContainer>
        <WinnerText>
          Winner
        </WinnerText>
        <VTEXAcceleratorProgram />
      </VTEXContainer>
      <SocialMediaContainer>
        <FooterSocialMedia />
      </SocialMediaContainer>
      <LogoAndTermsContainer>
        <StyledLogoLink
          to={"/"}
          isMain={isMain}
          aria-label={t("logoAria")}
        >
          <StorydotsLogo />
        </StyledLogoLink>
        <Link to={"/privacy-terms"}>
          <TermsAndConditions>
            {t("terms")}
          </TermsAndConditions>
        </Link>
      </LogoAndTermsContainer>
    </Container>
  );

  return (
    <Container component="footer">
      <LogoAndTermsContainer>
        <StyledLogoLink
          to={"/"}
          isMain={isMain}
          aria-label={t("logoAria")}
        >
          <StorydotsLogo />
        </StyledLogoLink>
        <Link to={"/privacy-terms"}>
          <TermsAndConditions>
            {t("terms")}
          </TermsAndConditions>
        </Link>
      </LogoAndTermsContainer>
      <GoogleAndAWSContainer>
        <SupportedByText>
          Supported by
        </SupportedByText>
        <GoogleForStartupsAndAWS />
        <PlatziStartups />
      </GoogleAndAWSContainer>
      <VTEXContainer>
        <WinnerText>
          Winner
        </WinnerText>
        <VTEXAcceleratorProgram />
      </VTEXContainer>
      <SocialMediaContainer>
        <FooterSocialMedia />
      </SocialMediaContainer>
    </Container>
  );
};

export default Footer;
