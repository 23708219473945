import { useHistory, useLocation } from "react-router-dom";
import { Link, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { FC, useEffect, useState } from "react";
import {
    ModalContainer,
    InvalidGiftImg,
    PlanButton,
    ModalContent,
    ImgContainer,
    CloseButton,
    Title,
    SubTitle,
} from "./styledComponents";
import invalid_gift from "../../../img/mainPage/invalid_gift.png"
import TN_logo from "../../../img/mainPage/tn_logo.svg"

const MainModalInvalidPlan: FC = () => {

    const queryParameter = useLocation().search
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false)
    const {t} = useTranslation("Main");

    const handleClose = () => {
        setOpen(false)
        history.push("/")
    }

    useEffect(() => {
        queryParameter === "?invalid_plan" && setOpen(true)
    }, [])

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalContainer>
                <CloseButton onClick={handleClose}>
                    <CloseIcon fontSize="large" />
                </CloseButton>
                <ImgContainer>
                    <InvalidGiftImg
                        src={invalid_gift}
                        alt="invalid_gift_img"
                    />
                </ImgContainer>
                <ModalContent>
                    <Title id="modal-modal-title">
                    {t(`InvalidPlanModal.title`)}
                    </Title>
                    <SubTitle id="modal-modal-description" sx={{mt: 1}}>
                    {t(`InvalidPlanModal.subTitle`)}
                    </SubTitle>
                    <Link href="https://www.tiendanube.com/planes-y-precios" target="_blank">
                        <PlanButton onClick={handleClose}>
                            <img src={TN_logo} alt="tiendanube_logo" style={{ marginRight: "5px" }} />
                            {t(`InvalidPlanModal.button`)}
                        </PlanButton>
                    </Link>
                </ModalContent>
            </ModalContainer>
        </Modal>
    )
}

export default MainModalInvalidPlan;

