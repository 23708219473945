import { FC, useContext } from "react";
import { PricingPlansContext } from 'context';
import { useTranslation } from "react-i18next";
import {
  UnlimitedMidContainer,
  UnlimitedMidUpperText,
  UnlimitedMidLowerText,
  TNEvolutionImageAnchor,
} from './styledComponents';
import TNEvolutionLogo from 'img/pricingPlans/tn-evolution-logo-black.svg';
import TNAdvancedLogo from 'img/pricingPlans/tn-plan-avanzado.svg';
import NVNextLogo from 'img/pricingPlans/nuvemshop-next-logo-black.svg';

const UnlimitedPlanMidSection: FC = () => {
  const { countrySelected } = useContext(PricingPlansContext);
  const {t} = useTranslation("pricingPlans");
  const tr = "mainSection.pricingCard.unlimitedMid.";
  const evolutionLink = "https://www.tiendanube.com/evolucion";
  const nextLink = "https://site.nuvemshop.com.br/next/plataforma";
  const advancedLink= "https://www.google.com.ar";

  return (
    <UnlimitedMidContainer>
      <UnlimitedMidUpperText>
        {t(`${tr}upperText`)}
      </UnlimitedMidUpperText>
      <TNEvolutionImageAnchor
        href={countrySelected === "br" ? nextLink : countrySelected === 'ar' ? evolutionLink : advancedLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={countrySelected === "br" ? NVNextLogo : countrySelected === "ar" ? TNEvolutionLogo : TNAdvancedLogo}
          alt="TiendaNube Evolution logo"
        />
      </TNEvolutionImageAnchor>
      <UnlimitedMidLowerText>
        {t(`${tr}lowerText`)}
      </UnlimitedMidLowerText>
    </UnlimitedMidContainer>
  );
};

export default UnlimitedPlanMidSection;
