import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#616161",
    color: "white",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "center",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "0px",
      height: "0px",
      border: "7px solid transparent"
    }
  },
}));

export default useStyles;

/* Styles required at specifics sent:
  container: {
    top: ,
    left: ,
    width: ,
    height: ,
    "&::before": {
      top: ,
      left: ,
      border(XXX): "7px solid #616161"
    }
  }
*/
