import { FC, useState, } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getStyledComponents from './getStyledComponents';
import useStyles from "./useStyles";
import { Tooltip } from "components";
import CheckIcon from '@mui/icons-material/Check';
import ualaBisLogo from 'img/pricingPlans/uala-bis-logo-black.svg';
import { countriesData } from "helpers";

interface IProps {
  plan: "scale" | "startup" | "unlimited";
  isHighlighted: boolean;
}

const PlanBottomItems: FC<IProps> = ({ plan, isHighlighted }) => {
  const classes = useStyles();
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const { t } = useTranslation("pricingPlans");
  const tr = "mainSection.pricingCard.planBottomItems.";
  const {
    PlanItemsContainer,
    PlanItem,
    CheckContainer,
    PlanItemText,
    UalaLogo,
    StyledInfoIcon,
    InfoTooltipContainer,
  } = getStyledComponents(isHighlighted);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const country = params.get("country");

  const plansItems = {
    scaleItems: [
      <PlanItemText><strong>{t(`${tr}scale.first`)}</strong></PlanItemText>,
      <PlanItemText><strong>{t(`${tr}scale.second`)}</strong></PlanItemText>,
      <PlanItemText>{t(`${tr}scale.third`)}</PlanItemText>
    ],
    startupItems: [
      <PlanItemText><strong>{t(`${tr}startup.first`)}</strong></PlanItemText>,
      <PlanItemText><strong>{t(`${tr}startup.second`)}</strong></PlanItemText>,

    ],
    unlimitedItems: [
      <PlanItemText><strong>{t(`${tr}unlimited.first`)}</strong></PlanItemText>,
      <PlanItemText>{t(`${tr}unlimited.second`)}</PlanItemText>,
      <PlanItemText sx={{ position: "relative" }}>
        {t(`${tr}unlimited.third`)}
        <InfoTooltipContainer>
          <UalaLogo src={ualaBisLogo} alt="ualá bis logo" />
          <StyledInfoIcon
            onMouseEnter={() => setDisplayTooltip(true)}
            onMouseLeave={() => setDisplayTooltip(false)}
            onFocus={() => setDisplayTooltip(true)}
            onBlur={() => setDisplayTooltip(false)}
          />
          {displayTooltip &&
            <Tooltip
              text={t(`${tr}unlimited.tooltip`)}
              specifics={classes.tooltipSpecifics}
            />
          }
        </InfoTooltipContainer>
      </PlanItemText>
    ]
  };

  return (
    <PlanItemsContainer>
      {plansItems[`${plan}Items`]
        .filter((item, id) => !(id === 2 && `${plan}Items` === 'unlimitedItems' && country !== 'ar'))
        .map((item, id) => (
          <PlanItem key={`${plan}PlanItem${id}`}>
            <CheckContainer>
              <CheckIcon sx={{ width: "15px" }}/>
            </CheckContainer>
            {item}
          </PlanItem>
        ))}
    </PlanItemsContainer>
  );
};

export default PlanBottomItems;
