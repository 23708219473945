import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const ScaleMidContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;

export const ScaleMidUpperText = styled(Typography)`
  width: 100%;
  margin-top: 19px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.88px;
  letter-spacing: 0.15px;
`;

export const PerfitImageAnchor = styled("a")`
  margin: "0px 10px 10px -10px";
  margin-bottom: "15px";
`;

export const GiftyImageAnchor = styled("a")`
  margin-top: "5px";
  margin-bottom: "15px";
`;

export const ScaleMidLowerText = styled(Typography)`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.02px;
  letter-spacing: 0.15px;
`;
