import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  marginTop: "69px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  alignItems: "center",
  justifyContent: "flex-start",
  "@media (max-width: 770px)": {
    marginTop: "calc(69px + (100vw - 770px) * 0.2)",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  margin: "0px",
  color: theme.palette.primary.contrastText,
  textAlign: "center",
  fontSize: "60px",
  fontWeight: 800,
  lineHeight: "60px",
  letterSpacing: "-1.5px",
  "@media (max-width: 900px)": {
    fontSize: "calc(60px + (100vw - 900px) * 0.065)",
    lineHeight: "calc(60px + (100vw - 900px) * 0.065)",
    marginBottom: "5px",
  },
}));

export const TitleSecondLine = styled(Title)(({ theme }) => ({
  marginBottom: "62px",
  backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} 42%, ${theme.palette.secondary.main} 46%, ${theme.palette.primary.main})`,
  backgroundClip: "text",
  backgroundSize: "200%",
  backgroundPosition: "center",
  color: "transparent",
  lineHeight: "75px",
  "@media (max-width: 900px)": {
    marginBottom: "62px",
  },
}));
