import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  marginBottom: "36px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const FallbackContainer = styled(Box)(() => ({
  width: "100%",
  height: "546px",
  marginBottom: "36px",
  background: "transparent",
  "@media (max-width: 770px)": {
    height: "534px",
  }
}));
