import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  maxWidth: "100%",
  height: "830px",
  paddingTop: "200px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  "@media (max-width: 1200px)": {
    paddingTop: "calc(200px + (100vw - 1200px) * 0.1)",
    height: "calc(830px + (100vw - 1200px) * 0.485)",
  },
  "@media (max-width: 770px)": {
    paddingTop: "140px",
    height: "855px",
  },
  "@media (max-width: 600px)": {
    height: "850px",
  },
  "@media (max-width: 500px)": {
    paddingTop: "150px",
    height: "calc(230px + ((50px + (100vw - 500px) * 0.1) * 1.2) * 2 + (100vw - 500px) * 0.7 + 615px + (100vw - 500px) * 0.51)", // video top + 68% of card height + video reducing size (negative number)
  },
  "@media (max-width: 350px)": {
    paddingTop: "165px",
    height: "calc(260px + ((50px + (100vw - 500px) * 0.1) * 1.2) * 2 + (100vw - 500px) * 0.7 + 615px + (100vw - 500px) * 0.51)", // video top + 68% of card height + video reducing size (negative number)
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: "60px",
  fontWeight: 700,
  lineHeight: "60px",
  letterSpacing: "-1.5px",
  textAlign: "center",
  "@media (max-width: 1200px)": {
    fontSize: "calc(60px + (100vw - 1200px) * 0.025)",
  },
  "@media (max-width: 770px)": {
    fontSize: "calc(49px + (100vw - 770px) * 0.05)",
  },
  "@media (max-width: 500px)": {
    fontSize: "calc(50px + (100vw - 500px) * 0.1)",
    lineHeight: "calc((50px + (100vw - 500px) * 0.1) * 1.2)",
  },
}));

export const Span = styled("span")(({ theme }) => ({
  color: theme.palette.secondary.main,
  "@media (max-width: 500px)": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));
