import fanbag from "./fanbag.svg";
import isadora from "./isadora.svg";
import kitchenaid from "./kitchenaid.svg";
import modo from "./modo.svg";
import quilmes from "./quilmes.svg";
import simplicity from "./simplicity.svg";
import todomoda from "./todomoda.svg";
import viamo from "./viamo.svg";

interface BrandObj {
  src: string;
  width: number;
  height: number;
}

type BrandsArr = BrandObj[];

export const brandsArray:BrandsArr = [
  {
    src: kitchenaid,
    width: 159,
    height: 36,
  }, {
    src: viamo,
    width: 95,
    height: 36,
  }, {
    src: isadora,
    width: 92,
    height: 36,
  }, {
    src: todomoda,
    width: 139,
    height: 36,
  }, {
    src: fanbag,
    width: 72,
    height: 36,
  }, {
    src: quilmes,
    width: 95,
    height: 36,
  }, {
    src: modo,
    width: 105,
    height: 36,
  }, {
    src: simplicity,
    width: 171,
    height: 36,
  }, {
    src: kitchenaid,
    width: 159,
    height: 36,
  }, {
    src: viamo,
    width: 95,
    height: 36,
  }, {
    src: isadora,
    width: 92,
    height: 36,
  }, {
    src: todomoda,
    width: 139,
    height: 36,
  }, {
    src: fanbag,
    width: 72,
    height: 36,
  }, {
    src: quilmes,
    width: 95,
    height: 36,
  }, {
    src: modo,
    width: 105,
    height: 36,
  }, {
    src: simplicity,
    width: 171,
    height: 36,
  },
];
