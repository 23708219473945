import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { PricingPlansContext } from "context";
import { SubscribeButton, StyledAnchor } from './styledComponents';

interface IProps {
  plan: "scale" | "startup" | "unlimited";
  country: string;
}

const PlanSubscribeButton: FC<IProps> = ({ plan, country }) => {
  const {
    scalePlanLink,
    offerScalePlanLink,
    startupPlanLink,
    offerStartupPlanLink,
    unlimitedPlanLink,
    offerUnlimitedPlanLink
  } = useContext(PricingPlansContext);
  const { t } = useTranslation("pricingPlans");
  const tr = "mainSection.pricingCard.";

  return (
    <StyledAnchor
      href={/*country === 'ar' ? (plan === "scale"
        ? offerScalePlanLink
        : plan === "startup"
          ? offerStartupPlanLink
          : offerUnlimitedPlanLink) :*/
        (plan === "scale"
          ? scalePlanLink
          : plan === "startup"
            ? startupPlanLink
            : unlimitedPlanLink)
      }
      target="_blank"
      rel="noopener noreferrer"
    >
      <SubscribeButton>
        {t(`${tr}subscribeButton`)}
      </SubscribeButton>
    </StyledAnchor>
  );
};

export default PlanSubscribeButton;