import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

const getStyledComponents = (isScale:boolean, isStartup:boolean, highlightCard:boolean) => {
  const setPlan = (scale:any, startup:any, unlimited:any) => {
    if (isScale) return scale;
    if (isStartup) return startup;
    return unlimited;
  }

  const BorderContainer = styled(Box)`
    box-sizing: border-box;
    width: ${highlightCard ? "371px" : "345px"};
    max-width: 100%;
    height: ${highlightCard ? "629px" : "585px"};
    ${highlightCard && "padding: 4px;"}
    margin: auto 15px;
    ${highlightCard && "background: linear-gradient(rgba(98, 66, 232, 1), rgba(2, 248, 196, 1));"}
    border-radius: ${highlightCard ? "20px" : "18px"};
    box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.25), -14px 28px 48px rgba(0, 0, 0, 0.2);
    @media (max-width: 1190px) {
      margin-bottom: 30px;
      ${isStartup && "transform: translateY(calc(50% + 15px));"}
    }
    @media (max-width: 775px) {
      ${isStartup && "transform: unset;"}
    }
    @media (max-width: 375px) {
      height: unset;
    }
  `;

  const Container = styled(Box)`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: white;
    border-radius: 18px;
  `;

  const GiftsImage = styled("img")`
    z-index: 3;
    position: absolute;
    top: ${setPlan("10px", "23px", "30px")};
    left: ${setPlan("-33px", "-26px", "-45px")};
    width: 205px;
    pointer-events: none;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    @media (max-width: 375px) {
      top: ${setPlan("5px", "13px", "0px")};
      left: ${setPlan("-3px", "5px", "-20px")};
      width: 155px;
    }
    @media (max-width: 350px) {
      left: ${setPlan("-13px", "-5px", "-30px")};
    }
  `;

  const HeaderContainer = styled(Box)`
    position: relative;
    height: 174px;
    margin-bottom: ${highlightCard ? "38px" : "37px"};
    background: ${highlightCard ? "linear-gradient(180deg, #6242E8 0%, #02F8C4 100%), #6242E8" : "rgba(98, 66, 232, 1)"};
    border-radius: 16px;
    overflow: hidden;
    @media (max-width: 375px) {
      height: 140px;
      margin-bottom: ${highlightCard ? "28px" : "27px"};
    }
  `;

  const GlowImage = styled("img")`
    position: absolute;
    top: ${setPlan("-83px", "-55px", "-79px")};
    left: ${setPlan("-142px", "-120px", "-110px")};
    width: 393px;
    transform: rotate(-16deg);
    pointer-events: none;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    @media (max-width: 375px) {
      top: ${setPlan("-120px", "-95px", "-150px")};
      left: ${setPlan("-137px", "-110px", "-120px")};
    }
    @media (max-width: 350px) {
      left: ${setPlan("-147px", "-120px", "-130px")};
    }
  `;

  const CardTitle = styled(Typography)`
    position: absolute;
    top: ${setPlan("78px", "77px", "74px")};
    left: ${setPlan("145px", "149px", "140px")};
    color: white;
    font-family: 'Nunito', 'Manrope', 'Poppins', sans-serif !important;
    font-size: 32px;
    line-height: 26px;
    letter-spacing: 0.46px;
    font-weight: 800;
    text-transform: capitalize;
    @media (max-width: 375px) {
      top: ${setPlan("60px", "60px", "60px")};
      left: unset;
      right: ${setPlan("22%", "15%", "10%")};
    }
    @media (max-width: 350px) {
      font-size: 30px;
      right: ${setPlan("20%", "10%", "7%")};
    }
  `;

  return {
    BorderContainer,
    Container,
    GiftsImage,
    HeaderContainer,
    GlowImage,
    CardTitle,
  }
}

export default getStyledComponents;
