import { useTranslation } from "react-i18next";
import { personsImages, reviewsImages, logosImages } from "img/mainPage/reviewsCarousel";

interface LogoObj {
  src: string;
  width: number;
  height: number;
};

interface Card {
  image: string;
  logos: LogoObj[];
  picture: string;
  job: string;
  company: string;
  name: string;
  text: string[];
};

const useGetCardsArray = () => {
  const { t } = useTranslation("Main");

  const rafaelSanchezCard: Card = {
    image: reviewsImages.rafaelSanchezDecorative,
    logos: [
      {
        src: logosImages.isadora,
        width: 92,
        height: 36,
      }, {
        src: logosImages.todomoda,
        width: 139,
        height: 36,
      },
    ],
    picture: personsImages.rafaelSanchez,
    job: "CIO, COO & Innovation Director",
    company: "TODOMODA e ISADORA",
    name: "Rafael Sánchez",
    text: [
      t("SuccessCases.carousel.card1.text1"),
      t("SuccessCases.carousel.card1.text2"),
    ],
  };
  
  const denisSerranoCard: Card = {
    image: reviewsImages.denisSerranoDecorative,
    logos: [
      {
        src: logosImages.tiendanube,
        width: 152,
        height: 24,
      },
    ],
    picture: personsImages.denisSerrano,
    job: "Domain Owner - Marketing",
    company: "TiendaNube",
    name: "Denis Serrano",
    text: [
      t("SuccessCases.carousel.card2.text1"),
      t("SuccessCases.carousel.card2.text2"),
    ],
  };
  
  const gabrielaWajnermanCard: Card = {
    image: reviewsImages.gabrielaWajnermanDecorative,
    logos: [
      {
        src: logosImages.modo,
        width: 105,
        height: 36,
      },
    ],
    picture: personsImages.gabrielaWajnerman,
    job: "Business Development Director",
    company: "MODO",
    name: "Gabriela Wajnerman",
    text: [
      t("SuccessCases.carousel.card3.text1"),
      t("SuccessCases.carousel.card3.text2"),
    ],
  };

  const cardsArray: Card[] = [
    rafaelSanchezCard,
    denisSerranoCard,
    gabrielaWajnermanCard,
  ];

  return cardsArray;
};

export default useGetCardsArray;
