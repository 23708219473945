import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  maxWidth: "min(880px, calc(100vw - 40px)) !important",
  height: "655px",
  maxHeight: "655px",
  overflow: "hidden",
  background: "rgba(255, 255, 255, 1)",
  borderRadius: "32px",
  boxShadow: "0px 5px 10px 5px rgb(0 0 0 / 12%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 2px 4px -1px rgb(0 0 0 / 20%)",
  "&::before, &::after": {
    content: "''",
    zIndex: 999,
    position: "absolute",
    top: "0px",
    left: "0px",
    bottom: "0px",
    width: "18px",
    height: "100%",
    background: "rgba(255, 255, 255, 1)",
  },
  "&::after": {
    left: "unset",
    right: "0px",
  },
  "@media (max-width: 920px)": {
    "&::before, &::after": {
      width: "calc(20px + (100vw - 920px) * 0.6)"
    },
  },
  "@media (max-width: 893px)": {
    "&::before, &::after": {
      width: "7px"
    },
  },
  "@media (max-width: 672px)": {
    width: "min(406px, 100% - 40px)",
    maxWidth: "min(406px, calc(100% - 40px)) !important",
  },
  "@media (max-width: 350px)": {
    width: "calc(100vw - 20px)",
    maxWidth: "calc(100vw - 20px) !important",
    "&::before, &::after": {
      width: "5px"
    },
  },
  "@media (max-width: 330px)": {
    width: "calc(100vw - 12px)",
    maxWidth: "calc(100vw - 12px) !important",
  },
}));

export const BorderContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  maxWidth: "100%",
  height: "100%",
  maxHeight: "100%",
  "&::before, &::after": {
    content: "''",
    zIndex: 999,
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    width: "100%",
    height: "18px",
    background: "rgba(255, 255, 255, 1)",
  },
  "&::after": {
    top: "unset",
    bottom: "0px",
  },
  "@media (max-width: 672px)": {
    "&::after": {
      height: "55px",
    },
  },
}));

export const FallbackContainer = styled(Box)(() => ({
  width: "100%",
  maxWidth: "min(880px, calc(100vw - 40px)) !important",
  height: "655px",
  maxHeight: "655px",
  background: "rgba(255, 255, 255, 1)",
  borderRadius: "32px",
  "@media (max-width: 672px)": {
    width: "min(406px, 100% - 40px)",
    maxWidth: "min(406px, calc(100% - 40px)) !important",
  },
  "@media (max-width: 350px)": {
    width: "calc(100vw - 20px)",
    maxWidth: "calc(100vw - 20px) !important",
  },
  "@media (max-width: 330px)": {
    width: "calc(100vw - 12px)",
    maxWidth: "calc(100vw - 12px) !important",
  },
}));

export const StyledHubspotWidget = styled("div")(() => ({
}));
