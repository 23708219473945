import { Typography } from "@mui/material";
import { styled } from "@mui/material";

const Subtitle = styled(Typography)(() => ({
  fontWeight: 400,
  maxWidth: "300px",
  fontSize: "18px",
  color: "#0000008A",
  textAlign: "center",
}));

export default Subtitle;
