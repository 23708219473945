import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { WHATSAPP_CONTACT } from "config";
import LaunchIcon from '@mui/icons-material/Launch';
import {
  StyledButton,
} from "./styledComponents";

interface Props {
  colorType?: "primaryBG" | "secondaryBG" | "contrastBG"
  margins?: string;
  text?: string;
  className?: string;
};

const ContactSalesButton: FC<Props> = ({
  colorType = "primaryBG",
  margins = "0px",
  text,
  className,
}) => {
  const { t } = useTranslation("Buttons");

  return (
    <a
      href={WHATSAPP_CONTACT}
      target="_blank"
      rel="noopener noreferrer"
    >
      <StyledButton
        endIcon={<LaunchIcon />}
        className={className}
        colorType={colorType}
        margins={margins}
        tabIndex={-1}
      >
        {text || t("schedule")}
      </StyledButton>
    </a>
  );
};

export default ContactSalesButton;
