import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tooltipSpecifics: {
    top: "-32px",
    left: "-85px",
    width: "144px",
    height: "25px",
    "&::before": {
      top: "calc(100% - 1px)",
      left: "72%",
      borderTop: "7px solid #616161"
    },
    "@media (min-width: 650px)": {
      left: "-65px",
      "&::before": {
        left: "62%"
      }
    }
  }
}));

export default useStyles;
