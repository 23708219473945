import { styled } from "@mui/system";
import { Slider } from "@mui/material";

export const StyledSlider = styled(Slider)`
  width: 195px;
  margin-bottom: 30px;
  .MuiSlider-rail {
    width: calc(100% + 10px);
    height: 11px;
    background: rgba(98, 66, 232, 0.8);
    transform: translate(-5px, -6px);
  }
  .MuiSlider-track {
    width: calc(100% + 10px);
    height: 11px;
    background: rgba(98, 66, 232, 1);
    transform: translate(-5px, -6px);
  }
  .MuiSlider-thumb {
    background: rgba(98, 66, 232, 1);
  }
  .MuiSlider-markLabel {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }
  .MuiSlider-markLabelActive {
    color: rgba(0, 0, 0, 0.54);
  }
`;
