import React from "react";
import InsertCode from "./InsertCode";
import { APP_URL, API_SITE_URL } from "config/environment";
import { useParams } from "react-router-dom";

function CodeAssociation() {
  const [code, setCode] = React.useState("");
  const { encryptedClientID } = useParams<{ encryptedClientID: string }>();

  const handleDefaultGreeting = React.useCallback(async () => {
    // get new code
    const response = await fetch(
      `${API_SITE_URL}/dispenser/${encryptedClientID}?redirect=0`,
      {
        method: "POST",
      }
    );
    if (!response.ok) {
      console.debug(response.statusText);
      throw new Error("Error fetching tag dispenser service >>");
    }
    const defaultGreetingURL = (await response.text()).split("?")[0];
    // redirect to new code
    window.location.href = defaultGreetingURL;
  }, [encryptedClientID]);

  const handleCodeSubmit = React.useCallback(() => {
    const storyURL = new URL(
      "/story/" + code?.toLowerCase(),
      APP_URL
    ).toString();
    window.location.href = storyURL;
  }, [code, encryptedClientID]);

  return (
    <InsertCode
      handleSubmit={handleCodeSubmit}
      handleDefaultGreeting={handleDefaultGreeting}
      setCode={setCode}
      code={code}
    />
  );
}

export default CodeAssociation;
