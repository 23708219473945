import { FC, useState } from "react";
import {
  LogoContainer,
  LogoImage,
} from "./styledComponents";

interface IProps {
  obj: {
    logo: string;
    disabled: string;
    link: string;
  };
  id: number;
}

const SupportCompanyLogo: FC<IProps> = ({obj, id}) => {
  const [imageToDisplay, setImageToDisplay] = useState(obj.disabled);

  const handleFocusOn = () => setImageToDisplay(obj.logo);
  const handleFocusOff = () => setImageToDisplay(obj.disabled);

  return (
    <LogoContainer
      href={obj.link}
      target="_blank"
      rel="noopener noreferrer"
      key={`supportLogo${id}}`}
      onMouseEnter={handleFocusOn}
      onMouseLeave={handleFocusOff}
      onFocus={handleFocusOn}
      onBlur={handleFocusOff}
    >
      <LogoImage src={imageToDisplay} alt={`company ${id} logo`} />
    </LogoContainer>
  );
};

export default SupportCompanyLogo;