const LOCALE_PT = {
  NavBar: {
    tour: "Demonstração",
    integrations: "Integrações",
    Gift: "Dê um presente",
  },
  Main: {
    Hero: {
      titleSpan: "Permita",
      title: " presentes online",
    },
    EndToEnd: {
      title: "End-to-end",
      titleSecondLine: "integração omni-canal",
      switch: {
        left: "Ecommerce",
        right: "Loja física",
      },
      cards: {
        left: {
          card1: {
            title: "Integração",
            text: "Integramos com seu carrinho de compras, permitindo que você indique quando uma compra é um presente.",
          },
          card2: {
            title: "Criação da saudação",
            text: "Ao final da compra, seu cliente receberá um e-mail para criar uma saudação virtual.",
          },
          card3: {
            title: "Impressão de QR",
            text: "De seu painel de controle, você precisará imprimir uma etiqueta QR contendo a saudação e adicioná-la à embalagem.",
          },
          card4: {
            title: "Surpresa!",
            text: "O destinatário do presente poderá ver a saudação e responder a ela.",
          },
        },
        right: {
          card1: {
            title: "Implementação",
            text: "Enviamos-lhe os cartões QR para imprimir e distribuir nas lojas.",
          },
          card2: {
            title: "Fácil de usar",
            text: "Ao detectar um presente, uma etiqueta é adicionada à embalagem e a outra é para o comprador.",
          },
          card3: {
            title: "Criação da saudação",
            text: "Ao digitalizar o QR, o comprador poderá criar sua saudação virtual.",
          },
          card4: {
            title: "Surpresa!",
            text: "O destinatário do presente poderá ver a saudação e responder a ela.",
          },
        },
      },
      platformsDescription:
        "Compatível com as principais plataformas de ecommerce",
      primaryButton: "Agendar Demo",
    },
    SuccessCases: {
      title: "Histórias de sucesso",
      subtitle: "entre nossos clientes",
      carousel: {
        card1: {
          text1:
            "Na BSG estamos constantemente desenvolvendo idéias que conectam, personalizam e surpreendem nossos clientes.",
          text2:
            "Storydots é uma solução para esta necessidade que também nos ajudou a entender métricas que desconhecíamos.",
        },
        card2: {
          text1:
            "O StoryDots permite-lhe acrescentar valor a todas as compras de presentes.",
          text2:
            "É por isso que a solução é altamente valorizada pelos nossos comerciantes e um dos melhores classificados na nossa App Store.",
        },
        card3: {
          text1:
            "StoryDots ajudou-nos a transformar os nossos dons em experiências.",
          text2:
            "Personalizámos centenas de presentes e garantimos respostas de forma dinâmica e disruptiva.",
        },
      },
      buttonDescription: "Interessado em nossa solução?",
      primaryButton: "Agendar Demo",
    },
    MeetingsCalendar: {
      title: "Agende uma demo",
      titleSecondLine: "faça evoluir seu ecommerce",
    },
    InvalidPlanModal: {
      title: "Não é possível instalar Storydots em sua loja",
      subTitle:
        'Desculpe, o Storydots só está disponível no plano "Impulso" do TiendaNube',
      button: "Aumente seu plano",
    },
  },
  Integrations: {
    header: {
      title: {
        firstLine: "Seleccione seu",
        secondLine: "plataforma ecommerce",
      },
      subtitle1:
        "Centenas de marcas estão a impulsionar as suas vendas e UX, utilizando a nossa solução.",
      subtitle2: "Encontre aqui as informações para integrar no seu ecommerce.",
      primaryButton: "Agendar Demo",
      secondaryButton: "Contatar",
    },
    Showcase: {
      install: "Instalar",
      seeDocumentation: "Ver documentação",
      bookADemo: "Agendar Demo",
    },
  },
  Footer: {
    terms: "Termos e Condições",
    logoAria: "Ir para a página principal",
    instagramAria: "Ir para Storydots Instagram",
    linkedinAria: "Ir para Storydots LinkedIn",
    facebookAria: "Ir para Storydots Facebook",
    emailAria: "Envie um e-mail para Storydots",
  },
  Buttons: {
    continue: "Continuar",
    retry: "Tentativa",
    cancel: "Cancelar",
    confirm: "Aceitar",
    learnMore: "Ver mais",
    schedule: "Agendar Demo",
    bookADemo: "Agendar Demo",
  },
  Carousel: {
    backwardsButton: "Voltar carousel",
    forwardButton: "Adiante carousel",
  },
  PrivacyTerms: {
    Title: {
      title: "Política de privacidade e cookies ",
    },
    GeneralSection: {
      title: "Informação geral",
      paragraph1: `A politica de privacidade se aplica a toda a informação proporcionada por você à StoryDots, uma empresa que comercializa uma solução de mensagens pessoais para presentes sob a marca StoryDots.\n
        A política de privacidade se aplica a todas as informações fornecidas por você à StoryDots que é utilizada por Storydots durante o uso do serviço e describe nossas praticas de gravação, uso, retenção, divulgação e proteção do seus dados pessoais.\n
        StoryDots respeita a sua preocupação com a confidencialidade das informações que nos fornece. Ao fornecer-nos as suas informações e dados pessoais, consente que utilizemos e estudemos esses dados, a fim de personalizar e optimizar a sua navegação no website e de lhe oferecer serviços e experiências que satisfaçam as suas expectativas.\n
        Encorajamo-lo a ler atentamente esta política de privacidade juntamente com os vários avisos que lhe possam ser comunicados no momento da recolha de dados, a fim de estar plenamente informado sobre a recolha e utilização dos seus dados pessoais.\n
        Podemos alterar esta política de privacidade. Os termos actualizados entrarão em vigor 30 dias após a sua publicação. Se necessário, quaisquer alterações significativas poderão ser-lhe notificadas por correio electrónico.`,
      paragraph2: `Ao utilizar a aplicação web, poderá ser-lhe pedido que forneça à StoryDots as suas informações pessoais, especialmente o seu endereço de correio electrónico ao criar uma saudação virtual.\n
        Todos os utilizadores concordam que a saudação carregada será associada a um URL único. Qualquer pessoa com acesso a esse URL (quer directamente ou através do QR atribuído) terá acesso à saudação. O Utilizador compreende e aceita o nível de privacidade oferecido por estes URLs.\n
        StoryDots pode recolher certas informações relacionadas com a sua navegação no Website. Tais dados são recolhidos em conformidade com os termos e condições do artigo 2 "Cookies" da presente política de privacidade.\n
        O tratamento de certas informações pessoais relacionadas com as suas interacções com os nossos serviços é considerado como a caracterização ao abrigo da lei aplicável. Este processamento é realizado no nosso interesse para lhe proporcionar uma experiência de alta qualidade e personalizada, a fim de criar e/ou visualizar uma mensagem personalizada.\n
        Tem o direito de aceder, modificar e apagar os seus dados pessoais, bem como o direito de se opor ao tratamento dos seus dados pessoais, em qualquer altura.\n
        Qualquer pedido relativo ao direito de acesso, modificação ou eliminação de dados pessoais que lhe digam respeito pode também ser enviado para o seguinte endereço dedicado: support@storydots.app.`,
    },
    PersonalDataSection: {
      title: "Utilização de dados pessoais por StoryDots",
      DataCollection: {
        title: "1.1 Finalidade do recolhimento de dados",
        paragraph1:
          "Através do Webapp, StoryDots recolhe e processa os dados pessoais necessários para os seguintes fins: disponibilizar uma saudação virtual à pessoa a quem envia ou recebe um presente.",
      },
      DataRetention: {
        title: "1.2 Segurança e confiabilidade dos dados",
        paragraph1:
          "Conservamos os seus dados pessoais durante o tempo mínimo exigido por lei, quando especificado. Quando o nosso interesse legítimo exigir um período diferente de conservação dos seus dados, teremos em conta a sensibilidade dos dados, o risco potencial e os requisitos de segurança ao fixar o período de conservação.",
        paragraph2:
          "Quando a retenção dos seus dados já não for necessária para o fim pretendido, StoryDots apagará os seus dados.",
      },
      DataRecipient: {
        title: "1.3 Destinatário dos dados",
        paragraph1: `Todos os dados pessoais recolhidos na nossa WebApp destinam-se a StoryDots. No entanto, a StoryDots pode, de tempos a tempos, precisar de transferir os seus dados para outras entidades com o único objectivo de relações com clientes.\n
            Ao processar encomendas, os dados devem ser transferidos para outras empresas em nome da StoryDots. Nessa medida, para reduzir o risco de perda ou dano de dados, StoryDots compromete-se a minimizar a informação revelada a terceiros para o que for absolutamente necessário para atingir o objectivo pretendido.\n
            Quaisquer que sejam as circunstâncias, StoryDots apenas transferirá os seus dados para outras empresas nos seguintes casos específicos:\n
            StoryDots deve transferir os seus dados pessoais para terceiros em relação aos pedidos que tenha feito (fornecedor de serviços, fornecedores de logística, fornecedores de serviços de marketing e publicidade para a optimização da sua experiência de compras);\n
            A StoryDots recebeu o seu consentimento expresso para transferir os seus dados para terceiros;\n
            StoryDots exige que os seus fornecedores de serviços (Amazon Web Services) garantam a segurança dos seus dados pessoais e cumpram os regulamentos. Os prestadores de serviços da StoryDots não têm o direito de utilizar os seus dados pessoais para fins diferentes dos especificados pela StoryDots, de acordo com as suas instruções.\n
            No âmbito de uma empresa contratante do serviço, os dados pessoais recolhidos serão transferidos para essa empresa com o objectivo de cumprir os seus objectivos. Nesta medida, o tratamento de dados pessoais será sujeito à política de confidencialidade estabelecida por essa empresa.
            `,
      },
      DataSecurity: {
        title: "1.4 Segurança e confidencialidade dos dados",
        paragraph1:
          "StoryDots fez todos os esforços para tomar todas as precauções para garantir a segurança e confidencialidade dos dados pessoais processados, para evitar que tais dados sejam distorcidos, danificados ou destruídos e para impedir que terceiros não autorizados tenham acesso aos seus dados. Todos os elementos de segurança utilizados são de última geração, particularmente no que diz respeito aos sistemas de informação.",
        paragraph2:
          "Na medida em que a StoryDots não pode controlar todos e cada um dos riscos associados às operações na Internet, chama-se a atenção dos utilizadores da Internet para os riscos potenciais inerentes à utilização da Internet e ao funcionamento na Internet.",
      },
    },
    CookieSection: {
      WhatIsACookie: {
        title: "2.1 O que é um cookie?",
        paragraph1: `Um cookie é um ficheiro de texto que pode ser armazenado num espaço dedicado no disco rígido do seu terminal (computador, tablet, smartphone) pelo browser que utiliza para consultar o nosso Website, dependendo das definições guardadas no seu browser em relação aos cookies. O ficheiro cookie permite ao servidor web identificar o terminal em que é armazenado durante o período de validade ou registo do cookie.\n
            Ao navegar no website, os dados relativos à navegação do seu terminal no nosso website podem ser armazenados em ficheiros cookie armazenados no seu terminal. Estes cookies permitem à StoryDots oferecer-lhe uma experiência de compra personalizada ligada ao seu dispositivo de acesso.\n
            Pode alterar as suas definições de cookies em qualquer altura. Abaixo encontrará mais informações sobre cookies e como pode gerir as suas definições de cookies.`,
      },
      CookiesAndTech: {
        title: "2.2 Cookies e tecnologias similares na aplicação web",
        paragraph1: `Dependendo das definições guardadas no seu browser, a navegação no nosso WebApp pode resultar em vários cookies, web beacons do seu endereço IP, IDFA, geolocalização via endereço IP, informação de login e navegação (URL de referência, dados de publicidade, estatísticas de visitantes, credenciais de login) armazenados no seu terminal, de modo a que a StoryDots possa reconhecer o browser do seu terminal durante o período de retenção desta informação. StoryDots recolhe informação das páginas que navega, as ligações que clica e outras acções como parte da sua navegação em ligação com as suas ofertas comerciais.\n
            Cookies e tecnologias similares que armazenamos no seu navegador podem:\n
            Recolher estatísticas e volumes de tráfego e utilização dos diferentes componentes do Webapp (elementos e conteúdos visitados, caminhos de navegação), para permitir à StoryDots oferecer serviços mais relevantes e ergonómicos;\n
            Adapte a apresentação do nosso Webapp às preferências de visualização do seu terminal (idioma utilizado, resolução de ecrã, sistema operativo utilizado, etc.) ao navegar no Webapp, de acordo com o hardware e o software de leitura utilizado pelo seu terminal.;\n
            na medida em que nos tenha fornecido os seus dados pessoais, em particular o seu endereço de correio electrónico durante a criação da saudação na WebApp, ao fazer a sua encomenda ou ao aceder aos nossos serviços, para associar estes dados aos seus dados de navegação, a fim de lhe enviar ofertas por correio electrónico ou para exibir no seu terminal anúncios personalizados que possam ser de particular interesse para si, nos espaços publicitários que colocamos à sua disposição;\n
            armazenar informações relacionadas com um formulário que tenha preenchido no Webapp ou com produtos, serviços ou informações que tenha escolhido no Webapp (serviço subscrito, conteúdo de um carrinho de compras, etc.);\n
            Poderá ser capaz de partilhar informações com redes sociais como parte do serviço. Estas redes sociais podem permitir-nos aceder automaticamente a informações que tenha publicado, por exemplo, um produto ou conteúdo de que tenha gostado ou clicado. O acesso a estas informações pode permitir-nos adaptar as nossas ofertas com base nas suas preferências,`,
      },
      StoredCookies: {
        title: "2.3 Cookies armazenados por aplicações de terceiros no sítio",
        paragraph1: `StoryDots pode incluir aplicações de software de outras fontes na WebApp concebidas para partilhar conteúdos da WebApp com outros ou para os informar de conteúdos que tenha visto. Isto aplica-se ao botão "Partilhar" no Facebook e WhatsApp.\n
            Los medios sociales que proporcionan el botón de la aplicación pueden entonces identificarte.\n
            Facebook: en el caso de que quieras compartir un mensaje recibido\n
            Google (Google Analytics e Adwords): Analytics. Este cookie fornece dados anónimos que nos permitem compreender o comportamento e as preferências de marketing de um utilizador no Sítio. Também nos ajuda a visar os nossos anúncios no Google de uma forma mais relevante.`,
      },
      YourChoices: {
        title: "2.4 As suas escolhas em relação aos cookies",
        paragraph1:
          "Há várias formas de gerir os cookies. Qualquer alteração às definições pode modificar as suas condições de navegação e acesso ao Website. Pode alterar estas configurações em qualquer altura, utilizando os procedimentos descritos abaixo.",
        paragraph2:
          "Pode configurar o seu navegador para que os cookies sejam guardados no seu terminal ou, pelo contrário, rejeitados, dependendo da sua escolha. Também pode configurar o seu navegador para que lhe seja oferecida a aceitação de cookies de forma atempada e antes de um cookie ser armazenado no seu terminal.",
        ApprovalOfCookies: {
          title: "a) Aprovação dos Cookies",
          paragraph:
            "Qualquer registo de cookies no seu terminal está sujeito à obtenção do seu consentimento, que pode expressar e modificar em qualquer altura e gratuitamente, alterando as definições do seu navegador. Se tiver aceite o registo de cookies no seu terminal, os cookies incorporados nas páginas e conteúdo que visualizou serão armazenados numa área reservada do seu terminal e só serão legíveis e reconhecíveis pelo emissor.",
        },
        RefusalOfCookies: {
          title: "b) Rejeição de cookies",
          paragraph:
            "Se rejeitar o registo de cookies ou se eliminar os que estão registados, não poderá beneficiar de muitas funcionalidades que são necessárias para facilitar a navegação na WebApp. Este seria o caso se quisesse aceder a espaços ou serviços onde precisasse de se identificar. Este seria ainda o caso se a StoryDots ou os seus fornecedores de serviços não pudessem reconhecer, por razões de compatibilidade técnica, o tipo de navegador que o seu dispositivo utiliza, o seu idioma e definições de visualização, ou o país a partir do qual o seu dispositivo parece estar ligado à Internet. Neste caso, não aceitamos qualquer responsabilidade pelas consequências de um desempenho degradado dos nossos serviços devido às configurações que escolheu.",
        },
        AccordingToBrowser: {
          title:
            "c) Como exercer as suas opções dependendo do navegador que utiliza?",
          paragraph:
            "No que diz respeito à gestão de cookies, cada browser oferece diferentes modos de configuração. Estes são descritos no menu de ajuda de cada navegador.",
        },
      },
    },
    UseOfDataSection: {
      title: "Utilização de dados",
      paragraph1:
        "A StoryDots não irá transferir ou vender os seus dados pessoais a terceiros ou parceiros não afiliados, a menos que a StoryDots o tenha informado previamente em mensagens, acordos ou informações disponíveis na Webapp ou que tenha expressamente consentido ou seja exigido por lei que o faça.",
      paragraph2:
        "Se a StoryDots for obrigada por lei, por uma autoridade administrativa ou judicial a fornecer os seus dados pessoais, a StoryDots informá-lo-á do facto na medida do possível (a menos que a StoryDots acredite razoavelmente que não está autorizada a fazê-lo, particularmente tendo em consideração as obrigações legais ou judiciais que lhes são aplicáveis).",
      paragraph3:
        "Dada a tecnologia actual, particularmente a que é geralmente utilizada para redes de telecomunicações, a Meemento não pode garantir a confidencialidade, integridade ou autenticação dos e-mails que envia ou recebe da StoryDots.",
    },
  },
  whatsappContact: {
    tooltip: "Dúvidas? Escreva-nos",
  },
  pricingPlans: {
    mainSection: {
      joinTitle: "Junte-se à nova forma de presentear online",
      pricingCard: {
        scaleMid: {
          upperText: {
            start: "Inclui ",
            bold: "10 cumprimentos virtuales",
            boldAR: "25 cumprimentos virtuales",
          },
          lowerText: "Integrações com os nossos parceiros",
        },
        startupMid: {
          upperText: "Seleccionar número de cumprimentos virtuales",
          lowerText: "Tudo desde o plano inicial mais:",
        },
        unlimitedMid: {
          upperText: "Só disponível em",
          lowerText: "Tudo no plano avançado mais:",
        },
        planBottomItems: {
          scale: {
            first: "Coleção de cumprimentos virtuales",
            second: "Pacote de Redes Sociais",
            third: "Acesso ao Dashboard e estatísticas",
          },
          startup: {
            first: "Personalização com a sua marca",
            second: "Personalização da paleta de cores",
            third: "",
          },
          unlimited: {
            first: "Stock ilimitado",
            second: "Redireccionar usuários para o site",
            third: "Comissão preferencial com  ",
            tooltip: "2% nos primeiros 3 meses com StoryDots",
          },
        },
        subscribeButton: "Inscrever-se",
      },
      bottomText: {
        start: "As dotações são ",
        bold: "renovados automaticamente ",
        end: "numa base mensal e",
        endBold: "não são cumulativos.",
      },
    },
    supportCompanies: {
      supportTitle: "Mais de 500 lojas confiam em nós",
    },
  },
  Stores: {
    Title: {
      title: "Encontra o presente perfeito",
    },
    Filters: {
      searchBar: "Procurar marca", // FIJARSE ESTO!!!
      brand: "Marca",
      category: "Categoria",
      everything: "Todos",
      noResults: "Não foi possível encontrar resultados para sua pesquisa",
      location: {
        physical: "Físico",
        online: "Online",
        everything: "Todos",
      },
    },
  },
  TagDispenser: {
    Main: {
      loading: "Carregando..."
    },
    Home: {
      title: "Adicione uma saudação surpresa ao seu presente",
      form: {
        title: "Digite seu <bold>e-mail</bold> para começar",
        label: "Seu e-mail",
        placeholder: "exemplo@exemplo.com",
        error: "Erro ao criar saudação",
      },
      action: {
        primary: "Continuar",
      },
    },
    GetTag: {
      title: "Você pode adicionar um vídeo, foto, voz ou texto",
      subtitle: "Você criará um cartão para imprimir e adicionar à embalagem",
      action: {
        loading: "Criando saudação",
        primary: "Vamos começar!",
        secondary: "Criar depois",
        tertiary: "Criar saudação",
      },
    },
    FinishFlow: {
      title: "Verifique seu e-mail",
      subtitle:
        "Enviamos um e-mail para <bold>{{email}}</bold> para que você crie a saudação",
    },
    CodeAssociation: {
      InsertCode: {
        title: "Insira o código",
        subtitle: "de 8 caracteres encontrado na bolsa",
        actionPrimary: "Continuar",
        actionSecondary: "Não tenho código",
      },
      SaveEmail: {
        title: "Insira seu email",
        subtitle: "Para poder ver a saudação",
        email: {
          placeholder: "exemplo@email.com",
          helperText: "Insira um email válido",
        },
        acceptanceInfo:
          "Eu concordo em compartilhar meu email. Meu endereço de email será utilizado de acordo com os <a target='_blank' href='https://storydots.app/privacy-terms'>termos e condições</a>.",
        action: "Próximo",
      },
    },
    RoleSelection: {
      senderButton: "Estou dando um presente",
      receiverButton: "Eu recebi um presente"
    },
  },
};

export default LOCALE_PT;
