import { styled } from "@mui/system";
import { Box, Button, IconButton } from "@mui/material";

export const NavbarWrapper = styled(
  Box,
  { shouldForwardProp: (prop) => !["isNavbarLight"].includes(prop as string)}
)<{ isNavbarLight?: boolean; }>(
  ({ theme, isNavbarLight }) => ({
    zIndex: 20,
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    padding: "41px 151px 0px 151px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "transparent",
    color: `${isNavbarLight ? theme.palette.primary.contrastText : theme.palette.primary.main}`,
    "@media (max-width: 1200px)": {
      justifyContent: "center",
    },
    "@media (max-width: 500px)": {
      padding: "41px 25px 0px 0px",
      justifyContent: "flex-end",
    },
    "@media (max-width: 350px)": {
      padding: "60px 0px 0px 0px",
      justifyContent: "center",
    },
  })
);

export const LogoContainer = styled(
  Box,
  { shouldForwardProp: (prop) => !["isMain"].includes(prop as string) }
)<{ isMain: boolean; }>(
  ({ isMain }) => ({
    position: "relative",
    width: "240px",
    display: "flex",
    alignItems: "center",
    cursor: `${isMain ? "default" : "pointer"}`,
  })
);

export const NavButtonsContainer = styled(Box)(() => ({
  height: "100%",
  margin: "0px",
  padding: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "32px",
  "@media (max-width: 1200px)": {
    display: "none",
  },
}));

export const NavbarButton = styled(
  Button,
  { shouldForwardProp: (prop) => !["buttonColor"].includes(prop as string)}
)<{ buttonColor?: string; }>(
  ({ buttonColor }) => ({
    position: "relative",
    color: "inherit",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.46px",
    borderColor: `${buttonColor ? "currentcolor" : "unset"}`,
    "&::after": {
      content: "''",
      position: "absolute",
      top: "85%",
      left: "5%",
      width: "0%",
      height: "3px",
      borderRadius: "6px",
      background: "currentcolor",
      transition: "0.4s all",
    },
    "&:hover": {
      background: "transparent",
      "&::after": {
        width: "90%",
      },
    }
  })
);

export const MenuButton = styled(IconButton)(() => ({
  position: "absolute",
  top: "35px",
  left: "35px",
  width: "60px",
  height: "60px",
  cursor: "pointer",
  color: "inherit",
  "& svg": {
    fontSize: "45px !important",
  },
  "@media (min-width: 1201px)": {
    display: "none",
  },
  "@media (max-width: 500px)": {
    left: "15px",
  },
  "@media (max-width: 350px)": {
    "& svg": {
      fontSize: "35px !important",
    },
    top: "10px",
    left: "10px",
  },
}));
