import { PageWrapper, PlatformsHeader, PlatformsShowcase } from "components";
import { useLightNavbar } from "hooks";
import { FC } from "react";
import { Container, InnerContainer } from "./styledComponents";

const Integrations: FC = () => {
  useLightNavbar(false);
  return (
    <PageWrapper>
      <Container>
        <InnerContainer>
          <PlatformsHeader />
          <PlatformsShowcase />
        </InnerContainer>
      </Container>
    </PageWrapper>
  );
};

export default Integrations;
