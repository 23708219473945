import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const PriceContainer = styled(Box)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CountryCoin = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.46px;
`;

export const Price = styled(Typography)`
  margin: 0 4px;
  font-size: 32px;
  font-weight: 700;
  line-height: 26px;
`;

export const DiscountPrice = styled(Typography)`
  margin: 0 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0,0,0,.55);
  text-decoration: line-through;
`;

export const IVA = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.46px;
`;
