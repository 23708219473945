import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Title,
  SectionTitle,
  LightParagraph,
} from "./styledComponents";

const PrivacyInfo: FC = () => {
  const {t} = useTranslation("PrivacyTerms");
  const generalSectionParagraph1 = t('GeneralSection.paragraph1').split('\n');
  const generalSectionParagraph2 = t('GeneralSection.paragraph2').split('\n');
  const dataRecipient = t('PersonalDataSection.DataRecipient.paragraph1').split('\n');
  const whatIsACookie = t('CookieSection.WhatIsACookie.paragraph1').split('\n');
  const cookiesAndTech = t("CookieSection.CookiesAndTech.paragraph1").split('\n');
  const storedCookies = t("CookieSection.StoredCookies.paragraph1").split('\n');

  return (
    <Container>
      {generalSectionParagraph1.map((item, id) =>
        item.trim() === ""
          ? null
          : <LightParagraph key={`generalSectionParagraph1-${id}`}>
              {item.trim()}
            </LightParagraph>
      )}
      <Title variant="h2">
        {t("GeneralSection.title")}
      </Title>
      {generalSectionParagraph2.map((item, id) =>
        item.trim() === ""
          ? null
          : <LightParagraph key={`generalSectionParagraph2-${id}`}>
              {item.trim()}
            </LightParagraph>
      )}

      <SectionTitle variant="h2">
        {t("PersonalDataSection.title")}
      </SectionTitle>
      <Title variant="h3">
        {t("PersonalDataSection.DataCollection.title")}
      </Title>
      <LightParagraph>
        {t("PersonalDataSection.DataCollection.paragraph1")}
      </LightParagraph>
      <Title variant="h3">
        {t("PersonalDataSection.DataRetention.title")}
      </Title>
      <LightParagraph>
        {t("PersonalDataSection.DataRetention.paragraph1")}
      </LightParagraph>
      <LightParagraph>
        {t("PersonalDataSection.DataRetention.paragraph2")}
      </LightParagraph>
      <Title variant="h3">
        {t("PersonalDataSection.DataRecipient.title")}
      </Title>
      {dataRecipient.map((item, id) =>
        item.trim() === ""
          ? null
          : <LightParagraph key={`dataRecipient-${id}`}>
              {item.trim()}
            </LightParagraph>
      )}
      <Title variant="h3">
        {t("PersonalDataSection.DataSecurity.title")}
      </Title>
      <LightParagraph>
        {t("PersonalDataSection.DataSecurity.paragraph1")}
      </LightParagraph>
      <LightParagraph>
        {t("PersonalDataSection.DataSecurity.paragraph2")}
      </LightParagraph>

      <SectionTitle variant="h2">
        Cookies
      </SectionTitle>
      <Title variant="h3">
        {t("CookieSection.WhatIsACookie.title")}
      </Title>
      {whatIsACookie.map((item, id) =>
        item.trim() === ""
          ? null
          : <LightParagraph key={`whatIsACookie-${id}`}>
              {item.trim()}
            </LightParagraph>
      )}
      <Title variant="h3">
        {t("CookieSection.CookiesAndTech.title")}
      </Title>
      {cookiesAndTech.map((item, id) =>
        item.trim() === ""
          ? null
          : <LightParagraph key={`cookiesAndTech-${id}`}>
              {item.trim()}
            </LightParagraph>
      )}
      <Title variant="h3">{t("CookieSection.StoredCookies.title")}</Title>
      {storedCookies.map((item, id) =>
        item.trim() === ""
        ? null
        : <LightParagraph key={`storedCookies-${id}`}>
            {item.trim()}
          </LightParagraph>
      )}
      <Title variant="h3">
        {t("CookieSection.YourChoices.title")}
      </Title>
      <LightParagraph>
        {t("CookieSection.YourChoices.paragraph1")}
      </LightParagraph>
      <LightParagraph>
        {t("CookieSection.YourChoices.paragraph2")}
      </LightParagraph>
      <Title variant="h3">
        {t("CookieSection.YourChoices.ApprovalOfCookies.title")}
      </Title>
      <LightParagraph>
        {t("CookieSection.YourChoices.ApprovalOfCookies.paragraph")}
      </LightParagraph>
      <Title variant="h3">
        {t("CookieSection.YourChoices.RefusalOfCookies.title")}
      </Title>
      <LightParagraph>
        {t("CookieSection.YourChoices.RefusalOfCookies.paragraph")}
      </LightParagraph>
      <Title variant="h3">
        {t("CookieSection.YourChoices.AccordingToBrowser.title")}
      </Title>
      <LightParagraph>
        {t("CookieSection.YourChoices.AccordingToBrowser.paragraph")}
      </LightParagraph>

      <SectionTitle variant="h2">
        {t("UseOfDataSection.title")}
      </SectionTitle>
      <LightParagraph >
        {t("UseOfDataSection.paragraph1")}
      </LightParagraph>
      <LightParagraph >
        {t("UseOfDataSection.paragraph2")}
      </LightParagraph>
      <LightParagraph >
        {t("UseOfDataSection.paragraph3")}
      </LightParagraph>
    </Container>
  );
};

export default PrivacyInfo;
