import { useHistory, useLocation, useParams } from "react-router-dom";
import { Container, Title } from "../components";
import { Form } from "../components";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CustomImagesContext } from "context";
import useTrackPOPEvent from "hooks/useTrackPOPEvent";
import Lottie from "react-lottie-player";
import VideoHero from "./dispenser-hero-banner.mp4";
import Video from "./styledComponents";


export default function Dispenser() {
  //NOTE this encryptedClientID is a hashed client ID using this method -> https://github.com/Storydots/storydots-back/blob/38a8c972d5c1ac7fa2c28a5bcfd92f881dadeb48/TS/layers/nodejs/Utils/hashing/index.ts#L8
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation("TagDispenser");
  window.localStorage.removeItem("storydots-story-url");
  const theme: any = useTheme();
  const { lotties } = useContext(CustomImagesContext);

  const { encryptedClientID } = useParams<{ encryptedClientID: string }>();
  const event = useTrackPOPEvent(encryptedClientID);

  return (
    <Container theme={theme}>
      <Title variant="h5">{t("Home.title")}</Title>
      {lotties["ready.json"] ? <Lottie
        loop
        animationData={lotties["ready.json"]}
        play
        style={{ width: "100%", maxWidth: 350, height: 350 }}
      /> : <Video loop autoPlay playsInline muted>
        <source src={VideoHero} />
      </Video>}

      <Box flexGrow={0.5} />
      <Form
        onSubmit={({ email }) =>
          history.push(
            location.pathname.endsWith("/")
              ? `${location.pathname}get-tag`
              : `${location.pathname}/get-tag`,
            { email }
          )
        }
      />
    </Container>
  );
}
