import { ToggleButton } from "@mui/material";
import { ButtonGroup } from "./styledComponents";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LOCATION } from "types/storesLocationEnum";
import { FILTERS } from "components/Organisms/StoresSearch";

interface Props {
    handleChange: any;
    filters: FILTERS;
}


const StoresInputLocation: FC<Props> = ({ filters, handleChange }) => {

    const {t} = useTranslation("Stores");

    return (
        <ButtonGroup size="medium" color="primary" exclusive value={filters.location_type} onChange={handleChange} aria-label="outlined button group">
            <ToggleButton name="location_type" value={""}>{t("Filters.location.everything")}</ToggleButton>
            <ToggleButton name="location_type" value={LOCATION.ONLINE}>{t("Filters.location.online")}</ToggleButton>
            <ToggleButton name="location_type" value={LOCATION.PHYSICAL}>{t("Filters.location.physical")}</ToggleButton>
        </ButtonGroup>
    );
};

export default StoresInputLocation;