import React, { FC } from "react";
import { brandsArray } from "img/mainPage/brands";
import {
  Container,
  ConveyorBand,
  LeftFade,
  RightFade,
  BrandsBandContainer,
  BrandsBand,
  Brand,
} from "./styledComponents";

const BrandsBanner: FC = () => {
  return (
    <Container>
      <ConveyorBand>
        <LeftFade />
        <BrandsBandContainer>
          <BrandsBand>
            {brandsArray.map((brand, id) => {
              return (
                <Brand
                  src={brand.src}
                  alt={`brand ${id} logo`}
                  height={brand.height}
                  width={brand.width}
                  loading="lazy"
                  key={`bannerBrand${id}`}
                />
              )
            })}
          </BrandsBand>
        </BrandsBandContainer>
        <RightFade />
      </ConveyorBand>
    </Container>
  );
};

export default BrandsBanner;
