import { useTheme } from "@mui/material";

const Retail4 = () => {
  const theme = useTheme();

  return (
    <svg width="253" height="248" viewBox="0 0 253 248" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3663_39653)">
      <path d="M38.3995 236V206.88H-42.9805V173.86L16.0395 48.8H56.5995L-2.42046 173.86H38.3995V127.06H74.2795V173.86H90.9195V206.88H74.2795V236H38.3995Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <g clipPath="url(#clip1_3663_39653)">
      <path d="M176.421 14.5828H137.314C136.11 14.5828 135.135 15.5617 135.135 16.7693V16.87C135.135 18.0776 136.11 19.0566 137.314 19.0566H176.421C177.624 19.0566 178.6 18.0776 178.6 16.87V16.7693C178.6 15.5617 177.624 14.5828 176.421 14.5828Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M228.635 2.55933H85.1004C82.1855 2.57105 79.3015 3.15903 76.6131 4.28967C73.9247 5.42032 71.4846 7.07147 69.4323 9.14879C67.3799 11.2261 65.7555 13.6889 64.6519 16.3964C63.5483 19.104 62.987 22.0032 63.0002 24.9285V385.072C62.987 387.997 63.5483 390.896 64.6519 393.604C65.7555 396.311 67.3799 398.774 69.4323 400.851C71.4846 402.929 73.9247 404.58 76.6131 405.711C79.3015 406.841 82.1855 407.429 85.1004 407.441H146.475C146.535 407.221 146.664 407.026 146.844 406.887C147.024 406.747 147.245 406.671 147.472 406.669H166.391C166.618 406.671 166.839 406.747 167.019 406.887C167.199 407.026 167.329 407.221 167.388 407.441H228.635C231.549 407.429 234.434 406.841 237.122 405.711C239.81 404.58 242.25 402.929 244.303 400.851C246.355 398.774 247.979 396.311 249.083 393.604C250.187 390.896 250.748 387.997 250.735 385.072V24.9285C250.748 22.0032 250.187 19.104 249.083 16.3964C247.979 13.6889 246.355 11.2261 244.303 9.14879C242.25 7.07147 239.81 5.42032 237.122 4.28967C234.434 3.15903 231.549 2.57105 228.635 2.55933Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M234.24 34.4353H79.4946C74.5243 34.4353 70.4951 38.4789 70.4951 43.4668V369.889C70.4951 374.877 74.5243 378.92 79.4946 378.92H234.24C239.211 378.92 243.24 374.877 243.24 369.889V43.4668C243.24 38.4789 239.211 34.4353 234.24 34.4353Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <mask id="mask0_3663_39653" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="70" y="33" width="174" height="346">
      <path d="M234.039 34H79.9606C75.0118 34 71 38.0379 71 43.0188V368.981C71 373.962 75.0118 378 79.9606 378H234.039C238.988 378 243 373.962 243 368.981V43.0188C243 38.0379 238.988 34 234.039 34Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      </mask>
      <g mask="url(#mask0_3663_39653)">
      <rect x="63.4429" y="35" width="187" height="13" rx="6" fill={theme.palette.primary.main} fillOpacity="0.8"/>
      <rect x="63.4429" y="35" width="187" height="38" rx="6" fill={theme.palette.primary.main} fillOpacity="0.4"/>
      </g>
      <g clipPath="url(#clip2_3663_39653)">
      <path d="M146.602 90C147.656 91.3418 148.622 92.5554 149.574 93.7788C151.653 96.4428 151.653 96.4477 149.086 98.7121C146.992 100.557 144.567 101.904 142.18 103.3C139.711 104.74 137.261 106.23 135.49 108.544C134.738 109.531 134.299 109.309 133.708 108.46C133.118 107.612 132.41 106.778 131.756 105.939C129.55 103.137 129.56 103.147 132.083 100.71C133.717 99.2289 135.531 97.9641 137.481 96.946C140.775 95.0763 144.084 93.1622 146.602 90Z" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <path d="M195.365 176.76C196.829 178.591 197.986 180.273 199.357 181.778C200.304 182.818 199.879 183.44 199.133 184.244C197.222 186.149 195.055 187.771 192.696 189.064C189.611 190.879 186.39 192.517 184.131 195.448C183.433 196.35 183.038 196.084 182.515 195.354C181.993 194.624 181.422 193.923 180.866 193.218C178.167 189.764 178.128 189.616 181.661 186.805C183.276 185.565 184.979 184.449 186.756 183.465C189.87 181.639 192.925 179.785 195.365 176.76Z" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <path d="M110 144.014C111.044 142.534 112.147 141.281 112.87 139.845C114.012 137.576 115.398 137.561 117.296 138.799C119.073 139.94 120.73 141.263 122.24 142.746C125.129 145.622 127.945 148.582 131.917 150.239C130.941 151.665 130.053 152.928 129.17 154.186C127.217 156.929 127.217 156.924 124.289 155.039C122.353 153.693 120.554 152.155 118.921 150.447C116.31 147.896 113.685 145.336 110 144.014Z" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <path d="M208 154.235C206.916 155.77 205.848 156.949 205.164 158.32C203.949 160.787 202.544 160.486 200.626 159.169C198.445 157.602 196.402 155.847 194.521 153.925C192.081 151.517 189.538 149.233 186.102 147.946C187.439 146.026 188.718 144.246 189.933 142.425C190.553 141.498 191.212 141.749 192.022 142.105C194.462 143.165 196.414 144.833 198.288 146.678C201.177 149.559 204.023 152.479 208 154.235Z" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <path d="M194.121 117.133C194.807 118.16 195.55 119.148 196.346 120.093C199.694 123.546 199.708 123.516 196.385 126.876C194.169 129.12 194.169 129.12 191.915 126.846C188.625 123.516 188.64 123.536 192.017 120.058C192.686 119.358 193.159 118.479 194.121 117.133Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M171.086 129.234C169.539 130.586 168.577 131.34 167.723 132.194C164.883 135.016 164.898 135.03 162.043 132.149C159.359 129.431 159.359 129.431 161.999 126.762C164.898 123.832 164.873 123.862 167.855 126.762C168.709 127.621 169.768 128.247 171.086 129.234Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M155.074 175.241C154.419 175.566 153.8 175.961 153.229 176.42C149.291 180.367 149.374 180.303 145.45 176.553C143.986 175.157 143.942 174.417 145.45 172.992C149.398 169.227 149.31 169.158 153.229 173.09C153.794 173.554 154.402 173.96 155.045 174.304L155.074 175.241Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M178.47 108.588C178.121 108.568 177.784 108.454 177.492 108.259C177.201 108.064 176.966 107.795 176.811 107.478C176.617 107.16 176.534 106.786 176.574 106.414C176.613 106.043 176.774 105.695 177.03 105.426C178.465 103.991 179.924 102.58 181.422 101.218C181.699 100.969 182.055 100.827 182.425 100.817C182.796 100.807 183.158 100.93 183.448 101.164C183.767 101.344 184.026 101.616 184.193 101.946C184.36 102.275 184.426 102.646 184.385 103.014C184.282 104.124 179.641 108.564 178.47 108.588Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M147.339 124.769C147.242 125.101 147.088 125.413 146.885 125.692C145.518 127.083 144.162 128.479 142.698 129.772C142.526 129.95 142.319 130.09 142.09 130.181C141.861 130.273 141.615 130.314 141.369 130.303C141.124 130.291 140.883 130.227 140.663 130.114C140.444 130.001 140.251 129.842 140.096 129.648C139.316 128.819 139.281 127.833 140.048 126.999C141.399 125.598 142.829 124.271 144.264 122.954C144.532 122.697 144.878 122.539 145.246 122.507C145.614 122.475 145.982 122.57 146.289 122.776C146.64 122.966 146.927 123.257 147.114 123.611C147.301 123.966 147.379 124.369 147.339 124.769Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M153.205 152.371C153.158 152.77 152.969 153.138 152.673 153.407C151.345 154.719 150.037 156.056 148.651 157.304C147.744 158.128 146.699 158.133 145.875 157.161C145.698 156.987 145.559 156.779 145.468 156.548C145.376 156.317 145.333 156.069 145.342 155.82C145.35 155.572 145.411 155.328 145.518 155.104C145.626 154.88 145.779 154.682 145.967 154.522C147.275 153.19 148.647 151.917 150.013 150.649C150.27 150.381 150.607 150.206 150.972 150.151C151.337 150.096 151.71 150.163 152.033 150.343C152.406 150.525 152.716 150.816 152.924 151.177C153.133 151.538 153.231 151.953 153.205 152.371Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M116.383 113.679C116.77 113.812 117.134 114.005 117.462 114.252C118.804 115.539 120.126 116.851 121.39 118.198C121.592 118.373 121.753 118.592 121.859 118.838C121.966 119.084 122.017 119.352 122.007 119.621C121.998 119.889 121.929 120.152 121.805 120.39C121.682 120.628 121.506 120.835 121.293 120.995C121.126 121.177 120.923 121.32 120.697 121.416C120.471 121.513 120.228 121.56 119.983 121.555C119.738 121.55 119.496 121.493 119.275 121.387C119.053 121.281 118.856 121.129 118.697 120.941C117.32 119.599 115.954 118.203 114.626 116.792C114.369 116.526 114.209 116.18 114.171 115.81C114.134 115.44 114.222 115.068 114.421 114.755C114.605 114.398 114.89 114.106 115.24 113.914C115.589 113.722 115.988 113.641 116.383 113.679Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M176.713 166.874C176.429 166.893 176.144 166.846 175.88 166.738C175.616 166.629 175.381 166.462 175.19 166.248C173.921 165 172.662 163.742 171.432 162.454C170.593 161.571 170.456 160.575 171.344 159.647C172.233 158.72 173.243 158.804 174.121 159.647C175.458 160.94 176.747 162.282 178.025 163.628C178.293 163.882 178.472 164.217 178.535 164.583C178.598 164.948 178.543 165.325 178.377 165.656C178.239 165.995 178.01 166.289 177.716 166.504C177.422 166.72 177.075 166.848 176.713 166.874Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      <path d="M125.87 184.56C125.846 186.173 124.118 187.209 122.942 186.227C121.297 184.8 119.763 183.246 118.355 181.58C117.779 180.924 118.033 180.031 118.653 179.365C119.204 178.783 119.985 178.433 120.605 178.872C122.564 180.286 124.29 182.004 125.719 183.963C125.813 184.148 125.865 184.352 125.87 184.56Z" fill={theme.palette.primary.main} fillOpacity="0.1"/>
      </g>
      <path d="M250.735 118.879V90.1346H250.974C251.554 90.1346 252.109 90.3655 252.519 90.7766C252.929 91.1878 253.159 91.7454 253.159 92.3268V116.687C253.159 117.27 252.928 117.829 252.517 118.241C252.107 118.653 251.55 118.885 250.969 118.885H250.729L250.735 118.879Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M250.735 145.498V202.204C251.379 202.204 251.997 201.947 252.453 201.49C252.908 201.032 253.164 200.412 253.164 199.766V147.959C253.163 147.313 252.906 146.694 252.451 146.238C251.995 145.782 251.378 145.526 250.735 145.526V145.498Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M120.898 27.1654C123.36 27.1654 125.356 25.1624 125.356 22.6916C125.356 20.2208 123.36 18.2178 120.898 18.2178C118.436 18.2178 116.44 20.2208 116.44 22.6916C116.44 25.1624 118.436 27.1654 120.898 27.1654Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M118.724 24.6879C118.332 24.2512 118.077 23.7075 117.992 23.1255C117.907 22.5435 117.996 21.9493 118.248 21.418C118.499 20.8867 118.902 20.4422 119.405 20.1406C119.908 19.839 120.489 19.6938 121.075 19.7235C121.66 19.7532 122.224 19.9564 122.694 20.3073C123.164 20.6583 123.521 21.1413 123.717 21.6954C123.914 22.2494 123.943 22.8496 123.8 23.42C123.657 23.9905 123.349 24.5057 122.915 24.9004C122.626 25.1636 122.289 25.3669 121.921 25.4987C121.554 25.6305 121.165 25.6882 120.775 25.6685C120.386 25.6487 120.004 25.5519 119.652 25.3836C119.3 25.2154 118.985 24.9789 118.724 24.6879Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M95.1641 24.5371C94.7996 24.0745 94.58 23.5138 94.5331 22.926C94.4863 22.3381 94.6142 21.7495 94.9008 21.2347C95.1873 20.7198 95.6196 20.3018 96.143 20.0336C96.6663 19.7654 97.2572 19.659 97.8408 19.7279C98.4244 19.7968 98.9745 20.0379 99.4216 20.4207C99.8686 20.8035 100.192 21.3108 100.352 21.8783C100.512 22.4458 100.5 23.0482 100.319 23.609C100.137 24.1699 99.7938 24.6642 99.3323 25.0292C99.0266 25.272 98.6761 25.4518 98.3011 25.5585C97.926 25.6651 97.5336 25.6965 97.1465 25.6508C96.7593 25.6051 96.3849 25.4832 96.0448 25.2921C95.7046 25.101 95.4053 24.8444 95.1641 24.5371Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M95.833 24.0058C95.5731 23.676 95.4165 23.2761 95.3832 22.8569C95.3498 22.4377 95.4412 22.018 95.6456 21.6509C95.8501 21.2839 96.1585 20.9859 96.5317 20.7948C96.905 20.6037 97.3264 20.5281 97.7425 20.5774C98.1587 20.6268 98.5509 20.799 98.8695 21.0721C99.1881 21.3453 99.4187 21.7072 99.5323 22.112C99.6458 22.5168 99.6371 22.9463 99.5073 23.3461C99.3775 23.746 99.1324 24.0982 98.803 24.3582C98.5851 24.5311 98.3353 24.6592 98.068 24.7351C97.8007 24.811 97.5211 24.8332 97.2452 24.8005C96.9692 24.7678 96.7025 24.6807 96.4602 24.5444C96.2178 24.408 96.0047 24.225 95.833 24.0058Z" fill={theme.palette.primary.contrastText} stroke={theme.palette.primary.main} strokeMiterlimit="10"/>
      <path d="M157 123.833C146.65 123.833 138.25 132.42 138.25 143C138.25 153.58 146.65 162.167 157 162.167C167.35 162.167 175.75 153.58 175.75 143C175.75 132.42 167.35 123.833 157 123.833ZM154.858 150.392C154.201 150.896 153.25 150.427 153.25 149.598V136.402C153.25 135.573 154.201 135.104 154.858 135.608L163.465 142.206C163.987 142.607 163.987 143.393 163.465 143.794L154.858 150.392Z" fill={theme.palette.primary.main}/>
      <rect x="84" y="83" width="146" height="120" rx="15" stroke={theme.palette.primary.main} strokeWidth="2"/>
      <rect x="84" y="212" width="146" height="25" rx="6" fill={theme.palette.primary.main}/>
      <g clipPath="url(#clip3_3663_39653)">
      <path d="M158.737 227.369C158.737 227.713 158.737 228.057 158.737 228.401C158.737 228.608 158.799 228.783 159.03 228.866C159.26 228.948 159.44 228.874 159.61 228.728C161.337 227.249 163.065 225.769 164.796 224.29C165.072 224.052 165.067 223.845 164.786 223.603C163.058 222.123 161.329 220.644 159.6 219.165C159.438 219.03 159.264 218.953 159.043 219.032C158.808 219.114 158.737 219.281 158.737 219.486C158.737 220.165 158.727 220.844 158.743 221.523C158.747 221.72 158.678 221.76 158.466 221.767C154.213 221.907 150.589 224.855 150.067 228.598C150.026 228.896 150.019 229.198 149.998 229.497C149.982 229.739 150.079 229.923 150.363 229.986C150.638 230.046 150.8 229.909 150.926 229.715C151.922 228.186 153.37 227.131 155.252 226.537C156.324 226.198 157.437 226.124 158.565 226.148C158.715 226.151 158.741 226.195 158.741 226.312C158.731 226.665 158.737 227.017 158.737 227.369Z" fill={theme.palette.primary.contrastText}/>
      </g>
      <rect x="95.4429" y="54" width="136" height="14" rx="7" fill={theme.palette.primary.contrastText}/>
      <circle cx="104.443" cy="61" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="221.443" cy="61" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <circle cx="208.443" cy="61" r="5" fill={theme.palette.primary.main} fillOpacity="0.2"/>
      <rect x="113.443" y="58" width="86" height="7" rx="3.5" fill={theme.palette.primary.main} fillOpacity="0.3"/>
      <path d="M85.0004 57.5575L83.9429 56.5L79.4429 61L83.9429 65.5L85.0004 64.4425L81.5654 61L85.0004 57.5575Z" fill={theme.palette.primary.contrastText}/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_3663_39653">
      <rect width="253" height="248" fill={theme.palette.primary.contrastText}/>
      </clipPath>
      <clipPath id="clip1_3663_39653">
      <rect width="193" height="248" fill={theme.palette.primary.contrastText} transform="translate(60)"/>
      </clipPath>
      <clipPath id="clip2_3663_39653">
      <rect width="98" height="106" fill={theme.palette.primary.contrastText} transform="translate(110 90)"/>
      </clipPath>
      <clipPath id="clip3_3663_39653">
      <rect width="15" height="11" fill={theme.palette.primary.contrastText} transform="translate(150 219)"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default Retail4;
