import { FC } from "react";
import { PlatformCard } from "components";
import { Container } from "./styledComponents";
import useGetPlatformsArray from "./useGetPlatformsArray";

const PlatformsShowcase: FC = () => {
  const platformsArray = useGetPlatformsArray();

  return (
    <Container>
      {platformsArray.map(({ imageSrc, primaryAction, secondaryAction, banner }, index) => (
        <PlatformCard
          imageSrc={imageSrc}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          banner={banner}
          key={`${imageSrc}${index}`}
        />
      ))}
    </Container>
  );
};

export default PlatformsShowcase;
