import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

function CodeInput(props: TextFieldProps) {
  return (
    <TextField
      placeholder="________"
      style={{
        width: "100%",
        maxWidth: "320px",
        padding: "0px",
      }}
      inputProps={{
        style: {
          padding: "2px 2px",
          textAlign: "center",
          textTransform: "uppercase",
        },
      }}
      InputProps={{
        style: {
          borderRadius: "8px",
          fontSize: "32px",
          fontWeight: 600,
          letterSpacing: "6px",
        },
      }}
      {...props}
    />
  );
}

export default CodeInput;
