import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "1394px",
  maxWidth: "90vw",
  height: "440px",
  minHeight: "440px",
  padding: "10px 15px",
  display: "flex",
  overflowX: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.primary.main.replace("1)", "0.2)")}`,
  "&::-webkit-scrollbar": {
    height: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: `${theme.palette.primary.main.replace("1)", "0.2)")}`,
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-button:end:increment, &::-webkit-scrollbar-button:start:decrement": {
    width: "30%",
  },
}));
