import React, { FC, useEffect, useRef } from "react";
import {
  Video, Image
} from "./styledComponents";
import useGetVideo from "./useGetVideo";

const MainHeroVideo: FC = () => {
  const [videoSrc, imageSrc] = useGetVideo();
  const videoRef = useRef<HTMLVideoElement>(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    videoRef.current?.load();
  }, [videoSrc]);

  return (
    <>
    {isSafari 
    ? 
    <Video ref={videoRef} poster={imageSrc} autoPlay playsInline muted loop>
      <source src={videoSrc} />
    </Video>
    : 
    <Video ref={videoRef} autoPlay playsInline muted loop>
      <source src={videoSrc} />
    </Video>
    }
    </>
  )
};

export default MainHeroVideo;
