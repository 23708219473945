import { styled } from "@mui/system";
import { Box, IconButton } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  maxWidth: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",
  "& img": {
    pointerEvents: "none",
  },
}));

export const ButtonsAndBandContainer = styled(
  Box,
  { shouldForwardProp: (prop) => !["cardWidth", "sidesSpace"].includes(prop as string) }
)<{ cardWidth: number; sidesSpace: number;}>(
  ({ cardWidth, sidesSpace }) => ({
    position: "relative",
    width: `${cardWidth}px`,
    maxWidth: `calc(100% - ${sidesSpace}px)`,
    borderRadius: "32px",
    boxShadow: "0px 5px 10px 5px rgb(0 0 0 / 12%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 2px 4px -1px rgb(0 0 0 / 20%)",
  })
);

export const BackwardsButton = styled(
  IconButton,
  { shouldForwardProp: (prop) => !["cardHeight", "navButtons"].includes(prop as string) }
)<{ cardHeight: number; navButtons: boolean; }>(
  ({ theme, cardHeight, navButtons }) => ({
    position: "absolute",
    top: `${cardHeight / 2 - 30}px`,
    left: "-70px",
    width: "60px",
    height: "60px",
    display: `${navButtons ? "block" : "none"}`,
    color: theme.palette.primary.contrastText,
    "& svg": {
      paddingLeft: "9px",
      fontSize: "45px",
    },
    "&:disabled": {
      color: theme.palette.primary.contrastText,
    },
  })
);

export const ForwardButton = styled(BackwardsButton)(() => ({
  left: "unset",
  right: "-68px",
}));

export const BandContainer = styled(
  Box,
  { shouldForwardProp: (prop) => !["cardWidth", "cardHeight"].includes(prop as string) }
)<{ cardWidth: number; cardHeight: number; }>(
  ({ theme, cardWidth, cardHeight }) => ({
    position: "relative",
    width: `${cardWidth}px`,
    maxWidth: "100%",
    height: `${cardHeight}px`,
    display: "flex",
    background: theme.palette.primary.contrastText,
    borderRadius: "32px",
    overflow: "hidden",
  })
);

export const ConveyorBand = styled(
  Box,
  { shouldForwardProp: (prop) => !["translateBand", "dragTranslate", "bandTransition"].includes(prop as string) }
)<{ translateBand: number; dragTranslate: number; bandTransition: string; }>(
  ({ translateBand, dragTranslate, bandTransition }) => ({
    position: "absolute",
    top: "0px",
    left: "0px",
    display: "flex",
    transform: `translateX(-${translateBand + dragTranslate}px)`,
    transition: bandTransition,
  })
);

export const ChildContainer = styled(
  Box,
  { shouldForwardProp: (prop) => !["cardWidth", "cardHeight", "sidesSpace"].includes(prop as string) }
)<{ cardWidth: number; cardHeight: number; sidesSpace: number}>(
  ({ cardWidth, cardHeight, sidesSpace }) => ({
    width: `${cardWidth}px`,
    maxWidth: `calc(100vw - ${sidesSpace}px)`,
    height: `${cardHeight}px`,
    maxHeigth: `${cardHeight}px`,
    overflow: "hidden",
  })
);

export const BulletsContainer = styled(Box)(() => ({
  width: "100%",
  height: "38px",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  gap: "12px",
}));

export const Bullet = styled(
  Box,
  { shouldForwardProp: (prop) => !["transitionTime", "selected", "bullets"].includes(prop as string) }
)<{ transitionTime: number; selected: boolean; bullets: boolean; }>(
  ({ theme, transitionTime, selected, bullets }) => ({
    width: "12px",
    height: "12px",
    display: `${bullets ? "block" : "none"}`,
    background: theme.palette.primary.contrastText,
    borderRadius: "50%",
    opacity: `${selected ? 1 : 0.5}`,
    transition: `${transitionTime}ms opacity`,
    cursor: "pointer",
  })
);
