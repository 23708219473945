import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  margin: "50px 120px 120px 120px",
  "@media (max-width: 600px)": {
    margin: "50px 60px 100px 60px",
  },
}));

export const Title = styled(Typography)(() => ({
  marginTop: "16px",
  marginBottom: "16px",
  fontSize: "1.25rem",
  fontWeight: 500,
  letterSpacing: "0.15px",
  fontStyle: "normal",
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: "16px",
  marginBottom: "16px",
  fontSize: "1.5rem",
  fontWeight: "normal",
  fontStyle: "normal",
}));

export const LightParagraph = styled(Typography)(() => ({
  margin: "16px 0px",
  fontSize: "1rem",
  fontWeight: "400",
}));
