const LOCALE_ES = {
  NavBar: {
    tour: "Ver Demo",
    integrations: "Integraciones",
    Gift: "Regalá",
  },
  Main: {
    Hero: {
      titleSpan: "Permite",
      title: " regalar online",
    },
    EndToEnd: {
      title: "End-to-end",
      titleSecondLine: "integración omnicanal",
      switch: {
        left: "Ecommerce",
        right: "Tienda física",
      },
      cards: {
        left: {
          card1: {
            title: "Integración",
            text: "Nos integramos a tu carrito, permitiendo indicar cuando una compra es para regalo",
          },
          card2: {
            title: "Creación del saludo",
            text: "Al finalizar la compra tu cliente recibirá un mail para crear un saludo virtual.",
          },
          card3: {
            title: "Impresión del QR",
            text: "Desde tu panel de control tendrás que imprimir una etiqueta QR que contiene el saludo y sumarla al paquete.",
          },
          card4: {
            title: "¡Sorpresa!",
            text: "Quien recibe el regalo podrá ver el saludo y responderlo.",
          },
        },
        right: {
          card1: {
            title: "Implementación",
            text: "Te enviamos las tarjetas QR para imprimir y distribuir en los locales.",
          },
          card2: {
            title: "Uso sencillo",
            text: "Al detectar un regalo, una etiqueta se agrega en el packaging y la otra es para el comprador.",
          },
          card3: {
            title: "Creación del saludo",
            text: "Escaneando el QR quien compró podrá crear su saludo virtual.",
          },
          card4: {
            title: "¡Sorpresa!",
            text: "Quien recibe el regalo podrá ver el saludo y responderlo.",
          },
        },
      },
      platformsDescription:
        "Compatible con  las plataformas líderes en ecommerce",
      primaryButton: "Agendar Demo",
    },
    SuccessCases: {
      title: "Casos de Éxito",
      subtitle: "entre nuestros clientes",
      carousel: {
        card1: {
          text1:
            "En BSG estamos contínuamente desarrollando ideas que conecten, personalicen y sorprendan a nuestras clientas.",
          text2:
            "Storydots es una solución para esta necesidad que además nos ayudó a conocer métricas que no conocíamos.",
        },
        card2: {
          text1:
            "StoryDots permite agregar valor en todas las compras para regalo.",
          text2:
            "Por ese motivo la solución es muy valorada por nuestros merchants y una de las mejor rankeadas en nuestro App Store.",
        },
        card3: {
          text1:
            "StoryDots nos ayudó a transformar nuestros regalos en experiencias.",
          text2:
            "Personalizamos cientos de regalos y nos aseguramos respuestas de una manera dinámica y disruptiva.",
        },
      },
      buttonDescription: "¿Te interesa nuestra solución?",
      primaryButton: "Agendar Demo",
    },
    MeetingsCalendar: {
      title: "Agenda una demo",
      titleSecondLine: "evoluciona tu ecommerce",
    },
    InvalidPlanModal: {
      title: "No es posible instalar Storydots en tu tienda",
      subTitle:
        "Lo sentimos, Storydots solo está disponible a partir del plan “Impulso” de TiendaNube",
      button: "Aumentá tu plan",
    },
  },
  Integrations: {
    header: {
      title: {
        firstLine: "Seleccioná tu",
        secondLine: "plataforma ecommerce",
      },
      subtitle1:
        "Cientos de marcas están potenciando sus ventas y su UX usando nuestra solución.",
      subtitle2:
        "Encuentra aquí la información para integrarnos en tu ecommerce.",
      primaryButton: "Agendar Demo",
      secondaryButton: "Contactanos",
    },
    Showcase: {
      install: "Instalar",
      seeDocumentation: "Ver documentación",
      bookADemo: "Agendar Demo",
    },
  },
  Footer: {
    terms: "Términos y condiciones",
    logoAria: "Ir a la página principal",
    instagramAria: "Ir al Instagram de Storydots",
    linkedinAria: "Ir al LinkedIn de Storydots",
    facebookAria: "Ir al Facebook de Storydots",
    emailAria: "Enviar un email a Storydots",
  },
  Buttons: {
    continue: "Continuar",
    retry: "Reintentar",
    cancel: "Cancelar",
    confirm: "Aceptar",
    learnMore: "Ver más",
    schedule: "Agendar Demo",
    bookADemo: "Agendar Demo",
  },
  Carousel: {
    backwardsButton: "Retroceder carousel",
    forwardButton: "Adelantar carousel",
  },
  PrivacyTerms: {
    Title: {
      title: "Política de privacidad y cookies ",
    },
    GeneralSection: {
      title: "Información general",
      paragraph1: `La siguiente política de privacidad ha sido establecida por StoryDots, empresa que comercializa una solución de mensajería personal para regalos bajo la marca StoryDots.\n
        La política de privacidad se aplica a toda la información proporcionada por usted a StoryDots o recopilada por StoryDots durante el uso del servicio y describe nuestras prácticas de recopilación, uso, retención, divulgación y protección de sus datos personales.\n
        StoryDots respeta su preocupación por la confidencialidad de la información que nos proporciona. Al darnos su información y datos personales, usted consiente en que utilicemos y estudiemos dichos datos, para personalizar y optimizar su navegación en el sitio web y para proponerle servicios y experiencias que respondan a sus expectativas.\n
        Le invitamos a leer detenidamente esta política de privacidad junto con las distintas notificaciones que se le puedan comunicar en el momento en que recojamos los datos para estar plenamente informado sobre la recogida y el uso de sus datos personales.\n
        Podemos modificar esta política de privacidad. Los términos actualizados entrarán en vigor 30 días después de su publicación. Si es necesario, cualquier cambio significativo podrá serle notificado por correo electrónico.`,
      paragraph2: `Al utilizar la aplicación web, es posible que se le pida que proporcione a StoryDots sus datos personales, especialmente su dirección de correo electrónico al crear un saludo virtual.\n
        Todos los usuarios aceptan que el saludo subido se asocie a una URL única. Cualquier persona con acceso a esa URL (directamente o a través del QR asignado) tendrá acceso al saludo. El Usuario entiende y acepta el nivel de privacidad que ofrecen estas URLs.\n
        StoryDots puede recoger cierta información relativa a su navegación en el Sitio Web. Dichos datos se recogen de acuerdo con los términos y condiciones del artículo 2 "Cookies" de esta política de privacidad.\n
        El tratamiento de cierta información personal relacionada con sus interacciones con nuestros servicios se considera elaboración de perfiles según la normativa aplicable. Este tratamiento se lleva a cabo en nuestro interés por ofrecerle una experiencia de alta calidad y personalizada para crear y/o ver un mensaje personalizado.\n
        Tienes derecho a acceder, modificar y eliminar tus datos personales, así como la posibilidad de oponerte al tratamiento de los mismos, en cualquier momento.\n
        Cualquier solicitud relativa al derecho de acceso, modificación o eliminación de los datos personales que le conciernen puede enviarse también a la siguiente dirección dedicada: support@storydots.app`,
    },
    PersonalDataSection: {
      title: "Uso de datos personales por StoryDots",
      DataCollection: {
        title: "1.1 Finalidad de la recopilación de datos",
        paragraph1:
          "A través de la Webapp, StoryDots recoge y trata los datos de carácter personal necesarios para las siguientes finalidades: poner a disposición de la persona a la que envía o recibe un regalo, un saludo virtual.",
      },
      DataRetention: {
        title: "1.2 Retención de datos",
        paragraph1:
          "Conservamos sus datos personales durante el tiempo mínimo previsto por la ley cuando así se especifica. Cuando nuestro interés legítimo requiera una duración diferente de la conservación de sus datos, tendremos en cuenta la sensibilidad de los datos, el riesgo potencial y los requisitos de seguridad para establecer la duración de la conservación.",
        paragraph2:
          "Cuando la conservación de sus datos ya no sea necesaria con respecto a la finalidad perseguida, StoryDots eliminará sus datos.",
      },
      DataRecipient: {
        title: "1.3 Destinatario de los datos",
        paragraph1: `Todos los datos personales recogidos en nuestra WebApp están destinados a StoryDots. No obstante, es posible que StoryDots tenga que ceder de vez en cuando sus datos a otras entidades con el fin exclusivo de la relación con el cliente.\n
            Al procesar los pedidos, los datos deben ser transferidos a otras empresas en nombre de StoryDots. En tal medida, para reducir el riesgo de pérdida o daño de los datos, StoryDots se compromete a minimizar la información revelada a terceros a lo absolutamente necesario para lograr el objetivo perseguido.\n
            Cualquiera que sea la circunstancia, StoryDots sólo transferirá sus datos a otras empresas en los siguientes casos específicos:\n
            StoryDots debe transferir sus datos personales a un tercero en relación con las solicitudes que usted haya realizado (empresa que contrata el servicio, proveedores logísticos, proveedores de servicios de marketing y publicidad para la optimización de su experiencia de compra);\n
            StoryDots ha recibido su consentimiento expreso para transferir sus datos a un tercero;\n
            StoryDots exige a sus proveedores de servicios (Amazon Web Services) que garanticen la seguridad de sus datos personales y cumplan con la normativa. Los proveedores de servicios de StoryDots no tienen derecho a utilizar sus datos personales para perseguir otros fines que los especificados por StoryDots de acuerdo con sus instrucciones.\n
            En el marco de una empresa que contrata el servicio, los datos personales recogidos serán transferidos a esa con el fin de cumplir con sus objetivos. En esta medida, el tratamiento de los datos personales estará sujeto a la política de confidencialidad establecida por dicha empresa.
            `,
      },
      DataSecurity: {
        title: "1.4 Seguridad y confidencialidad de los datos",
        paragraph1:
          "StoryDots ha hecho todo lo necesario para tomar todas y cada una de las precauciones útiles para garantizar la seguridad y la confidencialidad de los datos personales tratados, para evitar que dichos datos sean deformados, dañados o destruidos y para impedir que terceros no autorizados tengan acceso a sus datos. Todos los elementos de seguridad utilizados son de última generación, especialmente en lo que respecta a los sistemas de información.",
        paragraph2:
          "En la medida en que StoryDots no puede controlar todos y cada uno de los riesgos asociados a las operaciones en Internet, se llama la atención de los usuarios de Internet sobre los riesgos potenciales inherentes al uso de Internet y a operar en Internet.",
      },
    },
    CookieSection: {
      WhatIsACookie: {
        title: "2.1 ¿Qué es una cookie?",
        paragraph1: `Una cookie es un archivo de texto que puede ser almacenado en un espacio dedicado en el disco duro de su terminal (ordenador, tableta, smartphone) por el navegador web que utiliza para consultar nuestro Sitio Web, según las opciones guardadas en su navegador en relación con las cookies. El archivo cookie permite al servidor web identificar el terminal en el que se guarda durante el periodo de validez o registro de la cookie.\n
            Mientras navega por el sitio web, los datos relativos a la navegación de su terminal en nuestro sitio web pueden guardarse en archivos de cookies almacenados en su terminal. Estas cookies permiten a StoryDots ofrecerle una experiencia de compra personalizada vinculada a su dispositivo de acceso.\n
            Puede cambiar la configuración de las cookies en cualquier momento. A continuación encontrará más información sobre las cookies y cómo puede gestionar la configuración de las mismas.`,
      },
      CookiesAndTech: {
        title: "2.2 Cookies y tecnologías similares en la aplicación web",
        paragraph1: `Dependiendo de las opciones guardadas en su navegador, la navegación en nuestra WebApp puede dar lugar a diversas cookies, web beacons de su dirección IP, IDFA, geolocalización a través de la dirección IP, información de inicio de sesión y de navegación (URL de referencia, datos publicitarios, estadísticas de visitas, credenciales de inicio de sesión) almacenadas en su terminal, de forma que StoryDots pueda reconocer el navegador de su terminal durante todo el periodo de retención de esta información. StoryDots recoge información de las páginas por las que navegas, de los enlaces en los que haces clic y de otras acciones como parte de tu navegación relacionadas con su oferta comercial.\n
            Las cookies y tecnologías similares que almacenamos en su navegador pueden:\n
            Recopilar estadísticas y volúmenes de tráfico y uso de los distintos componentes de la Webapp (elementos y contenidos visitados, rutas de navegación), para permitir a StoryDots ofrecer servicios más pertinentes y ergonómicos;\n
            Adaptar la presentación de nuestra Webapp a las preferencias de visualización de su terminal (idioma utilizado, resolución de la pantalla, sistema operativo utilizado, etc.) durante su navegación por la Webapp, según el hardware y el software de lectura utilizados por su terminal;\n
            en la medida en que nos haya facilitado sus datos personales, en particular su dirección de correo electrónico durante su grabación de vídeo en la Webapp, al realizar su pedido o al acceder a nuestros servicios, asociar estos datos a sus datos de navegación para enviarle ofertas por correo electrónico o mostrarle en su terminal anuncios personalizados que puedan ser de su especial interés, en los espacios publicitarios que ponemos a su disposición;\n
            en la medida en que nos haya facilitado sus datos personales, en particular su dirección de correo electrónico durante la creación del saludo en la WebApp, al realizar su pedido o al acceder a nuestros servicios, asociar estos datos a sus datos de navegación para enviarle ofertas por correo electrónico o mostrarle en su terminal anuncios personalizados que puedan ser de su especial interés, en los espacios publicitarios que ponemos a su disposición;\n
            almacenar información relacionada con un formulario que hayas rellenado en la Webapp o con productos, servicios o información que hayas elegido en la Webapp (servicio suscrito, contenido de un carrito de la compra, etc.);\n
            Es posible que pueda compartir información con las redes sociales como parte del servicio. Estas redes sociales pueden permitirnos el acceso automático a la información que usted ha publicado, por ejemplo, un producto o contenido que le hubiera gustado o en el que hubiera hecho clic. El acceso a esta información puede permitirnos personalizar nuestras ofertas a su atención en función de sus preferencias.`,
      },
      StoredCookies: {
        title:
          "2.3 Cookies almacenadas debido a aplicaciones de terceros en el sitio web",
        paragraph1: `StoryDots puede incluir aplicaciones informáticas de otras fuentes en la WebApp que están diseñadas para compartir el contenido de la WebApp con otras personas o para informarles del contenido que ha consultado. Esto se aplica al botón "Compartir" de Facebook y WhatsApp.\n
            Los medios sociales que proporcionan el botón de la aplicación pueden entonces identificarte.\n
            Facebook: en el caso de que quieras compartir un mensaje recibido\n
            Google (Google Analytics y Adwords): Análisis. Esta cookie proporciona datos anónimos que nos permiten entender el comportamiento y las preferencias de marketing de un usuario en el Sitio. También ayuda a orientar nuestros anuncios en Google de forma más relevante.`,
      },
      YourChoices: {
        title: "2.4 Sus opciones con respecto a las cookies",
        paragraph1:
          "Hay varias formas de gestionar las cookies. Cualquier cambio en la configuración puede modificar sus condiciones de navegación y acceso al Sitio Web. Puede cambiar estos ajustes en cualquier momento mediante los procedimientos que se describen a continuación.",
        paragraph2:
          "Puede configurar su navegador para que, según su elección, las cookies se guarden en su terminal o, por el contrario, se rechacen. También puede configurar su navegador para que la aceptación de las cookies se le ofrezca puntualmente y antes de que se registre una cookie en su terminal.",
        ApprovalOfCookies: {
          title: "a) Aprobación de cookies",
          paragraph:
            "Todo registro de cookies en su terminal está sujeto a la obtención de su consentimiento que puede expresar y modificar en cualquier momento y de forma gratuita cambiando la configuración de su navegador. Si ha aceptado el registro de cookies en su terminal, las cookies incrustadas en las páginas y contenidos que haya visualizado se almacenarán en un área reservada de su terminal y serán legibles y reconocibles únicamente por el emisor.",
        },
        RefusalOfCookies: {
          title: "b) Rechazo de cookies",
          paragraph:
            "Si rechaza el registro de cookies o si elimina las que están registradas, no podrá beneficiarse de muchas funcionalidades que son necesarias para facilitar la navegación en la WebApp. Este sería el caso si quieres acceder a los espacios o servicios en los que necesitas identificarte. Este seguiría siendo el caso si StoryDots o sus proveedores de servicios no pueden reconocer, por motivos de compatibilidad técnica, el tipo de navegador que utiliza su dispositivo, su configuración de idioma y de pantalla, o el país desde el que su dispositivo parece estar conectado a Internet. En este caso, no aceptamos ninguna responsabilidad por las consecuencias del funcionamiento degradado de nuestros servicios debido a la configuración que usted haya elegido.",
        },
        AccordingToBrowser: {
          title:
            "c) ¿Cómo ejercer sus opciones según el navegador que utilice?",
          paragraph:
            "Respecto a la gestión de las cookies, cada navegador ofrece diferentes modos de configuración. Están descritos en el menú de ayuda de cada navegador.",
        },
      },
    },
    UseOfDataSection: {
      title: "Uso de datos",
      paragraph1:
        "StoryDots no transferirá ni venderá sus datos personales a terceros o socios no afiliados a menos que StoryDots le haya informado de antemano en mensajes, acuerdos o información disponible en la Webapp o usted haya dado expresamente su consentimiento o esté obligado por ley a hacerlo.",
      paragraph2:
        "Si StoryDots es requerido por ley, por una autoridad administrativa o judicial para proporcionar sus datos personales, StoryDots le informará de ello en la medida de lo posible (a menos que StoryDots crea razonablemente que no está autorizado para hacerlo, particularmente con consideración a las obligaciones legales o judiciales aplicables. a ellos).",
      paragraph3:
        "Dada la tecnología actual, en particular la que se utiliza generalmente para redes de telecomunicaciones, Meemento no puede garantizar la confidencialidad, integridad o autenticación de los correos electrónicos que envía o recibe de StoryDots.",
    },
  },
  whatsappContact: {
    tooltip: "¿Dudas? Escribinos",
  },
  pricingPlans: {
    mainSection: {
      joinTitle: "Sumate a la nueva forma de regalar online",
      pricingCard: {
        scaleMid: {
          upperText: {
            start: "Incluye ",
            bold: "10 saludos",
            boldAR: "10 saludos",
          },
          lowerText: "Integraciones con nuestros partners",
        },
        startupMid: {
          upperText: "Seleccionar cantidad de saludos",
          lowerText: "Todo lo del plan inicial, más:",
        },

        unlimitedMid: {
          upperText: "Solo disponible en",
          lowerText: "Todo lo del plan avanzado, más:",
        },
        planBottomItems: {
          scale: {
            first: "Posibilidad de cobrar el saludo",
            second: "Pack de redes sociales",
            third: "Acceso a Dashboard y estadísticas",
          },
          startup: {
            first: "Customización con tu branding",
            second: "Customización paleta de colores",
            third: "",
          },
          unlimited: {
            first: "Stock ilimitado",
            second: "Redirección de usuarios al sitio",
            third: "Comisión preferencial con  ",
            tooltip: "2% los primeros 3 meses con StoryDots",
          },
        },
        subscribeButton: "Suscribirme",
      },
      bottomText: {
        start: "Los créditos se ",
        bold: "renuevan automáticamente ",
        end: "mes a mes y ",
        endBold: "no son acumulables.",
      },
    },
    supportCompanies: {
      supportTitle: "Más de 500 tiendas confían en nosotros",
    },
  },
  Stores: {
    Title: {
      title: "Encontrá el regalo perfecto",
    },
    Filters: {
      searchBar: "Buscar marca",
      brand: "Marca",
      category: "Rubro",
      everything: "Todos",
      noResults: "No pudimos encontrar resultados para tu búsqueda",
      location: {
        physical: "Físico",
        online: "Online",
        everything: "Todos",
      },
    },
  },
  TagDispenser: {
    Main: {
      loading: "Cargando..."
    },
    Home: {
      title: "¡Súmale a tu regalo un saludo sorpresa!",
      form: {
        title: "Ingresa tu <bold>email</bold> para comenzar",
        label: "Tu email",
        placeholder: "ejemplo@ejemplo.com",
        error: "Error al crear el saludo",
      },
      action: {
        primary: "Continuar",
      },
    },
    GetTag: {
      title: "Puedes añadir un vídeo, foto, voz o texto",
      subtitle: "Crearás una tarjeta para imprimir y añadir al paquete",
      action: {
        loading: "Creando saludo",
        primary: "¡Comencemos!",
        secondary: "Crear más tarde",
        tertiary: "Crear saludo",
      },
    },
    FinishFlow: {
      title: "Revisa tu correo",
      subtitle:
        "Hemos enviado un correo a <bold>{{email}}</bold> para que crees el saludo",
    },
    CodeAssociation: {
      InsertCode: {
        title: "Ingresa el código",
        subtitle: "de 8 caracteres que figura en la bolsa",
        actionPrimary: "Continuar",
        actionSecondary: "No tengo código",
      },
      SaveEmail: {
        title: "Ingresa tu email",
        subtitle: "Para poder ver el saludo",
        email: {
          placeholder: "ejemplo@email.com",
          helperText: "Ingresa un email válido",
        },
        acceptanceInfo:
          "Acepto compartir mi dirección de email para utilizarla de acuerdo a los <a target='_blank' href='https://storydots.app/privacy-terms'>términos y condiciones</a>.",
        action: "Siguiente",
      },
    },
    RoleSelection: {
      senderButton: "Estoy regalando",
      receiverButton: "Recibí un regalo"
    },
  },
};

export default LOCALE_ES;
