import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Description,
  LogosContainer,
  PlatformLogo,
} from "./styledComponents";
import platformsLogos from "img/mainPage/compatiblePlatforms";

const PlatformsCompatibility: FC = () => {
  const { t } = useTranslation("Main");

  return (
    <Container>
      <Description>
        {t("EndToEnd.platformsDescription")}
      </Description>
      <LogosContainer>
        {platformsLogos.map((logo, id: number) => {
          return (
            <PlatformLogo
              src={logo.src}
              alt="Compatible platform logo"
              width={logo.width}
              height={logo.height}
              key={`${logo}${id}`}
            />
          )
        })}
      </LogosContainer>
    </Container>
  );
};

export default PlatformsCompatibility;
