import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  SupportTitle,
  CompaniesContainer,
} from "./styledComponents";
import { supportCompaniesArray } from "img/pricingPlans/supportCompanies"
import { SupportCompanyLogo } from "components";

const SupportCompanies: FC = () => {
  const {t} = useTranslation("pricingPlans");
  const tr = "supportCompanies.";

  return (
    <Container>
      <SupportTitle variant="h2">
        {t(`${tr}supportTitle`)}
      </SupportTitle>
      <CompaniesContainer>
        {supportCompaniesArray.map((obj, id) => {
          return (
            <SupportCompanyLogo
              obj={obj}
              id={id}
              key={`supportCompanyLogo${id}`}
            />
          )
        })}
      </CompaniesContainer>
    </Container>
  );
};

export default SupportCompanies;