const LOCALE_EN = {
  NavBar: {
    tour: "Live Demo",
    integrations: "Integrations",
    Gift: "Send a gift",
  },
  Main: {
    Hero: {
      titleSpan: "Enable",
      title: " online gifting",
    },
    EndToEnd: {
      title: "End-to-end",
      titleSecondLine: "omnichannel integration",
      switch: {
        left: "Ecommerce",
        right: "Retail",
      },
      cards: {
        left: {
          card1: {
            title: "Integration",
            text: "We integrate with your cart, allowing customers to indicate when a purchase is for a gift.",
          },
          card2: {
            title: "Greeting creation",
            text: "At the end of the purchase your customer will receive an email to create a virtual greeting.",
          },
          card3: {
            title: "QR Printing",
            text: "From your control panel you will have to print a QR tag containing the greeting, and attach it to the package.",
          },
          card4: {
            title: "Surprise!",
            text: "Whoever receives the gift will be able to see the greeting and reply.",
          },
        },
        right: {
          card1: {
            title: "Implementation",
            text: "We will send you QR cards to print and distribute in the stores.",
          },
          card2: {
            title: "Easy to use",
            text: "When a gift is detected, one tag is cut and placed on the packaging and the other is for the buyer.",
          },
          card3: {
            title: "Greeting creation",
            text: "By scanning the QR the purchaser will be able to create their virtual greeting.",
          },
          card4: {
            title: "Surprise!",
            text: "Whoever receives the gift will be able to see the greeting and reply.",
          },
        },
      },
      platformsDescription:
        "Compatible with market leading ecommerce platforms",
      primaryButton: "Book a demo",
    },
    SuccessCases: {
      title: "Success cases",
      subtitle: "Best cases of study",
      carousel: {
        card1: {
          text1:
            "At Blue Star Group we’re continuosly trying out new experiences to offer our customers customization, surprises and different ways to connect.",
          text2:
            "Storydots was a great fit for this marketing strategy, and they also offered us new analytics we didn’t have.",
        },
        card2: {
          text1:
            "Storydots adds value to every purchase thanks to a solid solution.",
          text2:
            "That is why it is one of the top rated Apps in our App Store.",
        },
        card3: {
          text1: "Storydots helped us to turn a gift into an experience.",
          text2:
            "We personalized hundred of gifts and ensured replies in an easy dynamic and disruptive way.",
        },
      },
      buttonDescription: "Let's talk about your needs",
      primaryButton: "Book a demo",
    },
    MeetingsCalendar: {
      title: "Schedule a demo and",
      titleSecondLine: "evolve your ecommerce",
    },
    InvalidPlanModal: {
      title: "It is not possible to install Storydots in your store",
      subTitle: `We're sorry, Storydots is only available from TiendaNube's "Impulso" plan`,
      button: "Increase your plan",
    },
  },
  Integrations: {
    header: {
      title: {
        firstLine: "Select your",
        secondLine: "ecommerce platform",
      },
      subtitle1:
        "Hundreds of brands are boosting their sales and UX by using our solution.",
      subtitle2: "Find here the information to integrate into your ecommerce.",
      primaryButton: "Book a demo",
      secondaryButton: "Contact us",
    },
    Showcase: {
      install: "Install",
      seeDocumentation: "See documentation",
      bookADemo: "Book a demo",
    },
  },
  Footer: {
    terms: "Terms & Conditions",
    logoAria: "Go to main page",
    instagramAria: "Go to Storydots's Instagram",
    linkedinAria: "Go to Storydots's LinkedIn",
    facebookAria: "Go to Storydots's Facebook",
    emailAria: "Send an email to Storydots",
  },
  Buttons: {
    continue: "Continue",
    retry: "Retry",
    cancel: "Cancel",
    confirm: "Confirm",
    learnMore: "Learn More",
    schedule: "Schedule",
    bookADemo: "Book a demo",
  },
  Carousel: {
    backwardsButton: "Carousel backwards",
    forwardButton: "Carousel forward",
  },
  PrivacyTerms: {
    Title: {
      title: "Privacy & Cookies Policy",
    },
    GeneralSection: {
      title: "General information",
      paragraph1: `The following privacy policy has been established by StoryDots, a company that markets a personal messaging solution for gifts under the StoryDots brand.\n
        The privacy policy applies to all information provided by you to StoryDots or collected by StoryDots while using the service and describes our practices for collecting, using, retaining, disclosing and protecting your personal data.\n
        StoryDots respects your concern for the confidentiality of the information you provide to us. By giving us your information and personal data, you consent to our use and study of said data, to personalize and optimize your navigation on the website and to offer you services and experiences that meet your expectations.\n
        We invite you to carefully read this privacy policy together with the different notifications that may be communicated to you at the time we collect the data to be fully informed about the collection and use of your personal data.\n
        We can modify this privacy policy. The updated terms will take effect 30 days after they are posted. If necessary, any significant change may be notified to you by email.`,
      paragraph2: `When using the web application, you may be asked to provide StoryDots with your personal data, especially your email address when creating a virtual greeting.\n
        All users agree that the uploaded greeting is associated with a unique URL. Anyone with access to that URL (directly or through the assigned QR) will have access to the greeting. The User understands and accepts the level of privacy offered by these URLs.\n
        StoryDots may collect certain information related to your navigation on the Website. Said data is collected in accordance with the terms and conditions of article 2 "Cookies" of this privacy policy.\n
        The processing of certain personal information related to your interactions with our services is considered profiling according to the applicable regulations. This treatment is carried out in our interest to offer you a high quality and personalized experience to create and / or view a personalized message.\n
        You have the right to access, modify and delete your personal data, as well as the possibility of opposing their treatment, at any time.\n
        Any request regarding the right of access, modification or deletion of personal data that concerns you can also be sent to the following dedicated address: support@storydots.app`,
    },
    PersonalDataSection: {
      title: "Use of personal data by StoryDots",
      DataCollection: {
        title: "1.1 Purpose of data collection",
        paragraph1:
          "Through the Webapp, StoryDots collects and processes the personal data necessary for the following purposes: to make a virtual greeting available to the person to whom you send or receive a gift.",
      },
      DataRetention: {
        title: "1.2 Data retention",
        paragraph1:
          "We keep your personal data for the minimum time provided by law when it is specified. When our legitimate interest requires a different duration of retention of your data, we will take into account the sensitivity of the data, the potential risk and the security requirements to establish the duration of the retention.",
        paragraph2:
          "When the retention of your data is no longer necessary with respect to the intended purpose, StoryDots will delete your data.",
      },
      DataRecipient: {
        title: "1.3 Recipient of data",
        paragraph1: `All personal data collected on our WebApp is intended for StoryDots. However, StoryDots may need to release your data to other entities from time to time for the sole purpose of the customer relationship.\n
            When processing orders, data must be transferred to other companies on behalf of StoryDots. To this extent, to reduce the risk of data loss or damage, StoryDots undertakes to minimize the information disclosed to third parties to what is absolutely necessary to achieve the objective pursued.\n
            Whatever the circumstance, StoryDots will only transfer your data to other companies in the following specific cases:\n
            StoryDots must transfer your personal data to a third party in relation to the requests you have made (company that contracts the service, logistics providers, marketing and advertising service providers to optimize your shopping experience);\n
            StoryDots has received your express consent to transfer your data to a third party;\n
            StoryDots requires its service providers (Amazon Web Services) to ensure the security of your personal data and comply with regulations. StoryDots service providers do not have the right to use your personal data for purposes other than those specified by StoryDots in accordance with their instructions.\n
            Within the framework of a company that contracts the service, the personal data collected will be transferred to that company in order to fulfill its objectives. To this extent, the processing of personal data will be subject to the confidentiality policy established by said company.
            `,
      },
      DataSecurity: {
        title: "1.4 Data security and confidentiality",
        paragraph1:
          "StoryDots has done everything necessary to take all and any useful precautions to ensure the security and confidentiality of the personal data processed, to prevent such data from being deformed, damaged or destroyed and to prevent any unauthorised third parties from having access to your data. All the security features used are state-of-the-art, particularly with respect to information systems.",
        paragraph2:
          "Insofar as StoryDots cannot control all and any risks associated with Internet operations, the attention of Internet users is drawn to the potential risks inherent to the use of the Internet and to operate on the Internet.",
      },
    },
    CookieSection: {
      WhatIsACookie: {
        title: "2.1 What is a cookie?",
        paragraph1: `A cookie is a text file that can be stored in a dedicated space on the hard drive of your terminal (computer, tablet, smartphone) by the web browser you use to consult our Website, according to the options saved in your browser in relation to with cookies. The cookie file allows the web server to identify the terminal in which it is stored during the period of validity or registration of the cookie.\n
            While browsing the website, data relating to your terminal's navigation on our website may be saved in cookie files stored on your terminal. These cookies allow StoryDots to offer you a personalized shopping experience linked to your access device.\n
            You can change your cookie settings at any time. Below you will find more information about cookies and how you can manage their settings.`,
      },
      CookiesAndTech: {
        title: "2.2 Cookies and similar technologies on the Webapp",
        paragraph1: `Depending on the options saved in your browser, browsing our WebApp may give rise to various cookies, web beacons of your IP address, IDFA, geolocation through the IP address, login and browsing information (referrer URL , advertising data, visit statistics, login credentials) stored on your terminal, so that StoryDots can recognize your terminal's browser throughout the retention period of this information. StoryDots collects information from the pages you browse, the links you click and other actions as part of your browsing related to its commercial offer.\n
            Cookies and similar technologies that we store in your browser can:\n
            Collect statistics and volumes of traffic and use of the different components of the Webapp (elements and content visited, navigation routes), to allow StoryDots to offer more pertinent and ergonomic services;\n
            Adapt the presentation of our Webapp to the display preferences of your terminal (language used, screen resolution, operating system used, etc.) while browsing the Webapp, depending on the hardware and reading software used by your terminal;\n
            to the extent that you have provided us with your personal data, in particular your email address during your video recording on the Webapp, when placing your order or when accessing our services, associate this data with your browsing data to send you offers by email or show you on your terminal personalized advertisements that may be of special interest to you, in the advertising spaces that we make available to you;\n
            to the extent that you have provided us with your personal data, in particular your email address during the creation of the greeting on the WebApp, when placing your order or when accessing our services, associate this data with your browsing data to send you offers by email or show you on your terminal personalized advertisements that may be of special interest to you, in the advertising spaces that we make available to you;\n
            store information related to a form that you have filled out on the Webapp or with products, services or information that you have chosen on the Webapp (subscribed service, content of a shopping cart, etc.);\n
            You may be able to share information with social media as part of the service. These social networks may allow us to automatically access information that you have posted, for example, a product or content that you would have liked or clicked on. Access to this information may allow us to tailor our offers to your attention based on your preferences.`,
      },
      StoredCookies: {
        title:
          "2.3 Cookies stored due to third party applications on the Website",
        paragraph1: `StoryDots may include computer applications from other sources on the WebApp that are designed to share the content of the WebApp with other people or to inform them of the content you have viewed. This applies to the "Share" button on Facebook and WhatsApp.\n
            The social media that provide the app button can then identify you.\n
            Facebook: in case you want to share a received message\n
            Google (Google Analytics and Adwords): Analysis. This cookie provides anonymous data that allows us to understand the behavior and marketing preferences of a user on the Site. It also helps to target our ads on Google more relevant.`,
      },
      YourChoices: {
        title: "2.4 Your choices regarding cookies",
        paragraph1:
          "There are several ways to manage cookies. Any change in the configuration may modify your browsing conditions and access to the Website. You can change these settings at any time using the procedures described below.",
        paragraph2:
          "You can configure your browser so that, according to your choice, cookies are saved on your terminal or, conversely, are rejected. You can also configure your browser so that acceptance of cookies is offered promptly and before a cookie is registered on your terminal.",
        ApprovalOfCookies: {
          title: "a) Approval of cookies",
          paragraph:
            "Any registration of cookies on your terminal is subject to obtaining your consent that you can express and modify at any time and free of charge by changing your browser settings. If you have accepted the registration of cookies on your terminal, the cookies embedded in the pages and content that you have viewed will be stored in a reserved area of your terminal and will be legible and recognizable only by the issuer.",
        },
        RefusalOfCookies: {
          title: "b) Refusal of cookies",
          paragraph:
            "If you reject the registration of cookies or if you delete those that are registered, you will not be able to benefit from many functionalities that are necessary to facilitate navigation on the WebApp. This would be the case if you want to access the spaces or services in which you need to identify yourself. This would continue to be the case if StoryDots or its service providers cannot recognize, for technical compatibility reasons, the type of browser your device uses, your language and display settings, or the country from which your device appears to be connected. to Internet. In this case, we do not accept any responsibility for the consequences of degraded performance of our services due to the settings you have chosen.",
        },
        AccordingToBrowser: {
          title:
            "c) How to exercise your choices according to the browser you use?",
          paragraph:
            "Regarding the management of cookies, each browser offers different configuration modes. They are described in the help menu of each browser.",
        },
      },
    },
    UseOfDataSection: {
      title: "Use of data",
      paragraph1:
        "StoryDots will not transfer or sell your personal data to any unaffiliated third parties or partners unless StoryDots has informed you beforehand in messages, agreements or information available on the Webapp or you have expressly given your consent or are required by law to do so.",
      paragraph2:
        "If StoryDots is required by law, by an administrative or judicial authority to provide your personal data, StoryDots will inform you thereof insofar as possible (unless StoryDots reasonably believe it is not authorised to do so, particularly with consideration to any legal or judicial obligations applicable to them).",
      paragraph3:
        "Given current technology, in particular that are generally used for telecommunications networks, Meemento cannot guarantee the confidentiality, integrity or authentication of the emails you send or receive from StoryDots.",
    },
  },
  whatsappContact: {
    tooltip: "Doubts? Contact us",
  },
  pricingPlans: {
    mainSection: {
      joinTitle: "Join the new way of gifting online",
      pricingCard: {
        scaleMid: {
          upperText: {
            start: "Includes ",
            bold: "20 greetings",
            boldAR: "25 greetings",
          },
          lowerText: "Integrations with our partners",
        },
        startupMid: {
          upperText: "Select greetings amount",
          lowerText: "Initial plan features plus:",
        },
        unlimitedMid: {
          upperText: "Only available at",
          lowerText: "Advanced plan features plus:",
        },
        planBottomItems: {
          scale: {
            first: "Redirecting users to your site",
            second: "Social networks pack",
            third: "Access to Dashboard and statistics",
          },
          startup: {
            first: "Customization with your branding",
            second: "Charging for the greeting",
            third: "Color palette customization",
          },
          unlimited: {
            first: "Unlimited stock",
            second: "Personal support",
            third: "Preferred commission with  ",
            tooltip: "2% the first 3 months with StoryDots",
          },
        },
        subscribeButton: "Subscribe",
      },
      bottomText: {
        start: "The credits are ",
        bold: "automatically renewed ",
        end: "on a monthly basis and ",
        endBold: "they are not cumulative.",
      },
    },
    supportCompanies: {
      supportTitle: "More than 500 stores trust us",
    },
  },
  Stores: {
    Title: {
      title: "Find the perfect gift",
    },
    Filters: {
      searchBar: "Search brand",
      brand: "Brand",
      category: "Category",
      everything: "All",
      noResults: "We couldn't find any results for your search",
      location: {
        physical: "Physical",
        online: "Online",
        everything: "All",
      },
    },
  },
  TagDispenser: {
    Main: {
      loading: "Loading..."
    },
    Home: {
      title: "Add a surprise greeting to your gift!",
      form: {
        title: "Enter your <bold>email</bold> to get started",
        label: "Your email",
        placeholder: "example@example.com",
        error: "Error creating greeting",
      },
      action: {
        primary: "Continue",
      },
    },
    GetTag: {
      title: "You can add a video, photo, voice, or text",
      subtitle: "You'll create a card to print and add to the package",
      action: {
        loading: "Creating greeting",
        primary: "Let's get started!",
        secondary: "Create later",
        tertiary: "Create greeting",
      },
    },
    FinishFlow: {
      title: "Check your email",
      subtitle:
        "We sent an email to <bold>{{email}}</bold> for you to create the greeting",
    },
    CodeAssociation: {
      InsertCode: {
        title: "Enter the code",
        subtitle: "of 8 characters found on the bag",
        actionPrimary: "Continue",
        actionSecondary: "I don't have a code",
      },
      SaveEmail: {
        title: "Enter your email",
        subtitle: "To be able to see the greeting",
        email: {
          placeholder: "example@email.com",
          helperText: "Enter a valid email",
        },
        acceptanceInfo:
          "I agree to share my email. My email address will be used in accordance with the <a target='_blank' href='https://storydots.app/privacy-terms'>terms and conditions</a>.",
        action: "Next",
      },
    },
    RoleSelection: {
      senderButton: "I am giving a gift",
      receiverButton: "I received a gift"
    },
  },
};

export default LOCALE_EN;
