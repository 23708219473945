import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  secondaryButton: {
    background: "transparent",
    boxShadow: "unset",
  },
}));

export default useStyles;
