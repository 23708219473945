import { createContext, useReducer } from 'react';
import STORES from 'helpers/createStoresObjects';
import { STOREINTERFACE } from 'types/storesInterface';
import { LOCATION } from 'types/storesLocationEnum';

export const StoresContext = createContext<any>(null);

interface Action {
    type: string,
    payload: {
        category?: string;
        brand?: string;
        location?: string;
        categoryValue?: string;
        brandValue?: string;
        locationValue?: LOCATION;
    }
}

const initialState = STORES

function storesReducer(StoresContext: any, action: Action) {
    switch (action.type) {
        case "filtersChange": {
            const filters = action.payload
            if (filters.brand) return STORES.filter((e) => e.alt === filters.brand)
            const filtersEntries = Object.entries(filters)
            let newStore = STORES
            for (const filter of filtersEntries) {
                const [key, value] = filter
                if (value) {
                newStore = newStore.filter((e: STOREINTERFACE) => e[key as keyof STOREINTERFACE].toString().includes(value))
                }
            }
            return newStore
        }
        // case "search": {
        //     const searchTerm = action.payload.trim().toLowerCase().replaceAll(" ", "_")
        //     const newStores = STORES.filter((e: STOREINTERFACE) => {
        //         const splitWords = e.alt.split("_")
        //         for (const word of splitWords) {
        //             if (word.startsWith(searchTerm)) return e
        //         }
        //     })
        //     return newStores
        // }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

export const StoresProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const [state, dispatch] = useReducer(storesReducer, initialState);

    return (
        <StoresContext.Provider value={{ state, dispatch }}>
            {children}
        </StoresContext.Provider>
    );
};