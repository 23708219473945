import { createContext, FC, useState } from "react";

export const LayoutContext = createContext({
  isNavbarLight: false,
  setIsNavbarLight: (_:boolean) => {},
  isNavbarVisible: true,
  setIsNavbarVisible: (_:boolean) => {},
});

export const LayoutContextProvider: FC = ({ children }) => {
  const [isNavbarLight, setIsNavbarLight] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  return (
    <LayoutContext.Provider 
      value={{
        isNavbarLight,
        setIsNavbarLight,
        isNavbarVisible,
        setIsNavbarVisible,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
