import heroVideoES from "./hero-video-es.webm";
import heroVideoEN from "./hero-video-en.webm";
import heroVideoPT from "./hero-video-pt.webm";
import heroVideoMobileES from "./hero-video-mobile-es.webm";
import heroVideoMobileEN from "./hero-video-mobile-en.webm";
import heroVideoMobilePT from "./hero-video-mobile-pt.webm";
import heroImgDesktopSafariES from './hero-desktop-safari-ES.png';
import heroImgDesktopSafariEN from './hero-desktop-safari-EN.png';
import heroImgDesktopSafariPT from './hero-desktop-safari-PT.png';
import heroImgMobileSafariES from './hero-mobile-safari-ES.png';
import heroImgMobileSafariEN from './hero-mobile-safari-EN.png';
import heroImgMobileSafariPT from './hero-mobile-safari-PT.png';
import heroVideoSafariES from "./hero-video-safari-es.mp4"
import heroVideoSafariEN from "./hero-video-safari-en.mp4"
import heroVideoSafariPT from "./hero-video-safari-pt.mp4"
import heroVideoMobileSafariES from "./hero-video-mobile-safari-es.mp4"
import heroVideoMobileSafariEN from "./hero-video-mobile-safari-en.mp4"
import heroVideoMobileSafariPT from "./hero-video-mobile-safari-pt.mp4"



const heroFiles = {
  heroVideoES,
  heroVideoEN,
  heroVideoPT,
  heroVideoSafariES,
  heroVideoSafariEN,
  heroVideoSafariPT,
  heroVideoMobileES,
  heroVideoMobileEN,
  heroVideoMobilePT,
  heroVideoMobileSafariES,
  heroVideoMobileSafariEN,
  heroVideoMobileSafariPT,
  heroImgDesktopSafariES,
  heroImgDesktopSafariEN,
  heroImgDesktopSafariPT,
  heroImgMobileSafariES,
  heroImgMobileSafariEN,
  heroImgMobileSafariPT
}

export default heroFiles;
