import { Box, Button, Card, styled } from "@mui/material";

const cardHeight = "240px";
const imageHeight = "182px";

export const Container = styled(Card)(() => ({
  width: "400px",
  height: cardHeight,
  margin: "0px 18px 18px 0px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "24px",
  "@media (max-width: 1500px)": {
    margin: "9px",
  },
  "@media (max-width: 1265px)": {
    margin: "15px",
  },
  "@media (max-width: 430px)": {
    height: "unset",
  },
}));

export const ImageContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: imageHeight,
  borderTopRightRadius: "24px",
  borderTopLeftRadius: "24px",
  border: "3px solid white",
  borderBottom: "0px",
  overflow: "hidden",
  "@media (max-width: 430px)": {
    height: "unset",
  },
}));

export const PlatformImage = styled("img")(() => ({
  width: "100%",
  objectFit: "cover",
}));

export const ActionsContainer = styled(Box)(() => ({
  width: "100%",
  height: `calc(${cardHeight} - ${imageHeight})`,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  "@media (max-width: 430px)": {
    height: "unset",
    flexDirection: "column",
  },
}));

export const SecondaryContainer = styled(Box)(() => ({
  width: "190px",
  display: "flex",
  justifyContent: "flex-start",
  "@media (max-width: 430px)": {
    display: "none",
  },
}));

export const PrimaryContainer = styled(Box)(() => ({
  width: "210px",
  display: "flex",
  justifyContent: "flex-end",
  "@media (max-width: 430px)": {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyledButton = styled(Button)(() => ({
  height: "30px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.4px",
  textTransform: "none",
  "@media (max-width: 430px)": {
    height: "40px",
  },
}));

export const SecondaryAction = styled(
  StyledButton,
  { shouldForwardProp: (prop) => !["setColor"].includes(prop as string) }
)<{ setColor?:string; }>(
  ({ setColor }) => ({
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: `${setColor ? setColor : "rgba(0, 0, 0, 0.87)" }`,
    "@media (max-width: 430px)": {
      paddingLeft: "10px",
      justifyContent: "center",
    },
  })
);

export const PrimaryAction = styled(
  StyledButton,
  { shouldForwardProp: (prop) => prop !== "setColor" }
)<{ setColor:string | undefined }>(({ theme, setColor }) => ({
  paddingRight: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  color: `${setColor ? setColor : theme.palette.primary.main }`,
  fontSize: "16px",
  "@media (max-width: 430px)": {
    marginBottom: "10px",
    paddingRight: "0px",
    justifyContent: "center",
  },
}));

