import { styled } from "@mui/system";
import { Container, Box } from "@mui/material";

export const Filterscontainer = styled(Container)(() => ({
  marginBottom: "42px",
  display: "flex",
  alignItems: "baseline",
  justifyContent: "space-between",
  "@media (max-width: 850px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    columns: 1,
    justifyContent: "center",
  },
}))

export const BoxInputs = styled(Box)(() => ({
  display: "flex",
  alignItems: "baseline",
  gap: 15,
  width:"40%",
  "@media (max-width: 850px)": {
    width: "100%",
    marginBottom: "10px",
  },
}))
