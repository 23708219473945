import { LayoutContext } from "context";
import { useContext, useEffect } from "react";

export const useLightNavbar = (light: boolean) => {
  const { setIsNavbarLight } = useContext(LayoutContext);

  useEffect(() => {
    setIsNavbarLight(light);
    window.scrollTo(0, 0);
  }, [setIsNavbarLight, light]);
};
