import React from "react";
import { Navbar, MainDrawer, Footer, WhatsappContact } from "components";

type PageWrapperProps = {
  children: React.ReactNode;
};

export default function PageWrapper({
  children,
}: React.PropsWithChildren<PageWrapperProps>) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <>
      <Navbar onClickMenuButton={() => setIsDrawerOpen(true)} />
      <MainDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
      />
      {children}
      <Footer />
      <WhatsappContact />
    </>
  );
}
