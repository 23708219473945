import { FC } from "react";
import {
  CardsContainer,
  CardStore
} from "./styledComponents";

interface Card {
  name: string;
  alt: string;
  image: string;
  url: string;
};

interface Props {
  card: Card;
};

const StoreCard: FC<Props> = ({ card }) => {
  const { image, url, alt } = card;

  return (
    <CardsContainer>
      <a href={url} target="_blank" rel="noreferrer">
        <CardStore src={image} alt={alt} />
      </a>
    </CardsContainer>
  );
};

export default StoreCard;
