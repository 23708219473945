import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import {
  Filterscontainer,
  BoxInputs
} from "./styledComponents";
import { 
  // StoresSearchBar,
  StoresInputBrand,
  StoresInputCategory, 
  StoresInputLocation} from "components/Molecules";
import { StoresContext } from "context";


export interface FILTERS {
  store_type: string;
  brand: string;
  location_type: string;
}


const StoresSearch: FC = () => {

  const { state, dispatch } = useContext(StoresContext);
  
  // const [ searchText, setSearchText] = useState<string>("")

  const [ filters, setFilters ] = useState<FILTERS>({
    store_type: "",
    brand: "",
    location_type: ""
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = event.target
    if (name !== "brand") {
      setFilters({...filters, [name]: value, brand: ""})
    } else {
      setFilters({...filters, [name]: value})
    }
  }

  useEffect(() => {
    dispatch({
      type: "filtersChange",
      payload: filters
    })
  }, [filters])

  // const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
  //   setCategory("")
  //   setBrand("")
  //   const searchTextChange = event.target.value
  //   setSearchText(searchTextChange)
  //   if (searchTextChange === "") {
  //     dispatch({
  //       type: "clear"
  //     })
  //   } else {
  //     dispatch({
  //           type: "search",
  //           payload: searchTextChange
  //         })
  //   }
  // }


  return (
    <Filterscontainer>
      {/* vv SEARCHBAR vv */}
      {/* <StoresSearchBar searchText={searchText} handleChangeSearch={handleChangeSearch}/> */}
      {/* ^^ SEARCHBAR ^^ */}
      <BoxInputs>
        <StoresInputBrand filters={filters} handleChange={handleChange}/>
        <StoresInputCategory filters={filters} handleChange={handleChange}/>
      </BoxInputs>
        <StoresInputLocation filters={filters} handleChange={handleChange}/>
    </Filterscontainer>
  );
};

export default StoresSearch;