import { styled } from "@mui/system";
import { Box } from "@mui/material";

const bandWidth = 1265;

export const Container = styled(Box)(() => ({
  width: "100%",
  height: "100px",
  marginBottom: "94px",
  display: "flex",
  background: "rgba(234, 234, 234, 1)",
  "@media (max-width: 770px)": {
    marginBottom: "calc(94px + (100vw - 770px) * 0.08)",
  },
}));

export const ConveyorBand = styled(Box)(() => ({
  position: "relative",
  width: `${bandWidth}px`,
  maxWidth: "100%",
  height: "100px",
  margin: "0 auto",
}));

export const LeftFade = styled("div")(() => ({
  zIndex: 10,
  position: "absolute",
  top: "0px",
  left: "-2px",
  width: "25px",
  height: "100px",
  background: "linear-gradient(90deg, rgba(234, 234, 234, 1), rgba(234, 234, 234, 0.1))"
}));

export const RightFade = styled(LeftFade)(() => ({
  left: "unset",
  right: "-2px",
  background: "linear-gradient(-90deg, rgba(234, 234, 234, 1), rgba(234, 234, 234, 0.1))"
}));

export const BrandsBandContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100px",
  overflow: "hidden",
}));

export const BrandsBand = styled(Box)(() => ({
  "@keyframes translateLeft": {
    from: { transform: "translateX(0px)" },
    to: { transform: `translateX(-${bandWidth}px)` },
  },
  position: "absolute",
  top: "0px",
  left: "0px",
  height: "100px",
  display: "flex",
  alignItems: "center",
  animation: "translateLeft 20s infinite linear",
}));

export const Brand = styled("img")(() => ({
  margin: "0px 21px",
}));
