import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "900px",
  maxWidth: "100%",
  paddingTop: "50px",
  display: "flex",
  flexWrap: "wrap",
  "@media (max-width: 1540px)": {
    width: "850px",
  },
  "@media (max-width: 1500px)": {
    width: "100%",
    paddingTop: "40px",
    justifyContent: "center",
  },
  "@media (max-width: 1265px)": {
    width: "100%",
    paddingTop: "20px",
    justifyContent: "center",
  },
}));
