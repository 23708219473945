import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  height: "100%",
  maxHeight: "100%",
  padding: "32px",
  display: "flex",
  background: theme.palette.primary.contrastText,
  borderRadius: "32px",
  gap: "32px",
}));

export const BigImageContainer = styled(Box)(() => ({
  width: "267px",
  minWidth: "267px",
  height: "303px",
  minHeight: "303px",
  borderRadius: "15px",
  overflow: "hidden",
  "@media (max-width: 1050px)": {
    display: "none",
  },
}));

export const BigImage = styled("img")(() => ({
  width: "100%",
  minWidth: "100%",
  height: "100%",
  minHeight: "100%",
  objectFit: "cover",
}));

export const InnerContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
}));

export const LogosContainer = styled(Box)(() => ({
  height: "50px",
  maxHeight: "50px",
  marginBottom: "14px",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: "24px",
  "@media (max-width: 1050px)": {
    justifyContent: "center",
  },
  "@media (max-width: 770px)": {
    height: "64px",
    maxHeight: "64px",
    marginBottom: "32px",
    flexDirection: "column",
    alignItems: "center",
    gap: "0px",
  },
}));

export const LogoImage = styled("img")(() => ({
  maxHeight: "100%",
  "@media (max-width: 770px)": {
    maxHeight: "50px",
  },
}));

export const PersonAndQuoteContainer = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "@media (max-width: 770px)": {
    height: "unset",
    flexDirection: "column-reverse",
  },
}));

export const PersonContainer = styled(Box)(() => ({
  height: "91px",
  marginBottom: "0px",
  display: "flex",
  alignItems: "flex-start",
  gap: "24px",
  "@media (max-width: 1050px)": {
    justifyContent: "center",
  },
  "@media (max-width: 770px)": {
    height: "unset",
    gap: "5px",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const PersonPicture = styled("img")(() => ({
  width: "91px",
  height: "91px",
  borderRadius: "50%",
  objectFit: "contain",
  "@media (max-width: 770px)": {
    width: "75px",
    height: "75px",
  },
}));

export const PersonDescriptionContainer = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-evenly",
  "@media (max-width: 770px)": {
    alignItems: "center",
    flexDirection: "column-reverse",
  },
}));

export const Job = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: "left",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "26.68px",
  "@media (max-width: 770px)": {
    maxWidth: "100%",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "21.34px",
    whiteSpace: "nowrap",
  }
}));

export const Company = styled(Job)(() => ({
  fontWeight: 700,
}));

export const Name = styled(Typography)(() => ({
  color: "rgba(37, 37, 37, 1)",
  textAlign: "left",
  fontSize: "18px",
  fontWeight: 300,
  lineHeight: "24.01px",
  "@media (max-width: 770px)": {
    fontSize: "16px",
    lineHeight: "21.34px",
  },
}));

export const QuoteContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 770px)": {
    height: "240px",
    marginBottom: "20px",
    flex: "unset",
    gap: "0px",
  },
}));

export const InnerQuoteContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  gap: "16px",
  "@media (max-width: 770px)": {
    height: "unset",
    maxHeight: "240px",
    marginBottom: "20px",
    flex: "unset",
    gap: "0px",
  },
}));

export const LeftQuotesContainer = styled(Box)(() => ({
  width: "50px",
  height: "50px",
  margin: "20px 0px 0px 0px",
  alignSelf: "flex-start",
  "@media (max-width: 770px)": {
    position: "absolute",
    top: "-4px",
    left: "-7.5%",
    width: "32px",
    height: "32px",
    margin: "0px",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
}));

export const RightQuotesContainer = styled(LeftQuotesContainer)(() => ({
  margin: "0px 0px 20px 0px",
  transform: "rotate(180deg)",
  alignSelf: "flex-end",
  "@media (max-width: 770px)": {
    top: "unset",
    left: "unset",
    bottom: "-4px",
    right: "-7.5%",
  },
}));

export const TextContainer = styled(Box)(() => ({
  width: "calc(100% - 100px)",
  maxWidth: "calc(100% - 100px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "16px",
  "@media (max-width: 770px)": {
    width: "100%",
    maxWidth: "100%",
    "& p:first-of-type": {
      textIndent: "15px",
    },
  },
}));

export const Text = styled(Typography)(() => ({
  maxWidth: "100%",
  color: "rgba(37, 37, 37, 1)",
  textAlign: "justify",
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "21.34px",
  "@media (max-width: 770px)": {
    lineHeight: "24.96px",
  },
}));
