import { useTranslation } from "react-i18next";
import {
  FACEBOOK_WEBSITE,
  INSTAGRAM_WEBSITE,
  LINKEDIN_WEBSITE,
  MAIL_TO,
} from "config";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MailToIcon,
} from "img/footer/media-icons";

const useGetSocialMediaArray = () => {
  const { t } = useTranslation("Footer");

  return ([
    {
      link: INSTAGRAM_WEBSITE,
      icon: InstagramIcon,
      aria: t("instagramAria"),
    }, {
      link: LINKEDIN_WEBSITE,
      icon: LinkedinIcon,
      aria: t("linkedinAria"),
    }, {
      link: FACEBOOK_WEBSITE,
      icon: FacebookIcon,
      aria: t("facebookAria"),
    }, {
      link: MAIL_TO,
      icon: MailToIcon,
      aria: t("emailAria"),
    },
  ])
};

export default useGetSocialMediaArray;
