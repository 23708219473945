import magento from "./compatible-magento.svg";
import shopify from "./compatible-shopify.svg";
import tiendanube from "./compatible-tiendanube.svg";
import vtex from "./compatible-vtex.svg";
import woocommerce from "./compatible-woocommerce.svg";

const platformsLogos = [
  {
    src: vtex,
    width: 104,
    height: 38,
  }, {
    src: magento,
    width: 135,
    height: 38,
  }, {
    src: shopify,
    width: 136,
    height: 38,
  }, {
    src: tiendanube,
    width: 217,
    height: 38,
  }, {
    src: woocommerce,
    width: 167,
    height: 38,
  },
];

export default platformsLogos;
