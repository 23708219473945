import React, { FC } from "react";
import {
  Container,
  BigImageContainer,
  BigImage,
  InnerContainer,
  LogosContainer,
  LogoImage,
  PersonAndQuoteContainer,
  PersonContainer,
  PersonPicture,
  PersonDescriptionContainer,
  Job,
  Company,
  Name,
  QuoteContainer,
  InnerQuoteContainer,
  LeftQuotesContainer,
  RightQuotesContainer,
  TextContainer,
  Text,
} from "./styledComponents";
import { Quotes } from "img/mainPage/reviewsCarousel";

interface LogoObj {
  src: string;
  width: number;
  height: number;
}
interface Card {
  image: string;
  logos: LogoObj[];
  picture: string;
  job: string;
  company: string;
  name: string;
  text: string[];
};

interface Props {
  card: Card;
};

const ReviewCard: FC<Props> = ({ card }) => {
  const { image, logos, picture, job, company, name, text } = card;

  return (
    <Container>
      <BigImageContainer>
        <BigImage
          src={image}
          alt="decorative"
          width={267}
          height={303}
          loading="lazy"
        />
      </BigImageContainer>
      <InnerContainer>
        <LogosContainer>
          {logos.map((logo, id) => {
            return (
              <LogoImage
                src={logo.src}
                alt={`company logo ${id}`}
                width={logo.width}
                height={logo.height}
                loading="lazy"
                key={`${logo}${id}`}
              />
            )
          })}
        </LogosContainer>
        <PersonAndQuoteContainer>
          <PersonContainer>
            <PersonPicture
              src={picture}
              alt="person picture"
              width={91}
              height={91}
              loading="lazy"
            />
            <PersonDescriptionContainer>
              <Job>
                {job}
              </Job>
              <Company>
                {company}
              </Company>
              <Name>
                {name}
              </Name>
            </PersonDescriptionContainer>
          </PersonContainer>
          <QuoteContainer>
            <InnerQuoteContainer>
              <LeftQuotesContainer>
                <Quotes />
              </LeftQuotesContainer>
              <TextContainer>
                {text.map((paragraph:string, id: number) => {
                  return (
                    <Text key={`${paragraph}${id}`}>
                      {paragraph}
                    </Text>
                  )
                })}
              </TextContainer>
              <RightQuotesContainer>
                <Quotes />
              </RightQuotesContainer>
            </InnerQuoteContainer>
          </QuoteContainer>
        </PersonAndQuoteContainer>
      </InnerContainer>
    </Container>
  );
};

export default ReviewCard;
