import { FC, useContext, useEffect, useState } from "react";
import { PricingPlansContext } from "context";
import { StyledSlider } from './styledComponents';

interface IProps {
  sliderData: {
    plan: "scale" | "startup" | "unlimited";
    baseUrl: string;
    planArray: {
      price: string;
      link: string;
      offerPrice?: string;
      offerLink?: string;
    }[];
    sliderMarks: {
      value: number;
      label: string;
    }[];
    sliderValues: {
      step: number;
      min: number;
      max: number;
      defaultValue: number;
    };
  };
}

const PlanSlider: FC<IProps> = ({sliderData}) => {
  const [labelSelector, setLabelSelector] = useState(4);
  const [subplan, setSubplan] = useState(0);
  const { plan, baseUrl, planArray, sliderMarks, sliderValues } = sliderData;
  const { step, min, max, defaultValue } = sliderValues;
  const {
    setScalePlanPrice,
    setScalePlanLink,
    setOfferScalePlanPrice,
    setOfferScalePlanLink,
    setStartupPlanPrice,
    setStartupPlanLink,
    setOfferStartupPlanPrice,
    setOfferUnlimitedPlanLink,
    setUnlimitedPlanPrice,
    setUnlimitedPlanLink,
    setOfferUnlimitedPlanPrice,
    setOfferStartupPlanLink,
    countrySelected,
  } = useContext(PricingPlansContext);

  useEffect(() => {
    if (plan === "scale") {
      setScalePlanPrice(`${planArray[subplan].price}`);
      setScalePlanLink(`${baseUrl}${planArray[subplan].link}`);
      setOfferScalePlanPrice(`${baseUrl}${planArray[subplan].offerPrice}`)
      setOfferScalePlanLink(`${baseUrl}${planArray[subplan].offerLink}`)
      return;
    };
    if (plan === "startup") {
      setStartupPlanPrice(`${planArray[subplan].price}`);
      setStartupPlanLink(`${baseUrl}${planArray[subplan].link}`);
      setOfferStartupPlanPrice(`${planArray[subplan].offerPrice}`)
      setOfferStartupPlanLink(`${baseUrl}${planArray[subplan].offerLink}`)
      return;
    };
    setUnlimitedPlanPrice(`${planArray[subplan].price}`);
    setUnlimitedPlanLink(`${baseUrl}${planArray[subplan].link}`);
    setOfferUnlimitedPlanPrice(`${planArray[subplan].offerPrice}`)
    setOfferUnlimitedPlanLink(`${baseUrl}${planArray[subplan].offerLink}`)
  }, [subplan, countrySelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSliderChange = (e:Event) => {
    const value:number = Number((e.target as HTMLInputElement).value);
    const labelToSelect = ( value / step) * 2 + 2;
    if (labelToSelect === labelSelector) return;
    setLabelSelector(labelToSelect);
    setSubplan(value / step - 1);
  };

  return (
    <StyledSlider
      size="medium"
      aria-label="Greetings amount"
      valueLabelDisplay="auto"
      step={step}
      min={min}
      max={max}
      defaultValue={defaultValue}
      marks={sliderMarks}
      onChange={handleSliderChange}
      sx={{
        [`& span:nth-of-type(${labelSelector})`]: {
          color: "rgba(0, 0, 0, 0.87) !important",
          fontSize: "16px",
          fontWeight: 600,
        }
      }}
    />
  );
};

export default PlanSlider;
