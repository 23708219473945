import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { S3_CLIENT_URL } from "config";
import { Container } from "../components";
import { Box, useTheme, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { CustomImagesContext } from "context";
import senderImage from "./sender.png";
import receiverImage from "./receiver.png";
import CustomSelection from "./CustomSelection"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    maxWidth: "500px",
  },
  half: {
    height: "50%",
    overflow: "hidden",
    position: "relative",
  },
  imageContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxHeight: "100%",
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  customImage: {
    width: "100%", 
    maxHeight: "none",
    display: "flex"
  },
  sender: {
    alignItems: "flex-end"
  },
  receiver: {
    alignItems: "flex-start"
  },
  button: {
    position: "absolute",
    top: "80%",
    right: "0%",
    transform: "translate(5%, -50%)",
  },
}));

export default function Dispenser() {
  //NOTE this encryptedClientID is a hashed client ID using this method -> https://github.com/Storydots/storydots-back/blob/38a8c972d5c1ac7fa2c28a5bcfd92f881dadeb48/TS/layers/nodejs/Utils/hashing/index.ts#L8
  const history = useHistory();
  const { t } = useTranslation("TagDispenser");
  const theme: any = useTheme();
  const { encryptedClientID } = useParams<{ encryptedClientID: string }>();
  const { clientID } = useContext(CustomImagesContext);
  const classes = useStyles();

  const handleSenderButton = () => {
    history.push(`/dispenser/${encryptedClientID}`);
  };

  const handleReceiverButton = () => {
    history.push(`/dispenser/${encryptedClientID}/code-association`);
  };

  return (
    <Container theme={theme}>
      <Stack className={classes.root}>
        <Box className={classes.half}>
          <CustomSelection
            customImage={`${S3_CLIENT_URL}/${clientID}/roleSender`}
            defaultImage={senderImage}
            classes={classes}
            handleButton={handleSenderButton}
            customLink={`/dispenser/${encryptedClientID}`}
            buttonText={t("RoleSelection.senderButton")}
            imageAlt={"sender image"}
          />
        </Box>
        <Box className={classes.half}>
          <CustomSelection
            customImage={`${S3_CLIENT_URL}/${clientID}/roleReceiver`}
            defaultImage={receiverImage}
            classes={classes}
            handleButton={handleReceiverButton}
            customLink={`/dispenser/${encryptedClientID}/code-association`}
            buttonText={t("RoleSelection.receiverButton")}
            imageAlt={"receiver image"}
          />
        </Box>
      </Stack>
    </Container>
  );
}
