export enum STORETYPE {
    CLOTHING = "clothing",
    CLOTHING_ACCESSORIES = "clothing_accessories",
    GASTRONOMY = "gastronomy",
    HOME_GARDEN = "home_garden",
    JEWELRY = "jewelry",
    HEALTH_BEAUTY = "health_beauty",
    SPORTS = "sports",
    GIFTS = "gifts",
    TOYS = "toys",
    ELECTRONICS_IT = "electronics_it",
    OTHERS = "others",
  }