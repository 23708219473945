import { useContext } from 'react';
import { PricingPlansContext } from 'context';
import { useTranslation } from 'react-i18next'
import { countriesData } from 'helpers/pricingPlansData';

const useSelectCountry = () => {
  const { i18n } = useTranslation();
  const {
    setScalePlanPrice,
    setScalePlanLink,
    setStartupPlanPrice,
    setStartupPlanLink,
    setUnlimitedPlanPrice,
    setUnlimitedPlanLink,
    setCountrySelected,
  } = useContext(PricingPlansContext);
  
  const selectCountry = (country:string) => {
    const { scale, startup, unlimited } = countriesData[country as keyof typeof countriesData];
    setScalePlanPrice(`${scale.planArray[0].price}`);
    setScalePlanLink(`${scale.baseUrl}${scale.planArray[0].link}`);
    setStartupPlanPrice(`${startup.planArray[0].price}`);
    setStartupPlanLink(`${startup.baseUrl}${startup.planArray[0].link}`);
    setUnlimitedPlanPrice(`${unlimited.planArray[0].price}`);
    setUnlimitedPlanLink(`${unlimited.baseUrl}${unlimited.planArray[0].link}`);
    setCountrySelected(country);
    i18n.changeLanguage(country === "br" ? "pt" : "es");
    window.history.replaceState(null, "", `?country=${country}`);
  }

  return selectCountry;
};

export default useSelectCountry;
