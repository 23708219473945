import { styled } from "@mui/system";
import { Box, Button } from "@mui/material";

const switchWidth = 360;
const leftTransform = "translateX(-5px)";
const rightTransform = `translateX(calc(100% - 10px))`;

export const Container = styled(Box)(() => ({
  position: "relative",
  width: `${switchWidth}px`,
  maxWidth: "85vw",
  height: "60px",
  margin: "0px auto 26px auto",
  display: "flex",
  alignItems: "center",
  background: "rgba(255, 255, 255, 1)",
  borderRadius: "40px",
  overflow: "hidden",
  "@media (max-width: 770px)": {
    height: "48px",
  },
}));

export const Background = styled(Box)(({ theme }) => ({
  position: "absolute",
  inset: "0px",
  width: "100%",
  height: "100%",
  background: theme.palette.primary.main,
  opacity: "0.2",
}));

interface Props {
  leftSelected: boolean;
};

export const Thumb = styled(
  Box,
  { shouldForwardProp: (prop) => !["leftSelected"].includes(prop as string) }
)<Props>(
  ({ theme, leftSelected }) => ({
    position: "absolute",
    top: "0px",
    left: "0px",
    width: `calc(50% + 5px)`,
    height: "100%",
    background: theme.palette.primary.main,
    borderRadius: "40px",
    transform: `${leftSelected ? leftTransform : rightTransform}`,
    transition: "0.2s all",
  })
);

export const LeftText = styled(
  Button,
  { shouldForwardProp: (prop) => !["leftSelected"].includes(prop as string) }
)<Props>(
  ({ theme, leftSelected }) => ({
    zIndex: 3,
    width: "50%",
    height: "100%",
    color: `${leftSelected ? theme.palette.primary.contrastText : theme.palette.primary.dark}`,
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "20px",
    textTransform: "unset",
    borderRadius: "40px !important",
    transition: "all 0.2s",
    overflow: "hidden",
    "&:hover": {
      background: "transparent",
      fontSize: "21px",
    },
    "@media (max-width: 770px)": {
      fontSize: "18px",
      "&:hover": {
        fontSize: "19px",
      },
    }
  })
);

export const RightText = styled(
  LeftText,
  { shouldForwardProp: (prop) => !["leftSelected"].includes(prop as string) }
)<Props>(
  ({ theme, leftSelected }) => ({
    color: `${leftSelected ? theme.palette.primary.dark : theme.palette.primary.contrastText}`,
  })
);
