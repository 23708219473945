import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
`;

export const SupportTitle = styled(Typography)`
  max-width: 80%;
  margin: 60px auto 50px auto;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
  @media (max-width: 775px) {
    font-size: 26px;
    line-height: 32px;
    margin: 40px auto 30px auto;
  }
`;

export const CompaniesContainer = styled(Box)`
  width: 1100px;
  max-width: 95%;
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 775px) {
    padding-bottom: 40px;
  }
`;
