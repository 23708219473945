import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const GradientContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "100vw",
	height: "160px",
	marginTop: "140px",
  paddingLeft: "120px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  background: `linear-gradient(112.52deg, ${theme.palette.primary.main} 32.84%, ${theme.palette.secondary.dark})`,
  "@media (max-width: 900px)": {
    paddingLeft: "0px",
    justifyContent: "center",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  maxWidth: "90%",
  margin: "0px",
  color: theme.palette.primary.contrastText,
  textAlign: "left",
  fontSize: "42px",
  fontWeight: 700,
  lineHeight: "60px",
  "@media (max-width: 900px)": {
    textAlign: "center",
  },
  "@media (max-width: 770px)": {
    fontSize: "calc(42px + (100vw - 770px) * 0.035)",
    lineHeight: "calc(60px + (100vw - 770px) * 0.055)",
  },
}));
