import { makeStyles } from "@mui/styles";

const verticalMarginTop = "10px";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "70%",
    margin: "70px auto 30px auto",
  },
  SDxTN: {
    height: "unset",
    marginTop: verticalMarginTop,
  },
  SDAnchor: {
    position: "absolute",
    top: verticalMarginTop,
    right: "0px",
    width: "100%",
    height: "35%",
  },
  TNAnchor: {
    position: "absolute",
    top: "60%",
    left: "0px",
    width: "100%",
    height: "35%",
  }
}));

export default useStyles;
