import { styled } from "@mui/system";
import { Box, Button, Container, Typography } from "@mui/material";

export const ModalContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: "24px 48px 24px 48px",
    transform: 'translate(-50%, -50%)',
    maxWidth: "720px",
    backgroundColor: '#FFFFFF',
    borderRadius: "24px",
    outline: "none",
    boxShadow: "24",
    p: "4",
    "@media (max-width: 1200px)": {
        width: "90%",
        padding: "24px 10px 24px 10px",
      },
    "@media (max-width: 850px)": {
        width: "90%",
        padding: "24px 10px 24px 10px",
      },
}));

export const ImgContainer = styled(Container)(() => ({
    display: "flex",
    justifyContent: "center",
}))

export const InvalidGiftImg = styled("img")(() => ({
    width: "55px"
}))

export const PlanButton = styled(Button)(() => ({
    backgroundColor: "#006BC8",
    fontWeight: 600,
    fontSize: "18px",
    padding: "8px 22px 8px 22px",
    borderRadius: "12px",
    letterSpacing: "0.46px",
    marginTop: "25px",
    color: "white",
    maxWidth: "fit-content",
    boxShadow: "0px 1px 5px 0px #0000001F",
    textTransform: "none",
    '&:hover': {
        backgroundColor: '#3e9df0',
      },
}))

export const CloseButton = styled(Button)(() => ({
    position: "absolute",
    top: "7px",
    right: "0",
    color: "#757575",
}))

export const Title = styled(Typography)(() => ({
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "center"
}))

export const SubTitle = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    "@media (max-width: 850px)": {
        fontSize: "14px",

      },
}))

export const ModalContent = styled("div")(() => ({
    paddingTop: "20px",
    display: "grid",
    justifyItems: "center",
}))
