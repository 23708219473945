const VTEXAcceleratorProgram = () => {
  return (
    <svg width="239" height="33" viewBox="0 0 239 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.8528 13.3508H48.3473V21.9478C48.3473 22.1087 48.2094 22.2466 48.0485 22.2466H46.1176C45.9567 22.2466 45.8188 22.1087 45.8188 21.9478V13.3508H43.2903C43.2213 13.3508 43.1294 13.3278 43.0834 13.2819C43.0374 13.2359 42.9914 13.1669 42.9914 13.075V11.5579C42.9914 11.4889 43.0144 11.397 43.0834 11.351C43.1294 11.305 43.2213 11.2591 43.2903 11.2821H50.8528C51.0137 11.2821 51.1516 11.397 51.1516 11.5579V13.075C51.1287 13.2359 51.0137 13.3738 50.8528 13.3508Z" fill="currentColor"/>
      <path d="M58.8981 22.2006C57.9097 22.3385 56.8983 22.4075 55.9098 22.3845C54.002 22.3845 52.3239 21.9018 52.3239 19.2124V14.3162C52.3239 11.6268 54.0249 11.1671 55.9328 11.1671C56.9212 11.1441 57.9097 11.2131 58.8981 11.351C59.105 11.374 59.1969 11.4659 59.1969 11.6498V13.029C59.1969 13.1899 59.059 13.3278 58.8981 13.3278H55.7949C55.1053 13.3278 54.8525 13.5577 54.8525 14.3392V15.6724H58.7831C58.944 15.6724 59.082 15.8104 59.082 15.9713V17.3734C59.082 17.5343 58.944 17.6723 58.7831 17.6723H54.8525V19.2353C54.8525 19.9939 55.1053 20.2468 55.7949 20.2468H58.8981C59.059 20.2468 59.1969 20.3847 59.1969 20.5456V21.9248C59.1969 22.0627 59.105 22.1546 58.8981 22.2006Z" fill="currentColor"/>
      <path d="M70.0235 22.2465H67.6789C67.518 22.2695 67.3571 22.1776 67.2881 22.0167L65.2423 18.7986L63.4034 21.9477C63.3115 22.1316 63.1965 22.2465 63.0356 22.2465H60.8749C60.8289 22.2465 60.76 22.2465 60.714 22.2006C60.668 22.1546 60.6451 22.1086 60.6451 22.0397C60.6451 21.9937 60.668 21.9707 60.668 21.9477L63.8632 16.6149L60.6221 11.5578C60.5991 11.5348 60.5991 11.4889 60.5991 11.4659C60.5991 11.3509 60.714 11.259 60.8289 11.282H63.1966C63.3575 11.282 63.4724 11.4199 63.5643 11.5578L65.4492 14.5461L67.2881 11.5578C67.3571 11.3969 67.495 11.305 67.6559 11.282H69.8396C69.9546 11.282 70.0695 11.3509 70.0695 11.4659C70.0695 11.4889 70.0465 11.5348 70.0465 11.5578L66.8284 16.6608L70.1844 21.9477C70.2074 21.9937 70.2304 22.0397 70.2304 22.0856C70.2304 22.2006 70.1385 22.2465 70.0235 22.2465Z" fill="currentColor"/>
      <path d="M39.4515 11.305C39.3366 11.305 39.2446 11.3739 39.2217 11.4889L37.0839 19.4192C37.0609 19.5801 37.015 19.6491 36.877 19.6491C36.7391 19.6491 36.6931 19.5801 36.6702 19.4192L34.5324 11.4889C34.5094 11.3739 34.4175 11.305 34.3026 11.305H32.1878C32.1188 11.305 32.0499 11.328 32.0039 11.3969C31.9579 11.4429 31.9349 11.5348 31.9579 11.6038C31.9579 11.6038 34.5784 20.7064 34.6014 20.7984C34.9462 21.8787 35.7967 22.4074 36.877 22.4074C37.9114 22.4534 38.8539 21.7868 39.1527 20.7984C39.1987 20.6834 41.7502 11.6038 41.7502 11.6038C41.7732 11.5348 41.7502 11.4659 41.7042 11.4199C41.6582 11.3739 41.5893 11.328 41.5203 11.328L39.4515 11.305Z" fill="currentColor"/>
      <path d="M27.4986 3.9264H5.47752C4.67299 3.9264 3.93742 4.36314 3.54665 5.02975C3.15588 5.69636 3.10991 6.56984 3.47769 7.25944L5.68439 11.443H1.70773C1.20203 11.443 0.719316 11.6958 0.443478 12.1326C0.167641 12.5693 0.167641 13.121 0.397505 13.5577L7.47734 26.9359C7.73019 27.4186 8.23589 27.7174 8.76458 27.7174C9.29327 27.7174 9.79897 27.4186 10.0518 26.9359L11.9827 23.304L14.3963 27.8783C14.787 28.6139 15.5686 29.0736 16.3961 29.0736C17.2236 29.0736 18.0051 28.6139 18.3959 27.8783L29.4294 7.14451C29.7972 6.45491 29.7742 5.6274 29.3605 4.98378C28.9927 4.31717 28.2571 3.9264 27.4986 3.9264ZM17.6603 12.7072L12.8332 21.7409C12.6723 22.0627 12.3505 22.2466 11.9827 22.2466C11.6149 22.2466 11.2931 22.0397 11.1322 21.7409L6.37399 12.7762C6.23607 12.5004 6.23607 12.1785 6.39697 11.9027C6.55788 11.6269 6.8567 11.466 7.15553 11.466H16.9018C17.2006 11.466 17.4765 11.6039 17.6144 11.8567C17.7983 12.1326 17.7983 12.4314 17.6603 12.7072Z" fill="currentColor"/>
      <path d="M80.3904 26V8.72H81.7704V26H80.3904Z" fill="currentColor"/>
      <g clipPath="url(#clip0_3577_30250)">
      <path d="M99.5741 17.2656L97.8319 12.1953L95.9804 17.2656H99.5741ZM97.0194 10.4922H98.7772L102.941 21.9688H101.238L100.074 18.5312H95.535L94.2929 21.9688H92.6991L97.0194 10.4922Z" fill="currentColor"/>
      <path d="M107.394 13.3594C108.337 13.3594 109.103 13.5885 109.691 14.0469C110.285 14.5052 110.642 15.2943 110.762 16.4141H109.394C109.311 15.8984 109.121 15.4714 108.824 15.1328C108.527 14.7891 108.051 14.6172 107.394 14.6172C106.499 14.6172 105.858 15.0547 105.473 15.9297C105.223 16.4974 105.098 17.1979 105.098 18.0312C105.098 18.8698 105.275 19.5755 105.629 20.1484C105.983 20.7214 106.54 21.0078 107.301 21.0078C107.884 21.0078 108.345 20.8307 108.683 20.4766C109.027 20.1172 109.264 19.6276 109.394 19.0078H110.762C110.605 20.1172 110.215 20.9297 109.59 21.4453C108.965 21.9557 108.165 22.2109 107.191 22.2109C106.098 22.2109 105.225 21.8125 104.574 21.0156C103.923 20.2135 103.598 19.2135 103.598 18.0156C103.598 16.5469 103.954 15.4036 104.668 14.5859C105.381 13.7682 106.29 13.3594 107.394 13.3594Z" fill="currentColor"/>
      <path d="M115.394 13.3594C116.337 13.3594 117.103 13.5885 117.691 14.0469C118.285 14.5052 118.642 15.2943 118.762 16.4141H117.394C117.311 15.8984 117.121 15.4714 116.824 15.1328C116.527 14.7891 116.051 14.6172 115.394 14.6172C114.499 14.6172 113.858 15.0547 113.473 15.9297C113.223 16.4974 113.098 17.1979 113.098 18.0312C113.098 18.8698 113.275 19.5755 113.629 20.1484C113.983 20.7214 114.54 21.0078 115.301 21.0078C115.884 21.0078 116.345 20.8307 116.683 20.4766C117.027 20.1172 117.264 19.6276 117.394 19.0078H118.762C118.605 20.1172 118.215 20.9297 117.59 21.4453C116.965 21.9557 116.165 22.2109 115.191 22.2109C114.098 22.2109 113.225 21.8125 112.574 21.0156C111.923 20.2135 111.598 19.2135 111.598 18.0156C111.598 16.5469 111.954 15.4036 112.668 14.5859C113.381 13.7682 114.29 13.3594 115.394 13.3594Z" fill="currentColor"/>
      <path d="M123.652 13.4141C124.246 13.4141 124.822 13.5547 125.379 13.8359C125.936 14.112 126.361 14.4714 126.652 14.9141C126.933 15.3359 127.121 15.8281 127.215 16.3906C127.298 16.776 127.34 17.3906 127.34 18.2344H121.207C121.233 19.0833 121.433 19.7656 121.808 20.2812C122.183 20.7917 122.764 21.0469 123.551 21.0469C124.285 21.0469 124.871 20.8047 125.308 20.3203C125.558 20.0391 125.736 19.7135 125.84 19.3438H127.223C127.186 19.651 127.064 19.9948 126.855 20.375C126.652 20.75 126.423 21.0573 126.168 21.2969C125.741 21.7135 125.212 21.9948 124.582 22.1406C124.243 22.224 123.861 22.2656 123.433 22.2656C122.392 22.2656 121.509 21.888 120.785 21.1328C120.061 20.3724 119.699 19.3099 119.699 17.9453C119.699 16.6016 120.064 15.5104 120.793 14.6719C121.522 13.8333 122.475 13.4141 123.652 13.4141ZM125.894 17.1172C125.837 16.5078 125.704 16.0208 125.496 15.6562C125.111 14.9792 124.467 14.6406 123.566 14.6406C122.92 14.6406 122.379 14.875 121.941 15.3438C121.504 15.8073 121.272 16.3984 121.246 17.1172H125.894Z" fill="currentColor"/>
      <path d="M129.113 10.4922H130.519V21.9688H129.113V10.4922Z" fill="currentColor"/>
      <path d="M136.121 13.4141C136.715 13.4141 137.29 13.5547 137.848 13.8359C138.405 14.112 138.829 14.4714 139.121 14.9141C139.402 15.3359 139.59 15.8281 139.683 16.3906C139.767 16.776 139.808 17.3906 139.808 18.2344H133.676C133.702 19.0833 133.902 19.7656 134.277 20.2812C134.652 20.7917 135.233 21.0469 136.019 21.0469C136.754 21.0469 137.34 20.8047 137.777 20.3203C138.027 20.0391 138.204 19.7135 138.308 19.3438H139.691C139.655 19.651 139.532 19.9948 139.324 20.375C139.121 20.75 138.892 21.0573 138.637 21.2969C138.21 21.7135 137.681 21.9948 137.051 22.1406C136.712 22.224 136.329 22.2656 135.902 22.2656C134.861 22.2656 133.978 21.888 133.254 21.1328C132.53 20.3724 132.168 19.3099 132.168 17.9453C132.168 16.6016 132.532 15.5104 133.262 14.6719C133.991 13.8333 134.944 13.4141 136.121 13.4141ZM138.363 17.1172C138.306 16.5078 138.173 16.0208 137.965 15.6562C137.579 14.9792 136.936 14.6406 136.035 14.6406C135.389 14.6406 134.848 14.875 134.41 15.3438C133.973 15.8073 133.741 16.3984 133.715 17.1172H138.363Z" fill="currentColor"/>
      <path d="M141.582 13.6016H142.918V15.0469C143.027 14.7656 143.295 14.4245 143.723 14.0234C144.15 13.6172 144.642 13.4141 145.199 13.4141C145.225 13.4141 145.269 13.4167 145.332 13.4219C145.394 13.4271 145.501 13.4375 145.652 13.4531V14.9375C145.569 14.9219 145.491 14.9115 145.418 14.9062C145.35 14.901 145.275 14.8984 145.191 14.8984C144.483 14.8984 143.939 15.1276 143.558 15.5859C143.178 16.0391 142.988 16.5625 142.988 17.1562V21.9688H141.582V13.6016Z" fill="currentColor"/>
      <path d="M147.949 19.7422C147.949 20.1484 148.098 20.4688 148.394 20.7031C148.691 20.9375 149.043 21.0547 149.449 21.0547C149.944 21.0547 150.423 20.9401 150.887 20.7109C151.668 20.3307 152.058 19.7083 152.058 18.8438V17.7109C151.887 17.8203 151.665 17.9115 151.394 17.9844C151.124 18.0573 150.858 18.1094 150.598 18.1406L149.746 18.25C149.236 18.3177 148.853 18.4245 148.598 18.5703C148.165 18.8151 147.949 19.2057 147.949 19.7422ZM151.355 16.8984C151.678 16.8568 151.894 16.7214 152.004 16.4922C152.066 16.3672 152.098 16.1875 152.098 15.9531C152.098 15.474 151.926 15.1276 151.582 14.9141C151.243 14.6953 150.756 14.5859 150.121 14.5859C149.387 14.5859 148.866 14.7839 148.558 15.1797C148.387 15.3984 148.275 15.724 148.223 16.1562H146.91C146.936 15.125 147.269 14.4089 147.91 14.0078C148.556 13.6016 149.303 13.3984 150.152 13.3984C151.137 13.3984 151.936 13.5859 152.551 13.9609C153.16 14.3359 153.465 14.9193 153.465 15.7109V20.5312C153.465 20.6771 153.493 20.7943 153.551 20.8828C153.613 20.9714 153.741 21.0156 153.933 21.0156C153.996 21.0156 154.066 21.013 154.144 21.0078C154.223 20.9974 154.306 20.9844 154.394 20.9688V22.0078C154.176 22.0703 154.009 22.1094 153.894 22.125C153.78 22.1406 153.624 22.1484 153.426 22.1484C152.941 22.1484 152.59 21.9766 152.371 21.6328C152.256 21.4505 152.176 21.1927 152.129 20.8594C151.842 21.2344 151.431 21.5599 150.894 21.8359C150.358 22.112 149.767 22.25 149.121 22.25C148.345 22.25 147.71 22.0156 147.215 21.5469C146.725 21.0729 146.48 20.4818 146.48 19.7734C146.48 18.9974 146.723 18.3958 147.207 17.9688C147.691 17.5417 148.327 17.2786 149.113 17.1797L151.355 16.8984Z" fill="currentColor"/>
      <path d="M156.058 11.2656H157.48V13.6016H158.816V14.75H157.48V20.2109C157.48 20.5026 157.579 20.6979 157.777 20.7969C157.887 20.8542 158.069 20.8828 158.324 20.8828C158.392 20.8828 158.465 20.8828 158.543 20.8828C158.621 20.8776 158.712 20.8698 158.816 20.8594V21.9688C158.655 22.0156 158.486 22.0495 158.308 22.0703C158.137 22.0911 157.949 22.1016 157.746 22.1016C157.09 22.1016 156.644 21.9349 156.41 21.6016C156.176 21.263 156.058 20.8255 156.058 20.2891V14.75H154.926V13.6016H156.058V11.2656Z" fill="currentColor"/>
      <path d="M163.551 21.0625C164.483 21.0625 165.121 20.7109 165.465 20.0078C165.814 19.2995 165.988 18.513 165.988 17.6484C165.988 16.8672 165.863 16.2318 165.613 15.7422C165.217 14.9714 164.535 14.5859 163.566 14.5859C162.707 14.5859 162.082 14.9141 161.691 15.5703C161.301 16.2266 161.105 17.0182 161.105 17.9453C161.105 18.8359 161.301 19.5781 161.691 20.1719C162.082 20.7656 162.702 21.0625 163.551 21.0625ZM163.605 13.3594C164.683 13.3594 165.595 13.7188 166.34 14.4375C167.085 15.1562 167.457 16.2135 167.457 17.6094C167.457 18.9583 167.129 20.0729 166.473 20.9531C165.816 21.8333 164.798 22.2734 163.418 22.2734C162.267 22.2734 161.353 21.8854 160.676 21.1094C159.999 20.3281 159.66 19.2812 159.66 17.9688C159.66 16.5625 160.017 15.4427 160.73 14.6094C161.444 13.776 162.402 13.3594 163.605 13.3594Z" fill="currentColor"/>
      <path d="M169.176 13.6016H170.512V15.0469C170.621 14.7656 170.889 14.4245 171.316 14.0234C171.743 13.6172 172.236 13.4141 172.793 13.4141C172.819 13.4141 172.863 13.4167 172.926 13.4219C172.988 13.4271 173.095 13.4375 173.246 13.4531V14.9375C173.163 14.9219 173.085 14.9115 173.012 14.9062C172.944 14.901 172.868 14.8984 172.785 14.8984C172.077 14.8984 171.532 15.1276 171.152 15.5859C170.772 16.0391 170.582 16.5625 170.582 17.1562V21.9688H169.176V13.6016Z" fill="currentColor"/>
      <path d="M179.254 10.4922H184.418C185.439 10.4922 186.262 10.7812 186.887 11.3594C187.512 11.9323 187.824 12.7396 187.824 13.7812C187.824 14.6771 187.545 15.4583 186.988 16.125C186.431 16.7865 185.574 17.1172 184.418 17.1172H180.808V21.9688H179.254V10.4922ZM186.254 13.7891C186.254 12.9453 185.941 12.3724 185.316 12.0703C184.973 11.9089 184.501 11.8281 183.902 11.8281H180.808V15.8047H183.902C184.6 15.8047 185.165 15.6562 185.598 15.3594C186.035 15.0625 186.254 14.5391 186.254 13.7891Z" fill="currentColor"/>
      <path d="M189.629 13.6016H190.965V15.0469C191.074 14.7656 191.342 14.4245 191.769 14.0234C192.197 13.6172 192.689 13.4141 193.246 13.4141C193.272 13.4141 193.316 13.4167 193.379 13.4219C193.441 13.4271 193.548 13.4375 193.699 13.4531V14.9375C193.616 14.9219 193.538 14.9115 193.465 14.9062C193.397 14.901 193.322 14.8984 193.238 14.8984C192.53 14.8984 191.986 15.1276 191.605 15.5859C191.225 16.0391 191.035 16.5625 191.035 17.1562V21.9688H189.629V13.6016Z" fill="currentColor"/>
      <path d="M198.238 21.0625C199.17 21.0625 199.808 20.7109 200.152 20.0078C200.501 19.2995 200.676 18.513 200.676 17.6484C200.676 16.8672 200.551 16.2318 200.301 15.7422C199.905 14.9714 199.223 14.5859 198.254 14.5859C197.394 14.5859 196.769 14.9141 196.379 15.5703C195.988 16.2266 195.793 17.0182 195.793 17.9453C195.793 18.8359 195.988 19.5781 196.379 20.1719C196.769 20.7656 197.389 21.0625 198.238 21.0625ZM198.293 13.3594C199.371 13.3594 200.282 13.7188 201.027 14.4375C201.772 15.1562 202.144 16.2135 202.144 17.6094C202.144 18.9583 201.816 20.0729 201.16 20.9531C200.504 21.8333 199.486 22.2734 198.105 22.2734C196.954 22.2734 196.04 21.8854 195.363 21.1094C194.686 20.3281 194.348 19.2812 194.348 17.9688C194.348 16.5625 194.704 15.4427 195.418 14.6094C196.131 13.776 197.09 13.3594 198.293 13.3594Z" fill="currentColor"/>
      <path d="M206.777 13.4531C207.433 13.4531 208.006 13.6146 208.496 13.9375C208.762 14.1198 209.032 14.3854 209.308 14.7344V13.6797H210.605V21.2891C210.605 22.3516 210.449 23.1901 210.137 23.8047C209.553 24.9401 208.452 25.5078 206.832 25.5078C205.931 25.5078 205.173 25.3047 204.558 24.8984C203.944 24.4974 203.6 23.8672 203.527 23.0078H204.957C205.025 23.3828 205.16 23.6719 205.363 23.875C205.681 24.1875 206.181 24.3438 206.863 24.3438C207.941 24.3438 208.647 23.9635 208.98 23.2031C209.178 22.7552 209.269 21.9557 209.254 20.8047C208.973 21.2318 208.634 21.5495 208.238 21.7578C207.842 21.9661 207.319 22.0703 206.668 22.0703C205.762 22.0703 204.967 21.75 204.285 21.1094C203.608 20.4635 203.269 19.3984 203.269 17.9141C203.269 16.513 203.611 15.4193 204.293 14.6328C204.98 13.8464 205.808 13.4531 206.777 13.4531ZM209.308 17.75C209.308 16.7135 209.095 15.9453 208.668 15.4453C208.241 14.9453 207.697 14.6953 207.035 14.6953C206.045 14.6953 205.368 15.1589 205.004 16.0859C204.811 16.5807 204.715 17.2292 204.715 18.0312C204.715 18.974 204.905 19.6927 205.285 20.1875C205.67 20.6771 206.186 20.9219 206.832 20.9219C207.842 20.9219 208.553 20.4661 208.965 19.5547C209.194 19.0391 209.308 18.4375 209.308 17.75Z" fill="currentColor"/>
      <path d="M212.769 13.6016H214.105V15.0469C214.215 14.7656 214.483 14.4245 214.91 14.0234C215.337 13.6172 215.829 13.4141 216.387 13.4141C216.413 13.4141 216.457 13.4167 216.519 13.4219C216.582 13.4271 216.689 13.4375 216.84 13.4531V14.9375C216.756 14.9219 216.678 14.9115 216.605 14.9062C216.538 14.901 216.462 14.8984 216.379 14.8984C215.67 14.8984 215.126 15.1276 214.746 15.5859C214.366 16.0391 214.176 16.5625 214.176 17.1562V21.9688H212.769V13.6016Z" fill="currentColor"/>
      <path d="M219.137 19.7422C219.137 20.1484 219.285 20.4688 219.582 20.7031C219.879 20.9375 220.23 21.0547 220.637 21.0547C221.131 21.0547 221.611 20.9401 222.074 20.7109C222.855 20.3307 223.246 19.7083 223.246 18.8438V17.7109C223.074 17.8203 222.853 17.9115 222.582 17.9844C222.311 18.0573 222.045 18.1094 221.785 18.1406L220.933 18.25C220.423 18.3177 220.04 18.4245 219.785 18.5703C219.353 18.8151 219.137 19.2057 219.137 19.7422ZM222.543 16.8984C222.866 16.8568 223.082 16.7214 223.191 16.4922C223.254 16.3672 223.285 16.1875 223.285 15.9531C223.285 15.474 223.113 15.1276 222.769 14.9141C222.431 14.6953 221.944 14.5859 221.308 14.5859C220.574 14.5859 220.053 14.7839 219.746 15.1797C219.574 15.3984 219.462 15.724 219.41 16.1562H218.098C218.124 15.125 218.457 14.4089 219.098 14.0078C219.743 13.6016 220.491 13.3984 221.34 13.3984C222.324 13.3984 223.124 13.5859 223.738 13.9609C224.348 14.3359 224.652 14.9193 224.652 15.7109V20.5312C224.652 20.6771 224.681 20.7943 224.738 20.8828C224.801 20.9714 224.928 21.0156 225.121 21.0156C225.183 21.0156 225.254 21.013 225.332 21.0078C225.41 20.9974 225.493 20.9844 225.582 20.9688V22.0078C225.363 22.0703 225.197 22.1094 225.082 22.125C224.967 22.1406 224.811 22.1484 224.613 22.1484C224.129 22.1484 223.777 21.9766 223.558 21.6328C223.444 21.4505 223.363 21.1927 223.316 20.8594C223.03 21.2344 222.618 21.5599 222.082 21.8359C221.545 22.112 220.954 22.25 220.308 22.25C219.532 22.25 218.897 22.0156 218.402 21.5469C217.913 21.0729 217.668 20.4818 217.668 19.7734C217.668 18.9974 217.91 18.3958 218.394 17.9688C218.879 17.5417 219.514 17.2786 220.301 17.1797L222.543 16.8984Z" fill="currentColor"/>
      <path d="M226.965 13.6016H228.355V14.7891C228.689 14.3776 228.991 14.0781 229.262 13.8906C229.725 13.5729 230.251 13.4141 230.84 13.4141C231.506 13.4141 232.043 13.5781 232.449 13.9062C232.678 14.0938 232.887 14.3698 233.074 14.7344C233.387 14.2865 233.754 13.9557 234.176 13.7422C234.598 13.5234 235.072 13.4141 235.598 13.4141C236.723 13.4141 237.488 13.8203 237.894 14.6328C238.113 15.0703 238.223 15.6589 238.223 16.3984V21.9688H236.762V16.1562C236.762 15.599 236.621 15.2161 236.34 15.0078C236.064 14.7995 235.725 14.6953 235.324 14.6953C234.772 14.6953 234.295 14.8802 233.894 15.25C233.499 15.6198 233.301 16.237 233.301 17.1016V21.9688H231.871V16.5078C231.871 15.9401 231.803 15.526 231.668 15.2656C231.454 14.875 231.056 14.6797 230.473 14.6797C229.941 14.6797 229.457 14.8854 229.019 15.2969C228.587 15.7083 228.371 16.4531 228.371 17.5312V21.9688H226.965V13.6016Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="clip0_3577_30250">
      <rect width="146" height="18" fill="currentColor" transform="translate(92.2304 7.5)"/>
      </clipPath>
      </defs>
    </svg>
  );
};

export default VTEXAcceleratorProgram;


