import { useContext, useRef, useState } from "react";
import { GeneralContext } from 'context';
import { useTranslation } from "react-i18next";
import useHandleSwapAsset from "hooks/useHandleSwapAsset";
import { defineUpperCaseLanguage } from "helpers";
import heroFiles from "img/mainPage/hero";

const useGetVideo = () => {
  const { viewportWidth } = useContext(GeneralContext);
  const widthBreakpoint = useRef(770);
  const { i18n } = useTranslation();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const currentLocale = i18n.language;
  const [videoSrc, setVideoSrc] = useState<string>(heroFiles[`heroVideo${
    viewportWidth < widthBreakpoint.current
      ? "Mobile"
      : ""
  }${defineUpperCaseLanguage(currentLocale)}` as keyof typeof heroFiles]
  );
  const [imageSrc, setImageSrc] = useState<string>(heroFiles[`heroImg${
    viewportWidth < widthBreakpoint.current
      ? "MobileSafari"
      : "DesktopSafari"
  }${defineUpperCaseLanguage(currentLocale)}` as keyof typeof heroFiles])

  const setSmallFunction = () => {
    if (isSafari) {
      setVideoSrc(heroFiles[`heroVideoMobileSafari${defineUpperCaseLanguage(currentLocale)}`]);
      setImageSrc(heroFiles[`heroImgMobileSafari${defineUpperCaseLanguage(currentLocale)}`]);
    } else {
      setVideoSrc(heroFiles[`heroVideoMobile${defineUpperCaseLanguage(currentLocale)}`]);
      setImageSrc(heroFiles[`heroImgMobileSafari${defineUpperCaseLanguage(currentLocale)}`]);
    }
  }

  const setLargeFunction = () => {
    if (isSafari) {
      setVideoSrc(heroFiles[`heroVideoSafari${defineUpperCaseLanguage(currentLocale)}`]);
      setImageSrc(heroFiles[`heroImgDesktopSafari${defineUpperCaseLanguage(currentLocale)}`]);
    } else {
      setVideoSrc(heroFiles[`heroVideo${defineUpperCaseLanguage(currentLocale)}`]);
      setImageSrc(heroFiles[`heroImgDesktopSafari${defineUpperCaseLanguage(currentLocale)}`]);
    }
  }

  useHandleSwapAsset({
    widthBreakpoint: widthBreakpoint.current,
    setSmallFunction,
    setLargeFunction,
  });

  return [videoSrc, imageSrc];
}

export default useGetVideo;
