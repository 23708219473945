import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "240px",
  display: "flex",
  flexWrap: "wrap",
  background: theme.palette.primary.main,
  "@media (max-width: 1200px)": {
    height: "fit-content",
    padding: "30px",
  },
  "@media (max-width: 672px)": {
    padding: "60px 0px",
    background: "linear-gradient(rgba(44, 36, 204, 1), rgba(44, 23, 131, 1))",
  },
}));

export const FragmentContainer = styled(Box)(({ theme }) => ({
  width: "25%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
  "@media (max-width: 1200px)": {
    height: "90px",
  },
  "@media (max-width: 672px)": {
    height: "60px",
  },
}));

export const LogoAndTermsContainer = styled(FragmentContainer)(() => ({
  "@media (max-width: 1200px)": {
    width: "100%",
  },
}));

export const StyledLogoLink = styled(
  Link,
  { shouldForwardProp: (prop) => !["isMain"].includes(prop as string)}
)<{ isMain: boolean; }>(
  ({ isMain }) => ({
    height: "40px",
    cursor: `${isMain ? "default" : "pointer"}`,
  })
);

export const StyledText = styled(Typography)(() => ({
  textAlign: "center",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "19.12px",
}));

export const TermsAndConditions = styled(StyledText)(({ theme }) => ({
  margin: "4px 0px 15px 0px",
  paddingLeft: "4px",
  width: "200px",
  textAlign: "left",
  "&:hover": {
    color: theme.palette.secondary.main,
  },
  "@media (max-width: 1200px)": {
    margin: "4px 0px 0px 0px",
    padding: "0px",
    textAlign: "center",
  },
}));

export const GoogleAndAWSContainer = styled(FragmentContainer)(() => ({
  paddingRight: "24px",
  alignItems: "flex-end",
  "@media (max-width: 1200px)": {
    padding: "0px",
    width: "50%",
    alignItems: "center",
  },
  "@media (max-width: 672px)": {
    width: "100%",
    marginBottom: "10px",
  },
}));

export const SupportedByText = styled(StyledText)(({ theme }) => ({
  width: "275px",
  margin: "0px 0px 2px 0px",
  "@media (max-width: 672px)": {
    color: theme.palette.secondary.main,
  },
}));

export const VTEXContainer = styled(FragmentContainer)(() => ({
  paddingLeft: "24px",
  alignItems: "flex-start",
  "@media (max-width: 1200px)": {
    padding: "0px",
    width: "50%",
    alignItems: "center",
  },
  "@media (max-width: 672px)": {
    width: "100%",
    marginBottom: "40px",
  },
}));

export const WinnerText = styled(StyledText)(({ theme }) => ({
  width: "239px",
  margin: "0px 0px 1px 0px",
  "@media (max-width: 672px)": {
    color: theme.palette.secondary.main,
  },
}));

export const SocialMediaContainer = styled(FragmentContainer)(() => ({
  "@media (max-width: 1200px)": {
    width: "100%",
  },
  "@media (max-width: 672px)": {
    marginBottom: "40px",
  },
}));
