import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "100%",
  padding: "100px 0px 69px 0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: "8px",
  color: theme.palette.secondary.main,
  textAlign: "center",
  fontSize: "48px",
  fontWeight: 800,
  lineHeight: "57.6px",
  letterSpacing: "-0.5px",
  "@media (max-width: 500px)": {
    fontSize: "32px",
    lineHeight: "38.4px",
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  margin: "0px auto 64px auto",
  color: theme.palette.primary.contrastText,
  textAlign: "center",
  fontSize: "32px",
  fontWeight: 300,
  lineHeight: "38.4px",
  letterSpacing: "-0.5px",
  "@media (max-width: 550px)": {
    fontSize: "24px",
    lineHeight: "28.8px",
  },
  "@media (max-width: 350px)": {
    fontSize: "20px",
    lineHeight: "24px",
  },
}));

export const ButtonDescription = styled(Subtitle)(() => ({
  margin: "0px auto 32px auto",
}));
