import { styled } from "@mui/system";
import { Button } from "@mui/material";

export const StyledAnchor = styled("a")`
  width: 100%;
  margin: auto 0 0 0;
  color: white;
  text-decoration: none;
`;

export const SubscribeButton = styled(Button)`
  width: 100%;
  height: 50px;
  background: rgba(98, 66, 232, 1);
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 1px;
  text-transform: unset;
  :hover {
    background: rgba(98, 66, 232, 0.8);
  }
`;
